/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleDto,
    RoleDtoFromJSON,
    RoleDtoFromJSONTyped,
    RoleDtoToJSON,
} from './RoleDto';

/**
 * 
 * @export
 * @interface SsoUserDto
 */
export interface SsoUserDto {
    /**
     * 
     * @type {number}
     * @memberof SsoUserDto
     */
    age?: number;
    /**
     * 
     * @type {string}
     * @memberof SsoUserDto
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoUserDto
     */
    classNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoUserDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoUserDto
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoUserDto
     */
    grade?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoUserDto
     */
    hashPassword?: string;
    /**
     * 
     * @type {RoleDto}
     * @memberof SsoUserDto
     */
    role?: RoleDto;
    /**
     * 
     * @type {number}
     * @memberof SsoUserDto
     */
    userId?: number;
}

export function SsoUserDtoFromJSON(json: any): SsoUserDto {
    return SsoUserDtoFromJSONTyped(json, false);
}

export function SsoUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SsoUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'age': !exists(json, 'age') ? undefined : json['age'],
        'className': !exists(json, 'className') ? undefined : json['className'],
        'classNumber': !exists(json, 'classNumber') ? undefined : json['classNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'hashPassword': !exists(json, 'hashPassword') ? undefined : json['hashPassword'],
        'role': !exists(json, 'role') ? undefined : RoleDtoFromJSON(json['role']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function SsoUserDtoToJSON(value?: SsoUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'age': value.age,
        'className': value.className,
        'classNumber': value.classNumber,
        'email': value.email,
        'gender': value.gender,
        'grade': value.grade,
        'hashPassword': value.hashPassword,
        'role': RoleDtoToJSON(value.role),
        'userId': value.userId,
    };
}

