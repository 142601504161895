import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "../../components/Button/Button";
import style from "./Complete.module.css";

export default function Complete() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [second, setSecond] = useState(10);

  const orderNo = useSelector((state: any) => state.checkoutSession.orderNo);

  useEffect(() => {
    timeout();
    if (second < 1) {
      navigate("/favourite");
      return;
    }
  }, [second]);

  const timeout = () => {
    // setTimeout(() => {
    //   setSecond((preState) => second - 1);
    // }, 1000);
  };
  return (
    <div className={style.shopingCartLayout}>
      <h1>交易失敗</h1>

      <div className={style.formContainer}>
        <h3 className={style.orderNo}>參考編號：{orderNo}</h3>
        <p>您的交易無法完成</p>
        <span>{second}秒後自動跳回我的書架</span>
      </div>
      <div className={style.buttonContainer}>
        <Button
          styleType={"bookIntroButton"}
          children={"前往我的書架"}
          callBack={() => navigate("/favourite")}
        />
      </div>
      <div className={style.buttonContainer1}>
        <Button
          styleType={"bookIntroButton"}
          children={"回到主頁"}
          callBack={() => navigate("/")}
        />
      </div>
    </div>
  );
}
