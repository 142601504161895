import React, { useEffect, useState } from "react";
import style from "../FrequentlyQuestions/FrequentlyQuestions.module.css";
import * as API from "../../api/PageContentAPI";
import { useTranslation } from "react-i18next";

export default function TermsOfService() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState("");

  useEffect(() => {
    API.getPageContentById(6).then((res) => setPageContent(res.content));
  }, []);
  return (
    <div
      className={style.container}
      dangerouslySetInnerHTML={{
        __html: pageContent.split(":::")[i18n.language === "en" ? 1 : 0],
      }}
    ></div>
  );
}
