import sha256 from "crypto-js/sha256";
var xml2js = require("xml2js");

function computeSHA256(lines: string) {
  console.log("sha256(lines).toString()", sha256(lines));
  return sha256(lines).toString();
}

function jsonSortByKey(json: { [key: string]: any }) {
  return Object.keys(json)
    .sort()
    .reduce((obj: { [key: string]: any }, key: string) => {
      obj[key] = json[key];
      return obj;
    }, {});
}

export function swiftPassPayment(
  amount: string,
  orderRef: string,
  msgId: string,
  paymentMethod:string
) {
  const json: { [key: string]: any } = {
    // version: "1.0", // version // Option
    charset: "UTF-8", // charset // ?
    merchantId: "181540003809", // merchant Id // Must
    orderRef: orderRef, // order ref // Must
    msgId: msgId, // msg Id // Must
    amount: +amount * 100, // amount // Must
    accessType: "SPC", // channel type // Must
    // MARK : default startTime = now ; expireTime = startTime + 2 hours
    // startTime: `${date.getFullYear()}${landingZero(date.getMonth())}${landingZero(date.getDate())}${landingZero(date.getHours())}${landingZero(date.getMinutes())}${landingZero(date.getSeconds())}`, // Order generation time // Option
    // expireTime: `${date.getFullYear()}${landingZero(date.getMonth())}${landingZero(date.getDate())}${landingZero(date.getHours())}${landingZero(date.getMinutes())}${landingZero(date.getSeconds())}`, // Order expiration time // Option
     payMethod: paymentMethod, // Payment Method // Option
    // attach: "attach", // Additional information, can be extended parameters // Option
    //goodsName: "goodsName", // Goods Name // Option
    // terminalNo: "1234567890ABCDEF", // device ID // Option
    // notifyUrl: `${process.env.REACT_APP_BACKEND}boc_checkout/notify` , // Receive the notification address of the payment result // Must
    notifyUrl: `${process.env.REACT_APP_BACKEND}boc_checkout/notify/`,
    callbackUrl: `${process.env.REACT_APP_WEB_URL}orderComplete?orderNo=${orderRef}`, // the front-end page to jump // Must
    randStr: "adf880d5c8986bd0deb6423c92c9d948", // random number // Must
    // lang: "zh-HK", // language // Option
    // currCode: "HKD" // currency // Option
  };
  let sortedJson = jsonSortByKey(json);
  console.log("sortedJson", sortedJson);

  let queryStrArr: string[] = [];

  // create query String
  Object.keys(sortedJson).forEach((key) => {
    if (sortedJson[key]) {
      console.log(`sortedJson >>>>>> key : ${key} ; value : ${sortedJson[key]}`)
      queryStrArr.push(`${key}=${sortedJson[key]}`);
    }
  });

  let signData =
    queryStrArr.join("&") + "&key=24cf76d8d1b1e77acdab39ab43394834";

  console.log("signData", signData);
  json["sign"] = computeSHA256(signData).toUpperCase();
  console.log("sign =", json["sign"]);

  sortedJson = jsonSortByKey(json);
  console.log(" sortedJson with sign =", sortedJson);

  console.log("sortedJson", sortedJson);

  var builder = new xml2js.Builder();
  var xml = builder.buildObject(sortedJson);
  console.log("sortedJson", sortedJson);
  console.log("xml", xml);
  return xml;
}
