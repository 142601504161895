// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-control-container {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
}
.single-page-icon,
.two-page-icon {
  font-size: 60px;
  text-align: center;
}
.single-mode-text,
.double-mode-text {
  font-size: 14px;
  line-height: 27px;
  opacity: 1;
  text-align: center;
}
.single-mode-container,
.double-mode-container {
  cursor: pointer;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Reader/readerSettings/modeControl/modeControl.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,6BAA6B;AAC/B;AACA;;EAEE,eAAe;EACf,kBAAkB;AACpB;AACA;;EAEE,eAAe;EACf,iBAAiB;EACjB,UAAU;EACV,kBAAkB;AACpB;AACA;;EAEE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".single-control-container {\n  width: 100%;\n  height: 60px;\n  display: flex;\n  justify-content: space-around;\n}\n.single-page-icon,\n.two-page-icon {\n  font-size: 60px;\n  text-align: center;\n}\n.single-mode-text,\n.double-mode-text {\n  font-size: 14px;\n  line-height: 27px;\n  opacity: 1;\n  text-align: center;\n}\n.single-mode-container,\n.double-mode-container {\n  cursor: pointer;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
