import React, { useState } from "react";
import style from "../../layout/Favourite/Favourite.module.css";
import OrderProduct from "../OrderProduct/OrderProduct";
import useWindowSize from "../../utils/useWindowSize";

export default function OrderHistory({ history }: any) {
  const [hiddenMessage, setHiddenMessage] = useState(true);
  const { width } = useWindowSize();

  let _orderTotal = "";
  if (history && history.orderTotal) {
    let __orderTotal = Math.floor(parseFloat(history.orderTotal) * 10) / 10;
    _orderTotal = __orderTotal.toFixed(2);
  }

  return (
    <>
      <div className={style.orderHistory}>
        <div>
          <p>{history.orderNo}</p>
        </div>
        {width > 1024 ?
          <>
            <div>
              <p>{history.orderDate.split("T")[0]}</p>
            </div>
            <div>
              <p>{history.orderStatus}</p>
            </div>
          </>
          :
          <></>
        }
        <div>
          <p>{_orderTotal}</p>
        </div>
        <div>
          <p
            onClick={() => setHiddenMessage(!hiddenMessage)}
            style={{ color: "rgb(0, 157, 165)", cursor: "pointer" }}
          >
            詳情
          </p>
        </div>
      </div>
      {hiddenMessage ? (
        <></>
      ) : (
        <>
          <div className={style.orderProductDetailHeader}>
            <div></div>
            {width > 1024 ?
              <>
                <div>
                  <span>編號</span>
                </div>
                <div>
                  <span>書本名稱</span>
                </div>
              </>
              :
              <></>
            }
            <div>
              <span>單價</span>
            </div>
          </div>
          {history.orderProducts.map((product: any) => (
            <>
              <OrderProduct product={product} />
            </>
          ))}
          <hr className={style.horizontalLine}></hr>
        </>
      )}
    </>
  );
}
