/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddUpdateMemberResponseModel,
    AddUpdateMemberResponseModelFromJSON,
    AddUpdateMemberResponseModelToJSON,
    AddressDto,
    AddressDtoFromJSON,
    AddressDtoToJSON,
    BatchMembersDto,
    BatchMembersDtoFromJSON,
    BatchMembersDtoToJSON,
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoToJSON,
    MemberFavorite,
    MemberFavoriteFromJSON,
    MemberFavoriteToJSON,
    PagingModelMemberDto,
    PagingModelMemberDtoFromJSON,
    PagingModelMemberDtoToJSON,
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoToJSON,
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
    SsoEmailResponseModel,
    SsoEmailResponseModelFromJSON,
    SsoEmailResponseModelToJSON,
    SsoUserDto,
    SsoUserDtoFromJSON,
    SsoUserDtoToJSON,
    SsoUserResponse,
    SsoUserResponseFromJSON,
    SsoUserResponseToJSON,
    SuccessResponseModel,
    SuccessResponseModelFromJSON,
    SuccessResponseModelToJSON,
    TokenModel,
    TokenModelFromJSON,
    TokenModelToJSON,
} from '../models';

export interface AddMemberAddressUsingPOSTRequest {
    recipient: string;
    contactNumber: string;
    addressRow1: string;
    addressRow2: string;
    countryCode: number;
    phoneCountryId: string;
    name?: string;
}

export interface AddMemberFavouriteByMemberIdUsingPUTRequest {
    memberId: number;
    productId: number;
}

export interface AddMemberUsingPOSTRequest {
    expireDate: string;
    userName: string;
    nameChi: string;
    nameEng: string;
    password: string;
    grade: string;
    className: string;
    classNumber: string;
    dbDelete?: boolean;
}

export interface AuthLoginUsingPOSTRequest {
    username: string;
    password: string;
}

export interface BatchImportUsingPOSTRequest {
    file: Blob;
}

export interface ChangeDefaultMemberAddressUsingPOSTRequest {
    name?: string;
    addressId?: number;
}

export interface CheckEmailUsingPOSTRequest {
    email: string;
}

export interface CheckFacebookIdUsingGETRequest {
    facebookId: string;
}

export interface CheckGoogleIdUsingGETRequest {
    googleId: string;
}

export interface CheckOpenIdUserUsingPOSTRequest {
    openId: string;
    ssoUserDto?: SsoUserDto;
}

export interface CheckSSoUserUsingPOSTRequest {
    username: string;
    ssoUserDto?: SsoUserDto;
}

export interface DeleteEmailUsingPATCHRequest {
    memberId: number;
}

export interface DeleteMemberAddressUsingDELETERequest {
    name?: string;
    addressId?: number;
}

export interface DeleteMemberFavouriteByMemberIdUsingDELETERequest {
    memberId: number;
    productId: number;
}

export interface GetDefaultMemberAddressUsingGETRequest {
    name?: string;
}

export interface GetMemberAddressUsingGETRequest {
    name?: string;
}

export interface GetMemberFavouritesByMemberIdProdIdUsingGETRequest {
    memberId: number;
    productId: number;
}

export interface GetMemberFavouritesByMemberIdUsingGETRequest {
    memberId: number;
}

export interface GetMemberUsingGETRequest {
    memberId: number;
}

export interface GetMemberUsingGET1Request {
    name?: string;
}

export interface GetTokenByFacebookIdUsingGETRequest {
    facebookId: string;
}

export interface GetTokenByGoogleIdUsingGETRequest {
    googleId: string;
}

export interface GetTokenBySsoEmailUsingGETRequest {
    email: string;
}

export interface IsValidEmailUsingPOSTRequest {
    email: string;
}

export interface RefreshTokenUsingPOSTRequest {
    refreshToken?: string;
}

export interface ResetPasswordUsingPATCHRequest {
    memberId: number;
    newPassword: string;
}

export interface ResetPasswordUsingPOSTRequest {
    locationId: number;
    phone: string;
    verificationCode: string;
    password: string;
}

export interface SearchMemberUsingGETRequest {
    page?: number;
    size?: number;
    keyword?: string;
    includeDeleted?: boolean;
}

export interface UpdateMemberAddressUsingPUTRequest {
    addressId: number;
    name?: string;
    recipient?: string;
    contactNumber?: string;
    addressRow1?: string;
    addressRow2?: string;
    countryCode?: number;
    phoneCountryId?: string;
}

export interface UpdateMemberUsingPATCH1Request {
    expireDate: string;
    memberId: number;
    nameChi?: string;
    nameEng?: string;
    grade?: string;
    className?: string;
    classNumber?: string;
    dbDelete?: boolean;
    email?: string;
}

/**
 * 
 */
export class MemberControllerApi extends runtime.BaseAPI {

    /**
     * addMemberAddress
     */
    async addMemberAddressUsingPOSTRaw(requestParameters: AddMemberAddressUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.recipient === null || requestParameters.recipient === undefined) {
            throw new runtime.RequiredError('recipient','Required parameter requestParameters.recipient was null or undefined when calling addMemberAddressUsingPOST.');
        }

        if (requestParameters.contactNumber === null || requestParameters.contactNumber === undefined) {
            throw new runtime.RequiredError('contactNumber','Required parameter requestParameters.contactNumber was null or undefined when calling addMemberAddressUsingPOST.');
        }

        if (requestParameters.addressRow1 === null || requestParameters.addressRow1 === undefined) {
            throw new runtime.RequiredError('addressRow1','Required parameter requestParameters.addressRow1 was null or undefined when calling addMemberAddressUsingPOST.');
        }

        if (requestParameters.addressRow2 === null || requestParameters.addressRow2 === undefined) {
            throw new runtime.RequiredError('addressRow2','Required parameter requestParameters.addressRow2 was null or undefined when calling addMemberAddressUsingPOST.');
        }

        if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
            throw new runtime.RequiredError('countryCode','Required parameter requestParameters.countryCode was null or undefined when calling addMemberAddressUsingPOST.');
        }

        if (requestParameters.phoneCountryId === null || requestParameters.phoneCountryId === undefined) {
            throw new runtime.RequiredError('phoneCountryId','Required parameter requestParameters.phoneCountryId was null or undefined when calling addMemberAddressUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.recipient !== undefined) {
            queryParameters['recipient'] = requestParameters.recipient;
        }

        if (requestParameters.contactNumber !== undefined) {
            queryParameters['contactNumber'] = requestParameters.contactNumber;
        }

        if (requestParameters.addressRow1 !== undefined) {
            queryParameters['addressRow1'] = requestParameters.addressRow1;
        }

        if (requestParameters.addressRow2 !== undefined) {
            queryParameters['addressRow2'] = requestParameters.addressRow2;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        if (requestParameters.phoneCountryId !== undefined) {
            queryParameters['phoneCountryId'] = requestParameters.phoneCountryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/address`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * addMemberAddress
     */
    async addMemberAddressUsingPOST(requestParameters: AddMemberAddressUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.addMemberAddressUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * add member favourite by member
     */
    async addMemberFavouriteByMemberIdUsingPUTRaw(requestParameters: AddMemberFavouriteByMemberIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling addMemberFavouriteByMemberIdUsingPUT.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling addMemberFavouriteByMemberIdUsingPUT.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/favourite`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * add member favourite by member
     */
    async addMemberFavouriteByMemberIdUsingPUT(requestParameters: AddMemberFavouriteByMemberIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.addMemberFavouriteByMemberIdUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Member by Admin
     */
    async addMemberUsingPOSTRaw(requestParameters: AddMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddUpdateMemberResponseModel>> {
        if (requestParameters.expireDate === null || requestParameters.expireDate === undefined) {
            throw new runtime.RequiredError('expireDate','Required parameter requestParameters.expireDate was null or undefined when calling addMemberUsingPOST.');
        }

        if (requestParameters.userName === null || requestParameters.userName === undefined) {
            throw new runtime.RequiredError('userName','Required parameter requestParameters.userName was null or undefined when calling addMemberUsingPOST.');
        }

        if (requestParameters.nameChi === null || requestParameters.nameChi === undefined) {
            throw new runtime.RequiredError('nameChi','Required parameter requestParameters.nameChi was null or undefined when calling addMemberUsingPOST.');
        }

        if (requestParameters.nameEng === null || requestParameters.nameEng === undefined) {
            throw new runtime.RequiredError('nameEng','Required parameter requestParameters.nameEng was null or undefined when calling addMemberUsingPOST.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling addMemberUsingPOST.');
        }

        if (requestParameters.grade === null || requestParameters.grade === undefined) {
            throw new runtime.RequiredError('grade','Required parameter requestParameters.grade was null or undefined when calling addMemberUsingPOST.');
        }

        if (requestParameters.className === null || requestParameters.className === undefined) {
            throw new runtime.RequiredError('className','Required parameter requestParameters.className was null or undefined when calling addMemberUsingPOST.');
        }

        if (requestParameters.classNumber === null || requestParameters.classNumber === undefined) {
            throw new runtime.RequiredError('classNumber','Required parameter requestParameters.classNumber was null or undefined when calling addMemberUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.expireDate !== undefined) {
            queryParameters['expireDate'] = requestParameters.expireDate;
        }

        if (requestParameters.userName !== undefined) {
            queryParameters['userName'] = requestParameters.userName;
        }

        if (requestParameters.nameChi !== undefined) {
            queryParameters['nameChi'] = requestParameters.nameChi;
        }

        if (requestParameters.nameEng !== undefined) {
            queryParameters['nameEng'] = requestParameters.nameEng;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        if (requestParameters.grade !== undefined) {
            queryParameters['grade'] = requestParameters.grade;
        }

        if (requestParameters.className !== undefined) {
            queryParameters['className'] = requestParameters.className;
        }

        if (requestParameters.classNumber !== undefined) {
            queryParameters['classNumber'] = requestParameters.classNumber;
        }

        if (requestParameters.dbDelete !== undefined) {
            queryParameters['dbDelete'] = requestParameters.dbDelete;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddUpdateMemberResponseModelFromJSON(jsonValue));
    }

    /**
     * Add Member by Admin
     */
    async addMemberUsingPOST(requestParameters: AddMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddUpdateMemberResponseModel> {
        const response = await this.addMemberUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * authLogin
     */
    async authLoginUsingPOSTRaw(requestParameters: AuthLoginUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SsoUserResponse>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling authLoginUsingPOST.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling authLoginUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/authLogin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SsoUserResponseFromJSON(jsonValue));
    }

    /**
     * authLogin
     */
    async authLoginUsingPOST(requestParameters: AuthLoginUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SsoUserResponse> {
        const response = await this.authLoginUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * upload excel to import student
     */
    async batchImportUsingPOSTRaw(requestParameters: BatchImportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BatchMembersDto>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling batchImportUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/member/batch-import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchMembersDtoFromJSON(jsonValue));
    }

    /**
     * upload excel to import student
     */
    async batchImportUsingPOST(requestParameters: BatchImportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BatchMembersDto> {
        const response = await this.batchImportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * changeDefaultMemberAddress
     */
    async changeDefaultMemberAddressUsingPOSTRaw(requestParameters: ChangeDefaultMemberAddressUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.addressId !== undefined) {
            queryParameters['addressId'] = requestParameters.addressId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/address/default`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * changeDefaultMemberAddress
     */
    async changeDefaultMemberAddressUsingPOST(requestParameters: ChangeDefaultMemberAddressUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.changeDefaultMemberAddressUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkEmail
     */
    async checkEmailUsingPOSTRaw(requestParameters: CheckEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling checkEmailUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * checkEmail
     */
    async checkEmailUsingPOST(requestParameters: CheckEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.checkEmailUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingGETRaw(requestParameters: CheckFacebookIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.facebookId === null || requestParameters.facebookId === undefined) {
            throw new runtime.RequiredError('facebookId','Required parameter requestParameters.facebookId was null or undefined when calling checkFacebookIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.facebookId !== undefined) {
            queryParameters['facebookId'] = requestParameters.facebookId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingGET(requestParameters: CheckFacebookIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.checkFacebookIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingGETRaw(requestParameters: CheckGoogleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.googleId === null || requestParameters.googleId === undefined) {
            throw new runtime.RequiredError('googleId','Required parameter requestParameters.googleId was null or undefined when calling checkGoogleIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.googleId !== undefined) {
            queryParameters['googleId'] = requestParameters.googleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingGET(requestParameters: CheckGoogleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.checkGoogleIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkOpenIdUser
     */
    async checkOpenIdUserUsingPOSTRaw(requestParameters: CheckOpenIdUserUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SsoUserResponse>> {
        if (requestParameters.openId === null || requestParameters.openId === undefined) {
            throw new runtime.RequiredError('openId','Required parameter requestParameters.openId was null or undefined when calling checkOpenIdUserUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.openId !== undefined) {
            queryParameters['openId'] = requestParameters.openId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/checkOpenIdUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SsoUserDtoToJSON(requestParameters.ssoUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SsoUserResponseFromJSON(jsonValue));
    }

    /**
     * checkOpenIdUser
     */
    async checkOpenIdUserUsingPOST(requestParameters: CheckOpenIdUserUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SsoUserResponse> {
        const response = await this.checkOpenIdUserUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkSSoUser
     */
    async checkSSoUserUsingPOSTRaw(requestParameters: CheckSSoUserUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SsoUserResponse>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling checkSSoUserUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/checkSsoUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SsoUserDtoToJSON(requestParameters.ssoUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SsoUserResponseFromJSON(jsonValue));
    }

    /**
     * checkSSoUser
     */
    async checkSSoUserUsingPOST(requestParameters: CheckSSoUserUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SsoUserResponse> {
        const response = await this.checkSSoUserUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Email
     */
    async deleteEmailUsingPATCHRaw(requestParameters: DeleteEmailUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling deleteEmailUsingPATCH.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/delete-email`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Delete Email
     */
    async deleteEmailUsingPATCH(requestParameters: DeleteEmailUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.deleteEmailUsingPATCHRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteMemberAddress
     */
    async deleteMemberAddressUsingDELETERaw(requestParameters: DeleteMemberAddressUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.addressId !== undefined) {
            queryParameters['addressId'] = requestParameters.addressId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/address`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * deleteMemberAddress
     */
    async deleteMemberAddressUsingDELETE(requestParameters: DeleteMemberAddressUsingDELETERequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.deleteMemberAddressUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete member favourites by member
     */
    async deleteMemberFavouriteByMemberIdUsingDELETERaw(requestParameters: DeleteMemberFavouriteByMemberIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling deleteMemberFavouriteByMemberIdUsingDELETE.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling deleteMemberFavouriteByMemberIdUsingDELETE.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/favourite`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * delete member favourites by member
     */
    async deleteMemberFavouriteByMemberIdUsingDELETE(requestParameters: DeleteMemberFavouriteByMemberIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.deleteMemberFavouriteByMemberIdUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * export excel for all student
     */
    async exportAllStudentUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/export-all-student`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * export excel for all student
     */
    async exportAllStudentUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.exportAllStudentUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMember
     */
    async getAllMemberUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * getAllMember
     */
    async getAllMemberUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MemberDto>> {
        const response = await this.getAllMemberUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getDefaultMemberAddress
     */
    async getDefaultMemberAddressUsingGETRaw(requestParameters: GetDefaultMemberAddressUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddressDto>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/address/default`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressDtoFromJSON(jsonValue));
    }

    /**
     * getDefaultMemberAddress
     */
    async getDefaultMemberAddressUsingGET(requestParameters: GetDefaultMemberAddressUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddressDto> {
        const response = await this.getDefaultMemberAddressUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberAddress
     */
    async getMemberAddressUsingGETRaw(requestParameters: GetMemberAddressUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<AddressDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressDtoFromJSON));
    }

    /**
     * getMemberAddress
     */
    async getMemberAddressUsingGET(requestParameters: GetMemberAddressUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<AddressDto>> {
        const response = await this.getMemberAddressUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get member favourites by member & prodId
     */
    async getMemberFavouritesByMemberIdProdIdUsingGETRaw(requestParameters: GetMemberFavouritesByMemberIdProdIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MemberFavorite>>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling getMemberFavouritesByMemberIdProdIdUsingGET.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getMemberFavouritesByMemberIdProdIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/favouriteByProdId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberFavoriteFromJSON));
    }

    /**
     * get member favourites by member & prodId
     */
    async getMemberFavouritesByMemberIdProdIdUsingGET(requestParameters: GetMemberFavouritesByMemberIdProdIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MemberFavorite>> {
        const response = await this.getMemberFavouritesByMemberIdProdIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get member favourites by member
     */
    async getMemberFavouritesByMemberIdUsingGETRaw(requestParameters: GetMemberFavouritesByMemberIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling getMemberFavouritesByMemberIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/favourite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * get member favourites by member
     */
    async getMemberFavouritesByMemberIdUsingGET(requestParameters: GetMemberFavouritesByMemberIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductDto>> {
        const response = await this.getMemberFavouritesByMemberIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMember
     */
    async getMemberUsingGETRaw(requestParameters: GetMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MemberDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling getMemberUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     * getMember
     */
    async getMemberUsingGET(requestParameters: GetMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MemberDto> {
        const response = await this.getMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMember
     */
    async getMemberUsingGET1Raw(requestParameters: GetMemberUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MemberDto>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     * getMember
     */
    async getMemberUsingGET1(requestParameters: GetMemberUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MemberDto> {
        const response = await this.getMemberUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingGETRaw(requestParameters: GetTokenByFacebookIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.facebookId === null || requestParameters.facebookId === undefined) {
            throw new runtime.RequiredError('facebookId','Required parameter requestParameters.facebookId was null or undefined when calling getTokenByFacebookIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.facebookId !== undefined) {
            queryParameters['facebookId'] = requestParameters.facebookId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingGET(requestParameters: GetTokenByFacebookIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.getTokenByFacebookIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingGETRaw(requestParameters: GetTokenByGoogleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.googleId === null || requestParameters.googleId === undefined) {
            throw new runtime.RequiredError('googleId','Required parameter requestParameters.googleId was null or undefined when calling getTokenByGoogleIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.googleId !== undefined) {
            queryParameters['googleId'] = requestParameters.googleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingGET(requestParameters: GetTokenByGoogleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.getTokenByGoogleIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTokenBySsoEmail
     */
    async getTokenBySsoEmailUsingGETRaw(requestParameters: GetTokenBySsoEmailUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SsoEmailResponseModel>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getTokenBySsoEmailUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/getTokenBySsoEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SsoEmailResponseModelFromJSON(jsonValue));
    }

    /**
     * getTokenBySsoEmail
     */
    async getTokenBySsoEmailUsingGET(requestParameters: GetTokenBySsoEmailUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SsoEmailResponseModel> {
        const response = await this.getTokenBySsoEmailUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingPOSTRaw(requestParameters: IsValidEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling isValidEmailUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingPOST(requestParameters: IsValidEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.isValidEmailUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * newRefreshToken
     */
    async newRefreshTokenUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/newRefreshToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * newRefreshToken
     */
    async newRefreshTokenUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<{ [key: string]: string; }> {
        const response = await this.newRefreshTokenUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingPOSTRaw(requestParameters: RefreshTokenUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<TokenModel>> {
        const queryParameters: any = {};

        if (requestParameters.refreshToken !== undefined) {
            queryParameters['refreshToken'] = requestParameters.refreshToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenModelFromJSON(jsonValue));
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingPOST(requestParameters: RefreshTokenUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<TokenModel> {
        const response = await this.refreshTokenUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset Password
     */
    async resetPasswordUsingPATCHRaw(requestParameters: ResetPasswordUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling resetPasswordUsingPATCH.');
        }

        if (requestParameters.newPassword === null || requestParameters.newPassword === undefined) {
            throw new runtime.RequiredError('newPassword','Required parameter requestParameters.newPassword was null or undefined when calling resetPasswordUsingPATCH.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.newPassword !== undefined) {
            queryParameters['newPassword'] = requestParameters.newPassword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/reset-password`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * Reset Password
     */
    async resetPasswordUsingPATCH(requestParameters: ResetPasswordUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SuccessResponseModel> {
        const response = await this.resetPasswordUsingPATCHRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * resetPassword
     */
    async resetPasswordUsingPOSTRaw(requestParameters: ResetPasswordUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling resetPasswordUsingPOST.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling resetPasswordUsingPOST.');
        }

        if (requestParameters.verificationCode === null || requestParameters.verificationCode === undefined) {
            throw new runtime.RequiredError('verificationCode','Required parameter requestParameters.verificationCode was null or undefined when calling resetPasswordUsingPOST.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling resetPasswordUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.locationId !== undefined) {
            queryParameters['locationId'] = requestParameters.locationId;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        if (requestParameters.verificationCode !== undefined) {
            queryParameters['verificationCode'] = requestParameters.verificationCode;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/resetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * resetPassword
     */
    async resetPasswordUsingPOST(requestParameters: ResetPasswordUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.resetPasswordUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * keyword searchBy userName, nameChi, nameEng, className, classNumber
     * get and search members
     */
    async searchMemberUsingGETRaw(requestParameters: SearchMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PagingModelMemberDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        if (requestParameters.includeDeleted !== undefined) {
            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagingModelMemberDtoFromJSON(jsonValue));
    }

    /**
     * keyword searchBy userName, nameChi, nameEng, className, classNumber
     * get and search members
     */
    async searchMemberUsingGET(requestParameters: SearchMemberUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PagingModelMemberDto> {
        const response = await this.searchMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateMemberAddress
     */
    async updateMemberAddressUsingPUTRaw(requestParameters: UpdateMemberAddressUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.addressId === null || requestParameters.addressId === undefined) {
            throw new runtime.RequiredError('addressId','Required parameter requestParameters.addressId was null or undefined when calling updateMemberAddressUsingPUT.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.addressId !== undefined) {
            queryParameters['addressId'] = requestParameters.addressId;
        }

        if (requestParameters.recipient !== undefined) {
            queryParameters['recipient'] = requestParameters.recipient;
        }

        if (requestParameters.contactNumber !== undefined) {
            queryParameters['contactNumber'] = requestParameters.contactNumber;
        }

        if (requestParameters.addressRow1 !== undefined) {
            queryParameters['addressRow1'] = requestParameters.addressRow1;
        }

        if (requestParameters.addressRow2 !== undefined) {
            queryParameters['addressRow2'] = requestParameters.addressRow2;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        if (requestParameters.phoneCountryId !== undefined) {
            queryParameters['phoneCountryId'] = requestParameters.phoneCountryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/address`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * updateMemberAddress
     */
    async updateMemberAddressUsingPUT(requestParameters: UpdateMemberAddressUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<string>> {
        const response = await this.updateMemberAddressUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Member by Admin
     */
    async updateMemberUsingPATCH1Raw(requestParameters: UpdateMemberUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AddUpdateMemberResponseModel>> {
        if (requestParameters.expireDate === null || requestParameters.expireDate === undefined) {
            throw new runtime.RequiredError('expireDate','Required parameter requestParameters.expireDate was null or undefined when calling updateMemberUsingPATCH1.');
        }

        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling updateMemberUsingPATCH1.');
        }

        const queryParameters: any = {};

        if (requestParameters.expireDate !== undefined) {
            queryParameters['expireDate'] = requestParameters.expireDate;
        }

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.nameChi !== undefined) {
            queryParameters['nameChi'] = requestParameters.nameChi;
        }

        if (requestParameters.nameEng !== undefined) {
            queryParameters['nameEng'] = requestParameters.nameEng;
        }

        if (requestParameters.grade !== undefined) {
            queryParameters['grade'] = requestParameters.grade;
        }

        if (requestParameters.className !== undefined) {
            queryParameters['className'] = requestParameters.className;
        }

        if (requestParameters.classNumber !== undefined) {
            queryParameters['classNumber'] = requestParameters.classNumber;
        }

        if (requestParameters.dbDelete !== undefined) {
            queryParameters['dbDelete'] = requestParameters.dbDelete;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/member/update`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddUpdateMemberResponseModelFromJSON(jsonValue));
    }

    /**
     * Update Member by Admin
     */
    async updateMemberUsingPATCH1(requestParameters: UpdateMemberUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AddUpdateMemberResponseModel> {
        const response = await this.updateMemberUsingPATCH1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
