import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  STYLETYPE_FIFTH,
  STYLETYPE_FOUR,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_SIX,
  STYLETYPE_THIRD,
} from "../../utils/Constant";

import style from "./DialogBox.module.css";
import "./DialogBox.css";

interface IProps {
  title?: any;
  showDialog?: boolean;
  styleType: string;
  children?: any;
  p1?: any;
  p2?: any;
  bookName?: string;
  scroll?: any;
}

export default class DialogBox extends Component<IProps> {
  heightRef = React.createRef();

  componentDidMount() {}

  render() {
    const { title, showDialog, children, styleType, p1, p2, bookName, scroll } =
      this.props;
    return (
      <>
        {styleType === STYLETYPE_MAIN ? (
          <div
            className={style.container}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.contentContainer}>
              <div className={style.title}>
                <h3>{title}</h3>
              </div>
              <div className={style.scroll} hidden={scroll ? false : true}>
                {scroll}
              </div>
              <div className={style.content}>{children}</div>
            </div>
          </div>
        ) : styleType === STYLETYPE_SECOND ? (
          <div
            className={style.container2}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.contentContainer2}>
              <div className={style.title}>
                <h2>{title}</h2>
              </div>
              <div className={style.content} style={{ display: "flex" }}>
                {p1}
                <p style={{ color: "orange" }}>{bookName}</p>
                {p2}
              </div>
              <div className={style.content}>{children}</div>
            </div>
          </div>
        ) : styleType === STYLETYPE_THIRD ? (
          <div
            className={style.container3}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.contentContainer3}>
              <div className={style.title}>
                <h2>{title}</h2>
              </div>
              <div className={style.content}>
                <p>{p1}</p>
                <p style={{ color: "orange" }}>{bookName}</p>
                {p2}
              </div>
              <div className={style.content3}>{children}</div>
            </div>
          </div>
        ) : styleType === STYLETYPE_FOUR ? (
          <div
            className={style.container2}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.contentContainer4}>
              <div className={style.title}>
                <h2>{title}</h2>
              </div>
              <div className={style.content} style={{ display: "inline" }}>
                <p style={{ display: "inline" }}>{p1}</p>
                <p style={{ color: "orange", display: "inline" }}>{bookName}</p>
                {p2}
              </div>
              <div className={style.content}>{children}</div>
            </div>
          </div>
        ) : styleType === STYLETYPE_FIFTH ? (
          <div
            className={style.container2}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.contentContainer5}>
              <div className={style.title}>
                <h2>{title}</h2>
              </div>
              <div className={style.content5}>{p1}</div>
              <div className={style.content}>{children}</div>
            </div>
          </div>
        ) : styleType === STYLETYPE_SIX ? (
          <div
            className={[style.container2, "dialogBoxClass"].join(" ")}
            style={{ display: "none" }}
          >
            <div className={style.contentContainer5}>
              <div className={style.title}>
                <h2>{title}</h2>
              </div>
              <div className={style.content5}>{p1}</div>
              <div className={style.content}>{children}</div>
            </div>
          </div>
        ) : styleType === "conflictCategory" ? (
          <div
            className={style.conflictCategoryDialog}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.conflictCategoryDialogContent}>
              <div className={style.title}>
                <h2>{title}</h2>
              </div>
              <div className={style.content}>{children}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}
