import {
    ADD_COUPON,
    ADD_PRODUCT_ITEM, CLEAR_SHOPPING_CART, DELETE_COUPON,
    DELETE_PRODUCT_ITEM, REFRESH_SHOPPING_CART
} from "../../utils/Constant";

export function addProductItem(payload) {
    return { type: ADD_PRODUCT_ITEM, payload}
};

export function deleteProductItem(payload) {
    return { type: DELETE_PRODUCT_ITEM, payload}
};

export function clearShoppingCart(payload){
    return { type: CLEAR_SHOPPING_CART, payload}
}

export function refreshShoppingCart(payload){
    return { type: REFRESH_SHOPPING_CART, payload}
}

export function addCoupon(payload) {
    return { type: ADD_COUPON, payload}
}

export function deleteCoupon(payload) {
    return{ type: DELETE_COUPON, payload}
}
