/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DisplayProductDto,
    DisplayProductDtoFromJSON,
    DisplayProductDtoToJSON,
    DisplayProductMember2Dto,
    DisplayProductMember2DtoFromJSON,
    DisplayProductMember2DtoToJSON,
} from '../models';

export interface AddDisplayProductUsingPOSTRequest {
    displayProductNameChi: string;
    displayProductNameEng: string;
    displayProductPrice: number;
    displayProductImage: string;
    displayProductDescriptionDetail: string;
    displayProductDescriptionFeature: string;
    displayProductDescriptionIngredient: string;
    displayProductDescriptionUsage: string;
    displayProductDescriptionUseCount: string;
    displayProductDescriptionTarget: string;
    functionalIcon: string;
    productIds: number;
    name?: string;
    onShelfDate?: Date;
    offShelfDate?: Date;
    order?: number;
    categoryIds?: number;
    hashtag?: string;
}

export interface DeleteProductUsingPOSTRequest {
    displayProductId: number;
    name?: string;
}

export interface GetAllDisplayProductAdminUsingGETRequest {
    name?: string;
}

export interface GetAllDisplayProductUsingGETRequest {
    name?: string;
}

export interface GetDisplayProductByCategoryIdAdminUsingGETRequest {
    categoryId: number;
    name?: string;
}

export interface GetDisplayProductByDisplayProductIdAdminUsingGETRequest {
    displayProductId: number;
    name?: string;
}

export interface GetDisplayProductByDisplayProductIdUsingGETRequest {
    displayProductId: number;
    name?: string;
}

export interface GetDisplayProductByHashtagNameAdminUsingGETRequest {
    hashtagName: string;
    name?: string;
}

export interface GetPagedDisplayProductByCategoryIdUsingGETRequest {
    page: string;
    size: string;
    categoryId: number;
    name?: string;
}

export interface GetPagedDisplayProductByHashtagNameUsingGETRequest {
    page: string;
    size: string;
    hashtagName: string;
    name?: string;
}

export interface SearchDisplayProductAdminUsingPOSTRequest {
    searchString: string;
    name?: string;
}

export interface SearchDisplayProductUsingPOSTRequest {
    searchString: string;
}

export interface SetDisplayProductOrderUsingPOST2Request {
    displayProductIds: number;
    categoryId: number;
    name?: string;
}

export interface SetDisplayProductOrderUsingPOST3Request {
    displayProductIds: number;
    name?: string;
}

export interface UpdateDisplayProductOrderUsingPOSTRequest {
    displayProductId: number;
    name?: string;
    order?: number;
}

export interface UpdateDisplayProductUsingPOSTRequest {
    displayProductId: number;
    name?: string;
    displayProductNameChi?: string;
    displayProductNameEng?: string;
    displayProductPrice?: number;
    displayProductImage?: string;
    displayProductDescriptionDetail?: string;
    displayProductDescriptionFeature?: string;
    displayProductDescriptionIngredient?: string;
    displayProductDescriptionUsage?: string;
    displayProductDescriptionUseCount?: string;
    displayProductDescriptionTarget?: string;
    functionalIcon?: string;
    order?: number;
    categoryIds?: number;
    productIds?: number;
    hashtag?: string;
    body?: Date;
}

/**
 * 
 */
export class DisplayProductControllerApi extends runtime.BaseAPI {

    /**
     * addDisplayProduct
     */
    async addDisplayProductUsingPOSTRaw(requestParameters: AddDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DisplayProductDto>> {
        if (requestParameters.displayProductNameChi === null || requestParameters.displayProductNameChi === undefined) {
            throw new runtime.RequiredError('displayProductNameChi','Required parameter requestParameters.displayProductNameChi was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductNameEng === null || requestParameters.displayProductNameEng === undefined) {
            throw new runtime.RequiredError('displayProductNameEng','Required parameter requestParameters.displayProductNameEng was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductPrice === null || requestParameters.displayProductPrice === undefined) {
            throw new runtime.RequiredError('displayProductPrice','Required parameter requestParameters.displayProductPrice was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductImage === null || requestParameters.displayProductImage === undefined) {
            throw new runtime.RequiredError('displayProductImage','Required parameter requestParameters.displayProductImage was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductDescriptionDetail === null || requestParameters.displayProductDescriptionDetail === undefined) {
            throw new runtime.RequiredError('displayProductDescriptionDetail','Required parameter requestParameters.displayProductDescriptionDetail was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductDescriptionFeature === null || requestParameters.displayProductDescriptionFeature === undefined) {
            throw new runtime.RequiredError('displayProductDescriptionFeature','Required parameter requestParameters.displayProductDescriptionFeature was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductDescriptionIngredient === null || requestParameters.displayProductDescriptionIngredient === undefined) {
            throw new runtime.RequiredError('displayProductDescriptionIngredient','Required parameter requestParameters.displayProductDescriptionIngredient was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductDescriptionUsage === null || requestParameters.displayProductDescriptionUsage === undefined) {
            throw new runtime.RequiredError('displayProductDescriptionUsage','Required parameter requestParameters.displayProductDescriptionUsage was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductDescriptionUseCount === null || requestParameters.displayProductDescriptionUseCount === undefined) {
            throw new runtime.RequiredError('displayProductDescriptionUseCount','Required parameter requestParameters.displayProductDescriptionUseCount was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.displayProductDescriptionTarget === null || requestParameters.displayProductDescriptionTarget === undefined) {
            throw new runtime.RequiredError('displayProductDescriptionTarget','Required parameter requestParameters.displayProductDescriptionTarget was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.functionalIcon === null || requestParameters.functionalIcon === undefined) {
            throw new runtime.RequiredError('functionalIcon','Required parameter requestParameters.functionalIcon was null or undefined when calling addDisplayProductUsingPOST.');
        }

        if (requestParameters.productIds === null || requestParameters.productIds === undefined) {
            throw new runtime.RequiredError('productIds','Required parameter requestParameters.productIds was null or undefined when calling addDisplayProductUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.displayProductNameChi !== undefined) {
            queryParameters['displayProductNameChi'] = requestParameters.displayProductNameChi;
        }

        if (requestParameters.displayProductNameEng !== undefined) {
            queryParameters['displayProductNameEng'] = requestParameters.displayProductNameEng;
        }

        if (requestParameters.displayProductPrice !== undefined) {
            queryParameters['displayProductPrice'] = requestParameters.displayProductPrice;
        }

        if (requestParameters.displayProductImage !== undefined) {
            queryParameters['displayProductImage'] = requestParameters.displayProductImage;
        }

        if (requestParameters.displayProductDescriptionDetail !== undefined) {
            queryParameters['displayProductDescriptionDetail'] = requestParameters.displayProductDescriptionDetail;
        }

        if (requestParameters.displayProductDescriptionFeature !== undefined) {
            queryParameters['displayProductDescriptionFeature'] = requestParameters.displayProductDescriptionFeature;
        }

        if (requestParameters.displayProductDescriptionIngredient !== undefined) {
            queryParameters['displayProductDescriptionIngredient'] = requestParameters.displayProductDescriptionIngredient;
        }

        if (requestParameters.displayProductDescriptionUsage !== undefined) {
            queryParameters['displayProductDescriptionUsage'] = requestParameters.displayProductDescriptionUsage;
        }

        if (requestParameters.displayProductDescriptionUseCount !== undefined) {
            queryParameters['displayProductDescriptionUseCount'] = requestParameters.displayProductDescriptionUseCount;
        }

        if (requestParameters.displayProductDescriptionTarget !== undefined) {
            queryParameters['displayProductDescriptionTarget'] = requestParameters.displayProductDescriptionTarget;
        }

        if (requestParameters.functionalIcon !== undefined) {
            queryParameters['functionalIcon'] = requestParameters.functionalIcon;
        }

        if (requestParameters.onShelfDate !== undefined) {
            queryParameters['onShelfDate'] = (requestParameters.onShelfDate as any).toISOString();
        }

        if (requestParameters.offShelfDate !== undefined) {
            queryParameters['offShelfDate'] = (requestParameters.offShelfDate as any).toISOString();
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.categoryIds !== undefined) {
            queryParameters['categoryIds'] = requestParameters.categoryIds;
        }

        if (requestParameters.productIds !== undefined) {
            queryParameters['productIds'] = requestParameters.productIds;
        }

        if (requestParameters.hashtag !== undefined) {
            queryParameters['hashtag'] = requestParameters.hashtag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductDtoFromJSON(jsonValue));
    }

    /**
     * addDisplayProduct
     */
    async addDisplayProductUsingPOST(requestParameters: AddDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DisplayProductDto> {
        const response = await this.addDisplayProductUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOSTRaw(requestParameters: DeleteProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DisplayProductDto>> {
        if (requestParameters.displayProductId === null || requestParameters.displayProductId === undefined) {
            throw new runtime.RequiredError('displayProductId','Required parameter requestParameters.displayProductId was null or undefined when calling deleteProductUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/delete/{displayProductId}`.replace(`{${"displayProductId"}}`, encodeURIComponent(String(requestParameters.displayProductId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductDtoFromJSON(jsonValue));
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST(requestParameters: DeleteProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DisplayProductDto> {
        const response = await this.deleteProductUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllDisplayProductAdmin
     */
    async getAllDisplayProductAdminUsingGETRaw(requestParameters: GetAllDisplayProductAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * getAllDisplayProductAdmin
     */
    async getAllDisplayProductAdminUsingGET(requestParameters: GetAllDisplayProductAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.getAllDisplayProductAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllDisplayProduct
     */
    async getAllDisplayProductUsingGETRaw(requestParameters: GetAllDisplayProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DisplayProductMember2Dto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductMember2DtoFromJSON));
    }

    /**
     * getAllDisplayProduct
     */
    async getAllDisplayProductUsingGET(requestParameters: GetAllDisplayProductUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DisplayProductMember2Dto>> {
        const response = await this.getAllDisplayProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByCategoryIdAdmin
     */
    async getDisplayProductByCategoryIdAdminUsingGETRaw(requestParameters: GetDisplayProductByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getDisplayProductByCategoryIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * getDisplayProductByCategoryIdAdmin
     */
    async getDisplayProductByCategoryIdAdminUsingGET(requestParameters: GetDisplayProductByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.getDisplayProductByCategoryIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByDisplayProductIdAdmin
     */
    async getDisplayProductByDisplayProductIdAdminUsingGETRaw(requestParameters: GetDisplayProductByDisplayProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DisplayProductMember2Dto>> {
        if (requestParameters.displayProductId === null || requestParameters.displayProductId === undefined) {
            throw new runtime.RequiredError('displayProductId','Required parameter requestParameters.displayProductId was null or undefined when calling getDisplayProductByDisplayProductIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/{displayProductId}`.replace(`{${"displayProductId"}}`, encodeURIComponent(String(requestParameters.displayProductId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductMember2DtoFromJSON(jsonValue));
    }

    /**
     * getDisplayProductByDisplayProductIdAdmin
     */
    async getDisplayProductByDisplayProductIdAdminUsingGET(requestParameters: GetDisplayProductByDisplayProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DisplayProductMember2Dto> {
        const response = await this.getDisplayProductByDisplayProductIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByDisplayProductId
     */
    async getDisplayProductByDisplayProductIdUsingGETRaw(requestParameters: GetDisplayProductByDisplayProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DisplayProductMember2Dto>> {
        if (requestParameters.displayProductId === null || requestParameters.displayProductId === undefined) {
            throw new runtime.RequiredError('displayProductId','Required parameter requestParameters.displayProductId was null or undefined when calling getDisplayProductByDisplayProductIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/{displayProductId}`.replace(`{${"displayProductId"}}`, encodeURIComponent(String(requestParameters.displayProductId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductMember2DtoFromJSON(jsonValue));
    }

    /**
     * getDisplayProductByDisplayProductId
     */
    async getDisplayProductByDisplayProductIdUsingGET(requestParameters: GetDisplayProductByDisplayProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DisplayProductMember2Dto> {
        const response = await this.getDisplayProductByDisplayProductIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByHashtagNameAdmin
     */
    async getDisplayProductByHashtagNameAdminUsingGETRaw(requestParameters: GetDisplayProductByHashtagNameAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        if (requestParameters.hashtagName === null || requestParameters.hashtagName === undefined) {
            throw new runtime.RequiredError('hashtagName','Required parameter requestParameters.hashtagName was null or undefined when calling getDisplayProductByHashtagNameAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/hashtag/{hashtagName}`.replace(`{${"hashtagName"}}`, encodeURIComponent(String(requestParameters.hashtagName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * getDisplayProductByHashtagNameAdmin
     */
    async getDisplayProductByHashtagNameAdminUsingGET(requestParameters: GetDisplayProductByHashtagNameAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.getDisplayProductByHashtagNameAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedDisplayProductByCategoryId
     */
    async getPagedDisplayProductByCategoryIdUsingGETRaw(requestParameters: GetPagedDisplayProductByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedDisplayProductByCategoryIdUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedDisplayProductByCategoryIdUsingGET.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getPagedDisplayProductByCategoryIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedDisplayProductByCategoryId
     */
    async getPagedDisplayProductByCategoryIdUsingGET(requestParameters: GetPagedDisplayProductByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedDisplayProductByCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedDisplayProductByHashtagName
     */
    async getPagedDisplayProductByHashtagNameUsingGETRaw(requestParameters: GetPagedDisplayProductByHashtagNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedDisplayProductByHashtagNameUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedDisplayProductByHashtagNameUsingGET.');
        }

        if (requestParameters.hashtagName === null || requestParameters.hashtagName === undefined) {
            throw new runtime.RequiredError('hashtagName','Required parameter requestParameters.hashtagName was null or undefined when calling getPagedDisplayProductByHashtagNameUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/hashtag/{hashtagName}`.replace(`{${"hashtagName"}}`, encodeURIComponent(String(requestParameters.hashtagName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedDisplayProductByHashtagName
     */
    async getPagedDisplayProductByHashtagNameUsingGET(requestParameters: GetPagedDisplayProductByHashtagNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedDisplayProductByHashtagNameUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * searchDisplayProductAdmin
     */
    async searchDisplayProductAdminUsingPOSTRaw(requestParameters: SearchDisplayProductAdminUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        if (requestParameters.searchString === null || requestParameters.searchString === undefined) {
            throw new runtime.RequiredError('searchString','Required parameter requestParameters.searchString was null or undefined when calling searchDisplayProductAdminUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.searchString !== undefined) {
            queryParameters['searchString'] = requestParameters.searchString;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * searchDisplayProductAdmin
     */
    async searchDisplayProductAdminUsingPOST(requestParameters: SearchDisplayProductAdminUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.searchDisplayProductAdminUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * searchDisplayProduct
     */
    async searchDisplayProductUsingPOSTRaw(requestParameters: SearchDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        if (requestParameters.searchString === null || requestParameters.searchString === undefined) {
            throw new runtime.RequiredError('searchString','Required parameter requestParameters.searchString was null or undefined when calling searchDisplayProductUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchString !== undefined) {
            queryParameters['searchString'] = requestParameters.searchString;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * searchDisplayProduct
     */
    async searchDisplayProductUsingPOST(requestParameters: SearchDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.searchDisplayProductUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setDisplayProductOrder
     */
    async setDisplayProductOrderUsingPOST2Raw(requestParameters: SetDisplayProductOrderUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.displayProductIds === null || requestParameters.displayProductIds === undefined) {
            throw new runtime.RequiredError('displayProductIds','Required parameter requestParameters.displayProductIds was null or undefined when calling setDisplayProductOrderUsingPOST2.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling setDisplayProductOrderUsingPOST2.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.displayProductIds !== undefined) {
            queryParameters['displayProductIds'] = requestParameters.displayProductIds;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/category/order/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * setDisplayProductOrder
     */
    async setDisplayProductOrderUsingPOST2(requestParameters: SetDisplayProductOrderUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.setDisplayProductOrderUsingPOST2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setDisplayProductOrder
     */
    async setDisplayProductOrderUsingPOST3Raw(requestParameters: SetDisplayProductOrderUsingPOST3Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.displayProductIds === null || requestParameters.displayProductIds === undefined) {
            throw new runtime.RequiredError('displayProductIds','Required parameter requestParameters.displayProductIds was null or undefined when calling setDisplayProductOrderUsingPOST3.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.displayProductIds !== undefined) {
            queryParameters['displayProductIds'] = requestParameters.displayProductIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/order/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * setDisplayProductOrder
     */
    async setDisplayProductOrderUsingPOST3(requestParameters: SetDisplayProductOrderUsingPOST3Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.setDisplayProductOrderUsingPOST3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateDisplayProductOrder
     */
    async updateDisplayProductOrderUsingPOSTRaw(requestParameters: UpdateDisplayProductOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DisplayProductDto>> {
        if (requestParameters.displayProductId === null || requestParameters.displayProductId === undefined) {
            throw new runtime.RequiredError('displayProductId','Required parameter requestParameters.displayProductId was null or undefined when calling updateDisplayProductOrderUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.displayProductId !== undefined) {
            queryParameters['displayProductId'] = requestParameters.displayProductId;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/updateDisplayProductOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductDtoFromJSON(jsonValue));
    }

    /**
     * updateDisplayProductOrder
     */
    async updateDisplayProductOrderUsingPOST(requestParameters: UpdateDisplayProductOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DisplayProductDto> {
        const response = await this.updateDisplayProductOrderUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateDisplayProduct
     */
    async updateDisplayProductUsingPOSTRaw(requestParameters: UpdateDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DisplayProductDto>> {
        if (requestParameters.displayProductId === null || requestParameters.displayProductId === undefined) {
            throw new runtime.RequiredError('displayProductId','Required parameter requestParameters.displayProductId was null or undefined when calling updateDisplayProductUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.displayProductId !== undefined) {
            queryParameters['displayProductId'] = requestParameters.displayProductId;
        }

        if (requestParameters.displayProductNameChi !== undefined) {
            queryParameters['displayProductNameChi'] = requestParameters.displayProductNameChi;
        }

        if (requestParameters.displayProductNameEng !== undefined) {
            queryParameters['displayProductNameEng'] = requestParameters.displayProductNameEng;
        }

        if (requestParameters.displayProductPrice !== undefined) {
            queryParameters['displayProductPrice'] = requestParameters.displayProductPrice;
        }

        if (requestParameters.displayProductImage !== undefined) {
            queryParameters['displayProductImage'] = requestParameters.displayProductImage;
        }

        if (requestParameters.displayProductDescriptionDetail !== undefined) {
            queryParameters['displayProductDescriptionDetail'] = requestParameters.displayProductDescriptionDetail;
        }

        if (requestParameters.displayProductDescriptionFeature !== undefined) {
            queryParameters['displayProductDescriptionFeature'] = requestParameters.displayProductDescriptionFeature;
        }

        if (requestParameters.displayProductDescriptionIngredient !== undefined) {
            queryParameters['displayProductDescriptionIngredient'] = requestParameters.displayProductDescriptionIngredient;
        }

        if (requestParameters.displayProductDescriptionUsage !== undefined) {
            queryParameters['displayProductDescriptionUsage'] = requestParameters.displayProductDescriptionUsage;
        }

        if (requestParameters.displayProductDescriptionUseCount !== undefined) {
            queryParameters['displayProductDescriptionUseCount'] = requestParameters.displayProductDescriptionUseCount;
        }

        if (requestParameters.displayProductDescriptionTarget !== undefined) {
            queryParameters['displayProductDescriptionTarget'] = requestParameters.displayProductDescriptionTarget;
        }

        if (requestParameters.functionalIcon !== undefined) {
            queryParameters['functionalIcon'] = requestParameters.functionalIcon;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.categoryIds !== undefined) {
            queryParameters['categoryIds'] = requestParameters.categoryIds;
        }

        if (requestParameters.productIds !== undefined) {
            queryParameters['productIds'] = requestParameters.productIds;
        }

        if (requestParameters.hashtag !== undefined) {
            queryParameters['hashtag'] = requestParameters.hashtag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/displayProduct/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductDtoFromJSON(jsonValue));
    }

    /**
     * updateDisplayProduct
     */
    async updateDisplayProductUsingPOST(requestParameters: UpdateDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DisplayProductDto> {
        const response = await this.updateDisplayProductUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
