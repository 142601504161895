// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-color-setting {
  width: calc(100% - 44px);
  margin: 10px 22px 0px;
}
.background-color-text {
  height: 20px;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: -5px;
  margin-top: 15px;
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.background-color-list {
  margin: 10px 0px 0px;
  width: 100%;
  min-height: 44px;
  padding-bottom: 6px;
}
.background-color-circle {
  display: inline-block;
  width: 35px;
  height: 35px;
  font-size: 20px;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  margin: 7px;
  margin-top: 3px;
  box-sizing: border-box;
  position: relative;
}

.background-color-circle .icon-more {
  position: absolute;
  margin: 8px;
}
.background-color-circle .icon-check {
  position: absolute;
  margin: 8px;
  font-weight: bold;
}
.background-color-circle .icon-close {
  position: absolute;
  margin: 6px;
  font-size: 19px;
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/components/Reader/readerSettings/themeList/themeList.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,UAAU;IACV,mBAAmB;EACrB;AACF;AACA;EACE,oBAAoB;EACpB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,WAAW;EACX,eAAe;EACf,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,YAAY;AACd","sourcesContent":[".background-color-setting {\n  width: calc(100% - 44px);\n  margin: 10px 22px 0px;\n}\n.background-color-text {\n  height: 20px;\n  width: 100%;\n  font-size: 15px;\n  font-weight: 500;\n  margin-bottom: -5px;\n  margin-top: 15px;\n}\n@keyframes fade-in {\n  0% {\n    opacity: 0;\n    transform: scale(0.8);\n  }\n  100% {\n    opacity: 1;\n    transform: scale(1);\n  }\n}\n.background-color-list {\n  margin: 10px 0px 0px;\n  width: 100%;\n  min-height: 44px;\n  padding-bottom: 6px;\n}\n.background-color-circle {\n  display: inline-block;\n  width: 35px;\n  height: 35px;\n  font-size: 20px;\n  border-radius: 50%;\n  opacity: 1;\n  cursor: pointer;\n  margin: 7px;\n  margin-top: 3px;\n  box-sizing: border-box;\n  position: relative;\n}\n\n.background-color-circle .icon-more {\n  position: absolute;\n  margin: 8px;\n}\n.background-color-circle .icon-check {\n  position: absolute;\n  margin: 8px;\n  font-weight: bold;\n}\n.background-color-circle .icon-close {\n  position: absolute;\n  margin: 6px;\n  font-size: 19px;\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
