import { Link, Outlet, useNavigate, useOutletContext } from "react-router-dom";
import LowerNavBar from "../../components/NavBar/LowerNavBar";
import React, { useState } from "react";
import style from "./LoginPage.module.css";
import loginBird from "../../assets/images/cts_birds.svg";
import { useTranslation } from "react-i18next";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../../assets/images/CTSLOG.svg";
import shoppingCartIcon from "../../assets/icons/cart.svg";
import favIcon from "../../assets/icons/fav.svg";
import bookshelfIcon from "../../assets/icons/bookshelf.svg";
import { useSelector } from "react-redux";
import { deleteMemberInformation } from "../../redux/actions/MemberAction";
import { clearShoppingCart } from "../../redux/actions/ShoppingCartAction";
import useWindowSize from "../../utils/useWindowSize";
import back from "../../assets/btn/back.svg";
import SideMenu from "../test/SideMenu";

interface ILng {
  nativeName: string;
}
interface ILngs {
  en: ILng;
  tw: ILng;
  cn: ILng;
}

const lngs: ILngs = {
  en: { nativeName: "ENG" },
  tw: { nativeName: "繁" },
  cn: { nativeName: "簡" },
};

export default function LoginLayout() {
  const [serachString, setSearchString] = React.useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [gospel, setGospel] = React.useState("");
  const member = useSelector((state: any) => state.MemberReducer);
  const quantity = useSelector(
    (state: any) => state.shoppingCartReducer.quantity
  );

  function navigateToSearch(searchString: string) {
    console.log("searchString", searchString);
    if (searchString === "" || searchString === null) {
      return;
    }
    navigate("search?querystring=" + searchString);
  }

  const logout = () => {
    deleteMemberInformation();
    clearShoppingCart();
    navigate("/");
  };

  React.useEffect(() => {
    fetchGospel();
  }, [i18n.language]);
  const fetchGospel = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND}gospel/current?date=${
        new Date().toISOString().split("T")[0]
      }`
    )
      .then((res) => res.json())
      .then((res: any) => {
        if (i18n.language === "en") {
          setGospel(res.engContent);
        } else {
          setGospel(res.chnContent);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };
  const { width, height } = useWindowSize();
  const [searchbtn, setSearchBtn] = useState(true);
  const [menuOut, setMenuOut] = useState(false);

  return (
    <>
      <nav className={style.navbar}>
        <div className={style.upperNavbar}>
          <div className={style.upperInnerNavbar}>
            {width > 1024 ? (
              <>
                <div
                  className={style.logoContainer}
                  onClick={() => navigate("/")}
                >
                  <img className={style.logo} src={logo} alt={"description"} />
                  <h2 className={style.logoTitle}>{t("header.logoName")}</h2>
                </div>
                <div className={style.searchContainer}>
                  <div className={style.searchInnerContainer}>
                    <Input
                      type={"text"}
                      styleType={"searchInput"}
                      placeholder={"header.searchPlaceholder"}
                      callBack={(value) => setSearchString(value)}
                      onKeyDown={{ action: navigate, value: serachString }}
                    />
                    <Button
                      styleType={"searchButtom"}
                      callBack={() => navigateToSearch(serachString)}
                    >
                      <SearchIcon />
                    </Button>
                  </div>
                </div>
                <div className={style.loginContainer}>
                  {member.member_first_name !== null ? (
                    <>
                      <p
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => navigate("user")}
                      >
                        {member.member_first_name}
                      </p>
                      <p
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => logout()}
                      >
                        {t("header.logout")}
                      </p>
                    </>
                  ) : (
                    <Link to="login">
                      <Button
                        styleType={"upperNavbarButton"}
                        callBack={() => console.log(1)}
                      >
                        {t("header.login")}
                      </Button>
                    </Link>
                  )}
                  {member.token !== null ? (
                    <div className={style.iconContainer}>
                      <div
                        className={style.shoppingCartIcon}
                        onClick={() => navigate("shoppingCart")}
                      >
                        <img src={shoppingCartIcon} alt="" />
                        {quantity === 0 ? (
                          <></>
                        ) : (
                          <div className={style.redDot}>
                            <p style={{ fontSize: "8px", color: "white" }}>
                              {quantity}
                            </p>
                          </div>
                        )}
                      </div>
                      <img
                        src={favIcon}
                        alt=""
                        onClick={() => navigate("favourite")}
                      />
                      <img
                        src={bookshelfIcon}
                        alt=""
                        onClick={() => navigate("bookshelf")}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={style.languageContainer}>
                  <div className={style.languageInnerContainer}>
                    {Object.keys(lngs).map((lng) => (
                      <span
                        style={{
                          margin: "3px",
                          cursor: "pointer",
                          color: "rgb(20, 61, 72)",
                          fontSize: "14px",
                        }}
                        onClick={() => i18n.changeLanguage(lng)}
                      >
                        {lngs[lng as keyof ILngs].nativeName}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className={style.upper}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: searchbtn ? "contents" : "none" }}>
                    <span
                      className={style.SideBtn}
                      style={{ height: "40px" }}
                      onClick={() => setMenuOut(!menuOut)}
                    >
                      {menuOut ? "X" : "☰"}
                    </span>
                    <div
                      style={{ display: searchbtn ? "contents" : "none" }}
                      className={style.logoContainer}
                      onClick={() => navigate("/")}
                    >
                      <img
                        className={style.logo}
                        src={logo}
                        alt={"description"}
                      />
                      <h2 className={style.logoTitle}>
                        {t("header.logoName")}
                      </h2>
                    </div>
                  </div>
                </div>
                {searchbtn ? (
                  <div className={style.displayRight}>
                    <SearchIcon onClick={() => setSearchBtn(!searchbtn)} />
                  </div>
                ) : (
                  <>
                    <img
                      src={back}
                      onClick={() => setSearchBtn(!searchbtn)}
                      style={{ height: "30px" }}
                    ></img>
                    <div
                      className={style.displayRight}
                      style={{ width: "100%" }}
                    >
                      <Input
                        type={"text"}
                        styleType={"searchInput"}
                        placeholder={"header.searchPlaceholder"}
                        callBack={(value) => setSearchString(value)}
                        onKeyDown={{ action: navigate, value: serachString }}
                      />
                      <Button
                        styleType={"searchButtom"}
                        callBack={() => navigateToSearch(serachString)}
                      >
                        <SearchIcon />
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <LowerNavBar gospel={gospel} />
        {/* {width <= 768 ? <></> : <LowerNavBar gospel={gospel} />} */}
      </nav>
      <div className={style.loginBackground}>
        {/* <h1>LoginPage</h1>
      <Button callBack={(value) => handleLogin(value)}>Login</Button> */}

        <div className={style.formContainer}>
          <img
            src={loginBird}
            alt=""
            style={{
              position: "absolute",
              width: "1000px",
              display: width > 1024 ? "flex" : "none",
            }}
          />
          <div className={style.loginForm}>
            <Outlet />
          </div>
        </div>
      </div>
      <div style={{ display: menuOut ? "contents" : "none" }}>
        <SideMenu menuOut={menuOut} setMenuOut={setMenuOut} />
      </div>
    </>
  );
}
