// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --color--white-reduce30: #B2B2B2;
    --color--white-reduce15: #D9D9D9;
    --color--white-reduce15-alpha50: #D9D9D980;
    --color--white: #FFFFFF;

    --color--white--reduce:#fdefe8;

    --color-brown-reduce30: #6A402A;
    --color-brown-reduce15: #824F33;
    --color-brown: #995D3D;
    --color-brown-add30: #B78D77;
    --color-brown-add70: #E0CEC5;
    --color-brown-add90: #F5EFEB;

    --color-black-reduce30: #212121;
    --color-black-reduce15: #282828;
    --color-black: #303030;
    --color-black-add30: #6E6E6E;
    --color-black-add70: #C1C1C1;
    --color-black-add90: #EAEAEA;
    --color-black-re1 : #333333;
    --color-black-re12 :#123533;
    --color-black-alpha30: rgb(0 0 0 / 30%);
    
    --color-orange-reduce30: #9E5529;
    --color-orange-reduce15: #C16833;
    --color-orange: #E37B3C;
    --color-orange-add30: #EBA276;
    --color-orange-add70: #F6D7C4;
    --color-orange-add90: #FCF2EB;

    --color-orange-add: #ef986f;

    --color-organge-main:#dd7636;
    --color-red-main : #b53c3f;

    --color-blue-add25: #47D5CD;
    --color-blue-add30: #47D5CD4D;
    --color-blue-add75: #bfe6e8;
    --color-blue-add1: #009da5;
    --color-blue-reduce50:  #214753;
}`, "",{"version":3,"sources":["webpack://./src/assets/color.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,gCAAgC;IAChC,0CAA0C;IAC1C,uBAAuB;;IAEvB,8BAA8B;;IAE9B,+BAA+B;IAC/B,+BAA+B;IAC/B,sBAAsB;IACtB,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;;IAE5B,+BAA+B;IAC/B,+BAA+B;IAC/B,sBAAsB;IACtB,4BAA4B;IAC5B,4BAA4B;IAC5B,4BAA4B;IAC5B,2BAA2B;IAC3B,2BAA2B;IAC3B,uCAAuC;;IAEvC,gCAAgC;IAChC,gCAAgC;IAChC,uBAAuB;IACvB,6BAA6B;IAC7B,6BAA6B;IAC7B,6BAA6B;;IAE7B,2BAA2B;;IAE3B,4BAA4B;IAC5B,0BAA0B;;IAE1B,2BAA2B;IAC3B,6BAA6B;IAC7B,2BAA2B;IAC3B,0BAA0B;IAC1B,+BAA+B;AACnC","sourcesContent":[":root{\n    --color--white-reduce30: #B2B2B2;\n    --color--white-reduce15: #D9D9D9;\n    --color--white-reduce15-alpha50: #D9D9D980;\n    --color--white: #FFFFFF;\n\n    --color--white--reduce:#fdefe8;\n\n    --color-brown-reduce30: #6A402A;\n    --color-brown-reduce15: #824F33;\n    --color-brown: #995D3D;\n    --color-brown-add30: #B78D77;\n    --color-brown-add70: #E0CEC5;\n    --color-brown-add90: #F5EFEB;\n\n    --color-black-reduce30: #212121;\n    --color-black-reduce15: #282828;\n    --color-black: #303030;\n    --color-black-add30: #6E6E6E;\n    --color-black-add70: #C1C1C1;\n    --color-black-add90: #EAEAEA;\n    --color-black-re1 : #333333;\n    --color-black-re12 :#123533;\n    --color-black-alpha30: rgb(0 0 0 / 30%);\n    \n    --color-orange-reduce30: #9E5529;\n    --color-orange-reduce15: #C16833;\n    --color-orange: #E37B3C;\n    --color-orange-add30: #EBA276;\n    --color-orange-add70: #F6D7C4;\n    --color-orange-add90: #FCF2EB;\n\n    --color-orange-add: #ef986f;\n\n    --color-organge-main:#dd7636;\n    --color-red-main : #b53c3f;\n\n    --color-blue-add25: #47D5CD;\n    --color-blue-add30: #47D5CD4D;\n    --color-blue-add75: #bfe6e8;\n    --color-blue-add1: #009da5;\n    --color-blue-reduce50:  #214753;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
