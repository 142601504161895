import React, { useEffect, useState } from "react";
import style from "./FrequentlyQuestions.module.css";
import * as API from "../../api/PageContentAPI";

export default function FrequentlyQuestions() {
  const [pageContent, setPageContent] = useState("");
  useEffect(() => {
    API.getPageContentById(3).then((res) => setPageContent(res.content));
  }, []);
  return (
    <div
      className={style.container}
      dangerouslySetInnerHTML={{ __html: pageContent }}
    ></div>
    // <div className={style.container}>
    //   <h1>常見問題</h1>
    //   <h3>Q加入「天主教香港教區電子書平台」（或「本平台」）會員需要付費嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     您只需設定您的個人會員帳號及密碼及提供基本資料即可加入，此服務均為免費。
    //   </p>
    //   <h3>Q成為會員有甚麼會員專享優惠？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     (1) 會員帳戶內記錄您的訂單明細、e-coupon明細，購買與待購清單，一覽無遺；
    //   </p>
    //   <p>會員專屬活動（不定期舉行）。</p>
    //   <p>(2) 即享首次購物優惠；</p>
    //   <p>(3) 生日購物優惠；</p>
    //   <p>(4) 專屬活動推廣優惠（不定期舉行）</p>
    //   <p>(5) 定期接收電子簡報，不會錯失最新商品資訊及各類活動。</p>
    //   <p>
    //     (6)
    //     加入會員後，您可以隨時登入會員享受閱讀，查詢訂單的處理進度，更新您的資料。
    //   </p>
    //   <h3>Q非會員可以使用「本平台」購書嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     目前「天主教香港教區電子書平台」僅提供會員訂購服務。如果您未登記成為會員，建成訂單前需請您先加入會員，加入會員是完全免費的。
    //   </p>
    //   <p>
    //     閣下需自行於網路上建成訂單，客服人員無法代您訂購，亦無法以電話、E-mail或傳真等其他方式下訂單。
    //   </p>
    //   <h3>Q為甚麼我無法註冊成為會員？</h3>
    //   <span>ANS： </span>
    //   <p>常見原因如下：</p>
    //   <p>忘記填寫「必須填寫」的欄目；或</p>
    //   <p>未點選「本人同意並閱讀私隱條款」；或</p>
    //   <p>
    //     同一電郵重複註冊；如果一直無法註冊成為會員，建議改用其他電郵註冊帳戶。
    //   </p>
    //   <h3>Q我註冊了帳戶，但一直收不到確認信，怎麼辦？</h3>
    //   <p>
    //     <span>ANS：</span>
    //     <p>
    //       如在電郵收件匣找不到確認信，你可以到「垃圾郵件」中查看。Hotmail、Yahoo等電郵服務備有過濾功能，間中會將確認信誤判為「垃圾郵件」。如果一直都收不到確認信，建議改用Gmail註冊帳戶。
    //     </p>
    //   </p>
    //   <h3>Q為甚麼我一直無法登入？</h3>
    //   <p>
    //     <span>ANS：</span>
    //     <p>
    //       請重新填寫登入帳戶和密碼，並確保資料無誤。你也可能未完成會員註冊程序，使帳號並未完成開通。請檢查郵箱，再點選認證電郵中的「確認連結」，正式啟用會員帳戶。
    //     </p>
    //   </p>
    //   <h3>Q忘記帳號密碼，或收不到認證電郵？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     到登入會員的入口處輸入已登記之電郵地址，系統將直接以電子郵件將帳號及密碼寄至您的信箱。
    //   </p>
    //   <h3>Q忘記了用戶名稱和密碼，需要重新登記嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     如果您忘記了用戶名稱及密碼，請發送電郵給我們，郵件主題請註明「忘記登入資料及密碼」，並請提供已登記之電話號碼及您的聯絡電話號碼，我們將回覆您。
    //   </p>
    //   <hr className={style.horizontalLine}></hr>
    //   <h1>購物需知</h1>
    //   <h3>Q首次購物是如何操作？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     「本平台」提供簡單又安全的交易環境，第一次到「本平台」購物，請先加入會員，加入會員完全免費。註冊完成後，系統會自動寄發認證信到您註冊時所填寫的電子信箱中，透過認證信的連結，即可開啟您的會員帳號，您就可以在「本平台」輕鬆購物。
    //   </p>
    //   <h3>Q購物流程是怎樣的？</h3>
    //   <span>ANS：</span>
    //   <p>(1)購物前，請先點選會員登入，輸入會員帳號、密碼進行登入。</p>
    //   <p>(2)將商品加入購物車內。</p>
    //   <p>(3)確認購物車內的商品。</p>
    //   <p>(4)選擇付款方式及結帳。</p>
    //   <p>(5)當您完成購物手續後，隨即以電子郵件通知您，確認完成購物。</p>
    //   <p>
    //     (6)完成付款後，可於會員賬戶內「我的書架」下載已購買的電子書並開始閱讀。
    //   </p>
    //   <h3>Q我可否修改或取消網上訂單？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     訂單一經確認，將不獲修改、取消或退款，請於付款前再次確認你所選購的商品。
    //   </p>
    //   <h3>Q「本平台」的售價或活動與實體門市不同嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     目前部分商品價格、活動僅適用於「本平台」，請以「本平台」公告或活動說明為主；有關實體門市之商品價格及優惠活動，請以實體門市公告為主。
    //   </p>
    //   <hr className={style.horizontalLine}></hr>
    //   <h1>付款</h1>
    //   <h3>Q網上購物可怎樣付款？</h3>
    //   <span>ANS：</span>
    //   <p>目前「本平台」所有交易均需經BOC Pay付款。</p>
    //   <h3>Q我可以退款或退換嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     一般情況下，訂單如已提交並付款，恕不接受退款。請於付款前再次確認你所選購的商品。
    //   </p>
    //   <h3>Q如何使用 e-coupon</h3>
    //   <span>ANS：</span>
    //   <p>(1) 使用方式</p>
    //   <h4 style={{ margin: "0 0 .5em 1em" }}>Step 1． 登入會員</h4>
    //   <h4 style={{ margin: "0 0 .5em 1em" }}>Step 2．將商品放入購物車</h4>
    //   <h4 style={{ margin: "0 0 .5em 1em" }}>Step 3．勾選可使用的 e-coupon</h4>
    //   <h4 style={{ margin: "0 0 10px 1em" }}>進入結帳</h4>
    //   <p>(2) 每張訂單最多可使用1張 e-coupon。</p>
    //   <p>(3) 每張 e-coupon 各有其使用期限，請於訂購結帳時安排使用</p>
    //   <hr className={style.horizontalLine}></hr>
    //   <h1>有關電子書</h1>
    //   <h3>Q電子書有試讀功能嗎？</h3>
    //   <span>ANS：</span>
    //   <p>一般情況下，大部分電子書籍都有提供試閱功能。</p>{" "}
    //   <h3>Q閱覽電子書有時間限制的嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     閱覽電子書沒有時間限制，購買之後，可隨時透過登入戶口閱讀已訂購之讀物。
    //   </p>
    //   <h3>Q購買電子書付款完成後，可即時閱讀嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     「本平台」於確認收到款項後，就會將訂購的電子書發送至「我的書櫃」。若付款完成後仍未在「我的書櫃」收到商品，請與我們聯絡。
    //   </p>
    //   <h3>Q電子書商品可有退換服務？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     「本平台」大多數電子書已提供試讀功能，購買後恕不提供退換，購買前請務必先行試閱，確認有購買該項商品之需求及意願時始下單購買。
    //   </p>
    //   <h3>Q在電腦、手機、平板上如何閱讀電子書？需要安裝軟件或是App 嗎？</h3>
    //   <span>ANS：</span>
    //   <h4 className={style.textDecoration}>使用桌上電腦或筆記本電腦閱讀</h4>
    //   <p>
    //     會員可隨時以 Windows 或 MacOS 登入戶口並閱讀電子書。只要使用 Chrome 或
    //     Firefox 等瀏覽器，前往我的書架，即可閱讀電子書。
    //   </p>
    //   <h4 className={style.textDecoration}>使用 iOS 裝置</h4>
    //   <p>
    //     iOS 用戶請前往 Apple Store 安裝「天主教香港教區電子書平台」
    //     App閱讀，給您提供良好的閱讀體驗。
    //   </p>
    //   <h4 className={style.textDecoration}>使用 Android 裝置</h4>
    //   <p>
    //     Android 用戶請前往 Google Play
    //     商店安裝「天主教香港教區電子書平台」App閱讀，給您提供良好的閱讀體驗。
    //   </p>
    //   <h3>Q沒有網路的時候，可以閱讀電子書嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     可以。請下載「天主教香港教區電子書平台」
    //     App，並於完成購買後，在我的書架內完成下載已購買的書籍，即可離線閱讀該電子書。
    //   </p>
    //   <h3>Q下載電子書後，可以列印、複製或轉寄給他人嗎？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     為了保障出版商和其他讀者的利益，我們不會提供可用作列印、複製或轉寄給他人的版本。
    //   </p>
    //   <h3>Q電子書商品下載後，打開卻沒有任何內容，怎麼辦？</h3>
    //   <span>ANS：</span>
    //   <p>
    //     若您開啟電子書或讀物時出現異常訊息，或開啟後沒有任何內容（如：黑頁、空白頁、頁碼顯示
    //     0/0...等），可能是電子書、雜誌檔案下載失敗或未完全造成。請將書籍刪除後重新下載。
    //   </p>
    //   <hr className={style.horizontalLine}></hr>
    //   <h1>購買實體書</h1>
    //   <h3></h3>
    //   <p>
    //     有關實體書之訂購由「公教進行社」負責及管理。當會員點選購買實體書後，隨即會轉到公教進行社網站{" "}
    //   </p>
    //   <a className={style.link} href="www.catholiccentre.org.hk。">
    //     按此
    //   </a>
    //   <p>如需查詢購買實體書，可與公教進行社聯絡：</p>
    //   <p>地址：香港中環干諾道中15-18號大昌大廈2樓, </p>
    //   <p>電話：(852) 2522-1071, (852) 2525-7063</p>
    //   <p>傳真：(852) 2521-7969</p>
    //   <p>
    //     電子郵箱：
    //     <a href="bookshop@catholiccentre.org.hk">
    //       bookshop@catholiccentre.org.hk
    //     </a>
    //   </p>
    // </div>
  );
}
