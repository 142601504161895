import style from "./SideMenu.module.css";
import { useTranslation } from "react-i18next";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import twitter from "../../assets/images/whatsapp.png";
import { Link, useNavigate } from "react-router-dom";
import { deleteMemberInformation } from "../../redux/actions/MemberAction";
import { clearShoppingCart } from "../../redux/actions/ShoppingCartAction";
import Button from "../../components/Button/Button";
import shoppingCartIcon from "../../assets/icons/cart.svg";
import favIcon from "../../assets/icons/fav.svg";
import bookshelfIcon from "../../assets/icons/bookshelf.svg";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_RIDIRECT_PATHNAME } from "../../utils/Constant";
import * as CategoryAPI from "../../api/CategoryAPI";
import { useState, useEffect } from "react";
import up from "../../assets/btn/up2.svg";
import down from "../../assets/btn/down.svg";
import next from "../../assets/btn/next.svg";
import back from "../../assets/btn/back.svg";
import { store } from "../../redux/store";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

interface SideProp {
  menuOut?: boolean;
  setMenuOut: Function;
  callBack?: (value: any) => void;
}

interface ILng {
  nativeName: string;
}

interface ILngs {
  en: ILng;
  tw: ILng;
  cn: ILng;
}

const lngs: ILngs = {
  en: { nativeName: "ENG" },
  tw: { nativeName: "繁" },
  cn: { nativeName: "簡" },
};

export default function SideMenu(props: SideProp) {
  // const [book, setBook] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [displayCategory, setDisplayCategory] = useState([]);
  const categoryList = async () => {
    const categories = await CategoryAPI.getAll();
    setDisplayCategory(categories);
  };
  useEffect(() => {
    categoryList();
  }, []);

  return (
    <div className={props.menuOut ? style.showOut : ""}>
      <div className={style.head}>
        <div className={style.header}>
          <span
            className={style.closeBtn}
            style={{ height: "40px" }}
            onClick={() => {
              props.setMenuOut!(false);
              // setBook(false);
            }}
          >
            {props.menuOut ? "X" : "☰"}
          </span>
          <div className={style.displayRight}>
            <div className={style.languageInnerContainer}>
              {Object.keys(lngs).map((lng) => (
                <span
                  className={style.language}
                  onClick={() => i18n.changeLanguage(lng)}
                >
                  {lngs[lng as keyof ILngs].nativeName}
                </span>
              ))}
            </div>
          </div>
        </div>
        {/* {book ? (
          <div className={style.book} onClick={() => setBook(false)}>
            <div>{t("back")}</div>
            <img src={back} style={{ height: "30px" }}></img>
          </div>
        ) : (
          <div className={style.book} onClick={() => setBook(true)}>
            <div>{t("category.bookClassifications")}</div>
            <img src={next} style={{ height: "30px" }}></img>
          </div>
        )} */}
      </div>
      <div className={style.body}>
        <UpperSide props={displayCategory} setMenuOut={props.setMenuOut} />
        <LowerSide props={displayCategory} setMenuOut={props.setMenuOut} />
        <Extra setMenuOut={props.setMenuOut} />
        <Other setMenuOut={props.setMenuOut} />
        {/* {book ? (
          <> 
            <UpperSide props={displayCategory} setMenuOut={props.setMenuOut} />
            <LowerSide props={displayCategory} setMenuOut={props.setMenuOut} />
            <Extra setMenuOut={props.setMenuOut}>
            <Other setMenuOut={props.setMenuOut} />
          </>
        ) : (
          <Other setMenuOut={props.setMenuOut} book={book} />
        )} */}
      </div>
      {/* <Foot setMenuOut={props.setMenuOut} /> */}
    </div>
  );
}

interface IProps {
  list: IBookDate[];
}

interface IBookDate {
  categoryId: number;
  categoryName: string;
}

function UpperSide({ props, setMenuOut }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {props &&
        props
          .filter((x: any) => x.categoryId > 9999 && x.isHeader !== 1)
          .map((item: any) => (
            <li
              key={item.categoryId}
              className={style.upperSideNavList}
              onClick={() => {
                navigate("/category/" + item.categoryId);
                setMenuOut(false);
              }}
            >
              {t(`category.${item.categoryId}`)}
            </li>
          ))}
    </>
  );
}

function LowerSide({ props, setMenuOut }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(true);

  return (
    <div className={style.footerLinkDetails}>
      {hidden ? (
        <div>
          <h3 onClick={() => setHidden(!hidden)}>
            {t("category.bookClassifications")}{" "}
            <KeyboardArrowLeft className={style.arrow} />
          </h3>{" "}
        </div>
      ) : (
        <div>
          <h3 onClick={() => setHidden(!hidden)}>
            {t("category.bookClassifications")}{" "}
            <KeyboardArrowDown className={style.arrow} />
          </h3>{" "}
        </div>
      )}
      {hidden
        ? null
        : props &&
          props
            .filter((x: any) => x.categoryId < 10000 && x.isHeader !== 1)
            .map((item: any) => (
              <div className={style.btnContainer2}>
                <div
                  key={item.categoryId}
                  className={style.btn}
                  onClick={() => {
                    navigate(`/category/${item.categoryId}/`);
                    setMenuOut(false);
                  }}
                >
                  {t(`category.${item.categoryId}`)} ({item.productCount })
                </div>
              </div>
            ))}

      {/* <div className={style.btnContainer}>
        {props &&
          props
            .filter((x: any) => x.categoryId < 10000 && x.isHeader !== 1)
            .map((item: any) => (
              <div
                key={item.categoryId}
                className={style.btn}
                onClick={() => {
                  navigate(`/category/${item.categoryId}/`);
                  setMenuOut(false);
                }}
              >
                {t(`category.${item.categoryId}`)}
              </div>
            ))}
      </div> */}
    </div>
  );
}

function Extra(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div
        className={style.upperSideNavList}
        onClick={() => {
          navigate("events");
          props.setMenuOut(false);
        }}
      >
        {t("header.events")}
      </div>
      <div
        className={style.upperSideNavList}
        onClick={() => {
          navigate("news");
          props.setMenuOut(false);
        }}
      >
        {t("header.news")}
      </div>
      <div
        className={style.upperSideNavList}
        onClick={() => {
          navigate("FAQ");
          props.setMenuOut(false);
        }}
      >
        {t("header.faq")}
      </div>
    </>
  );
}

function Other(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hidden1, setHidden1] = useState(true);
  const [hidden2, setHidden2] = useState(true);
  const [hidden3, setHidden3] = useState(true);
  const [hidden4, setHidden4] = useState(true);

  return (
    <>
      <div className={style.footerLinkDetails}>
        {hidden1 ? (
          <div>
            <h3 onClick={() => setHidden1(!hidden1)}>
              {t("footer.aboutUs")}{" "}
              <KeyboardArrowLeft className={style.arrow} />
            </h3>
          </div>
        ) : (
          <div className={style.btnContainer}>
            <h3 onClick={() => setHidden1(!hidden1)}>
              {t("footer.aboutUs")}{" "}
              <KeyboardArrowDown className={style.arrow} />
            </h3>
            <div
              className={style.btn}
              onClick={() => {
                navigate("aboutus");
                props.setMenuOut(false);
              }}
            >
              {t("footer.aboutCTS")}
            </div>
            {/* <div className={style.btnContainerMulti}> */}
            <div
              className={style.btn}
              onClick={() => {
                navigate("PrivacyPolicy");
                props.setMenuOut(false);
              }}
            >
              {t("footer.privacyPolicy")}
            </div>
            <div
              className={style.btn}
              onClick={() => {
                navigate("TermsOfService");
                props.setMenuOut(false);
              }}
            >
              {t("footer.terms")}
            </div>
            <div
              className={style.btn}
              onClick={() => {
                navigate("Disclaimer");
                props.setMenuOut(false);
              }}
            >
              {t("footer.disclaimer")}
            </div>
            {/* </div> */}
          </div>
        )}
      </div>

      <div className={style.footerLinkDetails}>
        {hidden2 ? (
          <div>
            <h3 onClick={() => setHidden2(!hidden2)}>
              {t("footer.services")}{" "}
              <KeyboardArrowLeft className={style.arrow} />
            </h3>
          </div>
        ) : (
          <div className={style.btnContainer}>
            <h3 onClick={() => setHidden2(!hidden2)}>
              {t("footer.services")}{" "}
              <KeyboardArrowDown className={style.arrow} />
            </h3>
            <div
              className={style.btn}
              onClick={() => {
                navigate("BecomeMember");
                props.setMenuOut(false);
              }}
            >
              {t("footer.becomeMember")}
            </div>
            <div
              className={style.btn}
              onClick={() => {
                navigate("beginnerGuide");
                props.setMenuOut(false);
              }}
            >
              {t("footer.beginner")}
            </div>
            <div
              className={style.btn}
              onClick={() => {
                navigate("subscription");
                props.setMenuOut(false);
              }}
            >
              {t("footer.subscribe")}
            </div>
          </div>
        )}
      </div>
      <div className={style.footerLinkDetails}>
        {hidden3 ? (
          <h3 onClick={() => setHidden3(!hidden3)}>
            {t("footer.collaborate")}{" "}
            <KeyboardArrowLeft className={style.arrow} />
          </h3>
        ) : (
          <div className={style.btnContainer}>
            <h3 onClick={() => setHidden3(!hidden3)}>
              {t("footer.collaborate")}{" "}
              <KeyboardArrowDown className={style.arrow} />
            </h3>
            <div
              className={style.btn}
              onClick={() => {
                navigate("joinUs");
                props.setMenuOut(false);
              }}
            >
              {t("footer.joinUs")}
            </div>
            {/* <div className={style.btnContainerMulti}> */}
            <div
              className={style.btn}
              onClick={() => {
                navigate("customer");
                props.setMenuOut(false);
              }}
            >
              {t("footer.customer")}
            </div>
            <div
              className={style.btn}
              onClick={() => {
                navigate("AccountRecovery");
                props.setMenuOut(false);
              }}
            >
              {t("footer.account")}
            </div>
            <div
              className={style.btn}
              onClick={() => {
                navigate("customerService");
                props.setMenuOut(false);
              }}
            >
              {t("footer.customerQ")}
            </div>
            {/* </div> */}
          </div>
        )}
      </div>
      <div className={style.footerLinkDetails}>
        {hidden4 ? (
          <div>
            <h3 onClick={() => setHidden4(!hidden4)}>
              {t("footer.links")} <KeyboardArrowLeft className={style.arrow} />
            </h3>
          </div>
        ) : (
          <div className={style.btnContainer}>
            <h3 onClick={() => setHidden4(!hidden4)}>
              {t("footer.links")} <KeyboardArrowDown className={style.arrow} />
            </h3>
            <div
              className={style.btn}
              onClick={() =>
                (window.location.href =
                  "https://www.facebook.com/CatholicTruthSocietyofHongKong")
              }
            >
              香港公教真理學會 Facebook
            </div>
            <div
              className={style.btn}
              onClick={() =>
                (window.location.href = "https://wa.me/+85294800113")
              }
            >
              香港公教真理學會 Whatsapp Group
            </div>
            <div
              className={style.btn}
              onClick={() =>
                (window.location.href = "https://cts.catholic.org.hk/")
              }
            >
              香港公教真理學會網站
            </div>
            <div
              className={style.btn}
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/catholictruthsocietyofhk/?igshid=YmMyMTA2M2Y%3D")
              }
            >
              香港公教真理學會 Instagram
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function Foot(props: SideProp) {
  const [gospel, setGospel] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const dispatch = useDispatch();
  const logout = () => {
    console.log("test");
    dispatch(deleteMemberInformation());
    dispatch(clearShoppingCart());
    store.dispatch({
      type: "REFRESH_BOOKSHELF",
      payload: {
        productList: [],
      },
    });
    navigate("/");
  };

  const fetchGospel = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND}gospel/current?date=${
        new Date().toISOString().split("T")[0]
      }`
    )
      .then((res) => res.json())
      .then((res: any) => {
        if (i18n.language === "en") {
          setGospel(res.engContent);
        } else {
          setGospel(res.chnContent);
        }
      });
  };

  useEffect(() => {
    fetchGospel();
  }, []);
  const member = useSelector((state: any) => state.MemberReducer);
  const quantity = useSelector(
    (state: any) => state.shoppingCartReducer.quantity
  );

  return (
    <div className={style.foot}>
      <div className={style.register}>
        {member.member_first_name !== null ? (
          <>
            <p
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => {
                navigate("user");
                props.setMenuOut(false);
              }}
            >
              {member.member_first_name}
            </p>
            <p
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => {
                logout();
                props.setMenuOut(false);
              }}
            >
              {t("header.logout")}
            </p>
          </>
        ) : (
          <Link to="login">
            <Button
              styleType={"upperNavbarButton"}
              callBack={() =>
                window.location.pathname !== "/login"
                  ? localStorage.setItem(
                      LOGIN_RIDIRECT_PATHNAME,
                      window.location.pathname
                    )
                  : console.log()
              }
            >
              <span>{t("header.login")}</span>
            </Button>
          </Link>
        )}
        {member.token !== null ? (
          <div className={style.iconContainer}>
            <div
              className={style.shoppingCartIcon}
              onClick={() => {
                navigate("shoppingCart");
                props.setMenuOut(false);
              }}
            >
              <img src={shoppingCartIcon} alt="" />
              {quantity === 0 ? (
                <></>
              ) : (
                <div className={style.redDot}>
                  <p style={{ fontSize: "8px", color: "white" }}>{quantity}</p>
                </div>
              )}
            </div>
            <img
              src={favIcon}
              alt=""
              onClick={() => {
                navigate("favourite");
                props.setMenuOut(false);
              }}
            />
            <img
              src={bookshelfIcon}
              alt=""
              onClick={() => {
                navigate("bookshelf");
                props.setMenuOut(false);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className={style.displayRight}>
          <div className={style.languageInnerContainer}>
            <img
              src={facebook}
              style={{ width: "25px", cursor: "pointer" }}
              onClick={() =>
                openInNewTab(
                  "https://www.facebook.com/CatholicTruthSocietyofHongKong"
                )
              }
            />
            <img
              src={instagram}
              style={{ width: "25px", cursor: "pointer" }}
              onClick={() =>
                openInNewTab(
                  "https://www.instagram.com/catholictruthsocietyofhk/?igshid=YmMyMTA2M2Y%3D"
                )
              }
            />
            <img
              src={twitter}
              style={{ width: "25px", cursor: "pointer" }}
              onClick={() => openInNewTab("https://wa.me/+85294800113")}
            />
          </div>
        </div>
      </div>
      <div className={style.searchContainer}>
        <div className={style.scrollContainer}>
          <div className={style.cs_c1}>每日福音</div>
          <p className={style.scrollText} title={gospel}>
            {gospel}
          </p>
        </div>
      </div>
      <div className={style.btnContainerMulti}>
        <div
          className={style.btn}
          onClick={() => {
            props.setMenuOut(false);
            navigate("events");
          }}
        >
          {t("header.events")}
        </div>
        <div
          className={style.btn}
          onClick={() => {
            props.setMenuOut(false);
            navigate("news");
          }}
        >
          {t("header.news")}
        </div>
        <div
          className={style.btn}
          onClick={() => {
            props.setMenuOut(props.menuOut);
            navigate("FAQ");
          }}
        >
          {t("header.faq")}
        </div>
      </div>
    </div>
  );
}
