import React, { useEffect } from "react";
import { NewBooks } from "../../layout/test/BookDetail";
import useWindowSize from "../../utils/useWindowSize";
import BookCard from "../BookCard/BookCard";
import Button from "../Button/Button";
import CartItem from "../CartItem/CartItem";
import style from "./ShoppingCart.module.css";
import footer from "../../assets/images/Logo.svg";
import { useNavigate } from "react-router-dom";
import { RefreshShoppingCartItem } from "../../redux/services/ShoppingCartService";
import { connect } from "react-redux";
import * as ShoppingCartService from "../../redux/services/ShoppingCartService";
import { deleteProductItem } from "../../redux/actions/ShoppingCartAction";
import { useTranslation } from "react-i18next";
import { checkLogin } from "../../utils/GlobalFunction";

function ShoppingCart(props: any) {
  const { width, height } = useWindowSize();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const isLogined = checkLogin();
    if (!isLogined) {
      navigate("/");
      return;
    }
    RefreshShoppingCartItem();
  }, []);

  function handleRemoveCartItemClick(value: any) {
    if (value.product.discounts && value.product.discounts.length > 0) {
      let discount = value.product.discounts[0];
      if (discount.discountType === "GWP" && discount.actionValue !== 0) {
        if (props.memberToken !== null) {
          console.log(1111);
          ShoppingCartService.removeGwpDiscountFromShoppingCart(
            discount.discountId,
            () => {
              ShoppingCartService.refreshShoppingCartItemWithCallback(() => {
                // reset point if any changes
                ShoppingCartService.setPointCash(0);
                props.setShowLoading(false);
              });
            }
          );
        } else {
          props.deleteProductItem({ product_id: props.product.product_id });
        }
        return;
      }
      if (discount.discountType === "禮物券") {
        return;
      }
    }

    if (value.product.point != null) {
      console.log("test1");
      ShoppingCartService.deletePointProductWithCallback(
        value.product.product_id,
        value.product.point.productPointId,
        () => {
          // reset point if any changes
          ShoppingCartService.setPointCash(0);
        }
      );
      //setPwpMessage(null);
    } else {
      console.log("test129");
      ShoppingCartService.deleteShoppingCartItemWithCallback(
        value.product,
        () => {
          // reset point if any changes
          ShoppingCartService.setPointCash(0);
        }
      );
      //setPwpMessage(null);
    }
  }

  const totalpriceBeforeDiscount = () => {
    const initialValue = 0;
    const sumWithInitial = props.productList.reduce(
      (previousValue: number, currentValue: any) =>
        previousValue +
        (currentValue.product_discount_price === null
          ? currentValue.product_price
          : currentValue.product_discount_price),
      initialValue
    );
    return sumWithInitial;
  };
  return (
    <div className={style.shopingCartContainer}>
      <div>
        <h1>{t("checkout.shoppingCart")}</h1>
        {props.productList.length > 0 ? (
          <p style={{ margin: " 0 0 1em 0" }}>
            {props.productList.length} 件產品，共HKD
            {props.totalPrice}。
          </p>
        ) : (
          <h3 style={{ margin: " 0 0 1em 0" }}>{t("checkout.empty")}</h3>
        )}
      </div>
      <div className={style.shopingCartDetailContainer}>
        <div className={style.cartHeader}>
          <p>{t("checkout.product")}</p>
          <span></span>
          <span></span>
          <p>{t("checkout.subtotal")}</p>
        </div>
      </div>
      <hr className={style.horizontalLine}></hr>
      {props.productList.map((item: any) => (
        <CartItem
          key={item.product_id}
          editable={true}
          props={item}
          callBack={(value: any) => handleRemoveCartItemClick(value)}
        />
      ))}
      {props.productList.length > 0 ? (
        <hr className={style.horizontalLine}></hr>
      ) : (
        <></>
      )}
      <div className={style.totalPriceContainer}>
        <p>{t("checkout.total")}</p>
        <div style={{ display: "flex" }}>
          <p>HKD</p>
          &nbsp;&nbsp;
          <p style={{ color: "#47D5CD" }}>
            {props.totalPrice.toFixed(2)}
          </p>
        </div>
      </div>
      <div className={style.buttonContainer}>
        <Button
          styleType={"loginButton"}
          children={"確認訂單"}
          disabled={props.productList.length > 0 ? false : true}
          callBack={() => navigate("/checkout")}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    memberToken: state.MemberReducer.token,
    productList: state.shoppingCartReducer.productList,
    totalPrice: state.shoppingCartReducer.total,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteProductItem: (id: number) => dispatch(deleteProductItem(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
