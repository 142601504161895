import {g, p, u, d} from './BasicAPI';

export const getAll = () => 
{
    return g('/member/all')
        .then(t => t.json());
}

export const getById = (id) =>
{
    return g(`/member/${id}`)
        .then(t => t.json());

}

export const update = (data) => 
{
    return p('/member/update', data)
        .then(t => t.json());
}

export const resetPasswordEmail = (data) => 
{
    return p('/member/reset-password/email', data)
        .then(t => t.json());
}

export const resetPasswordVerify = (data) => 
{
    return p('/member/reset-password/verify', data)
        .then(t => t.json());
}

export const resetPasswordSetMemberPassword = (data) => 
{
    return p('/member/reset-password/change-password', data)
        .then(t => t.json());
}

export const accountConfirmFromEmail = (data) => 
{
    return p('/member/email-confirm', data)
        .then(t => t.json());
}