import React from "react";
import style from "./CarItem.module.css";
import trash from "../../assets/icons/bin@1x.svg";
import { Navigate, useNavigate } from "react-router-dom";

interface IBookData {
  id: number;
  img: string;
  title: string;
  author: string;
  price: number;
}

interface IProps {
  editable: boolean;
  item: IBookData;
}

export default function CartItem({ editable, props, callBack }: any) {
  const navigate = useNavigate();
  return (
    <div className={style.cartItemContainer}>
      <div
        className={style.bookContainer}
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/product/${props.product_url_naming}`)}
      >
        <img
          className={style.bookCover}
          src={process.env.REACT_APP_BACKEND + "file/" + props.product_image}
        />
        <div className={style.bookDetailsContainer}>
          <div className={style.bookDetails}>
            <p>{props.product_name_chi}</p>
            <p>{props.author}</p>
            <div style={{ margin: "1em 0 0 0 " }}>
              <span>HKD</span>
              {props.product_discount_price === null ? (
                <>
                  &nbsp;&nbsp;
                  <span style={{ color: "#47D5CD" }}>
                    {props.product_price.toFixed(2)}
                  </span>
                  &nbsp;&nbsp;
                </>
              ) : (
                <>
                  &nbsp;&nbsp;
                  <span className={style.originalPrice}>
                    {props.product_price.toFixed(2)}
                  </span>
                  &nbsp;&nbsp;
                  <span style={{ color: "#47D5CD" }}>
                    {props.product_discount_price.toFixed(2)}
                  </span>
                  &nbsp;&nbsp;
                </>
              )}

              <span>/本</span>
            </div>
          </div>
        </div>
      </div>
      <div className={style.cartItemOther}>
        <div className={style.bookPrice}>
          <p>HKD</p>
          <p style={{ color: "#47D5CD" }}>
            {
              props.product_discount_price === null
                ? props.product_price
                  ? props.product_price.toFixed(2)
                  : "0.00"
                : props.product_discount_price
                  ? props.product_discount_price.toFixed(2)
                  : "0.00"
            }
          </p>
        </div>
        {editable ? (
          <>
            <div className={style.cartItemButtonContainer}>
              {/* <img src={fav_wired} /> */}
              <img
                style={{ cursor: "pointer" }}
                src={trash}
                onClick={() =>
                  callBack({ action: "item_remove", product: props })
                }
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
