import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookCard from "../../components/BookCard/BookCard";
import PageLoading from "../loading/PageLoading";
import style from "./Category.module.css";
import * as CategoryAPI from "../../api/CategoryAPI";
import * as displayProductAPI from "../../api/DisplayProductAPI";
import { useTranslation } from "react-i18next";
import UpperSideNav from "../SideNav/UpperSideNav";
import LowerSideNav from "../SideNav/LowerSideNav";
import useWindowSize from "../../utils/useWindowSize";
import { display } from "@mui/system";

export default function SearchResult() {
  const [categoryName, setCategoryName] = useState<string>();
  const [displayCategory, setDisplayCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [bookList, setBookList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { width, height } = useWindowSize();
  const { id } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    fetchCategory();
    fetchCollectionCategory();
    categoryList();
  }, [window.location.pathname]);

  const fetchCategory = async () => {
    const category = await CategoryAPI.getById(id);
    setCategoryName(category.categoryId);
  };

  const categoryList = async () => {
    const categories = await CategoryAPI.getAll();
    setDisplayCategory(categories);
  };

  const fetchCollectionCategory = async () => {
    const productByCategory =
      await displayProductAPI.getDisplayProductByCategoryId(id);

    setProduct(productByCategory.list);
  };

  return (
    <div className={style.searchResultContainer}>
      <div style={width < 768 ? { display: "none" } : {}}>
        <UpperSideNav props={displayCategory} />
        <LowerSideNav props={displayCategory} />
      </div>
      <div className={style.searchResultInnerContainer}>
        <h1>
          {categoryName != undefined ? t(`category.${categoryName}`) : categoryName}
        </h1>
        <div className={style.cardLayout}>
          {loading ? (
            <PageLoading />
          ) : (
            <>
              {product &&
                product.map((x: any) => (
                  <BookCard props={x} key={x.displayProductId} />
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
