import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { accountConfirmFromEmail } from "../../api/MemberAPI";

export default function EmailConfirm() {
    const [message, setMessage] = useState("未能成功開啟帳戶")
    const [countDown, setCountDown] = useState(10)
    const [countDownStart, setCountDownStart] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        let token = new URLSearchParams(window.location.search).get("token");
        if (token && token !== undefined) {
            accountConfirmFromEmail({ token: token })
                .then((res: any) => {
                    if (res.success) {
                        setMessage("成功開啟帳戶")
                    }
                    setCountDownStart(true)
                })
        }
    }, [])

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (countDownStart) {
                if (countDown <= 0) {
                    clearInterval(myInterval);
                    navigate("/")
                } else {
                    setCountDown(countDown - 1)
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    })
    return (
        <div style={{ height: "70%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {`${message}，頁面將於${countDown}秒後自動轉至首頁⋯⋯`}
        </div>
    )
}