class StorageUtil {
  static getReaderConfig(key) {
    let readerConfig = JSON.parse(localStorage.getItem("readerConfig")) || {};
    let _return = readerConfig[key];
    if (key === "isVertical") {
      _return = "no"; // handle 用家 之前 Set 過 true
    }
    return _return;
  }

  static setReaderConfig(key, value) {
    let readerConfig = JSON.parse(localStorage.getItem("readerConfig")) || {};
    readerConfig[key] = value;
    localStorage.setItem("readerConfig", JSON.stringify(readerConfig));
  }
  static getKookitConfig(key) {
    let kookitConfig = JSON.parse(localStorage.getItem("kookitConfig")) || {};
    return kookitConfig[key];
  }

  static setKookitConfig(key, value) {
    let kookitConfig = JSON.parse(localStorage.getItem("kookitConfig")) || {};
    kookitConfig[key] = value;
    localStorage.setItem("kookitConfig", JSON.stringify(kookitConfig));
  }
}

export default StorageUtil;
