import {
  addProductItem,
  deleteProductItem,
} from "../actions/ShoppingCartAction";
import {
  deleteMemberInformation,
  setMemberInformation,
} from "../actions/MemberAction";
import React from "react";
import ReactDOM from "react-dom";
import { connect, useSelector } from "react-redux";
import { store } from "../store";
import { Redirect } from "react-router-dom";

function combinePath() {
  if (arguments.length == 0) {
    return undefined;
  }

  if (arguments.length == 1) {
    return arguments[0];
  }

  let ret = arguments[0];
  for (let i = 1; i < arguments.length; i++) {
    ret = combinePath2(ret, arguments[i]);
  }
  return ret;
}

function combinePath2(a, b) {
  let ret = a;
  if (!ret.endsWith("/")) {
    ret = ret + "/";
  }
  ret = ret + b;
  return ret;
}

function mapDispatchToProps(dispatch) {
  return {
    setMemberInformation: (member) => {
      dispatch(setMemberInformation(member));
    },
    deleteMemberInformation: (member) => {
      dispatch(deleteMemberInformation(member));
    },
  };
}

export function authFetch(url, body, method) {
  const state = store.getState();
  const token = state.MemberReducer.token;

  console.log("token",token)
  return fetch(url, {
    method: method,
    body: body,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      //console.log(res);
      if (res.status != 200) {
        res.json().then((res) => {
          alert(res.message);
        });
        throw new Error("something fail");
      } else if (res == null) {
        return res;
      } else {
        return res.json();
      }
    })
    .then(
      (res) => res,
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
}

export async function asyncAuthedGet(url) {
  const state = store.getState();
  const token = state.MemberReducer.token;
  if (!token) {
    return Promise.reject("token is null");
  }

  const opts = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: "omit",
    cache: "no-cache",
    redirect: "follow",
    mode: "cors",
  };

  return fetch(process.env.REACT_APP_BACKEND + url, opts);
}

export async function asyncAuthedPost(url, body) {
  const state = store.getState();
  const token = state.MemberReducer.token;
  if (!token) {
    return Promise.reject("token is null");
  }

  const body2 = new FormData();
  for (let key in body) {
    body2.append(key, body[key]);
  }

  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: "omit",
    cache: "no-cache",
    redirect: "follow",
    mode: "cors",
    body: body2,
  };

  return fetch(combinePath(process.env.REACT_APP_BACKEND, url), opts);
}
