import { rejects } from "assert";
import { composeInitialProps } from "react-i18next";
import { IBookBorrowSetting } from "../model/setting/BorrowSetting";
import { TEL } from "../utils/Constant";
import { httpGet, httpPost } from "./http";

let backendUrl = process.env.REACT_APP_BACKEND;

const api = {
  //file
  getFileByFileName(fileName: string) {
    return httpGet(`${backendUrl}file/${fileName}`, {
      "Content-Type": "application/epub+zip",
    });
  },

  getFileByString(fileName: String): Promise<Blob> {
    return new Promise((resolve, rejects) => {
      httpGet(`${backendUrl}file/fileEncrypt/${fileName}`)
        .then((str: any) => {
          console.log("str", str);
          if (str) {

          } else {
            console.log("err Server 沒有檔案");
            alert("發生問題");
            return;
          }
          str.text().then((res: any) => {
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth() + 1;
            const currentDay = today.getDate();
            const saltStr = (currentYear + currentMonth + currentDay) * TEL;
            if (res !== "") {
              res = res.replaceAll(saltStr, "");
            }
            var sliceSize = 1024;
            var byteCharacters = atob(res);
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);

            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
              var begin = sliceIndex * sliceSize;
              var end = Math.min(begin + sliceSize, bytesLength);

              var bytes = new Array(end - begin);
              for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
              }
              byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            resolve(new Blob(byteArrays, { type: "application/pdf" }));
          });
        })

    });
  },
  //search

  getTokenByCode(code: string, state: string | null): boolean {
    let flag = false;
    console.log("here?");

    const grantType = "authorization_code";
    const param = `grant_type=${grantType}&code=${code}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
    const url = `${process.env.REACT_APP_OAUTH_SERVER_URL}/oauth/token?${param}`;
    const headers = {
      Authorization: `Basic ${btoa(
        `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
      )}`,
    };

    fetch(url, {
      method: "POST",
      headers: headers,
    })
      .then(async (res) => {
        flag = true;
        console.log(res);
      })
      .catch((err) => console.log(err));
    return flag;
  },
};

export default api;
