import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import style from "../Favourite/Favourite.module.css";
import bookshelfIcon from "../../assets/images/shelf.svg";
import { authFetch } from "../../redux/services/AuthenticationService";
import { connect, useSelector } from "react-redux";
import { handleReaderMode } from "../../redux/actions/reader/reader";
import { IMember } from "../UserInformation/UserInformation";
import { openBook } from "../../utils/openBook";
import { useTranslation } from "react-i18next";
import UpperSideNav from "../SideNav/UpperSideNav";
import useWindowSize from "../../utils/useWindowSize";

import * as CategoryAPI from "../../api/CategoryAPI";
import LowerSideNav from "../SideNav/LowerSideNav";
import { width } from "@mui/system";
import { useNavigate } from "react-router";
import { checkLogin } from "../../utils/GlobalFunction";

interface IPorps {
  member: IMember;
  reader: any;
  handleReaderMode: any;
}

function Bookshelf({ member, reader, handleReaderMode }: IPorps) {
  const [bookshelf, setBookShelf] = useState<any>([]);
  const [tempProductId, setTempProductId] = useState<number | null>();
  const [readBook, setReadBook] = useState();
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingProd, setLoadingProd] = useState(false);
  const [loadingOnlineRead, setLoadingOnlineRead] = useState(false);
  const { width, height } = useWindowSize();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [bookForRow, setBookForRow] = useState(0);

  useEffect(() => {
    const isLogined = checkLogin();
    if (!isLogined) {
      navigate("/");
      return;
    }
    fetchUserBookShelf();
    categoryList();
  }, []);

  useEffect(() => {
    if (width <= 768) {
      setBookForRow(2);
    } else {
      setBookForRow(5);
    }
  }, [width]);

  useEffect(() => {
    //import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
    if (tempProductId !== undefined) {
      let readerConfig = localStorage.getItem("readerConfig");
      if (readerConfig !== null) {
        let readerConfigObj = JSON.parse(readerConfig);
        if (reader.mode !== "" && reader.mode !== undefined) {
          console.log(123);
          readerConfigObj["rm"] = reader.mode.substring(0, 1);
          localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));
          if (reader.mode === "read") {
            setLoadingProd(true);
          }

          // if (reader.mode === "preview") {
          //   setLoadingPreview(true);
          // }

          // if (props.reader.mode === "online") {
          //   setLoadingOnlineRead(true);
          //   handleReaderMode("");
          // }
        }
      }
    }

    // if (props.userInfo.mode !== undefined) {
    //   console.log("props10", props.userInfo.mode);
    //   if (props.reader.mode === "read") {
    //     setLoadingProd(true);
    //   }
    //   if (props.reader.mode === "preview") {
    //     setLoadingPreview(true);
    //   }
    // }
  }, [reader]);

  // useEffect(() => {
  //   if (loadingPreview) {
  //     openBook(readBook);
  //     setLoadingPreview(false);
  //     setLoadingProd(false);
  //     setLoadingOnlineRead(false);
  //     setTempProductId(null);
  //   }
  // }, [loadingPreview]);

  useEffect(() => {
    if (loadingProd) {
      openBook(readBook, setLoader);
      setLoadingPreview(false);
      setLoadingProd(false);
      setLoadingOnlineRead(false);
      setTempProductId(null);
    }
  }, [loadingProd]);

  const fetchUserBookShelf = async () => {
    authFetch(
      process.env.REACT_APP_BACKEND +
        `book_shelf/getByMemberId/${parseInt(member.member_id)}`,
      null,
      "get"
    ).then((res) => {
      console.log("res", res);
      if (res.length % bookForRow !== 0) {
        const addNumberOfRow = bookForRow - (res.length % bookForRow);
        const x = res.slice();
        for (let i = 0; i < addNumberOfRow; i++) {
          x.push(null);
        }
        setBookShelf(x);
      } else {
        setBookShelf(res);
      }
    });
  };

  const handleReadBook = (actionType: string, book: any) => {
    setTempProductId(book.productId);
    setReadBook(book);
    console.log("actionType", actionType);
    console.log("book", book);
    if (
      actionType === "read" ||
      actionType === "preview" ||
      actionType === "online"
    ) {
      handleReaderMode(actionType);
      //setReadAction(actionType);
    } else {
      //   setLoadingOnlineRead(true);
    }
  };

  const [displayCategory, setDisplayCategory] = useState([]);

  const categoryList = async () => {
    const categories = await CategoryAPI.getAll();
    setDisplayCategory(categories);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        maxWidth: "1440px",
      }}
    >
      {width > 1024 ? (
        <div
          style={{ display: "flex", width: "250px", flexDirection: "column" }}
        >
          <UpperSideNav props={displayCategory} />
          <LowerSideNav props={displayCategory} />
        </div>
      ) : (
        <></>
      )}
      <div className={style.shopingCartLayout}>
        <section>
          <h1>{t("page.myBookShelf")}</h1>
          <p>
            {bookshelf.filter((x: any) => x !== null).length}
            {t("page.books")}
          </p>
          <div className={style.bookshelfLayout}>
            {bookshelf &&
              bookshelf.map((book: any, idx: any) => (
                <>
                  {book && book.isGroupProduct ? (
                    book!.products.map((b: any) => (
                      <>
                        <div className={style.bookContainer} key={idx}>
                          <div className={style.bookshelfBookTitleContainer}>
                            <p className={style.bookshelfBookTitle}>
                              {b.productNameChi}
                            </p>
                          </div>

                          <div className={style.readButtonContainer}>
                            <Button
                              styleType={"bookIntroButton"}
                              callBack={() => handleReadBook("read", b)}
                              children={"開始閱讀"}
                            />
                          </div>
                          <div className={style.bookImgContainer} key={idx}>
                            <img
                              src={
                                process.env.REACT_APP_BACKEND +
                                "file/" +
                                b.productImage[0]
                              }
                            ></img>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <>
                      <div className={style.bookContainer} key={idx}>
                        <div className={style.bookshelfBookTitleContainer}>
                          <p className={style.bookshelfBookTitle}>
                            {book.productNameChi}
                          </p>
                        </div>

                        <div className={style.readButtonContainer}>
                          <Button
                            styleType={"bookIntroButton"}
                            callBack={() => handleReadBook("read", book)}
                            children={"開始閱讀"}
                          />
                        </div>
                        <div className={style.bookImgContainer} key={idx}>
                          <img
                            src={
                              process.env.REACT_APP_BACKEND +
                              "file/" +
                              book.productImage[0]
                            }
                          ></img>
                        </div>
                      </div>
                    </>
                  )}

                  {(idx + 1) % bookForRow === 0 ||
                  idx === bookshelf.length - 1 ? (
                    <img src={bookshelfIcon} className={style.bookshelf} />
                  ) : (
                    <></>
                  )}
                </>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    member: state.MemberReducer,
    reader: state.reader,
  };
};

const actionCreator = {
  handleReaderMode,
};
export default connect(mapStateToProps, actionCreator)(Bookshelf);
