// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-chapter-name,
.header-book-name {
  width: 45vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  opacity: 0.3;
}
.header-container,
.footer-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 30px;
}
.header-container {
  position: absolute;
  top: 3px;
}
.footer-container {
  position: absolute;
  bottom: 22px;
}

.background-page-left,
.background-page-right {
  max-width: 45vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  opacity: 0.3;
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/containers/Reader/pageWidget/background.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;AACA;;EAEE,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,QAAQ;AACV;AACA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;;EAEE,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,aAAa;AACf","sourcesContent":[".header-chapter-name,\n.header-book-name {\n  width: 45vw;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  line-height: 30px;\n  text-align: center;\n  opacity: 0.3;\n}\n.header-container,\n.footer-container {\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  height: 30px;\n}\n.header-container {\n  position: absolute;\n  top: 3px;\n}\n.footer-container {\n  position: absolute;\n  bottom: 22px;\n}\n\n.background-page-left,\n.background-page-right {\n  max-width: 45vw;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  line-height: 30px;\n  text-align: center;\n  font-size: 14px;\n  opacity: 0.3;\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
