// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trans-container {
  width: 300px; /* 264px; */
  height: 200px; /* 172px; */
}
.original-text-box,
.trans-text-box {
  width: 270px; /* 230px; */
  height: 140px; /* 100px; */
  opacity: 1;
  border-radius: 5px;
  position: absolute;
  -webkit-user-select: text;
          user-select: text;
  overflow-y: scroll;
  padding: 5px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 18px;
}
.trans-button-container {
  position: absolute;
  bottom: 8px;
  right: 20px;
}
.trans-text-box {
  left: 16px;
  top: 16px;
}
.target-lang-container {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Reader/popups/popupTrans/popupTrans.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,WAAW;EACzB,aAAa,EAAE,WAAW;AAC5B;AACA;;EAEE,YAAY,EAAE,WAAW;EACzB,aAAa,EAAE,WAAW;EAC1B,UAAU;EACV,kBAAkB;EAClB,kBAAkB;EAClB,yBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb;AACA;EACE,UAAU;EACV,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".trans-container {\n  width: 300px; /* 264px; */\n  height: 200px; /* 172px; */\n}\n.original-text-box,\n.trans-text-box {\n  width: 270px; /* 230px; */\n  height: 140px; /* 100px; */\n  opacity: 1;\n  border-radius: 5px;\n  position: absolute;\n  user-select: text;\n  overflow-y: scroll;\n  padding: 5px;\n  box-sizing: border-box;\n  font-size: 15px;\n  line-height: 18px;\n}\n.trans-button-container {\n  position: absolute;\n  bottom: 8px;\n  right: 20px;\n}\n.trans-text-box {\n  left: 16px;\n  top: 16px;\n}\n.target-lang-container {\n  position: absolute;\n  bottom: 16px;\n  right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
