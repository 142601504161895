import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import style from "./LoginPage.module.css";
import { Path, SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input2 from "../../components/Input/Input2";
import Checkbox from "../../components/Input/Checkbox";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import * as API from "../../api/PageContentAPI";
import Facebook from "@mui/icons-material/Facebook";

const month = () => {
  let monthArray = [{ value: "", display: t("signUp.birthdayMonth") }];
  for (let i = 1; i <= 12; i++) {
    monthArray.push({ value: `${i}`, display: `${i}月` });
  }
  return monthArray;
};

export interface IFormValues {
  firstname: string;
  lastname: string;
  ageGroup: number;
  gender: string;
  verificationCode: number;
  birthday: number;
  password: string;
  email: string;
  phone: string;
  acceptPromoption: string;
  confirmPassword: string;
  district: string;
  agreement: string;
  agreement2: string;
}

// type InputProps = {
//   label: Path<IFormValues>;
//   placeholder: string;
//   register: UseFormRegister<IFormValues>;
//   required: boolean;
//   type?: string;
//   pattern?: any;
//   minLength?: any;
// };

const formSchema = yup
  .object()
  .shape({
    email: yup.string().email("電郵格式錯誤").required("必須輸入電郵"),
    password: yup
      .string()
      .required("必須輸入密碼")
      .min(8, "密碼長度至少8位")
      .matches(RegExp("(.*[a-zA-Z].*)"), "必須包含字母和數字")
      .matches(RegExp("(.*\\d.*)"), "必須包含字母和數字"),
    confirmPassword: yup
      .string()
      .required("必須輸入密碼")
      .min(8, "密碼長度至少8位")
      .oneOf([yup.ref("password")], "確定密碼不吻合"),
    agreement: yup.bool().oneOf([true], "必須同意政策條款及細則"),
  })
  .required();

const formSchema2 = yup
  .object()
  .shape({
    agreement: yup.bool().oneOf([true], "必須同意政策條款及細則"),
  })
  .required();

export default function RegisterPage() {
  //const [checked, setChecked] = useState(false);
  const [pageContent, setPageContent] = useState("");
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const googleId = searchParams.get("googleId");

  const countryCode = [
    { value: "", display: t("signUp.areaCode") },
    { value: "+86", display: t("country.CN") },
    { value: "+852", display: t("country.HK") },
    { value: "+886", display: t("country.TW") },
    { value: "+853", display: t("country.MO") },
    { value: "+65", display: t("country.SG") },
    { value: "+81", display: t("country.JP") },
    { value: "+66", display: t("country.TH") },
    { value: "+60", display: t("country.MY") },
    { value: "+63", display: t("country.PH") },
    { value: "+61", display: t("country.AU") },
    { value: "+61-8", display: t("country.CX") },
    { value: "+61-891", display: t("country.CC") },
    { value: "+1", display: t("country.CA") },
    { value: "+44", display: t("country.GB") },
    { value: "+1", display: t("country.US") },
    { value: "+244", display: t("country.AO") },
    { value: "+93", display: t("country.AF") },
    { value: "+355", display: t("country.AL") },
    { value: "+213", display: t("country.DZ") },
    { value: "+376", display: t("country.AD") },
    { value: "+1-264", display: t("country.AI") },
    { value: "+1-268", display: t("country.AG") },
    { value: "+54", display: t("country.AR") },
    { value: "+374", display: t("country.AM") },
    { value: "+247", display: t("country.AC") },
    { value: "+43", display: t("country.AT") },
    { value: "+994", display: t("country.AZ") },
    { value: "+1-242", display: t("country.BS") },
    { value: "+973", display: t("country.BH") },
    { value: "+880", display: t("country.BD") },
    { value: "+1-246", display: t("country.BB") },
    { value: "+375", display: t("country.BY") },
    { value: "+7", display: t("country.RU") },
    { value: "+501", display: t("country.BZ") },
    { value: "+229", display: t("country.BJ") },
    { value: "+1-441", display: t("country.BM") },
    { value: "+591", display: t("country.BO") },
    { value: "+267", display: t("country.BW") },
    { value: "+55", display: t("country.BR") },
    { value: "+673", display: t("country.BN") },
    { value: "+359", display: t("country.BG") },
    { value: "+226", display: t("country.BF") },
    { value: "+95", display: t("country.MM") },
    { value: "+257", display: t("country.BI") },
    { value: "+237", display: t("country.CM") },
    { value: "+1-345", display: t("country.KY") },
    { value: "+236", display: t("country.CF") },
    { value: "+235", display: t("country.TD") },
    { value: "+56", display: t("country.CL") },
    { value: "+57", display: t("country.CO") },
    { value: "+242", display: t("country.CG") },
    { value: "+682", display: t("country.CK") },
    { value: "+506", display: t("country.CR") },
    { value: "+53", display: t("country.CU") },
    { value: "+357", display: t("country.CY") },
    { value: "+420", display: t("country.CZ") },
    { value: "+45", display: t("country.DK") },
    { value: "+253", display: t("country.DJ") },
    { value: "+1-809", display: t("country.DO") },
    { value: "+593", display: t("country.EC") },
    { value: "+20", display: t("country.EG") },
    { value: "+503", display: t("country.SV") },
    { value: "+372", display: t("country.EE") },
    { value: "+251", display: t("country.ET") },
    { value: "+679", display: t("country.FJ") },
    { value: "+358", display: t("country.FI") },
    { value: "+33", display: t("country.FR") },
    { value: "+594", display: t("country.GF") },
    { value: "+241", display: t("country.GA") },
    { value: "+220", display: t("country.GM") },
    { value: "+995", display: t("country.GE") },
    { value: "+49", display: t("country.DE") },
    { value: "+233", display: t("country.GH") },
    { value: "+350", display: t("country.GI") },
    { value: "+30", display: t("country.GR") },
    { value: "+1-473", display: t("country.GD") },
    { value: "+1-671", display: t("country.GU") },
    { value: "+502", display: t("country.GT") },
    { value: "+224", display: t("country.GN") },
    { value: "+592", display: t("country.GY") },
    { value: "+509", display: t("country.HT") },
    { value: "+504", display: t("country.HN") },
    { value: "+36", display: t("country.HU") },
    { value: "+354", display: t("country.IS") },
    { value: "+91", display: t("country.IN") },
    { value: "+62", display: t("country.ID") },
    { value: "+98", display: t("country.IR") },
    { value: "+964", display: t("country.IQ") },
    { value: "+353", display: t("country.IE") },
    { value: "+972", display: t("country.IL") },
    { value: "+39", display: t("country.IT") },
    { value: "+225", display: t("country.CI") },
    { value: "+1-876", display: t("country.JM") },
    { value: "+962", display: t("country.JO") },
    { value: "+855", display: t("country.KH") },
    { value: "+7", display: t("country.KZ") },
    { value: "+254", display: t("country.KE") },
    { value: "+82", display: t("country.KR") },
    { value: "+850", display: t("country.KP") },
    { value: "+47", display: t("country.NO") },
    { value: "+968", display: t("country.OM") },
    { value: "+92", display: t("country.PK") },
    { value: "+507", display: t("country.PA") },
    { value: "+675", display: t("country.PG") },
    { value: "+595", display: t("country.PY") },
    { value: "+51", display: t("country.PE") },
    { value: "+48", display: t("country.PL") },
    { value: "+689", display: t("country.PF") },
    { value: "+351", display: t("country.PT") },
    { value: "+1-787", display: t("country.PR") },
    { value: "+974", display: t("country.QA") },
    { value: "+262", display: t("country.RE") },
    { value: "+40", display: t("country.RO") },
    { value: "+32", display: t("country.BE") },
    { value: "+965", display: t("country.KW") },
    { value: "+996", display: t("country.KG") },
    { value: "+856", display: t("country.LA") },
    { value: "+371", display: t("country.LV") },
    { value: "+961", display: t("country.LB") },
    { value: "+266", display: t("country.LS") },
    { value: "+231", display: t("country.LR") },
    { value: "+218", display: t("country.LY") },
    { value: "+423", display: t("country.LI") },
    { value: "+370", display: t("country.LT") },
    { value: "+352", display: t("country.LU") },
    { value: "+261", display: t("country.MG") },
    { value: "+265", display: t("country.MW") },
    { value: "+960", display: t("country.MV") },
    { value: "+223", display: t("country.ML") },
    { value: "+356", display: t("country.MT") },
    { value: "+1-670", display: t("country.MP") },
    { value: "+596", display: t("country.MQ") },
    { value: "+230", display: t("country.MU") },
    { value: "+52", display: t("country.MX") },
    { value: "+373", display: t("country.MD") },
    { value: "+377", display: t("country.MC") },
    { value: "+212", display: t("country.MA") },
    { value: "+976", display: t("country.MN") },
    { value: "+1-664", display: t("country.MS") },
    { value: "+258", display: t("country.MZ") },
    { value: "+264", display: t("country.NA") },
    { value: "+674", display: t("country.NR") },
    { value: "+977", display: t("country.NP") },
    { value: "+599", display: t("country.AN") },
    { value: "+31", display: t("country.NL") },
    { value: "+64", display: t("country.NZ") },
    { value: "+505", display: t("country.NI") },
    { value: "+227", display: t("country.NE") },
    { value: "+234", display: t("country.NG") },
    { value: "+1-758", display: t("country.LC") },
    { value: "+1-784", display: t("country.VC") },
    { value: "+1-684", display: t("country.AS") },
    { value: "+685", display: t("country.WS") },
    { value: "+378", display: t("country.SM") },
    { value: "+239", display: t("country.ST") },
    { value: "+966", display: t("country.SA") },
    { value: "+221", display: t("country.SN") },
    { value: "+248", display: t("country.SC") },
    { value: "+232", display: t("country.SL") },
    { value: "+421", display: t("country.SK") },
    { value: "+386", display: t("country.SI") },
    { value: "+677", display: t("country.SB") },
    { value: "+252", display: t("country.SO") },
    { value: "+27", display: t("country.ZA") },
    { value: "+34", display: t("country.ES") },
    { value: "+94", display: t("country.LK") },
    { value: "+249", display: t("country.SD") },
    { value: "+597", display: t("country.SR") },
    { value: "+268", display: t("country.SZ") },
    { value: "+46", display: t("country.SE") },
    { value: "+41", display: t("country.CH") },
    { value: "+963", display: t("country.SY") },
    { value: "+992", display: t("country.TJ") },
    { value: "+255", display: t("country.TZ") },
    { value: "+228", display: t("country.TG") },
    { value: "+676", display: t("country.TO") },
    { value: "+1-868", display: t("country.TT") },
    { value: "+216", display: t("country.TN") },
    { value: "+90", display: t("country.TR") },
    { value: "+993", display: t("country.TM") },
    { value: "+256", display: t("country.UG") },
    { value: "+380", display: t("country.UA") },
    { value: "+971", display: t("country.AE") },
    { value: "+598", display: t("country.UY") },
    { value: "+998", display: t("country.UZ") },
    { value: "+58", display: t("country.VE") },
    { value: "+84", display: t("country.VN") },
    { value: "+967", display: t("country.YE") },
    { value: "+38", display: t("country.YU") },
    { value: "+263", display: t("country.ZW") },
    { value: "+243", display: t("country.ZR") },
    { value: "+243", display: t("country.CD") },
    { value: "+260", display: t("country.ZM") },
    { value: "+44", display: t("country.JE") },
    { value: "+44-1481", display: t("country.GG") },
    { value: "+44-1624", display: t("country.IM") },
    { value: "+358", display: t("country.AX") },
    { value: "+47", display: t("country.SJ") },
    { value: "+262", display: t("country.YT") },
    { value: "+1-767", display: t("country.DM") },
    { value: "+382", display: t("country.ME") },
    { value: "+1-284", display: t("country.VG") },
    { value: "+1-721", display: t("country.AN / MF / SX") },
    { value: "+1-869", display: t("country.KN") },
    { value: "+1-649", display: t("country.TC") },
    { value: "+1-340", display: t("country.VI / US") },
    { value: "+211", display: t("country.SS") },
    { value: "+222", display: t("country.MR") },
    { value: "+238", display: t("country.CV") },
    { value: "+240", display: t("country.GQ") },
    { value: "+245", display: t("country.GW") },
    { value: "+269", display: t("country.KM") },
    { value: "+290", display: t("country.SH") },
    { value: "+291", display: t("country.ER") },
    { value: "+297", display: t("country.AW") },
    { value: "+298", display: t("country.FO") },
    { value: "+299", display: t("country.GL") },
    { value: "+385", display: t("country.HR") },
    { value: "+387", display: t("country.BA") },
    { value: "+389", display: t("country.MK") },
    { value: "+508", display: t("country.PM") },
    { value: "+590", display: t("country.FR") },
    { value: "+590", display: t("country.ST") },
    { value: "+590", display: t("country.BL / FR") },
    { value: "+599-3", display: t("country.AN / NL") },
    { value: "+599-4", display: t("country.AN / NL") },
    { value: "+599-7", display: t("country.AN / BQ / NL") },
    { value: "+599-9", display: t("country.CW / AN") },
    { value: "+670", display: t("country.TL") },
    { value: "+680", display: t("country.PW") },
    { value: "+681", display: t("country.WF") },
    { value: "+683", display: t("country.NU") },
    { value: "+686", display: t("country.KI") },
    { value: "+687", display: t("country.NC") },
    { value: "+688", display: t("country.TV") },
    { value: "+690", display: t("country.TK") },
    { value: "+691", display: t("country.FM") },
    { value: "+692", display: t("country.MH") },
    { value: "+975", display: t("country.BT") },
    { value: "+381", display: t("country.RS") },
    { value: "+678", display: t("country.VU") },
    { value: "+500", display: t("country.FK") },
    { value: "+250", display: t("country.RW") },
    { value: "+379", display: t("country.VA") },
  ];
  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IFormValues>({
    mode: "onTouched",
    resolver: yupResolver(email !== null ? formSchema2 : formSchema),
  });

  useEffect(() => {
    if (email != "" && email !== null) {
      setValue("email", email);
    }
  }, []);

  const navigate = useNavigate();

  const submitSignUpForm = (data: any) => {
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    formData.append("locationId", "6");
    if (email !== null && email !== "") {
      if (googleId) {
        formData.append("googleId", googleId);
        formData.append("facebookId", "");
      }
      fetch(process.env.REACT_APP_BACKEND + "member/addOauth", {
        method: "post",
        headers: {
          ContentType: "application/json",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          switch (res.status) {
            case 1:
              alert("電郵地址已被註冊");
              break;
            case 2:
              alert("電話已被註冊");
              break;
            case 3:
              alert("驗證服務發生錯誤");
              break;
            case 4:
              alert("驗證碼錯誤");
              break;
            case 5:
              alert("註冊成功。");
              navigate("/");
          }
        })
        .catch((error) => alert(error));
    } else {
      fetch(process.env.REACT_APP_BACKEND + "member/add", {
        method: "post",
        headers: {
          ContentType: "application/json",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          switch (res.status) {
            case 1:
              alert("電郵地址已被註冊");
              break;
            case 2:
              alert("電話已被註冊");
              break;
            case 3:
              alert("驗證服務發生錯誤");
              break;
            case 4:
              alert("驗證碼錯誤");
              break;
            case 5:
              alert("註冊成功, 請檢查你的電郵信箱, 以激活你的帳號。");
              navigate("/");
          }
        })
        .catch((error) => alert(error));
    }
  };

  const Select = React.forwardRef<
    HTMLSelectElement,
    { value: any } & ReturnType<UseFormRegister<IFormValues>>
  >(({ onChange, onBlur, name, value }, ref) => (
    <>
      <select ref={ref} name={name} onChange={onChange}>
        {value.map((item: any, idx: number) => (
          <option key={idx} value={item.value}>
            {item.display}
          </option>
        ))}
      </select>
    </>
  ));

  // const Input = ({
  //   label,
  //   placeholder,
  //   type,
  //   pattern,
  //   register,
  //   required,
  //   minLength,
  // }: InputProps) => (
  //   <>
  //     <input
  //       placeholder={placeholder}
  //       type={type}
  //       {...register(
  //         label,
  //         type === "password"
  //           ? { required, minLength: minLength }
  //           : { required, pattern: pattern }
  //       )}
  //     />
  //     {errors.email && label === "email" && (
  //       <span role="alert">{errors.email.message}</span>
  //     )}
  //     {errors.password && label === "password" && (
  //       <span role="alert">{errors.password.message}</span>
  //     )}
  //   </>
  // );

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    // alert(JSON.stringify(data));

    console.log("datadatadatadatadata", data);

    submitSignUpForm(data);
  };

  React.useEffect(() => {
    API.getPageContentById(5).then((res) => setPageContent(res.content));
  }, []);

  return (
    <>
      <div className={style.tabButtonContainer}>
        <Button
          buttonType={"button"}
          styleType={"tabButton"}
          callBack={(value) => navigate("/login")}
        >
          <p className={style.tabButtonText}>{t("signIn.memberSignIn")}</p>
        </Button>

        <Button
          buttonType={"button"}
          styleType={"tabButtonCurrent"}
          callBack={(value) => navigate("/register")}
        >
          <p className={style.tabButtonText}>{t("signUp.signUp")}</p>
        </Button>
      </div>
      <div className={style.abc}>
        <form
          className={style.registerAlignment}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={style.registerInnerAlignment}>
            <h5>{t("signUp.pleaseEnterYourDetails")}</h5>
            <p className={style.registerTitle}>
              {/* {t("signUp.fieldsMarkedWithAnAreMandatory")} */}
            </p>
            <div className={style.formRow}>
              <Select
                {...register("gender")}
                value={[
                  { value: "", display: t("signUp.title") },
                  { value: "Mr", display: "先生" },
                  { value: "Miss", display: "小姐" },
                  { value: "None", display: "不適用" },
                ]}
              />
              <Input2
                id="firstname"
                type="text"
                placeholder={t("signUp.name")}
                register={register}
                errors={errors.firstname?.message}
              />
            </div>
            <div className={style.formRow}>
              <Input2
                id="email"
                type="text"
                placeholder={t("signUp.emailAddress")}
                register={register}
                errors={errors.email?.message}
                disabled={email !== null && email !== "" ? true : false}
              />

              {/* <Input
                label="email"
                placeholder="電子郵件"
                register={register}
                pattern={{
                  value: /\S+@\S+\.\S+/,
                  message: "電子郵件格式錯誤",
                }}
                required
              /> */}
            </div>
            <div className={style.formRow}>
              <Select {...register("verificationCode")} value={countryCode} />
              {/* <Input2
                id="verificationCode"
                type="tel"
                placeholder={t("signUp.areaCode")}
                register={register}
                errors={errors.verificationCode?.message}
              /> */}
              <Input2
                id="phone"
                type="tel"
                placeholder={t("signUp.phoneNumber")}
                register={register}
                errors={errors.phone?.message}
              />
              {/* <Input
                type="tel"
                label="phone"
                placeholder="電話"
                register={register}
                required
              /> */}
            </div>
            {/* <div className={style.registerDetailTextContainer}>
              <span className={style.registerDetailText}>
                只需驗證 <span>電郵</span> 或 <span>電話</span> 其中一樣.
              </span>
            </div> */}
            {/* <div className={style.regsiterNameInputContainer}>
              <Input
                type="text"
                placeholder={"輸入六位數驗証碼"}
                styleType={"loginInput"}
                callBack={() => console.log(1)}
              ></Input>
            </div> */}
            {email !== null && email !== "" ? (
              <></>
            ) : (
              <>
                <div className={style.formRow}>
                  {/* <Input
                type="password"
                label="password"
                placeholder="密碼"
                register={register}
                required
                minLength={{
                  value: 8,
                  message: "密碼長度至少8位",
                }}
              /> */}

                  <Input2
                    id="password"
                    type="password"
                    placeholder={t("signUp.password")}
                    register={register}
                    errors={errors.password?.message}
                  />
                </div>
                <div className={style.formRow}>
                  {/* <Input
                type="password"
                label="confirmPassword"
                placeholder="確認密碼"
                register={register}
                required
                minLength={{
                  value: 8,
                  message: "密碼長度至少8位",
                }}
              /> */}
                  <Input2
                    id="confirmPassword"
                    type="password"
                    placeholder={t("signUp.confirmPassword")}
                    register={register}
                    errors={errors.confirmPassword?.message}
                  />
                </div>
              </>
            )}

            <div className={style.formRow2}>
              <Select {...register("birthday")} value={month()} />
              {/* <Select {...register("district")} value={district} /> */}
            </div>
            {/* <div className={style.regsiterIDInputContainer}>
                            <Input type="text" placeholder={"身份證號碼 *"} styleType={"loginInput"} callBack={() => console.log(1)}></Input>
                            <Select firstValue={"年齡組別"} styleType={"registerSelect"} callBack={() => console.log(1)} />
                        </div> */}
            <div className={style.AgreementRow}>
              <Checkbox
                id="agreement"
                type="checkbox"
                register={register}
                label={t("signUp.agreeConditions")}
                errors={errors.agreement?.message}
              />
              {/* <span className={style.spanText}>
                  本人已閱讀並同意
                  <span onClick={() => navigate("/PrivacyPolicy")}>
                    私隱政策
                  </span>
                  及
                  <span onClick={() => navigate("/TermsOfService")}>
                    條款及細則
                  </span>

                </span> */}
            </div>
            <div className={style.AgreementRow2}>
              <Checkbox
                id="agreement2"
                type="checkbox"
                register={register}
                label={t("signUp.agreeToReceive")}
                errors={errors.agreement2?.message}
              />
              {/* <span className={style.spanText}>
                  本人已閱讀並同意
                  <span onClick={() => navigate("/PrivacyPolicy")}>
                    私隱政策
                  </span>
                  及
                  <span onClick={() => navigate("/TermsOfService")}>
                    條款及細則
                  </span>

                </span> */}
            </div>

            {/* <div className={style.regsiterNameInputContainer}> */}
            {/* <div className={style.tabButtonContainer}> */}
            <input type="submit" value="註冊" className={style.submitButton} />
          </div>
        </form>
      </div>
    </>
  );
}
