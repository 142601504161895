import { Path, UseFormRegister } from "react-hook-form";
import { IFormValues } from "../../layout/Login/RegisterPage";

type InputProps = {
  id: Path<IFormValues>;
  label?: string;
  placeholder?: any;
  register: UseFormRegister<IFormValues>;
  type?: string;
  errors?: any;
  disabled?: boolean;
};

export default function Input2({
  id,
  label,
  placeholder,
  type,
  register,
  errors,
  disabled,
}: InputProps) {
  return (
    <div>
      <input
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        {...register(id)}
      />

      <span style={{ fontSize: " 10px", color: "red" }}>{errors}</span>

      {label ? <label htmlFor={id}>{label}</label> : <></>}
    </div>
  );
}
