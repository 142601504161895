import * as OrderAPI from "../../api/OrderAPI";
import { store } from "../store";

function throwErrorIfResponseIsNotOk(response) {
  if (!response.ok) {
    throw new Error("response is not ok");
  }
}

export async function getMemberOrder() {
  let response = await OrderAPI.getCurrentMemberOrder();
  if (!response.ok) {
    throw new Error("cannot retrive member order");
  }

  return response.json();
}

export function calculateShippingFee(location_id, order_total) {
  //console.trace('calculateShippingFee', location_id, order_total);
  if (location_id === 6) {
    return order_total >= 500 ? 0 : 50;
  } else if (location_id === 11) {
    return 50;
  }
  return 250;
}

export async function asyncCheckOrder(orderNo, phone) {
  let response;
  response = await OrderAPI.checkOrder(orderNo, phone);
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncGetOrder(id) {
  let response;
  response = await OrderAPI.getMemberOrder(id);
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncGetGuestOrder(id) {
  let response;
  response = await OrderAPI.getGuestOrder(id);
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

/* Member non-express */

export async function asyncAddDirectDeliveryOrder() {
  let response = await OrderAPI.addOrder(
    6, // countryId
    "送貨到府", // shippingMethod
    0, // pointToCash
    null, // productId
    null, // quantity
    null, // deliveryTimes
    " ", // orderAddress
    null, // orderAddressRecipient
    null, // orderAddressProvince
    null, // orderAddressCity
    null, // orderAddressZone
    null, // orderAddress1
    null, // orderAddress2
    null, // orderAddress3
    null, // orderAddress4
    undefined // phone
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncAddSFLockerOrder(
  countryId,
  pointToCash,
  orderAddress,
  phone = undefined
) {
  let response = await OrderAPI.addOrder(
    countryId, // countryId
    "智能櫃自取", // shippingMethod
    pointToCash, // pointToCash
    null, // productId
    null, // quantity
    null, // deliveryTimes
    orderAddress, // orderAddress
    null, // orderAddressRecipient
    null, // orderAddressProvince
    null, // orderAddressCity
    null, // orderAddressZone
    null, // orderAddress1
    null, // orderAddress2
    null, // orderAddress3
    null, // orderAddress4
    phone // phone
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncAddSFStationOrder(
  countryId,
  pointToCash,
  orderAddress,
  phone = undefined
) {
  let response = await OrderAPI.addOrder(
    countryId, // countryId
    "順豐門市取貨", // shippingMethod
    pointToCash, // pointToCash
    null, // productId
    null, // quantity
    null, // deliveryTimes
    orderAddress, // orderAddress
    null, // orderAddressRecipient
    null, // orderAddressProvince
    null, // orderAddressCity
    null, // orderAddressZone
    null, // orderAddress1
    null, // orderAddress2
    null, // orderAddress3
    null, // orderAddress4
    phone // phone
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

/* Member express */

export async function asyncAddExpressDirectDeliveryOrder(
  countryId,
  productId,
  quantity,
  deliveryTimes,
  orderAddressRecipient,
  orderAddressProvince,
  orderAddressCity,
  orderAddressZone,
  orderAddress1,
  orderAddress2,
  orderAddress3,
  orderAddress4,
  phone = undefined
) {
  let response = await OrderAPI.addExpressOrder(
    countryId, // countryId
    "送貨到府", // shippingMethod
    null, // pointToCash
    productId, // productId
    quantity, // quantity
    deliveryTimes, // deliveryTimes
    null, // orderAddress
    orderAddressRecipient, // orderAddressRecipient
    orderAddressProvince, // orderAddressProvince
    orderAddressCity, // orderAddressCity
    orderAddressZone, // orderAddressZone
    orderAddress1, // orderAddress1
    orderAddress2, // orderAddress2
    orderAddress3, // orderAddress3
    orderAddress4, // orderAddress4
    phone // phone
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncAddExpressSFLockerOrder(
  countryId,
  productId,
  quantity,
  deliveryTimes,
  orderAddress,
  phone = undefined
) {
  let response = await OrderAPI.addExpressOrder(
    countryId, // countryId
    "智能櫃自取", // shippingMethod
    null, // pointToCash
    productId, // productId
    quantity, // quantity
    deliveryTimes, // deliveryTimes
    orderAddress, // orderAddress
    null, // orderAddressRecipient
    null, // orderAddressProvince
    null, // orderAddressCity
    null, // orderAddressZone
    null, // orderAddress1
    null, // orderAddress2
    null, // orderAddress3
    null, // orderAddress4
    phone // phone
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncAddExpressSFStationOrder(
  countryId,
  productId,
  quantity,
  deliveryTimes,
  orderAddress,
  phone = undefined
) {
  let response = await OrderAPI.addExpressOrder(
    countryId, // countryId
    "順豐門市取貨", // shippingMethod
    null, // pointToCash
    productId, // productId
    quantity, // quantity
    deliveryTimes, // deliveryTimes
    orderAddress, // orderAddress
    null, // orderAddressRecipient
    null, // orderAddressProvince
    null, // orderAddressCity
    null, // orderAddressZone
    null, // orderAddress1
    null, // orderAddress2
    null, // orderAddress3
    null, // orderAddress4
    phone // phone
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

/* Guest */

export async function asyncAddGuestDirectDeliveryOrder(
  countryId,
  orderAddressRecipient,
  orderAddressProvince,
  orderAddressCity,
  orderAddressZone,
  orderAddress1,
  orderAddress2,
  orderAddress3,
  orderAddress4,
  phone,
  productList,
  couponCode
) {
  let response = await OrderAPI.addGuestOrder(
    countryId, // countryId
    "送貨到府", // shippingMethod
    null, // orderAddress
    orderAddressRecipient, // orderAddressRecipient
    orderAddressProvince, // orderAddressProvince
    orderAddressCity, // orderAddressCity
    orderAddressZone, // orderAddressZone
    orderAddress1, // orderAddress1
    orderAddress2, // orderAddress2
    orderAddress3, // orderAddress3
    orderAddress4, // orderAddress4
    phone, // phone
    JSON.stringify(productList), // productListJson
    couponCode // couponCode
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncAddGuestSFLockerOrder(
  countryId,
  orderAddressRecipient,
  orderAddress,
  phone,
  productList,
  couponCode
) {
  let response = await OrderAPI.addGuestOrder(
    countryId, // countryId
    "智能櫃自取", // shippingMethod
    orderAddress, // orderAddress
    orderAddressRecipient, // orderAddressRecipient
    null, // orderAddressProvince
    null, // orderAddressCity
    null, // orderAddressZone
    null, // orderAddress1
    null, // orderAddress2
    null, // orderAddress3
    null, // orderAddress4
    phone, // phone
    JSON.stringify(productList), // productListJson
    couponCode // couponCode
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncAddGuestSFStationOrder(
  countryId,
  orderAddressRecipient,
  orderAddress,
  phone,
  productList,
  couponCode
) {
  let response = await OrderAPI.addGuestOrder(
    countryId, // countryId
    "順豐門市取貨", // shippingMethod
    orderAddress, // orderAddress
    orderAddressRecipient, // orderAddressRecipient
    null, // orderAddressProvince
    null, // orderAddressCity
    null, // orderAddressZone
    null, // orderAddress1
    null, // orderAddress2
    null, // orderAddress3
    null, // orderAddress4
    phone, // phone
    JSON.stringify(productList), // productListJson
    couponCode // couponCode
  );
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncCalcExpressAmount(
  isLogin,
  productId,
  quantity,
  deliveryTimes,
  promotionCode
) {
  let response;
  if (isLogin) {
    response = await OrderAPI.calcMemberExpressAmount(
      productId,
      quantity,
      deliveryTimes,
      promotionCode
    );
  } else {
    response = await OrderAPI.calcGuestExpressAmount(
      JSON.stringify([{ productId, quantity, deliveryTimes }]),
      promotionCode
    );
  }
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export async function asyncGetMemberOrderPointRecords() {
  let response = await OrderAPI.getMemberOrderPointRecords();
  throwErrorIfResponseIsNotOk(response);

  return response.json();
}

export function storeCheckoutSession(sessionData, orderData) {

  
  store.dispatch({
    type: "REFRESH_CHECKOUT_SESSION",
    payload: {
      merchant: sessionData["merchant"],
      result: sessionData["result"],
      sessionId: sessionData["session.id"],
      sessionUpdateStatus: sessionData["session.updateStatus"],
      sessionVersion: sessionData["session.version"],
      successIndicator: sessionData["successIndicator"],
      orderId: orderData.orderId,
      orderNo: orderData.orderNo,
    },
  });
}


export function claerCheckoutSession() {

  
  store.dispatch({
    type: "CLEAR_CHECKOUT_SESSION",
  });
}
