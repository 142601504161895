import {g, p} from './BasicAPI';

export function getById(id)
{
    return g('/coupon/' + id);
}

export function getAll()
{
    return g('/coupon').then(res => res.json());;
}

export function getByCode(couponCode)
{
    return g(`/coupon/?couponCode=${encodeURIComponent(couponCode)}`);
}

export function getByCodeGuest(couponCode)
{
    return g(`/coupon/?couponCode=${encodeURIComponent(couponCode)}`);
}