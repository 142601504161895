import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import style from "./UpperSideNav.module.css";
import useWindowSize from "../../utils/useWindowSize";

interface IBookDate {
  categoryId: number;
  categoryName: string;
}

interface IProps {
  list: IBookDate[];
}

export default function UpperSideNav({ props }: any) {

  // let {id} = useParams()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();

  if (width > 1024) {
    return (
      <nav>
        <ul className={style.upperSideNavContainer}>
          {/* <h4>圖書分類</h4> */}
          {props &&
            props
              .filter((x: any) => x.categoryId > 9999 && x.isHeader !== 1)
              .map((item: any) => (
                <li
                  key={item.categoryId}
                  className={style.upperSideNavList}
                  onClick={() => navigate("/category/" + item.categoryId)}
                >
                  {/* <Button
                      buttonType="button"
                      styleType={"upperSideNavButton"}
                      callBack={() =>
                        navigate("category/" + item.categoryUrlNaming)
                      }
                    >
                      {t(`category.${item.categoryId}`)}
                    </Button> */}
                  {t(`category.${item.categoryId}`)}
                </li>
              ))}
        </ul>
      </nav>
    );
  }
  else{
    return (<></>)
  }
}
