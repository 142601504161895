// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.bookmarkRecord_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid;
  padding: 0 0 6px 0;
}


.bookmarkRecord_row_btn{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.delete_btn{
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  /* position: absolute;
  top: 3px !important;
  right: 6px !important; */
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
  background: var(--color-orange-add70);
  visibility: hidden;
}

.bookmarkRecord_row:hover .delete_btn{
  visibility: visible;
}
@media (max-width: 1366px) {
  .bookmarkRecord_row .delete_btn{
    visibility: visible;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DialogBox/DialogBox.css"],"names":[],"mappings":";;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,wBAAwB;EACxB,kBAAkB;AACpB;;;AAGA;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB;;0BAEwB;EACxB,WAAW;EACX,sCAAsC;EACtC,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":["\n\n\n.bookmarkRecord_row{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid;\n  padding: 0 0 6px 0;\n}\n\n\n.bookmarkRecord_row_btn{\n  display: flex;\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.delete_btn{\n  width: 18px !important;\n  height: 18px !important;\n  border-radius: 50%;\n  cursor: pointer;\n  text-align: center;\n  /* position: absolute;\n  top: 3px !important;\n  right: 6px !important; */\n  z-index: 10;\n  animation: popup 0.1s ease-in-out 0s 1;\n  background: var(--color-orange-add70);\n  visibility: hidden;\n}\n\n.bookmarkRecord_row:hover .delete_btn{\n  visibility: visible;\n}\n@media (max-width: 1366px) {\n  .bookmarkRecord_row .delete_btn{\n    visibility: visible;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
