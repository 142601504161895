const initialState = {
  SessionData: {},
};

function CheckoutSessionReducer(state = initialState, action) {
  if (action.type == "REFRESH_CHECKOUT_SESSION") {
    return action.payload;
  }
  return state;
}

export default CheckoutSessionReducer;
