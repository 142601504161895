/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BorrowDto
 */
export interface BorrowDto {
    /**
     * 
     * @type {string}
     * @memberof BorrowDto
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowDto
     */
    bookCover?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowDto
     */
    bookISBN?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowDto
     */
    bookThumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowDto
     */
    bookname?: string;
    /**
     * 
     * @type {Date}
     * @memberof BorrowDto
     */
    borrowDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof BorrowDto
     */
    borrowId?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowDto
     */
    borrowTime?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowDto
     */
    ePubProFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowDto
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowDto
     */
    percentage?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof BorrowDto
     */
    publisherName?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowDto
     */
    resumeBorrowTime?: number;
    /**
     * 
     * @type {Date}
     * @memberof BorrowDto
     */
    returnDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof BorrowDto
     */
    seriesName?: string;
    /**
     * 
     * @type {number}
     * @memberof BorrowDto
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof BorrowDto
     */
    time?: number;
}

export function BorrowDtoFromJSON(json: any): BorrowDto {
    return BorrowDtoFromJSONTyped(json, false);
}

export function BorrowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BorrowDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': !exists(json, 'author') ? undefined : json['author'],
        'bookCover': !exists(json, 'bookCover') ? undefined : json['bookCover'],
        'bookISBN': !exists(json, 'bookISBN') ? undefined : json['bookISBN'],
        'bookThumbnail': !exists(json, 'bookThumbnail') ? undefined : json['bookThumbnail'],
        'bookname': !exists(json, 'bookname') ? undefined : json['bookname'],
        'borrowDate': !exists(json, 'borrowDate') ? undefined : (new Date(json['borrowDate'])),
        'borrowId': !exists(json, 'borrowId') ? undefined : json['borrowId'],
        'borrowTime': !exists(json, 'borrowTime') ? undefined : json['borrowTime'],
        'ePubProFileName': !exists(json, 'ePubProFileName') ? undefined : json['ePubProFileName'],
        'memberId': !exists(json, 'memberId') ? undefined : json['memberId'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'publisherName': !exists(json, 'publisherName') ? undefined : json['publisherName'],
        'resumeBorrowTime': !exists(json, 'resumeBorrowTime') ? undefined : json['resumeBorrowTime'],
        'returnDate': !exists(json, 'returnDate') ? undefined : (new Date(json['returnDate'])),
        'seriesName': !exists(json, 'seriesName') ? undefined : json['seriesName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'time': !exists(json, 'time') ? undefined : json['time'],
    };
}

export function BorrowDtoToJSON(value?: BorrowDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'bookCover': value.bookCover,
        'bookISBN': value.bookISBN,
        'bookThumbnail': value.bookThumbnail,
        'bookname': value.bookname,
        'borrowDate': value.borrowDate === undefined ? undefined : (value.borrowDate.toISOString()),
        'borrowId': value.borrowId,
        'borrowTime': value.borrowTime,
        'ePubProFileName': value.ePubProFileName,
        'memberId': value.memberId,
        'percentage': value.percentage,
        'productId': value.productId,
        'publisherName': value.publisherName,
        'resumeBorrowTime': value.resumeBorrowTime,
        'returnDate': value.returnDate === undefined ? undefined : (value.returnDate.toISOString()),
        'seriesName': value.seriesName,
        'status': value.status,
        'time': value.time,
    };
}

