import { useEffect, useState } from "react";
import io from "socket.io-client";

export interface ISocketMsg {
  bookPage: IBookPage;
  detailPage: IDetailPage;
  error?: {};
}

interface IBookPage {
  [key: string]: [];
}

interface IDetailPage {
  [key: string]: [];
}

export default function useSocket() {
  const [needRetry, setNeedRetry] = useState(true);
  const [socket, setSocket] = useState<any>(null);
  const [socketMsg, setSocketMsg] = useState<ISocketMsg>();

  const [detailPage, setDetailPage] = useState("");
  const [bookPage, setBookPage] = useState("");

  useEffect(() => {
    if (socket === null && needRetry) {
      /*
      setSocket(
        io(`wss://sso.nblib.com?orgId=${process.env.REACT_APP_WEB_NAME}`)
      );
      */
    }

    if (socket !== null) {
      socket.on("sendMsg", (message: ISocketMsg) => {
        //console.log("useSocket sendMsg", message);
        setSocketMsg(Object.assign(message, { socketId: socket.id }));
      });
    }

    return () => {
      if (socket !== null) {
        socket.disconnect();
        socket.off();
      }
    };
  }, [socket]);

  useEffect(() => {
    if (socketMsg?.error) {
      socket.disconnect();
      socket.off();
      setNeedRetry(false);
      //console.log("useSocket socketMsg1", socketMsg);
      //console.log("aaaaaa", socketMsg);
    }
  }, [socketMsg]);

  useEffect(() => {
    if (socket !== null) {
      socket.emit("detailPage", detailPage);
    }
  }, [detailPage]);

  useEffect(() => {
    if (socket !== null) {
      socket.emit("bookPage", bookPage);
    }
  }, [bookPage]);

  return { socketMsg, setDetailPage, setBookPage };
}
