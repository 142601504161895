/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Sync,
    SyncFromJSON,
    SyncToJSON,
} from '../models';

export interface SyncMissionDataUsingPOSTRequest {
    sync?: Sync;
}

export interface SyncResetCategoryUsingPOSTRequest {
    sync?: Sync;
}

/**
 * 
 */
export class SyncControllerApi extends runtime.BaseAPI {

    /**
     * syncMissionData
     */
    async syncMissionDataUsingPOSTRaw(requestParameters: SyncMissionDataUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sync/adds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncToJSON(requestParameters.sync),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * syncMissionData
     */
    async syncMissionDataUsingPOST(requestParameters: SyncMissionDataUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.syncMissionDataUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * syncResetCategory
     */
    async syncResetCategoryUsingPOSTRaw(requestParameters: SyncResetCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/sync/reset-category`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncToJSON(requestParameters.sync),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * syncResetCategory
     */
    async syncResetCategoryUsingPOST(requestParameters: SyncResetCategoryUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<number> {
        const response = await this.syncResetCategoryUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
