import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as API from "../../api/PageContentAPI";

export default function AboutUs() {
  const [pageContent, setPageContent] = useState("");
  const { i18n } = useTranslation();

  window.scrollTo(0, 0);
  useEffect(() => {
    API.getPageContentById(4).then((res) => setPageContent(res.content));
  }, []);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: pageContent.split(":::")[i18n.language === "en" ? 1 : 0],
      }}
    ></div>
    // <div>
    //   <h1>關於我們</h1>
    //   天主教香港教區電子書平台由天主教香港教區創建。旨在透過這電子閱讀平台，為身處世界各地的教友及讀者提供閱讀書籍、報刊及觀看影片等服務，以增進教友對教會教義的認識，提升信仰生活，並向大眾傳播基督的福音。
    //   耶穌對他們說：「你們往普天下去，向一切受造物宣傳福音。」（谷16:15）
    //   <h1>ABOUT US</h1> eBook Platform of the Catholic Diocese of Hong Kong is
    //   founded by Catholic Diocese of Hong Kong. The aim of this platform is to
    //   provide books and newspapers reading, videos and related materials to
    //   faithful all over the world to enhance their understanding of the church
    //   doctrine, to upgrade their spiritual life and spread the gospel of Jesus
    //   Christ to the public. Jesus said to them, “Go into all the world and
    //   preach the gospel to the whole creation.” (Mk 16:15)
    // </div>
  );
}
