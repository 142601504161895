import {g, p} from './BasicAPI';

export const getAll = () =>
{
    return g(`/discount/all`)
        .then(res => res.json());

}

export const getById = (id) => 
{
    return g(`/discount/${id}`)
        .then(res => res.json());
}

export function createX(discountType, discountTitle, condition, actionType, actionValue, target, limitToUse, couponCode, discountProductId, startDate, endDate, limitToUseRest)
{
    return p('/discount/add', {
        discountTitle,
        discountType,
        condition,
        actionType,
        actionValue,
        target,
        limitToUse,
        couponCode,
		discountProductId,
        startDate,
        endDate,
        limitToUseRest,
    });
}

export function updateX(id, discountType, discountTitle, condition, actionType, actionValue, target, limitToUse, couponCode, discountProductId, startDate, endDate, limitToUseRest)
{
    return p('/discount/update', {
        discountId: id,
        discountTitle,
        discountType,
        condition,
        actionType,
        actionValue,
        target,
        limitToUse,
        couponCode,
		discountProductId,
        startDate,
        endDate,
        limitToUseRest,
    });
}

export function trashX(id)
{
    return p(`/discount/delete/${id}`);
}