import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import CartItem from "../../components/CartItem/CartItem";
import style from "./Checkout.module.css";
import backArrow from "../../assets/icons/iconsarrowleft@1x.svg";
import { connect } from "react-redux";
import * as DiscountAPI from "../../api/DiscountAPI";
import CheckoutBox from "../../components/CheckoutBox/CheckoutBox";
import unionPayIcon from "../../assets/images/2593673_pay_union_icon.png";
import visaIcon from "../../assets/images/294654_visa_icon.png";
import aliPayIcon from "../../assets/images/alipay hk.png";
import weChatPayIcon from "../../assets/images/WeChat-Pay-HK-Logo.png";
import masterIcon from "../../assets/images/1322426_card_master_master card_master card new logo_method_icon.png";
import jcbIcon from "../../assets/images/2593677_jcb_icon.png";
import * as ShoppingCartService from "../../redux/services/ShoppingCartService";
import * as OrderService from "../../redux/services/OrderService";
import { useTranslation } from "react-i18next";
import DialogBox from "../DialogBox/DialogBox";
import { swiftPassPayment } from "../../utils/Payment";
import { authFetch } from "../../redux/services/AuthenticationService";
import { RefreshShoppingCartItem } from "../../redux/services/ShoppingCartService";
import { listMybookShelf } from "../../redux/services/FavoriteService";
import { store } from "../../redux/store";
import { checkLogin } from "../../utils/GlobalFunction";
import * as API from "../../api/PageContentAPI";

var xml2js = require("xml2js");

function CheckoutPage(props: any) {
  const [discountList, setDiscountList] = useState([]);
  const [discountTitle, setDiscountTitle] = useState<any>(undefined);
  const [confrim, setComfirm] = useState(false);
  const [topping, setTopping] = useState("UNIONPAY");
  const { t, i18n } = useTranslation();
  const [messageOne, setMessageOne] = useState(false);
  const [messageTwo, setMessageTwo] = useState(false);
  const [checkoutButton, setCheckoutButton] = useState(false);
  const [pageContent, setPageContent] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const isLogined = checkLogin();
    if (!isLogined) {
      navigate("/");
      return;
    }
    fetchDiscount();
  }, []);


  useEffect(() => {
    console.log("bugs logs")
    if (messageOne) {
      API.getPageContentById(6).then((res) =>
        setPageContent(res.content.split(":::")[i18n.language === "en" ? 1 : 0])
      );
    }
    if (messageTwo) {
      API.getPageContentById(5).then((res) =>
        setPageContent(res.content.split(":::")[i18n.language === "en" ? 1 : 0])
      );
    }

  }, [messageOne, messageTwo])

  useEffect(() => {
    console.log("e.topping", topping);
    if (topping === "VISA") {
      const script = document.createElement("script");
      script.src =
        "https://ap-gateway.mastercard.com/static/checkout/checkout.min.js";
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => setCheckoutButton(true);
      return () => {
        console.log("e.removeChildonOptionChangeVISA");
        document.body.removeChild(script);
      };
    }
    if (topping === "UNIONPAY") {
      const script = document.createElement("script");
      /*
      script.src =
        "https://test-gateway.mastercard.com/static/checkout/checkout.min.js";
      */
      script.src =
       "https://ap-gateway.mastercard.com/static/checkout/checkout.min.js";
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => setCheckoutButton(true);
      return () => {
        console.log("e.removeChildonOptionChangeUNIONPAY");
        document.body.removeChild(script);
      };
    }
  }, [topping]);

  const onOptionChange = (e: any) => {
    if (e.target.value == "VISA" || e.target.value == "UNIONPAY") {
      setCheckoutButton(false);
    }
    setTopping(e.target.value);
  };

  const fetchDiscount = async () => {
    const discount = await DiscountAPI.getAll();
    setDiscountList(discount);
  };

  const handleConfirm = (value: { action: string; value: string }) => {
    console.log("string", value);
  };

  const discountAmount = () => {
    console.log("props.productList.", props.productList);
    const initialValue = 0;
    const sumWithInitial = props.productList.reduce(
      (previousValue: number, currentValue: any) => {
        if (currentValue.product_discount_price == null) {
          return 0;
        } else {
          return (
            previousValue +
            (currentValue.product_discount_price - currentValue.product_price)
          );
        }
      },
      initialValue
    );
    return sumWithInitial;
  };
  const totalpriceBeforeDiscount = () => {
    const initialValue = 0;

    const sumWithInitial = props.productList.reduce(
      (previousValue: number, currentValue: any) =>
        previousValue + currentValue.product_discount_price,
      initialValue
    );
    return sumWithInitial;
  };

  function handleSetCouponCode(value: any) {
    console.log("handleSetCouponCode value", value);
    if (value.action === "applyDiscount") {
      if (value.couponCode === "") {
        alert("請輸入優惠代碼");
        return;
      }
      let couponCode = value.couponCode;
      try {
      } catch (err) {
        console.log("err");
      }
      ShoppingCartService.asyncApplyCoupon(couponCode)
        .then((t: any) => {
          if (t.result) {
          } else {
            alert("無效的的優惠代碼");
          }
        })
        .finally(() => {
          try {
          } catch (err) {
            console.log("err");
          }
        });
    }
    if (value.action === "cancelDiscount") {
      ShoppingCartService.asyncDeleteCoupon().then((t) =>
        !t ? alert("取消使用優惠代碼失敗") : undefined
      );
      return;
    }
    if (value.action === "confirm") {
      if (props.total === 0) {
        OrderService.asyncAddDirectDeliveryOrder().then((res) => {
          if (res !== undefined) {
            const orderReturn = res;
            console.log("orderNo", orderReturn.orderNo);
            if (orderReturn.orderNo !== undefined) {
              fetch(
                process.env.REACT_APP_BACKEND +
                "order/mastercard/datafeed?orderid=" +
                res.orderId
              ).then((res: any) => {
                if (res !== undefined) {
                  RefreshShoppingCartItem();
                  listMybookShelf(store.getState().MemberReducer.member_id);
                  navigate("/orderComplete?order=" + orderReturn.orderNo);
                }
              });

              return;
            } else {
              console.log("書本出錯");
            }
          }
        });
        return;
      }

      setComfirm(true);

      return;
    }
    if (value.action === "modal") {
      callBackModal(value.message);
      return;
    }
  }

  const fetchPlaceOrder = () => {
    if (props.member !== null) {
      OrderService.asyncAddDirectDeliveryOrder().then((res) => {
        if (res !== undefined) {
          const orderReturn = res;
          console.log("orderNo", orderReturn.orderNo);
          if (orderReturn.orderNo !== undefined) {
            fetchCheckout(res);
            return;
          } else {
            console.log("書本出錯");
          }
          // fetch(
          //   process.env.REACT_APP_BACKEND +
          //     "order/mastercard/datafeed?orderid=" +
          //     res.orderId
          // ).then((res: any) => {
          //   if (res !== undefined) {
          //     navigate("/orderComplete?order=" + orderReturn.orderNo);
          //   }
          // });
        }
      });
    }
  };
  const setUpCheckoutConfig = (sessionData: any) => {
    //alert(sessionData["successIndicator"]);
    (window as any).Checkout.configure({
      session: {
        id: sessionData["session.id"],
      },
      interaction: {
        merchant: {
          name: "CATHOLIC CENTRE ",
          address: {
            line1: "200 Sample St",
            line2: "1234 Example Town",
          },
        },
      },
    });
  };

  const fetchCheckout = (orderData: any) => {
    //console.log("Checkout", Checkout);
    (window as any).Checkout = (window as any).Checkout || {};

    let formData = new FormData();
    formData.append("amount", props.total);
    formData.append("currency", "HKD");
    formData.append("orderId", orderData.orderNo);
    formData.append("orderReference", orderData.orderNo);
    formData.append("operation", "PURCHASE");
    formData.append("description", "test");
    formData.append("transactionSrc", "INTERNET");

    if (topping === "VISA") {
      fetch(process.env.REACT_APP_BACKEND + "boc_checkout/getSession", {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          return res.text();
        })
        .then((res2) => {
          let sessionData = JSON.parse(
            '{"' + res2.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
            function (key, value) {
              return key === "" ? value : decodeURIComponent(value);
            }
          );

          OrderService.storeCheckoutSession(sessionData, orderData);
          setUpCheckoutConfig(sessionData);

          (window as any).Checkout.showPaymentPage();
        });
      return;
    }
    if (topping === "UNIONPAY") {
      fetch(
        process.env.REACT_APP_BACKEND + "boc_checkout/unionPay/getSession",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((res) => {
          return res.text();
        })
        .then((res2) => {
          let sessionData = JSON.parse(
            '{"' + res2.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
            function (key, value) {
              return key === "" ? value : decodeURIComponent(value);
            }
          );
          console.log("sessionData", sessionData, JSON.stringify(sessionData));

          OrderService.storeCheckoutSession(sessionData, orderData);
          setUpCheckoutConfig(sessionData);

          (window as any).Checkout.showPaymentPage();
        });
      return;
    }

    if (topping === "ALIPAY") {
      const xml = swiftPassPayment(
        props.total,
        orderData.orderNo,
        orderData.orderNo,
        "ALIPAYHK"
      );

      fetch(process.env.REACT_APP_BACKEND + "boc_checkout/prepay", {
        method: "POST",
        headers: {
          "Content-Type": "application/xml",
        },
        body: xml,
      }).then((response) => {
        response.text().then((res) =>
          xml2js.parseStringPromise(res).then(function (result: any) {
            const link = result.xml.checkoutUrl[0];

            //window.location.pathname = result.xml.checkoutUrl[0];
            window.location.replace(link);
          })
        );
      });
    }
    if (topping === "WECHATPAY") {
      const xml = swiftPassPayment(
        props.total,
        orderData.orderNo,
        orderData.orderNo,
        "WECHAT"
      );

      fetch(process.env.REACT_APP_BACKEND + "boc_checkout/prepay", {
        method: "POST",
        headers: {
          "Content-Type": "application/xml",
        },
        body: xml,
      }).then((response) => {
        console.log("CP1 response", response);
        response.text().then((res) =>
          xml2js.parseStringPromise(res).then(function (result: any) {
            console.log("CP1 res", res);
            console.log("CP1 result", result);
            const link = result.xml.checkoutUrl[0];

            //window.location.pathname = result.xml.checkoutUrl[0];
            window.location.replace(link);
          })
        );
      });
    }

    //
  };

  const callBackModal = (message: string) => {
    console.log(message, "message");
    if (message === "messageOne") {
      setMessageOne(true);
    }
    if (message === "messageTwo") {
      setMessageTwo(true);
      return;
    }
    if (message === "close") {
      setMessageOne(false);
      setMessageTwo(false);
      return;
    }
  };

  return (
    <div>
      {confrim === false ? (
        <div className={style.checkoutLayout}>
          <div className={style.shopingCartContainer}>
            <div className={style.backButtonContainer}>
              <img src={backArrow} style={{ width: "20px" }} />
              <Button
                styleType={"upperSideNavButton"}
                children={"返回"}
                callBack={() => navigate(-1)}
              />
            </div>
            <h1>{t("checkout.orderConfirmation")}</h1>
            <div className={style.boxItemLayout2}>
              <span>
                {t("checkout.product")}({props.productList.length})
              </span>
              <span>{t("checkout.price")}</span>
            </div>
            <hr className={style.horizontalLine}></hr>
            {props.productList.map((item: any) => (
              <CartItem editable={false} props={item} key={item.product_id} />
            ))}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span
                style={{ color: "rgb(0, 157, 165)", cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                繼續選購
              </span>
            </div>
          </div>

          <CheckoutBox
            props={props}
            discountAmount={discountAmount}
            totalpriceBeforeDiscount={props.total_before_discount}
            callBack={(value: { action: string; value?: string }) =>
              handleSetCouponCode(value)
            }
          />
          <div style={{ fontSize: "10px" }}>
            <DialogBox
              showDialog={messageOne}
              title={"服務及使用條款"}
              styleType={"Main"}
              button={"關閉"}
              callBack={(value: any) => callBackModal(value.message)}
            >
              <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
            </DialogBox>
          </div>
          <div style={{ fontSize: "10px" }}>
            <DialogBox
              showDialog={messageTwo}
              title={"私隱政策聲明"}
              styleType={"Main"}
              button={"關閉"}
              callBack={(value: any) => callBackModal(value.message)}
            >
              <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
            </DialogBox>
          </div>
        </div>
      ) : (
        <></>
      )}

      {confrim === false ? (
        <></>
      ) : (
        <div className={style.shopingCartContainer2}>
          <div className={style.backButtonContainer}>
            <img src={backArrow} style={{ width: "20px" }} />
            <Button
              styleType={"upperSideNavButton"}
              children={"返回"}
              callBack={() => setComfirm(false)}
            />
          </div>
          <h1>{t("checkout.checkout")}</h1>
          <h2>{t("checkout.paymentMethod")}</h2>
          <hr />
          <div className={style.paymentInputContainer}>
            <div className={style.paymentInput}>
              <input
                type="radio"
                name="GOBALPAY"
                value={"UNIONPAY"}
                id={"unioinPay"}
                checked={topping === "UNIONPAY"}
                onChange={onOptionChange}
              />
              <label htmlFor={"unioinPay"}>
                <img src={unionPayIcon} />
              </label>
            </div>
            <div className={style.paymentInput}>
              <input
                type="radio"
                name="GOBALPAY"
                value={"VISA"}
                id={"visa"}
                checked={topping === "VISA"}
                onChange={onOptionChange}
              />
              <label htmlFor={"visa"}>
                <img src={visaIcon} />
                <img src={masterIcon} />
                <img src={jcbIcon} />
              </label>
            </div>
            <div className={style.paymentInput}>
              <input
                type="radio"
                name="GOBALPAY"
                value={"ALIPAY"}
                id={"alipay"}
                checked={topping === "ALIPAY"}
                onChange={onOptionChange}
              />
              <label htmlFor={"alipay"}>
                <img src={aliPayIcon} />
              </label>
            </div>
            <div className={style.paymentInput}>
              <input
                type="radio"
                name="GOBALPAY"
                value={"WECHATPAY"}
                id={"wechatPay"}
                checked={topping === "WECHATPAY"}
                onChange={onOptionChange}
              />
              <label htmlFor={"wechatPay"}>
                <img src={weChatPayIcon} />
              </label>
            </div>
          </div>
          <hr />

          <div className={style.orderInformation}>
            <p>{t("checkout.terms")}</p>
          </div>
          <hr />
          <div className={style.totalPriceContainer}>
            <p>{t("checkout.total")}</p>
            <div>
              <p>HKD</p>
              <p style={{ color: "#47D5CD" }}>
                {(Math.floor((props.total + Number.EPSILON) * 10) / 10).toFixed(2)}
              </p>
            </div>
          </div>
          {checkoutButton ? (
            <div className={style.buttonContainer}>
              <Button
                styleType={"loginButton"}
                children={"確認訂單"}
                callBack={() => fetchPlaceOrder()}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    productList: state.shoppingCartReducer.productList,
    total_before_discount: state.shoppingCartReducer.total_before_discount,
    total:
      Math.floor((state.shoppingCartReducer.total + Number.EPSILON) * 10) / 10,
    coupon: state.shoppingCartReducer.coupon,
    member: state.MemberReducer.token,
    checkoutSession: state.checkoutSession,
  };
};

export default connect(mapStateToProps, null)(CheckoutPage);
