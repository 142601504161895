// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Complete_shopingCartLayout__YeX98 {
  width: min(100%, 1095px);
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
  min-height: 80vh;
}

.Complete_formContainer__44y4A {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 0px 5px 2px rgba(204, 204, 204, 0.5);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(204, 204, 204, 0.5);
  -moz-box-shadow: 0px 0px 5px 2px rgba(204, 204, 204, 0.5);
}
.Complete_formContainer__44y4A > span,
.Complete_formContainer__44y4A > p {
  display: block;
  margin: 0.5em 0 0 0;
}

.Complete_buttonContainer__cX5qL {
  height: 3em;
  margin: 2em 0 10px 0;
}

.Complete_buttonContainer1__JzLIf {
  height: 3em;
}

.Complete_buttonContainer1__JzLIf > button {
  background-color: white;
  color: #009da5;
}

.Complete_orderNo__zFyVN {
  margin: 0 0 1em 0;
}

@media only screen and (max-width: 1024px) {

  .Complete_shopingCartLayout__YeX98{
    margin: 3em 2em;
    width: auto;
  }

}`, "",{"version":3,"sources":["webpack://./src/layout/Complete/Complete.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,aAAa;EACb,oDAAoD;EACpD,4DAA4D;EAC5D,yDAAyD;AAC3D;AACA;;EAEE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE;IACE,eAAe;IACf,WAAW;EACb;;AAEF","sourcesContent":[".shopingCartLayout {\n  width: min(100%, 1095px);\n  display: flex;\n  flex-direction: column;\n  margin: 3rem auto;\n  min-height: 80vh;\n}\n\n.formContainer {\n  border: 1px solid rgb(230, 230, 230);\n  border-radius: 10px;\n  padding: 30px;\n  box-shadow: 0px 0px 5px 2px rgba(204, 204, 204, 0.5);\n  -webkit-box-shadow: 0px 0px 5px 2px rgba(204, 204, 204, 0.5);\n  -moz-box-shadow: 0px 0px 5px 2px rgba(204, 204, 204, 0.5);\n}\n.formContainer > span,\n.formContainer > p {\n  display: block;\n  margin: 0.5em 0 0 0;\n}\n\n.buttonContainer {\n  height: 3em;\n  margin: 2em 0 10px 0;\n}\n\n.buttonContainer1 {\n  height: 3em;\n}\n\n.buttonContainer1 > button {\n  background-color: white;\n  color: #009da5;\n}\n\n.orderNo {\n  margin: 0 0 1em 0;\n}\n\n@media only screen and (max-width: 1024px) {\n\n  .shopingCartLayout{\n    margin: 3em 2em;\n    width: auto;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shopingCartLayout": `Complete_shopingCartLayout__YeX98`,
	"formContainer": `Complete_formContainer__44y4A`,
	"buttonContainer": `Complete_buttonContainer__cX5qL`,
	"buttonContainer1": `Complete_buttonContainer1__JzLIf`,
	"orderNo": `Complete_orderNo__zFyVN`
};
export default ___CSS_LOADER_EXPORT___;
