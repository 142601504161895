import React, { useEffect, useState } from "react";
import ButtonGroup from "../ButtonGroup/ButtonGroup";
import ramdomPicture from "../../assets/images/randomPicture.jpeg";
import style from "../test/Home.module.css";
import banner from "../../assets/images/banner.jpeg";
import BookCollection from "../../components/BookCollection/BookCollection";
import newBookIcon from "../../assets/icons/icons40new_book@1x.svg";
import recommendIcon from "../../assets/icons/icons40recommend@1x.svg";
import trophyIcon from "../../assets/icons/icons40trophy@1x.svg";
import priestIcon from "../../assets/icons/priest@1x.svg";
import bestSellerIcon from "../../assets/icons/best_seller@1x.svg";
import * as displayProductAPI from "../../api/DisplayProductAPI";
import { useBanner } from "../test/Home";
import Carousel, { CarouselItem } from "../../components/Carousel/Carousel";

export default function LandingPage() {
  const [displsyArr1, setDisplayArr1] = useState<any>([]);
  const [displsyArr2, setDisplayArr2] = useState<any>([]);
  const [displsyArr3, setDisplayArr3] = useState<any>([]);
  const [displsyArr4, setDisplayArr4] = useState<any>([]);
  const [displsyArr5, setDisplayArr5] = useState<any>([]);
  const { bannerList } = useBanner();
  useEffect(() => {
    fetchCollectionCategory();
  }, []);

  const fetchCollectionCategory = async () => {
    const newBookRecommend =
      await displayProductAPI.getDisplayProductByCategoryId(10000);
    const bestSeller = await displayProductAPI.getDisplayProductByCategoryId(
      20000
    );
    const tenBestBook = await displayProductAPI.getDisplayProductByCategoryId(
      30000
    );
    const Recommendation =
      await displayProductAPI.getDisplayProductByCategoryId(40000);
    const editorRecommendation =
      await displayProductAPI.getDisplayProductByCategoryId(50000);
    setDisplayArr1(newBookRecommend.list);
    setDisplayArr2(bestSeller.list);
    setDisplayArr3(tenBestBook.list);
    setDisplayArr4(Recommendation.list);
    setDisplayArr5(editorRecommendation.list);
  };

  function getRandom(arr: [], n: number) {
    if (arr.length < 4) return;
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
  return (
    <div>
      <Carousel>
        {bannerList
          .filter((x: any) => x.bannerPosition === 2)
          .map((x: any) => (
            <CarouselItem
              children={
                <img
                  src={
                    process.env.REACT_APP_BACKEND + "file/" + x.bannerContent
                  }
                />
              }
            />
          ))}
      </Carousel>
      <div className={style.heroContainer}>
        {/* {bannerList
          .filter((x: any) => x.bannerPosition === 2)
          .map((x: any) => (
            <img
              className={style.hero}
              src={process.env.REACT_APP_BACKEND + "file/" + x.bannerContent}
            />
          ))} */}
      </div>
      {/* <ButtonGroup /> */}
      <div className={style.bookCollectionContainer}>
        <BookCollection
          title={10000}
          icon={newBookIcon}
          props={
            // displsyArr1.length < 5 ? displsyArr1 : getRandom(displsyArr1, 4)
            displsyArr1.slice(0, 4)
          }
        />
      </div>
      <div className={style.bookCollectionContainer}>
        <BookCollection
          title={20000}
          icon={bestSellerIcon}
          props={
            // displsyArr2.length < 5 ? displsyArr2 : getRandom(displsyArr2, 4)
            displsyArr2.slice(0, 4)
          }
        />
      </div>
      <div className={style.bookCollectionContainer}>
        <BookCollection
          title={30000}
          icon={trophyIcon}
          props={
            displsyArr3.slice(0, 4)
            // displsyArr3.length < 5 ? displsyArr3 : getRandom(displsyArr3, 4)
          }
        />
      </div>
      <div className={style.bookCollectionContainer}>
        <BookCollection
          title={40000}
          icon={priestIcon}
          props={
            displsyArr4.slice(0, 4)
            // displsyArr4.length < 5 ? displsyArr4 : getRandom(displsyArr4, 4)
          }
        />
      </div>
      <div className={style.bookCollectionContainer}>
        <BookCollection
          title={50000}
          icon={recommendIcon}
          props={
            displsyArr5.slice(0, 4)
            // displsyArr5.length < 5 ? displsyArr5 : getRandom(displsyArr5, 4)
          }
        />
      </div>
    </div>
  );
}
