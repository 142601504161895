import { store } from "../store";
import { asyncAuthedPost } from "./AuthenticationService";
import { decode as b64decode } from "js-base64";

import * as MemberAction from "./../actions/MemberAction";
import * as ShoppingCartService from "./ShoppingCartService";

export const AuthenticateTokenStatus = {
  NotLogin: 0,
  LoggedIn: 1,
  LoggedInNearExpire: 2,
  LoggedInAndExpired: 3,
};

export function isAuthenticateTokenRequireRefresh() {
  const token = store.getState().MemberReducer.token;
  const expire = store.getState().MemberReducer.exp;
  if (expire !== null && typeof expire == "number") {
    const now = Math.floor(Date.now() / 1000);
    if (expire <= now) {
      return true;
    }
  }

  if (token && expire == null) {
    logout();
  }
  return false;
}

export function getAuthenticateTokenStatus() {
  const token = store.getState().MemberReducer.token;
  const expire = store.getState().MemberReducer.exp;
  if (expire !== null && typeof expire == "number") {
    const now = Math.floor(Date.now() / 1000);
    if (expire <= now) {
      return AuthenticateTokenStatus.LoggedInAndExpired;
    }

    const threeDaysBefore = now - 86400 * 7;
    if (expire <= threeDaysBefore) {
      return AuthenticateTokenStatus.LoggedInNearExpire;
    }

    return AuthenticateTokenStatus.LoggedIn;
  }

  if (token && expire == null) {
    logout();
  }
  return AuthenticateTokenStatus.NotLogin;
}

export async function asyncRefreshUserToken(force) {
  const mReducer = store.getState().MemberReducer;

  if (mReducer && mReducer.token != null) {
    if (!force) {
      let exp = mReducer.exp || 0;
      exp = Number.parseInt(exp);
      if (Number.isNaN(exp)) {
        exp = 0;
      }

      const now = Number.parseInt(+new Date() / 1000);
      if (exp > now) {
        return "";
      }
    }

    try {
      let response = await asyncAuthedPost("/member/refreshToken", {});
      if (!response.ok) {
        throw new Error("response is not ok");
      }
      let newToken = await response.text();
      if (!newToken) {
        throw new Error("server doesnt accept refresh token");
      }
      let [, information] = newToken.split(".");
      information = JSON.parse(b64decode(information));
      store.dispatch({
        type: "SET_MEMBER_INFORMATION",
        member_id: information.sub,
        member_first_name: information.fistname, // <-- yes is fist name....
        member_last_name: information.lastname,
        member_level: information.authority,
        token: newToken,
        exp: information.exp,
      });
      return "";
    } catch ($e) {
      throw $e;
    }
  }

  throw new Error("user is not login");
}

export function logout() {
  let member = {
    type: "DELETE_MEMBER_INFORMATION",
  };

  store.dispatch(member);
  ShoppingCartService.RefreshShoppingCartItemAfterLogout();
}
