// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_carousel__ePVOM {
  overflow: hidden;
  position: relative;
}

.Carousel_innerCarousel__BITOF {
  white-space: nowrap;
  transition: transform 0.3s;
}

.Carousel_carouselItem__gsgD- {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: white;
}

.Carousel_carouselItem__gsgD- > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.Carousel_carouselButtonContainer__krMH1 {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 20px;
  gap: 5px;
}

.Carousel_carouselButtonContainer__krMH1 button {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  border: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Carousel/Carousel.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".carousel {\n  overflow: hidden;\n  position: relative;\n}\n\n.innerCarousel {\n  white-space: nowrap;\n  transition: transform 0.3s;\n}\n\n.carouselItem {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  background-color: green;\n  color: white;\n}\n\n.carouselItem > img {\n  height: 100%;\n  width: 100%;\n  object-fit: contain;\n}\n\n.carouselButtonContainer {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  bottom: 20px;\n  gap: 5px;\n}\n\n.carouselButtonContainer button {\n  border-radius: 50%;\n  height: 10px;\n  width: 10px;\n  border: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": `Carousel_carousel__ePVOM`,
	"innerCarousel": `Carousel_innerCarousel__BITOF`,
	"carouselItem": `Carousel_carouselItem__gsgD-`,
	"carouselButtonContainer": `Carousel_carouselButtonContainer__krMH1`
};
export default ___CSS_LOADER_EXPORT___;
