/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReaderLogDto,
    ReaderLogDtoFromJSON,
    ReaderLogDtoToJSON,
} from '../models';

export interface GetLogUsingGETRequest {
    memberId: number;
    productId: number;
}

export interface SaveLogUsingPOSTRequest {
    memberId: number;
    productId: number;
    body?: string;
}

/**
 * 
 */
export class ReaderLogControllerApi extends runtime.BaseAPI {

    /**
     * getLog
     */
    async getLogUsingGETRaw(requestParameters: GetLogUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReaderLogDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling getLogUsingGET.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getLogUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `reader-log/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReaderLogDtoFromJSON(jsonValue));
    }

    /**
     * getLog
     */
    async getLogUsingGET(requestParameters: GetLogUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReaderLogDto> {
        const response = await this.getLogUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveLog
     */
    async saveLogUsingPOSTRaw(requestParameters: SaveLogUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ReaderLogDto>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling saveLogUsingPOST.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling saveLogUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `reader-log/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReaderLogDtoFromJSON(jsonValue));
    }

    /**
     * saveLog
     */
    async saveLogUsingPOST(requestParameters: SaveLogUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ReaderLogDto> {
        const response = await this.saveLogUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
