import {
  ADD_GUEST_FAVORITE,
  DELETE_GUEST_FAVORITE,
  REFRESH_GUEST_FAVOURITE,
} from "../../utils/Constant";

export function addGuestFavorite(payload) {
  return { type: ADD_GUEST_FAVORITE, payload };
}

export function deleteGuestFavorite(payload) {
  return { type: DELETE_GUEST_FAVORITE, payload };
}

export function refreshGuestFavourite(payload) {
  return { type: REFRESH_GUEST_FAVOURITE, payload };
}
