import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "../../components/Button/Button";
import { authFetch } from "../../redux/services/AuthenticationService";
import { listMybookShelf } from "../../redux/services/FavoriteService";
import { RefreshShoppingCartItem } from "../../redux/services/ShoppingCartService";
import style from "./Complete.module.css";
import { store } from "../../redux/store";
import { checkLogin, checkUserAgentIOS } from "../../utils/GlobalFunction";
import { claerCheckoutSession } from "../../redux/services/OrderService";

export default function Complete() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [second, setSecond] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();

  const resultIndicator = searchParams.get("resultIndicator");
  const resultOrderNo = searchParams.get("orderNo");
  const successIndicator = useSelector(
    (state: any) => state.checkoutSession.successIndicator
  );
  const orderId = useSelector((state: any) => state.checkoutSession.orderId);
  const orderNo = useSelector((state: any) => state.checkoutSession.orderNo);

  useEffect(() => {
    const agent = checkUserAgentIOS()
    console.log("agent",agent)
    if (agent == "ios") {
       window.location.href = "app://localhost/index.html#/orderComplete" + window.location.search
    }else if (agent == "android"){
      window.location.href = "https://app.catholic.org.hk/#/orderComplete" + window.location.search
    } else {
      const isLogined = checkLogin();
      if (!isLogined) {
        navigate("/");
        return;
      }

      //member/{orderNo}
      if (resultIndicator !== null) {
        console.log("redirect 1")
        if (resultIndicator === successIndicator) {
          console.log("redirect 2")

          authFetch(
            `${process.env.REACT_APP_BACKEND}order/status/update?orderNo=${orderNo}`
          )
            .then((res) => {
              if (res) {
                console.log("redirect 2-1")
                RefreshShoppingCartItem();
              } else {
                console.log("redirect 2-2")
                RefreshShoppingCartItem();
                navigate("/transaction/failed");
              }
            })
            .then(() => navigate("/orderComplete?order=" + orderNo));
        } else {
          navigate("/transaction/failed");
        }
        // orderNo
        // result of resultIndicator === successIndicator

        // header user -> ?
        // if admin -> pass
        // is member -> check order owner ?
      }

      if (resultOrderNo !== null) {
        console.log("redirect 3")
        authFetch(
          `${process.env.REACT_APP_BACKEND}order/member2/${resultOrderNo}`
        ).then((res) => {
          console.log("res", res);
          if (res.orderStatus == "paid") {
            console.log("redirect 4")

            listMybookShelf(store.getState().MemberReducer.member_id);
            RefreshShoppingCartItem();
          } else {
            console.log("redirect 5")

            navigate("/transaction/failed");
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    timeout();
    if (second < 1) {
      navigate("/bookshelf");
      return;
    }
  }, [second]);

  const timeout = () => {
    // setTimeout(() => {
    //   setSecond((preState) => second - 1);
    // }, 1000);
  };
  return (
    <div className={style.shopingCartLayout}>
      <h1>交易成功</h1>

      <div className={style.formContainer}>
        <h3 className={style.orderNo}>
          參考編號：{resultOrderNo ? resultOrderNo : orderNo}
        </h3>
        <p>閣下已成功付款</p>
        <span>{second}秒後自動跳回我的書架</span>
      </div>
      <div className={style.buttonContainer}>
        <Button
          styleType={"bookIntroButton"}
          children={"前往我的書架"}
          callBack={() => navigate("/bookshelf")}
        />
      </div>
      <div className={style.buttonContainer1}>
        <Button
          styleType={"bookIntroButton"}
          children={"回到主頁"}
          callBack={() => navigate("/")}
        />
      </div>
    </div>
  );
}
