import React from "react";
import { connect } from "react-redux";
import { speedList } from "../../../constants/reader/dropdownList";
import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
import { Trans } from "react-i18next";
import minus from "../../../assets/reader/minus_btn.svg";
import plus from "../../../assets/reader/plus_btn.svg";
class TextToSpeech extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSupported: false,
      isAudioOn: false,
      voices: [],
      langTxt: "粵語",
      currentSpeed: 1,
      isChangeMenu: false,
      defaultSpeedIndex: 6,
    };
  }
  componentDidMount() {
    if ("speechSynthesis" in window) {
      this.setState({ isSupported: true });
    }
    if (this.state.isAudioOn) {
      if (window.speechSynthesis && window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
      this.setState({ isAudioOn: false });
    }
  }
  handleChangeAudio = () => {
    if (this.state.isAudioOn) {
      if (window.speechSynthesis && window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
      this.setState({ isAudioOn: false });
    } else {
      const setSpeech = () => {
        return new Promise((resolve, reject) => {
          let synth = window.speechSynthesis;
          let id;

          id = setInterval(() => {
            try {
              if (synth.getVoices().length !== 0) {
                resolve(synth.getVoices());
                clearInterval(id);
              } else {
                this.setState({ isSupported: false });
              }
            } catch (e) {
              console.log("getVoices", e);
            }
          }, 10);
        });
      };

      let s = setSpeech();
      s.then((voices) => {
        this.setState({ voices }, () => {
          this.setState({ isAudioOn: true }, () => {
            this.handleAudio();
            if (
              document.querySelector("#text-speech-speed") &&
              document.querySelector("#text-speech-voice") &&
              document.querySelector("#text-speech-speed").children[0] &&
              document.querySelector("#text-speech-voice").children[0]
            ) {
              document
                .querySelector("#text-speech-speed")
                .children[
                  speedList.option.indexOf(
                    StorageUtil.getReaderConfig("voiceSpeed") || "1"
                  )
                ]?.setAttribute("selected", "selected");
              document
                .querySelector("#text-speech-voice")
                .children[
                  StorageUtil.getReaderConfig("voiceIndex") || 0
                ]?.setAttribute("selected", "selected");
            }
          });
        });
      });
    }
  };

  handleAudio = async () => {
    let text = "";
    if (this.props.currentBook.format === "EPUB") {
      const currentLocation =
        this.props.currentEpub.rendition.currentLocation();
      const cfibase = currentLocation.start.cfi
        .replace(/!.*/, "")
        .replace("epubcfi(", "");
      const cfistart = currentLocation.start.cfi
        .replace(/.*!/, "")
        .replace(/\)/, "");
      const cfiend = currentLocation.end.cfi
        .replace(/.*!/, "")
        .replace(/\)/, "");
      const cfiRange = `epubcfi(${cfibase}!,${cfistart},${cfiend})`;
      let range = await this.props.currentEpub.getRange(cfiRange);
      text = range.toString();
    } else {
      text = this.props.htmlBook.rendition.visibleText();
    }

    text = text
      .replace(/\s\s/g, "")
      .replace(/\r/g, "")
      .replace(/\n/g, "")
      .replace(/\t/g, "")
      .replace(/\f/g, "");
    this.handleSpeech(
      text,
      StorageUtil.getReaderConfig("voiceIndex") || 0,
      StorageUtil.getReaderConfig("voiceSpeed") || 1
    );
  };

  handleSpeech = (text, voiceIndex, speed) => {
    console.log("this.state.voices", this.state.voices);
    if (voiceIndex === undefined) {
      voiceIndex = 1;
    }

    console.log("handleSpeech", {
      text: text,
      voiceIndex: voiceIndex,
      speed: speed,
    });
    // stop
    if (window.speechSynthesis && window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }
    // stop end

    var msg = new SpeechSynthesisUtterance();
    msg.text = text;
    switch (this.state.langTxt) {
      case "粵語":
        msg.lang = "zh-HK";
        break;
      case "普通話":
        msg.lang = "zh-TW";
        break;
      case "英語":
        msg.lang = "en-US";
        break;
    }

    msg.voice =
      window.speechSynthesis.getVoices()[
        window.speechSynthesis.getVoices().findIndex((it) => {
          console.log(it.lang, msg.lang, it.lang === msg.lang);
          return it.lang === msg.lang;
        })
      ];
    msg.rate = speed;
    window.speechSynthesis.speak(msg);

    msg.onerror = (err) => {
      console.log(err);
    };

    msg.onend = (event) => {
      if (!(this.state.isAudioOn && this.props.isReading)) {
        return;
      }

      if (!this.state.isChangeMenu) {
        if (this.props.currentBook.format === "EPUB") {
          this.props.currentEpub.rendition.next().then(() => {
            this.handleAudio();
          });
        } else {
          this.props.htmlBook.rendition.next();
          this.handleAudio();
        }
      }

      if (this.state.isChangeMenu) {
        this.setState({ ...this.state, isChangeMenu: false });
      }
    };
  };

  handleVoiceLang = (_langTxt, index) => {
    let langTxt = "";

    if (index === 1) {
      switch (_langTxt) {
        case "zh-HK":
          this.langTxt = "粵語";
          break;
        case "zh-TW":
          this.langTxt = "普通話";
          break;
        case "en-US":
          this.langTxt = "英語";
          break;
      }
    }
    switch (_langTxt) {
      case "zh-HK":
        langTxt = "粵語";
        break;
      case "zh-TW":
        langTxt = "普通話";
        break;
      case "en-US":
        langTxt = "英語"; //"美式英語";
        break;
    }

    return langTxt;
  };

  handleChangeVoiceLang = (event) => {
    StorageUtil.setReaderConfig("voiceIndex", event.target.value);
    if (window.speechSynthesis && window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }
    const selectOption = document.getElementById("text-speech-voice");
    const text = selectOption.options[selectOption.selectedIndex].text;
    this.setState({ ...this.state, langTxt: text, isChangeMenu: true });
    this.handleAudio();
  };

  handleChangeVoiceSpeed = (operator) => {
    if (this.state.defaultSpeedIndex <= 0 && operator == "-") {
      return;
    }
    if (this.state.defaultSpeedIndex == 13 && operator == "+") return;
    console.log("this.state.defaultSpeedIndex", this.state);

    if (operator == "-") {
      this.currentSpeed = this.state.defaultSpeedIndex - 1;
      console.log("this.currentSpeed ", this.currentSpeed);
    } else {
      console.log("this.currentSpeed ", this.currentSpeed);

      this.currentSpeed = this.state.defaultSpeedIndex + 1;
    }

    StorageUtil.setReaderConfig(
      "voiceSpeed",
      speedList.option[this.currentSpeed]
    );
    if (window.speechSynthesis && window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }
    this.setState({
      ...this.state,
      currentSpeed: speedList.option[this.currentSpeed],
      defaultSpeedIndex: this.currentSpeed,
    });
    this.handleAudio();
  };

  render() {
    return (
      <>
        {this.state.isSupported ? (
          <>
            <div className="single-control-switch-container">
              <span className="single-control-switch-title">
                <Trans>Turn on text-to-speech</Trans>
              </span>

              <span
                className={[
                  "single-control-switch",
                  this.state.isAudioOn ? "" : "off",
                ].join(" ")}
                onClick={() => {
                  this.handleChangeAudio();
                }}
                style={this.state.isAudioOn ? {} : { opacity: 0.6 }}
              >
                <span
                  className="single-control-button"
                  style={
                    this.state.isAudioOn
                      ? {
                          transform: "translateX(20px)",
                          transition: "transform 0.5s ease",
                        }
                      : {
                          transform: "translateX(0px)",
                          transition: "transform 0.5s ease",
                        }
                  }
                ></span>
              </span>
            </div>
            {this.state.voices.length > 0 && (
              <div
                className="setting-dialog-new-title"
                style={
                  this.state.isAudioOn
                    ? {
                        marginLeft: "20px",
                        width: "88%",
                        marginTop: "20px",
                        marginBottom: "5px",
                        fontWeight: 500,
                      }
                    : { display: "none" }
                }
              >
                Voice
                <select
                  name=""
                  className="lang-setting-dropdown"
                  id="text-speech-voice"
                  onChange={(event) => {
                    this.handleChangeVoiceLang(event);
                  }}
                >
                  {this.state.voices
                    .filter(
                      (v, i, a) =>
                        a?.findIndex((v2) => v2.lang === v.lang) === i
                    )
                    .filter((it) => {
                      if (
                        it.lang.toLowerCase().includes("en-us") ||
                        it.lang.toLowerCase().includes("hk") ||
                        it.lang.toLowerCase().includes("tw")
                      ) {
                        return it;
                      }
                    })
                    .map((item, index) => {
                      return this.handleVoiceLang(item.lang) !== "" ? (
                        <>
                          <option value={index + 1}>
                            {this.handleVoiceLang(item.lang, index + 1)}
                          </option>
                        </>
                      ) : (
                        <></>
                      );
                    })}
                </select>
              </div>
            )}
            {this.state.isAudioOn && (
              <div
                className="setting-dialog-new-title"
                style={{ marginLeft: "20px", width: "88%", fontWeight: 500 }}
              >
                Speed
                {/* <select
                  name=""
                  id="text-speech-speed"
                  className="lang-setting-dropdown"
                  onChange={(event) => {
                    this.handleChangeVoiceSpeed(event);

                    // StorageUtil.setReaderConfig(
                    //   "voiceSpeed",
                    //   event.target.value
                    // );
                    // window.speechSynthesis.cancel();
                  }}
                >
                  {speedList.option.map((item) => (
                    <option value={item} className="lang-setting-option">
                      {item}
                    </option>
                  ))}
                </select> */}
                <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "20px",
                    gap: "5px",
                  }}
                >
                  <img
                    src={minus}
                    onClick={() => this.handleChangeVoiceSpeed("-")}
                  ></img>
                  <div
                    style={{
                      minWidth: "30px",
                      width: "30px",
                      textAlign: "center",
                    }}
                  >
                    {speedList.option[this.state.defaultSpeedIndex]}
                  </div>
                  <img
                    src={plus}
                    onClick={() => this.handleChangeVoiceSpeed("+")}
                  ></img>
                </div>
              </div>
            )}
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    htmlBook: state.reader.htmlBook,
    locations: state.progressPanel.locations,
    isReading: state.book.isReading,
  };
};
const actionCreator = {};
export default connect(mapStateToProps, actionCreator)(TextToSpeech);
