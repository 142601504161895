/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface GetBirthdayDiscountBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetBusinessReportUsingGETRequest {
    name?: string;
}

export interface GetCashCouponUsingGETRequest {
    name?: string;
}

export interface GetCodeCouponBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetConsumptionExcelBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetInventoryBalanceExcelFileUsingGETRequest {
    name?: string;
}

export interface GetMarcExcelFileUsingGETRequest {
    name?: string;
}

export interface GetMemberFileUsingGETRequest {
    name?: string;
}

export interface GetMemberPointExcelUsingGETRequest {
    name?: string;
}

export interface GetMemberUpgradeFileUsingGETRequest {
    name?: string;
}

export interface GetNewMemberExcelFileUsingGETRequest {
    name?: string;
}

export interface GetPointGiftExcelBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetPointToCashUsingGETRequest {
    name?: string;
}

export interface GetPointToGiftBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetProductInventoryExcelFileBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetProductSalesBetweenDate2UsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface GetProductSalesUsingGETRequest {
    name?: string;
}

/**
 * 
 */
export class ExcelControllerApi extends runtime.BaseAPI {

    /**
     * getBirthdayDiscountBetweenDate
     */
    async getBirthdayDiscountBetweenDateUsingGETRaw(requestParameters: GetBirthdayDiscountBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getBirthdayDiscountBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getBirthdayDiscountBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadBirthdayDiscountExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getBirthdayDiscountBetweenDate
     */
    async getBirthdayDiscountBetweenDateUsingGET(requestParameters: GetBirthdayDiscountBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getBirthdayDiscountBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBusinessReport
     */
    async getBusinessReportUsingGETRaw(requestParameters: GetBusinessReportUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadBusinessExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getBusinessReport
     */
    async getBusinessReportUsingGET(requestParameters: GetBusinessReportUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getBusinessReportUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCashCoupon
     */
    async getCashCouponUsingGETRaw(requestParameters: GetCashCouponUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadCashCouponExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getCashCoupon
     */
    async getCashCouponUsingGET(requestParameters: GetCashCouponUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getCashCouponUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCodeCouponBetweenDate
     */
    async getCodeCouponBetweenDateUsingGETRaw(requestParameters: GetCodeCouponBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getCodeCouponBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getCodeCouponBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadCodeCouponExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getCodeCouponBetweenDate
     */
    async getCodeCouponBetweenDateUsingGET(requestParameters: GetCodeCouponBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getCodeCouponBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getConsumptionExcelBetweenDate
     */
    async getConsumptionExcelBetweenDateUsingGETRaw(requestParameters: GetConsumptionExcelBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getConsumptionExcelBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getConsumptionExcelBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadConsumptionExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getConsumptionExcelBetweenDate
     */
    async getConsumptionExcelBetweenDateUsingGET(requestParameters: GetConsumptionExcelBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getConsumptionExcelBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInventoryBalanceExcelFile
     */
    async getInventoryBalanceExcelFileUsingGETRaw(requestParameters: GetInventoryBalanceExcelFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadInventoryBalanceExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getInventoryBalanceExcelFile
     */
    async getInventoryBalanceExcelFileUsingGET(requestParameters: GetInventoryBalanceExcelFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getInventoryBalanceExcelFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMarcExcelFile
     */
    async getMarcExcelFileUsingGETRaw(requestParameters: GetMarcExcelFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadMarcExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMarcExcelFile
     */
    async getMarcExcelFileUsingGET(requestParameters: GetMarcExcelFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMarcExcelFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberFile
     */
    async getMemberFileUsingGETRaw(requestParameters: GetMemberFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadMemberExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMemberFile
     */
    async getMemberFileUsingGET(requestParameters: GetMemberFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMemberFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberPointExcel
     */
    async getMemberPointExcelUsingGETRaw(requestParameters: GetMemberPointExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadMemberPointsExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMemberPointExcel
     */
    async getMemberPointExcelUsingGET(requestParameters: GetMemberPointExcelUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMemberPointExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberUpgradeFile
     */
    async getMemberUpgradeFileUsingGETRaw(requestParameters: GetMemberUpgradeFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadMemberUpgradeExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getMemberUpgradeFile
     */
    async getMemberUpgradeFileUsingGET(requestParameters: GetMemberUpgradeFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getMemberUpgradeFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getNewMemberExcelFile
     */
    async getNewMemberExcelFileUsingGETRaw(requestParameters: GetNewMemberExcelFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadNewMemberExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getNewMemberExcelFile
     */
    async getNewMemberExcelFileUsingGET(requestParameters: GetNewMemberExcelFileUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getNewMemberExcelFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPointGiftExcelBetweenDate
     */
    async getPointGiftExcelBetweenDateUsingGETRaw(requestParameters: GetPointGiftExcelBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getPointGiftExcelBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getPointGiftExcelBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadPointGiftExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getPointGiftExcelBetweenDate
     */
    async getPointGiftExcelBetweenDateUsingGET(requestParameters: GetPointGiftExcelBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getPointGiftExcelBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPointToCash
     */
    async getPointToCashUsingGETRaw(requestParameters: GetPointToCashUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadPointToCashExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getPointToCash
     */
    async getPointToCashUsingGET(requestParameters: GetPointToCashUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getPointToCashUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPointToGiftBetweenDate
     */
    async getPointToGiftBetweenDateUsingGETRaw(requestParameters: GetPointToGiftBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getPointToGiftBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getPointToGiftBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadPointToGiftExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getPointToGiftBetweenDate
     */
    async getPointToGiftBetweenDateUsingGET(requestParameters: GetPointToGiftBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getPointToGiftBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductInventoryExcelFileBetweenDate
     */
    async getProductInventoryExcelFileBetweenDateUsingGETRaw(requestParameters: GetProductInventoryExcelFileBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getProductInventoryExcelFileBetweenDateUsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getProductInventoryExcelFileBetweenDateUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadProductInventoryExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getProductInventoryExcelFileBetweenDate
     */
    async getProductInventoryExcelFileBetweenDateUsingGET(requestParameters: GetProductInventoryExcelFileBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getProductInventoryExcelFileBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductSalesBetweenDate2
     */
    async getProductSalesBetweenDate2UsingGETRaw(requestParameters: GetProductSalesBetweenDate2UsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getProductSalesBetweenDate2UsingGET.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getProductSalesBetweenDate2UsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadProductSalesExcel2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getProductSalesBetweenDate2
     */
    async getProductSalesBetweenDate2UsingGET(requestParameters: GetProductSalesBetweenDate2UsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getProductSalesBetweenDate2UsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductSales
     */
    async getProductSalesUsingGETRaw(requestParameters: GetProductSalesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/excel/downloadProductSalesExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getProductSales
     */
    async getProductSalesUsingGET(requestParameters: GetProductSalesUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Resource> {
        const response = await this.getProductSalesUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
