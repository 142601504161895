import React, { Component } from "react";
import {
  STYLETYPE_FOUR,
  STYLETYPE_IMG,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_THIRD,
} from "../../utils/Constant";
import style from "./Button.module.css";
import { withTranslation } from "react-i18next";

interface IProps {
  id?: string;
  styleType?: string;
  disabled?: boolean;
  hidden?: boolean;
  buttonType?: "button" | "reset" | "submit";
  customStyle?: object;
  callBack: (value: boolean) => void;
  t: any;
  children: any;
}

class Button extends Component<IProps> {
  handleClick = () => {
    this.props.callBack(true);
  };
  render() {
    //   console.log( this.props.children );
    return (
      <button
        id={this.props.id}
        className={
          this.props.styleType === "style1"
            ? style.btnPrimary
            : this.props.styleType === STYLETYPE_MAIN
            ? style.MainButton
            : this.props.styleType === STYLETYPE_SECOND
            ? style.SecondButton
            : this.props.styleType === STYLETYPE_THIRD
            ? style.ThirdButton
            : this.props.styleType === STYLETYPE_FOUR
            ? style.ThirdButton
            : this.props.styleType === STYLETYPE_IMG
            ? style.btnImg
            : this.props.styleType === "searchButtom"
            ? style.searchButtom
            : this.props.styleType === "upperNavbarButton"
            ? style.upperNavbarButton
            : this.props.styleType === "lowerNavbarButton"
            ? style.lowerNavbarButton
            : this.props.styleType === "upperSideNavButton"
            ? style.upperSideNavButton
            : this.props.styleType === "lowerSideNavButton"
            ? style.lowerSideNavButton
            : this.props.styleType === "buttonGroupButton"
            ? style.buttonGroupButton
            : this.props.styleType === "cardButton"
            ? style.cardButton
            : this.props.styleType === "toggleButton"
            ? style.toggleButton
            : this.props.styleType === "toggleButtonCurrent"
            ? style.toggleButtonCurrent
            : this.props.styleType === "loginbutton"
            ? style.loginbutton
            : this.props.styleType === "loginButton"
            ? style.loginButton
            : this.props.styleType === "forgetButton"
            ? style.forgetButton
            : this.props.styleType === "googleButton"
            ? style.googleButton
            : this.props.styleType === "facebookButton"
            ? style.facebookButton
            : this.props.styleType === "tabButton"
            ? style.tabButton
            : this.props.styleType === "tabButtonCurrent"
            ? style.tabButtonCurrent
            : this.props.styleType === "verifyButton"
            ? style.verifyButton
            : this.props.styleType === "bookIntroButton"
            ? style.bookIntroButton
            : this.props.styleType === "submitButton"
            ? style.submitButton
            : this.props.styleType === "cancelButton"
            ? style.cancelButton
            : ""
        }
        disabled={this.props.disabled}
        type={this.props.buttonType}
        hidden={this.props.hidden}
        onClick={this.handleClick}
      >
        {this.props.children === "確認訂單"
          ? this.props.t("checkout.orderConfirmation")
          : this.props.children === "付款"
          ? this.props.t("checkout.payment")
          : this.props.children === "返回"
          ? this.props.t("back")
          : this.props.children}
      </button>
    );
  }
}

export default withTranslation()(Button);
