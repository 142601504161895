/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoToJSON,
    UserPayloadModel,
    UserPayloadModelFromJSON,
    UserPayloadModelToJSON,
} from '../models';

export interface AddNewMemberUsingPOSTRequest {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    password: string;
    username: string;
    role: string;
}

export interface DeleteMemberUsingDELETERequest {
    memberId: number;
    isAdmin: boolean;
}

export interface GetVerificationCodeUsingPOSTRequest {
    locationId: number;
    phone: string;
}

export interface InsertNewMemberUsingPOSTRequest {
    userPayloadModel?: UserPayloadModel;
}

export interface UpdateMemberUsingPATCHRequest {
    memberId: number;
    userPayloadModel?: UserPayloadModel;
}

/**
 * 
 */
export class LoginControllerApi extends runtime.BaseAPI {

    /**
     * addNewMember
     */
    async addNewMemberUsingPOSTRaw(requestParameters: AddNewMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.firstName === null || requestParameters.firstName === undefined) {
            throw new runtime.RequiredError('firstName','Required parameter requestParameters.firstName was null or undefined when calling addNewMemberUsingPOST.');
        }

        if (requestParameters.lastName === null || requestParameters.lastName === undefined) {
            throw new runtime.RequiredError('lastName','Required parameter requestParameters.lastName was null or undefined when calling addNewMemberUsingPOST.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling addNewMemberUsingPOST.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling addNewMemberUsingPOST.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling addNewMemberUsingPOST.');
        }

        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling addNewMemberUsingPOST.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling addNewMemberUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstName !== undefined) {
            queryParameters['first_name'] = requestParameters.firstName;
        }

        if (requestParameters.lastName !== undefined) {
            queryParameters['last_name'] = requestParameters.lastName;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        if (requestParameters.role !== undefined) {
            queryParameters['role'] = requestParameters.role;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/login/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * addNewMember
     */
    async addNewMemberUsingPOST(requestParameters: AddNewMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.addNewMemberUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkLogin
     */
    async checkLoginUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/login/checkLogin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * checkLogin
     */
    async checkLoginUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.checkLoginUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * clearCookie
     */
    async clearCookieUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/login/cookie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * clearCookie
     */
    async clearCookieUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.clearCookieUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * deleteMember
     */
    async deleteMemberUsingDELETERaw(requestParameters: DeleteMemberUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling deleteMemberUsingDELETE.');
        }

        if (requestParameters.isAdmin === null || requestParameters.isAdmin === undefined) {
            throw new runtime.RequiredError('isAdmin','Required parameter requestParameters.isAdmin was null or undefined when calling deleteMemberUsingDELETE.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.isAdmin !== undefined) {
            queryParameters['isAdmin'] = requestParameters.isAdmin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/login/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * deleteMember
     */
    async deleteMemberUsingDELETE(requestParameters: DeleteMemberUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.deleteMemberUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMember
     */
    async getAllMemberUsingPATCHRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/login`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * getAllMember
     */
    async getAllMemberUsingPATCH(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MemberDto>> {
        const response = await this.getAllMemberUsingPATCHRaw(initOverrides);
        return await response.value();
    }

    /**
     * getVerificationCode
     */
    async getVerificationCodeUsingPOSTRaw(requestParameters: GetVerificationCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling getVerificationCodeUsingPOST.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling getVerificationCodeUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.locationId !== undefined) {
            queryParameters['locationId'] = requestParameters.locationId;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/login/phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * getVerificationCode
     */
    async getVerificationCodeUsingPOST(requestParameters: GetVerificationCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.getVerificationCodeUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * insertNewMember
     */
    async insertNewMemberUsingPOSTRaw(requestParameters: InsertNewMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/login/insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPayloadModelToJSON(requestParameters.userPayloadModel),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * insertNewMember
     */
    async insertNewMemberUsingPOST(requestParameters: InsertNewMemberUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.insertNewMemberUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateMember
     */
    async updateMemberUsingPATCHRaw(requestParameters: UpdateMemberUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling updateMemberUsingPATCH.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/login/update`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserPayloadModelToJSON(requestParameters.userPayloadModel),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * updateMember
     */
    async updateMemberUsingPATCH(requestParameters: UpdateMemberUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.updateMemberUsingPATCHRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
