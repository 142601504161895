import {
    SET_MEMBER_INFORMATION,
    DELETE_MEMBER_INFORMATION,
    SET_TOKEN,
    DELETE_TOKEN
} from "../../utils/Constant";

export function setMemberInformation(payload){
    return {type:SET_MEMBER_INFORMATION, payload}
}

export function deleteMemberInformation(payload){
    return {type:DELETE_MEMBER_INFORMATION, payload}
}

export function setToken(payload){
    return {type:SET_TOKEN, payload}
}

export function deleteToken(payload){
    return {type:DELETE_TOKEN, payload}
}
