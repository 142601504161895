import React, { useEffect, useRef, useState } from "react";
import { Path, useForm, UseFormRegister, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { authFetch } from "../../redux/services/AuthenticationService";
import { useSelector } from "react-redux";
import style from "../Favourite/Favourite.module.css";
import OrderProduct from "../../components/OrderProduct/OrderProduct";
import OrderHistory from "../../components/OrderHistory/OrderHistory";
import UpperSideNav from "../SideNav/UpperSideNav";
import LowerSideNav from "../SideNav/LowerSideNav";
import * as CategoryAPI from "../../api/CategoryAPI";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/useWindowSize";

const month = () => {
  let monthArray = [];
  for (let i = 1; i <= 12; i++) {
    monthArray.push({ value: `${i}`, display: `${i}月` });
  }
  return monthArray;
};

export interface IMember {
  member_id: string;
  member_first_name: string;
  member_level: string;
  token: string;
  exp: number;
}

interface IFormValues {
  firstName: string;
  gender: string;
  code: number;
  birthday: string;
  district: string;
  password: string;
  confirmPassword: string;
  email: string;
  phone: string;
}

type InputProps = {
  label: Path<IFormValues>;
  placeholder: string;
  register: UseFormRegister<IFormValues>;
  required: boolean;
  type?: string;
  pattern?: any;
  value?: string;
  ref?: any;
  disabled?: boolean;
};

const formSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("必須輸入密碼")
      .min(8, "密碼長度至少8位")
      .matches(RegExp("(.*[a-zA-Z].*)"), "必須包含字母和數字")
      .matches(RegExp("(.*\\d.*)"), "必須包含字母和數字"),
    confirmPassword: yup
      .string()
      .required("必須輸入密碼")
      .min(8, "密碼長度至少8位")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();

export default function UserInformation() {
  const [orderHistory, setOrderHistory] = useState([]);
  const userInfoRef = useRef<any>(null);
  const member = useSelector((state: any) => state.MemberReducer);
  const [coupon, setCoupon] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setFocus,
    setValue,
  } = useForm<IFormValues>({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    console.log("data", data);
    console.log("member", member);
    //alert(JSON.stringify(data));
    handleUpdate(data);
  };

  function handleUpdate(data: any) {
    let formBody = new FormData();
    for (var key in data) {
      formBody.append(key, data[key]);
    }
    formBody.append("memberId", member.member_id);
    authFetch(
      process.env.REACT_APP_BACKEND + "member/update",
      formBody,
      "post"
    ).then((res) => {
      alert("成功更新");
    });
  }

  const countryCode = [
    { value: "", display: t("signUp.areaCode") },
    { value: "+86", display: t("country.CN") },
    { value: "+852", display: t("country.HK") },
    { value: "+886", display: t("country.TW") },
    { value: "+853", display: t("country.MO") },
    { value: "+65", display: t("country.SG") },
    { value: "+81", display: t("country.JP") },
    { value: "+66", display: t("country.TH") },
    { value: "+60", display: t("country.MY") },
    { value: "+63", display: t("country.PH") },
    { value: "+61", display: t("country.AU") },
    { value: "+61-8", display: t("country.CX") },
    { value: "+61-891", display: t("country.CC") },
    { value: "+1", display: t("country.CA") },
    { value: "+44", display: t("country.GB") },
    { value: "+1", display: t("country.US") },
    { value: "+244", display: t("country.AO") },
    { value: "+93", display: t("country.AF") },
    { value: "+355", display: t("country.AL") },
    { value: "+213", display: t("country.DZ") },
    { value: "+376", display: t("country.AD") },
    { value: "+1-264", display: t("country.AI") },
    { value: "+1-268", display: t("country.AG") },
    { value: "+54", display: t("country.AR") },
    { value: "+374", display: t("country.AM") },
    { value: "+247", display: t("country.AC") },
    { value: "+43", display: t("country.AT") },
    { value: "+994", display: t("country.AZ") },
    { value: "+1-242", display: t("country.BS") },
    { value: "+973", display: t("country.BH") },
    { value: "+880", display: t("country.BD") },
    { value: "+1-246", display: t("country.BB") },
    { value: "+375", display: t("country.BY") },
    { value: "+7", display: t("country.RU") },
    { value: "+501", display: t("country.BZ") },
    { value: "+229", display: t("country.BJ") },
    { value: "+1-441", display: t("country.BM") },
    { value: "+591", display: t("country.BO") },
    { value: "+267", display: t("country.BW") },
    { value: "+55", display: t("country.BR") },
    { value: "+673", display: t("country.BN") },
    { value: "+359", display: t("country.BG") },
    { value: "+226", display: t("country.BF") },
    { value: "+95", display: t("country.MM") },
    { value: "+257", display: t("country.BI") },
    { value: "+237", display: t("country.CM") },
    { value: "+1-345", display: t("country.KY") },
    { value: "+236", display: t("country.CF") },
    { value: "+235", display: t("country.TD") },
    { value: "+56", display: t("country.CL") },
    { value: "+57", display: t("country.CO") },
    { value: "+242", display: t("country.CG") },
    { value: "+682", display: t("country.CK") },
    { value: "+506", display: t("country.CR") },
    { value: "+53", display: t("country.CU") },
    { value: "+357", display: t("country.CY") },
    { value: "+420", display: t("country.CZ") },
    { value: "+45", display: t("country.DK") },
    { value: "+253", display: t("country.DJ") },
    { value: "+1-809", display: t("country.DO") },
    { value: "+1-829", display: t("country.") },
    { value: "+1-849", display: t("country.") },
    { value: "+593", display: t("country.EC") },
    { value: "+20", display: t("country.EG") },
    { value: "+503", display: t("country.SV") },
    { value: "+372", display: t("country.EE") },
    { value: "+251", display: t("country.ET") },
    { value: "+679", display: t("country.FJ") },
    { value: "+358", display: t("country.FI") },
    { value: "+33", display: t("country.FR") },
    { value: "+594", display: t("country.GF") },
    { value: "+241", display: t("country.GA") },
    { value: "+220", display: t("country.GM") },
    { value: "+995", display: t("country.GE") },
    { value: "+49", display: t("country.DE") },
    { value: "+233", display: t("country.GH") },
    { value: "+350", display: t("country.GI") },
    { value: "+30", display: t("country.GR") },
    { value: "+1-473", display: t("country.GD") },
    { value: "+1-671", display: t("country.GU") },
    { value: "+502", display: t("country.GT") },
    { value: "+224", display: t("country.GN") },
    { value: "+592", display: t("country.GY") },
    { value: "+509", display: t("country.HT") },
    { value: "+504", display: t("country.HN") },
    { value: "+36", display: t("country.HU") },
    { value: "+354", display: t("country.IS") },
    { value: "+91", display: t("country.IN") },
    { value: "+62", display: t("country.ID") },
    { value: "+98", display: t("country.IR") },
    { value: "+964", display: t("country.IQ") },
    { value: "+353", display: t("country.IE") },
    { value: "+972", display: t("country.IL") },
    { value: "+39", display: t("country.IT") },
    { value: "+225", display: t("country.CI") },
    { value: "+1-876", display: t("country.JM") },
    { value: "+962", display: t("country.JO") },
    { value: "+855", display: t("country.KH") },
    { value: "+7", display: t("country.KZ") },
    { value: "+254", display: t("country.KE") },
    { value: "+82", display: t("country.KR") },
    { value: "+850", display: t("country.KP") },
    { value: "+47", display: t("country.NO") },
    { value: "+968", display: t("country.OM") },
    { value: "+92", display: t("country.PK") },
    { value: "+507", display: t("country.PA") },
    { value: "+675", display: t("country.PG") },
    { value: "+595", display: t("country.PY") },
    { value: "+51", display: t("country.PE") },
    { value: "+48", display: t("country.PL") },
    { value: "+689", display: t("country.PF") },
    { value: "+351", display: t("country.PT") },
    { value: "+1-787", display: t("country.PR") },
    { value: "+1-939", display: t("country.") },
    { value: "+974", display: t("country.QA") },
    { value: "+262", display: t("country.RE") },
    { value: "+40", display: t("country.RO") },
    { value: "+32", display: t("country.BE") },
    { value: "+965", display: t("country.KW") },
    { value: "+996", display: t("country.KG") },
    { value: "+856", display: t("country.LA") },
    { value: "+371", display: t("country.LV") },
    { value: "+961", display: t("country.LB") },
    { value: "+266", display: t("country.LS") },
    { value: "+231", display: t("country.LR") },
    { value: "+218", display: t("country.LY") },
    { value: "+423", display: t("country.LI") },
    { value: "+370", display: t("country.LT") },
    { value: "+352", display: t("country.LU") },
    { value: "+261", display: t("country.MG") },
    { value: "+265", display: t("country.MW") },
    { value: "+960", display: t("country.MV") },
    { value: "+223", display: t("country.ML") },
    { value: "+356", display: t("country.MT") },
    { value: "+1-670", display: t("country.MP") },
    { value: "+596", display: t("country.MQ") },
    { value: "+230", display: t("country.MU") },
    { value: "+52", display: t("country.MX") },
    { value: "+373", display: t("country.MD") },
    { value: "+377", display: t("country.MC") },
    { value: "+212", display: t("country.MA") },
    { value: "+976", display: t("country.MN") },
    { value: "+1-664", display: t("country.MS") },
    { value: "+258", display: t("country.MZ") },
    { value: "+264", display: t("country.NA") },
    { value: "+674", display: t("country.NR") },
    { value: "+977", display: t("country.NP") },
    { value: "+599", display: t("country.AN") },
    { value: "+31", display: t("country.NL") },
    { value: "+64", display: t("country.NZ") },
    { value: "+505", display: t("country.NI") },
    { value: "+227", display: t("country.NE") },
    { value: "+234", display: t("country.NG") },
    { value: "+1-758", display: t("country.LC") },
    { value: "+1-784", display: t("country.VC") },
    { value: "+1-684", display: t("country.AS") },
    { value: "+685", display: t("country.WS") },
    { value: "+378", display: t("country.SM") },
    { value: "+239", display: t("country.ST") },
    { value: "+966", display: t("country.SA") },
    { value: "+221", display: t("country.SN") },
    { value: "+248", display: t("country.SC") },
    { value: "+232", display: t("country.SL") },
    { value: "+421", display: t("country.SK") },
    { value: "+386", display: t("country.SI") },
    { value: "+677", display: t("country.SB") },
    { value: "+252", display: t("country.SO") },
    { value: "+27", display: t("country.ZA") },
    { value: "+34", display: t("country.ES") },
    { value: "+94", display: t("country.LK") },
    { value: "+249", display: t("country.SD") },
    { value: "+597", display: t("country.SR") },
    { value: "+268", display: t("country.SZ") },
    { value: "+46", display: t("country.SE") },
    { value: "+41", display: t("country.CH") },
    { value: "+963", display: t("country.SY") },
    { value: "+992", display: t("country.TJ") },
    { value: "+255", display: t("country.TZ") },
    { value: "+228", display: t("country.TG") },
    { value: "+676", display: t("country.TO") },
    { value: "+1-868", display: t("country.TT") },
    { value: "+216", display: t("country.TN") },
    { value: "+90", display: t("country.TR") },
    { value: "+993", display: t("country.TM") },
    { value: "+256", display: t("country.UG") },
    { value: "+380", display: t("country.UA") },
    { value: "+971", display: t("country.AE") },
    { value: "+598", display: t("country.UY") },
    { value: "+998", display: t("country.UZ") },
    { value: "+58", display: t("country.VE") },
    { value: "+84", display: t("country.VN") },
    { value: "+967", display: t("country.YE") },
    { value: "+38", display: t("country.YU") },
    { value: "+263", display: t("country.ZW") },
    { value: "+243", display: t("country.ZR") },
    { value: "+243", display: t("country.CD") },
    { value: "+260", display: t("country.ZM") },
    { value: "+44", display: t("country.JE") },
    { value: "+44-1481", display: t("country.GG") },
    { value: "+44-1624", display: t("country.IM") },
    { value: "+358", display: t("country.AX") },
    { value: "+47", display: t("country.SJ") },
    { value: "+262", display: t("country.YT") },
    { value: "+1-767", display: t("country.DM") },
    { value: "+382", display: t("country.ME") },
    { value: "+1-284", display: t("country.VG") },
    { value: "+1-721", display: t("country.AN / MF / SX") },
    { value: "+1-869", display: t("country.KN") },
    { value: "+1-649", display: t("country.TC") },
    { value: "+1-340", display: t("country.VI / US") },
    { value: "+211", display: t("country.SS") },
    { value: "+222", display: t("country.MR") },
    { value: "+238", display: t("country.CV") },
    { value: "+240", display: t("country.GQ") },
    { value: "+245", display: t("country.GW") },
    { value: "+269", display: t("country.KM") },
    { value: "+290", display: t("country.SH") },
    { value: "+291", display: t("country.ER") },
    { value: "+297", display: t("country.AW") },
    { value: "+298", display: t("country.FO") },
    { value: "+299", display: t("country.GL") },
    { value: "+385", display: t("country.HR") },
    { value: "+387", display: t("country.BA") },
    { value: "+389", display: t("country.MK") },
    { value: "+508", display: t("country.PM") },
    { value: "+590", display: t("country.FR") },
    { value: "+590", display: t("country.ST") },
    { value: "+590", display: t("country.BL / FR") },
    { value: "+599-3", display: t("country.AN / NL") },
    { value: "+599-4", display: t("country.AN / NL") },
    { value: "+599-7", display: t("country.AN / BQ / NL") },
    { value: "+599-9", display: t("country.CW / AN") },
    { value: "+670", display: t("country.TL") },
    { value: "+680", display: t("country.PW") },
    { value: "+681", display: t("country.WF") },
    { value: "+683", display: t("country.NU") },
    { value: "+686", display: t("country.KI") },
    { value: "+687", display: t("country.NC") },
    { value: "+688", display: t("country.TV") },
    { value: "+690", display: t("country.TK") },
    { value: "+691", display: t("country.FM") },
    { value: "+692", display: t("country.MH") },
    { value: "+975", display: t("country.BT") },
    { value: "+381", display: t("country.RS") },
    { value: "+678", display: t("country.VU") },
    { value: "+500", display: t("country.FK") },
    { value: "+250", display: t("country.RW") },
    { value: "+379", display: t("country.VA") },
  ];

  // React.useEffect(() => {

  //   const firstError = Object.keys(errors).reduce((field, a) => {
  //     return !!errors[field] ? field : a;
  //   }, null);

  //   if (firstError) {
  //     setFocus(firstError);
  //   }
  // }, [errors, setFocus]);
  // The following component is an example of your existing Input Component
  const Input = ({
    label,
    placeholder,
    type,
    pattern,
    register,
    required,
    value,
    disabled,
  }: InputProps) => (
    <>
      <input
        placeholder={placeholder}
        value={value}
        type={type}
        {...register(label)}
        disabled={disabled ? disabled : false}
      />
      {errors.email && label === "email" && (
        <span role="alert">{errors.email.message}</span>
      )}
      {errors.password && label === "password" && (
        <span role="alert">{errors.password.message}</span>
      )}
      {errors.confirmPassword && label === "confirmPassword" && (
        <span role="alert">{errors.confirmPassword.message}</span>
      )}
    </>
  );
  console.log("errors", errors);
  // you can use React.forwardRef to pass the ref too
  const Select = React.forwardRef<
    HTMLSelectElement,
    { value: any[] } & ReturnType<UseFormRegister<IFormValues>>
  >(({ onChange, onBlur, name, value, disabled }, ref) => (
    <>
      <select
        name={name}
        ref={ref}
        onChange={onChange}
        disabled={disabled ? disabled : false}
      >
        {value.map((item, idx) => (
          <option key={idx} value={item.value}>
            {item.display}
          </option>
        ))}
      </select>
    </>
  ));

  const userInformation = async () => {
    authFetch(process.env.REACT_APP_BACKEND + "member/", null, "get").then(
      (res) => {
        userInfoRef.current = res;
        setValue("firstName", res.firstname);
        setValue("email", res.email);
        setValue("phone", res.phone);
        setValue("gender", res.gender);
        setValue("birthday", res.birthdayMonth);
        setValue("district", res.district);
      }
    );
  };

  const fetchOrderHistory = () => {
    authFetch(process.env.REACT_APP_BACKEND + "order/member", null, "get").then(
      (response) => {
        //console.log("response", response);
        setOrderHistory(response);
      }
    );
  };

  const fetchAvailablecoupon = () => {
    authFetch(process.env.REACT_APP_BACKEND + "coupon/all", null, "get").then(
      (response) => {
        console.log("response", response);
        setCoupon(response);
      }
    );
  };

  useEffect(() => {
    userInformation();
    fetchOrderHistory();
    fetchAvailablecoupon();
    categoryList();
  }, []);
  const [displayCategory, setDisplayCategory] = useState([]);

  const categoryList = async () => {
    const categories = await CategoryAPI.getAll();
    setDisplayCategory(categories);
  };

  const { width } = useWindowSize();

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: "auto", maxWidth: "1440px" }}>
      {width > 1024 ?
        <div style={{ display: "flex", width: "250px", flexDirection: "column" }}>
          <UpperSideNav props={displayCategory} />
          <LowerSideNav props={displayCategory} />
        </div>
        :
        <></>
      }
      <div className={style.shopingCartLayout}>
        <section>
          <h1> 我的資料</h1>
          <div className={style.informationLayout}>
            <form
              className={style.formContainer}
              onSubmit={handleSubmit(onSubmit)}
            >
              <h4>個人資料</h4>
              <div className={style.formRow}>
                <Select
                  {...register("gender")}
                  value={[
                    { value: "", display: t("signUp.title") },
                    { value: "Mr", display: "先生" },
                    { value: "Miss", display: "小姐" },
                    { value: "None", display: "不適用" },
                  ]}
                />
                <Input
                  label="firstName"
                  placeholder="姓名"
                  register={register}
                  required
                />
              </div>

              <div className={style.formRow}>
                <Input
                  label="email"
                  placeholder="電子郵件"
                  register={register}
                  required
                  disabled
                />
              </div>

              <div className={style.formRow}>
                <Select {...register("code")} value={countryCode} />
                <Input
                  type="tel"
                  label="phone"
                  placeholder="電話"
                  register={register}
                  required
                />
              </div>
              <div className={style.formRow2}>
                <Select {...register("birthday")} value={month()} disabled />
                {/* <Select {...register("district")} value={district} /> */}
              </div>
              <div className={style.formRow}>
                <Input
                  type="password"
                  label="password"
                  placeholder="更改密碼"
                  register={register}
                  required
                />
              </div>

              <div className={style.formRow}>
                <Input
                  type="password"
                  label="confirmPassword"
                  placeholder="確定密碼"
                  register={register}
                  required
                />
              </div>
              <div className={style.submitButtonContainer}>
                <input
                  type="submit"
                  value="儲存設定"
                  className={style.submitButton}
                />
              </div>
            </form>
            <div className={style.couponContainer}>
              <h4>電子優惠券</h4>

              {coupon.map((c: any) => (
                <>
                  <div className={style.formRow3}>
                    <div>
                      <p style={{ color: "#009da5" }}>
                        {c.discount.discountTitle}
                      </p>
                      <span style={{ fontSize: "12px" }}>
                        編號： {c.couponCode}
                      </span>
                    </div>
                    <p style={{ fontSize: "12px" }}>
                      有效期至 {c.endDate.split("T")[0]}
                    </p>
                  </div>
                  <hr className={style.horizontalLine}></hr>
                </>
              ))}
              {/* <div className={style.formRow3}>
              <div>
                <p style={{ color: "#009da5" }}>生日月份 8 折優惠</p>
                <span style={{ fontSize: "12px" }}>編號： SWF200456289</span>
              </div>
              <p style={{ fontSize: "12px" }}>有效期至 2023年8月31日</p>
            </div>
            <hr className={style.horizontalLine}></hr> */}
            </div>
          </div>
        </section>

        <section>
          <h1>訂單紀錄</h1>
          <div className={style.orderHistoryHeader}>
            <div>
              <span>訂單編號</span>
            </div>
            {width > 1024 ?
              <>
                <div>
                  <span>訂單日期</span>
                </div>
                <div>
                  <span>訂單狀態</span>
                </div>
              </>
              : <></>}
            <div>
              <span>訂單總數</span>
            </div>
            <div></div>
          </div>
          <hr className={style.horizontalLine}></hr>
          {orderHistory.map((history: any) => (
            <OrderHistory history={history} h />
          ))}
        </section>
      </div>
    </div>
  );
}
