/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DiscountDto,
    DiscountDtoFromJSON,
    DiscountDtoToJSON,
    MemberLevel,
    MemberLevelFromJSON,
    MemberLevelToJSON,
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoToJSON,
} from '../models';

export interface AddDiscountUsingPOSTRequest {
    discountTitle: string;
    discountType: string;
    name?: string;
    condition?: string;
    actionType?: string;
    actionValue?: number;
    target?: string;
    limitToUse?: number;
    couponCode?: string;
    discountProductId?: number;
    body?: Date;
}

export interface ClearDiscountProductUsingGETRequest {
    discountId: number;
    name?: string;
}

export interface ClearMemberLevelDiscountUsingGETRequest {
    memberLevelId: number;
    name?: string;
}

export interface ClearProductDiscountUsingGETRequest {
    productId: number;
    name?: string;
}

export interface DeleteDiscountProductUsingGETRequest {
    discountId: number;
    productIds: number;
    name?: string;
}

export interface DeleteDiscountUsingPOSTRequest {
    discountId: number;
    name?: string;
}

export interface GetAllDiscountAdminUsingGETRequest {
    name?: string;
}

export interface GetAllDiscountUsingGETRequest {
    name?: string;
}

export interface GetAllPwpDiscountUsingGETRequest {
    name?: string;
}

export interface GetAllSpecificDiscountUsingGETRequest {
    name?: string;
}

export interface GetAvailableGwpUsingGETRequest {
    name?: string;
}

export interface GetAvailablePwpByMemberIdUsingGETRequest {
    name?: string;
}

export interface GetAvailablePwpTargetByMemberIdUsingGETRequest {
    name?: string;
}

export interface GetDiscountByDiscountIdAdminUsingGETRequest {
    discountId: number;
    name?: string;
}

export interface GetDiscountByDiscountIdUsingGETRequest {
    discountId: number;
}

export interface GetDiscountProductByDiscountIdUsingGETRequest {
    discountId: number;
}

export interface SetDiscountProductUsingGETRequest {
    discountId: number;
    productIds: number;
    name?: string;
}

export interface SetMemberLevelDiscountUsingGETRequest {
    memberLevelId: number;
    discountIds: number;
    name?: string;
}

export interface UpdateDiscountLimitToUseUsingGETRequest {
    discountId: number;
    limitToUse: number;
    name?: string;
}

export interface UpdateDiscountUsingPOSTRequest {
    discountId: number;
    name?: string;
    discountTitle?: string;
    discountType?: string;
    condition?: string;
    actionType?: string;
    actionValue?: number;
    target?: string;
    limitToUse?: number;
    couponCode?: string;
    discountProductId?: number;
    body?: Date;
}

/**
 * 
 */
export class DiscontControllerApi extends runtime.BaseAPI {

    /**
     * addDiscount
     */
    async addDiscountUsingPOSTRaw(requestParameters: AddDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountTitle === null || requestParameters.discountTitle === undefined) {
            throw new runtime.RequiredError('discountTitle','Required parameter requestParameters.discountTitle was null or undefined when calling addDiscountUsingPOST.');
        }

        if (requestParameters.discountType === null || requestParameters.discountType === undefined) {
            throw new runtime.RequiredError('discountType','Required parameter requestParameters.discountType was null or undefined when calling addDiscountUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.discountTitle !== undefined) {
            queryParameters['discountTitle'] = requestParameters.discountTitle;
        }

        if (requestParameters.discountType !== undefined) {
            queryParameters['discountType'] = requestParameters.discountType;
        }

        if (requestParameters.condition !== undefined) {
            queryParameters['condition'] = requestParameters.condition;
        }

        if (requestParameters.actionType !== undefined) {
            queryParameters['actionType'] = requestParameters.actionType;
        }

        if (requestParameters.actionValue !== undefined) {
            queryParameters['actionValue'] = requestParameters.actionValue;
        }

        if (requestParameters.target !== undefined) {
            queryParameters['target'] = requestParameters.target;
        }

        if (requestParameters.limitToUse !== undefined) {
            queryParameters['limitToUse'] = requestParameters.limitToUse;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.discountProductId !== undefined) {
            queryParameters['discountProductId'] = requestParameters.discountProductId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * addDiscount
     */
    async addDiscountUsingPOST(requestParameters: AddDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.addDiscountUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * clearDiscountProduct
     */
    async clearDiscountProductUsingGETRaw(requestParameters: ClearDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling clearDiscountProductUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}/products/clear`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * clearDiscountProduct
     */
    async clearDiscountProductUsingGET(requestParameters: ClearDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.clearDiscountProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * clearMemberLevelDiscount
     */
    async clearMemberLevelDiscountUsingGETRaw(requestParameters: ClearMemberLevelDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MemberLevel>> {
        if (requestParameters.memberLevelId === null || requestParameters.memberLevelId === undefined) {
            throw new runtime.RequiredError('memberLevelId','Required parameter requestParameters.memberLevelId was null or undefined when calling clearMemberLevelDiscountUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/clearMemberLevelDiscount/{memberLevelId}`.replace(`{${"memberLevelId"}}`, encodeURIComponent(String(requestParameters.memberLevelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberLevelFromJSON(jsonValue));
    }

    /**
     * clearMemberLevelDiscount
     */
    async clearMemberLevelDiscountUsingGET(requestParameters: ClearMemberLevelDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MemberLevel> {
        const response = await this.clearMemberLevelDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * clearProductDiscount
     */
    async clearProductDiscountUsingGETRaw(requestParameters: ClearProductDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling clearProductDiscountUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/product/{productId}/clear`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * clearProductDiscount
     */
    async clearProductDiscountUsingGET(requestParameters: ClearProductDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.clearProductDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteDiscountProduct
     */
    async deleteDiscountProductUsingGETRaw(requestParameters: DeleteDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling deleteDiscountProductUsingGET.');
        }

        if (requestParameters.productIds === null || requestParameters.productIds === undefined) {
            throw new runtime.RequiredError('productIds','Required parameter requestParameters.productIds was null or undefined when calling deleteDiscountProductUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productIds !== undefined) {
            queryParameters['productIds'] = requestParameters.productIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}/products/delete`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * deleteDiscountProduct
     */
    async deleteDiscountProductUsingGET(requestParameters: DeleteDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.deleteDiscountProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteDiscount
     */
    async deleteDiscountUsingPOSTRaw(requestParameters: DeleteDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling deleteDiscountUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/delete/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * deleteDiscount
     */
    async deleteDiscountUsingPOST(requestParameters: DeleteDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.deleteDiscountUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllDiscountAdmin
     */
    async getAllDiscountAdminUsingGETRaw(requestParameters: GetAllDiscountAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAllDiscountAdmin
     */
    async getAllDiscountAdminUsingGET(requestParameters: GetAllDiscountAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAllDiscountAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllDiscount
     */
    async getAllDiscountUsingGETRaw(requestParameters: GetAllDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAllDiscount
     */
    async getAllDiscountUsingGET(requestParameters: GetAllDiscountUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAllDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllPwpDiscount
     */
    async getAllPwpDiscountUsingGETRaw(requestParameters: GetAllPwpDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/pwp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAllPwpDiscount
     */
    async getAllPwpDiscountUsingGET(requestParameters: GetAllPwpDiscountUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAllPwpDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllSpecificDiscount
     */
    async getAllSpecificDiscountUsingGETRaw(requestParameters: GetAllSpecificDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/allSpecific`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAllSpecificDiscount
     */
    async getAllSpecificDiscountUsingGET(requestParameters: GetAllSpecificDiscountUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAllSpecificDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAvailableGwp
     */
    async getAvailableGwpUsingGETRaw(requestParameters: GetAvailableGwpUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/available_gwp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAvailableGwp
     */
    async getAvailableGwpUsingGET(requestParameters: GetAvailableGwpUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAvailableGwpUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAvailablePwpByMemberId
     */
    async getAvailablePwpByMemberIdUsingGETRaw(requestParameters: GetAvailablePwpByMemberIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpByMemberId
     */
    async getAvailablePwpByMemberIdUsingGET(requestParameters: GetAvailablePwpByMemberIdUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<{ [key: string]: Array<DiscountDto>; }> {
        const response = await this.getAvailablePwpByMemberIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAvailablePwpTargetByMemberId
     */
    async getAvailablePwpTargetByMemberIdUsingGETRaw(requestParameters: GetAvailablePwpTargetByMemberIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetByMemberId
     */
    async getAvailablePwpTargetByMemberIdUsingGET(requestParameters: GetAvailablePwpTargetByMemberIdUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<{ [key: string]: Array<DiscountDto>; }> {
        const response = await this.getAvailablePwpTargetByMemberIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDiscountByDiscountIdAdmin
     */
    async getDiscountByDiscountIdAdminUsingGETRaw(requestParameters: GetDiscountByDiscountIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling getDiscountByDiscountIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/admin/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * getDiscountByDiscountIdAdmin
     */
    async getDiscountByDiscountIdAdminUsingGET(requestParameters: GetDiscountByDiscountIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.getDiscountByDiscountIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDiscountByDiscountId
     */
    async getDiscountByDiscountIdUsingGETRaw(requestParameters: GetDiscountByDiscountIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling getDiscountByDiscountIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * getDiscountByDiscountId
     */
    async getDiscountByDiscountIdUsingGET(requestParameters: GetDiscountByDiscountIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.getDiscountByDiscountIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDiscountProductByDiscountId
     */
    async getDiscountProductByDiscountIdUsingGETRaw(requestParameters: GetDiscountProductByDiscountIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling getDiscountProductByDiscountIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}/products`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getDiscountProductByDiscountId
     */
    async getDiscountProductByDiscountIdUsingGET(requestParameters: GetDiscountProductByDiscountIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductDto>> {
        const response = await this.getDiscountProductByDiscountIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setDiscountProduct
     */
    async setDiscountProductUsingGETRaw(requestParameters: SetDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling setDiscountProductUsingGET.');
        }

        if (requestParameters.productIds === null || requestParameters.productIds === undefined) {
            throw new runtime.RequiredError('productIds','Required parameter requestParameters.productIds was null or undefined when calling setDiscountProductUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productIds !== undefined) {
            queryParameters['productIds'] = requestParameters.productIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}/products/set`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters.discountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * setDiscountProduct
     */
    async setDiscountProductUsingGET(requestParameters: SetDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.setDiscountProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setMemberLevelDiscount
     */
    async setMemberLevelDiscountUsingGETRaw(requestParameters: SetMemberLevelDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MemberLevel>> {
        if (requestParameters.memberLevelId === null || requestParameters.memberLevelId === undefined) {
            throw new runtime.RequiredError('memberLevelId','Required parameter requestParameters.memberLevelId was null or undefined when calling setMemberLevelDiscountUsingGET.');
        }

        if (requestParameters.discountIds === null || requestParameters.discountIds === undefined) {
            throw new runtime.RequiredError('discountIds','Required parameter requestParameters.discountIds was null or undefined when calling setMemberLevelDiscountUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.memberLevelId !== undefined) {
            queryParameters['memberLevelId'] = requestParameters.memberLevelId;
        }

        if (requestParameters.discountIds !== undefined) {
            queryParameters['discountIds'] = requestParameters.discountIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/setMemberLevelDiscount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberLevelFromJSON(jsonValue));
    }

    /**
     * setMemberLevelDiscount
     */
    async setMemberLevelDiscountUsingGET(requestParameters: SetMemberLevelDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MemberLevel> {
        const response = await this.setMemberLevelDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateDiscountLimitToUse
     */
    async updateDiscountLimitToUseUsingGETRaw(requestParameters: UpdateDiscountLimitToUseUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling updateDiscountLimitToUseUsingGET.');
        }

        if (requestParameters.limitToUse === null || requestParameters.limitToUse === undefined) {
            throw new runtime.RequiredError('limitToUse','Required parameter requestParameters.limitToUse was null or undefined when calling updateDiscountLimitToUseUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.discountId !== undefined) {
            queryParameters['discountId'] = requestParameters.discountId;
        }

        if (requestParameters.limitToUse !== undefined) {
            queryParameters['limitToUse'] = requestParameters.limitToUse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/update/limitToUse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * updateDiscountLimitToUse
     */
    async updateDiscountLimitToUseUsingGET(requestParameters: UpdateDiscountLimitToUseUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.updateDiscountLimitToUseUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateDiscount
     */
    async updateDiscountUsingPOSTRaw(requestParameters: UpdateDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters.discountId === null || requestParameters.discountId === undefined) {
            throw new runtime.RequiredError('discountId','Required parameter requestParameters.discountId was null or undefined when calling updateDiscountUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.discountId !== undefined) {
            queryParameters['discountId'] = requestParameters.discountId;
        }

        if (requestParameters.discountTitle !== undefined) {
            queryParameters['discountTitle'] = requestParameters.discountTitle;
        }

        if (requestParameters.discountType !== undefined) {
            queryParameters['discountType'] = requestParameters.discountType;
        }

        if (requestParameters.condition !== undefined) {
            queryParameters['condition'] = requestParameters.condition;
        }

        if (requestParameters.actionType !== undefined) {
            queryParameters['actionType'] = requestParameters.actionType;
        }

        if (requestParameters.actionValue !== undefined) {
            queryParameters['actionValue'] = requestParameters.actionValue;
        }

        if (requestParameters.target !== undefined) {
            queryParameters['target'] = requestParameters.target;
        }

        if (requestParameters.limitToUse !== undefined) {
            queryParameters['limitToUse'] = requestParameters.limitToUse;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.discountProductId !== undefined) {
            queryParameters['discountProductId'] = requestParameters.discountProductId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/discount/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * updateDiscount
     */
    async updateDiscountUsingPOST(requestParameters: UpdateDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DiscountDto> {
        const response = await this.updateDiscountUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
