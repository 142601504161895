/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageSetting,
    PageSettingFromJSON,
    PageSettingToJSON,
    PageSettingDto,
    PageSettingDtoFromJSON,
    PageSettingDtoToJSON,
} from '../models';

export interface GetPageSettingByUrlUsingGETRequest {
    url: string;
}

export interface UpdatePageSettingUsingPOSTRequest {
    id: number;
    name?: string;
    pageSettingDto?: PageSettingDto;
}

/**
 * 
 */
export class PageSettingControllerApi extends runtime.BaseAPI {

    /**
     * getAllPageSetting
     */
    async getAllPageSettingUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PageSetting>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageSetting/get/page_setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PageSettingFromJSON));
    }

    /**
     * getAllPageSetting
     */
    async getAllPageSettingUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PageSetting>> {
        const response = await this.getAllPageSettingUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getPageSettingByUrl
     */
    async getPageSettingByUrlUsingGETRaw(requestParameters: GetPageSettingByUrlUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PageSetting>> {
        if (requestParameters.url === null || requestParameters.url === undefined) {
            throw new runtime.RequiredError('url','Required parameter requestParameters.url was null or undefined when calling getPageSettingByUrlUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageSetting/get/page_setting/{url}`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSettingFromJSON(jsonValue));
    }

    /**
     * getPageSettingByUrl
     */
    async getPageSettingByUrlUsingGET(requestParameters: GetPageSettingByUrlUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PageSetting> {
        const response = await this.getPageSettingByUrlUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updatePageSetting
     */
    async updatePageSettingUsingPOSTRaw(requestParameters: UpdatePageSettingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PageSetting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePageSettingUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/pageSetting/update/page_setting`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageSettingDtoToJSON(requestParameters.pageSettingDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSettingFromJSON(jsonValue));
    }

    /**
     * updatePageSetting
     */
    async updatePageSettingUsingPOST(requestParameters: UpdatePageSettingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PageSetting> {
        const response = await this.updatePageSettingUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
