const initState = {
  userInfo: { ...JSON.parse(localStorage.getItem("userInfo")!!) },
};
export function userInfo(state: any = initState, action: any) {
  switch (action.type) {
    case "HANDLE_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
}
