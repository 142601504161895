import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  persistStore,
  persistReducer,
  persistCombineReducers,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import MemberReducer from "./reducers/MemberReducer";
import shoppingCartReducer from "./reducers/ShoppingCartReducer";
import FavoriteReducer from "./reducers/FavoriteReducer";
import MyBookShelfReducer from "./reducers/MyBookShelfReducer";
import { reader } from "./reducers/reader/reader";
import { book } from "./reducers/reader/book";
import { manager } from "./reducers/reader/manager";
import { progressPanel } from "./reducers/reader/progressPanel";
import { userInfo } from "./reducers/userInfo";
import { viewArea } from "./reducers/reader/viewArea";
import { sidebar } from "./reducers/reader/sidebar";
import checkoutSession from "./reducers/CheckoutSessionReducer";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    MemberReducer,
    shoppingCartReducer,
    FavoriteReducer,
    reader,
    userInfo,
    book,
    manager,
    viewArea,
    progressPanel,
    MyBookShelfReducer,
    sidebar,
    checkoutSession,
  })
);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
export const persistor = persistStore(store);

export type stateType = {
  manager: {
    books: BookModel[],
    deletedBooks: BookModel[],
    searchResults: number[],
    isSearch: boolean,
    isBookSort: boolean,
    isSettingOpen: boolean,
    viewMode: string,
    isSortDisplay: boolean,
    isAboutOpen: boolean,
    isShowLoading: boolean,
    isShowNew: boolean,
    isNewWarning: boolean,
    isSelectBook: boolean,
    selectedBooks: string[],
    isTipDialog: boolean,
    bookSortCode: { sort: number, order: number },
    noteSortCode: { sort: number, order: number },
    tip: string,
  },
  book: {
    isOpenEditDialog: boolean,
    isOpenDeleteDialog: boolean,
    isOpenAddDialog: boolean,
    isOpenActionDialog: boolean,
    isReading: boolean,
    dragItem: string,
    currentBook: BookModel,
    currentEpub: any,
    renderFunc: () => void,
  },
  backupPage: {
    isBackup: boolean,
    isOpenTokenDialog: boolean,
  },
  progressPanel: {
    percentage: number,
    locations: any[],
  },
  reader: {
    bookmarks: BookmarkModel[],
    notes: NoteModel[],
    digests: NoteModel[],
    color: number,
    chapters: any[],
    flattenChapters: any,
    noteKey: string,
    currentChapter: string,
    originalText: string,
    htmlBook: HtmlBookModel,
  },
  sidebar: {
    mode: string,
    shelfIndex: number,
    isCollapsed: boolean,
  },
  viewArea: {
    selection: string,
    menuMode: string,
    isOpenMenu: boolean,
    isChangeDirection: boolean,
    isShowBookmark: boolean,
  },
};
