import React from "react";
import { store } from "../store";
import { authFetch, asyncAuthedPost } from "./AuthenticationService";
import {
  clearShoppingCart,
  refreshShoppingCart,
} from "../actions/ShoppingCartAction";
import * as ShoppingCartAction from "../../redux/actions/ShoppingCartAction";
import * as ShoppingCartAPI from "../../api/ShoppingCartAPI";
import * as CouponAPI from "../../api/CouponAPI";
import * as OrderAPI from "../../api/OrderAPI";
import { myGtag } from "../../api/GtagAPI";

function refreshReducerDispatch(res) {
  console.log("[ShoppingCartService][refreshReducerDispatch] res", res);
  let productList = [];
  let total = res.total;
  let quantity = res.totalQuantity;
  let pointToCash = store.getState().shoppingCartReducer.pointToCash;
  let pointInCart = 0;
  //console.log("refreshReducerDispatch", store.getState().shoppingCartReducer);
  res.cartProducts.forEach((cartProduct) => {
    if (cartProduct.productPoint != null) {
      //console.log(cartProduct.productPoint)
      pointInCart +=
        Number.parseInt(cartProduct.productPoint.point) *
        Number.parseInt(cartProduct.quantity);
    }
    //console.log("[ShoppingCartService][refreshReducerDispatch] cartProduct", cartProduct);
    productList.push({
      product_cart_product_id: cartProduct.cartProductId,
      product_display_product: cartProduct.product.displayProductId,
      product_image: cartProduct.product.productImage[0],
      product_discount_price: cartProduct.productDiscountPrice,
      product_name_chi: cartProduct.product.productNameChi,
      product_name_eng: cartProduct.product.productNameEng,
      product_price: cartProduct.product.productPrice,
      product_id: cartProduct.product.productId,
      product_volume: cartProduct.product.productVolume,
      quantity: cartProduct.quantity,
      point: cartProduct.productPoint == null ? null : cartProduct.productPoint,
      subscription_period: cartProduct.product.subscriptionPeriod,
      delivery_times: cartProduct.deliveryTimes,
      discounts: cartProduct.discounts,
      products: cartProduct.product.products,
      product_inventory: cartProduct.product.productInventory,
      product_sku: cartProduct.product.sku,
      product_url_naming:
        cartProduct.product.displayProduct == null
          ? null
          : cartProduct.product.displayProduct.displayProductUrlNaming,
      recommended:
        cartProduct.product.displayProduct == null
          ? null
          : cartProduct.product.displayProduct.recommended,
    });
  });
  //console.log("[ShoppingCartService][refreshReducerDispatch] productList", productList);
  //console.log(pointInCart);
  let payload = {
    productList: productList,
    total: total,
    coupon: res.coupon,
    total_before_discount: res.totalWithoutDiscount,
    quantity: quantity,
    pointToCash: 0,
    promotionCode: res.promotionCode,
    point: pointInCart,
  };
  store.dispatch(refreshShoppingCart(payload));
}

export function refreshDeleteShoppingCartByOrder(productId) {
  //console.log("refreshDeleteShoppingCartByOrder", productId);
  let shoppingCartList = [];
  let couponCode = store.getState().shoppingCartReducer.coupon;
  let flag = 0;
  store.getState().shoppingCartReducer.productList.forEach((item) => {
    if (item.product_id == productId) {
    } else
      shoppingCartList.push({
        productId: item.product_id,
        quantity: item.quantity,
        discounts: item.discounts ?? [],
      });
  });

  const purifiedShoppingCartList = shoppingCartList
    .filter(
      (t) => !t.discounts.some((tt) => ["PWP", "GWP"].includes(tt.discountType))
    )
    .map((t) => {
      let n = Object.assign({}, t);
      delete n.discounts;
      return n;
    });
  const pwpShoppingCartList = store
    .getState()
    .shoppingCartReducer.productList.filter((t) => {
      if (t.discounts) {
        return t.discounts.some((tt) => ["GWP"].includes(tt.discountType));
      }
      return false;
    });

  const gwpShoppingCartList = store
    .getState()
    .shoppingCartReducer.productList.filter((t) => {
      if (t.discounts) {
        return t.discounts.some((tt) => ["PWP"].includes(tt.discountType));
      }
      return false;
    });

  //console.log("refreshDeleteShoppingCartByOrder, purifiedShoppingCartList", purifiedShoppingCartList);
  OrderAPI.calcGuestExpressAmount(
    JSON.stringify(purifiedShoppingCartList),
    couponCode
  )
    .then((res) => res.json())
    .then((res) => {
      let productList = [];
      let productQuantity = 0;
      res.orderProducts.forEach((cartProduct) => {
        //console.log("[ShoppingCartService] refreshDeleteShoppingCartByOrder cartProduct", cartProduct);
        productList.push({
          product_display_product: cartProduct.product.displayProductId,
          product_image: cartProduct.product.productImage[0],
          product_discount_price: cartProduct.product.productDiscountPrice,
          product_name_chi: cartProduct.product.productNameChi,
          product_name_eng: cartProduct.product.productNameEng,
          product_price: cartProduct.product.productPrice,
          product_id: cartProduct.product.productId,
          point: null,
          quantity: cartProduct.quantity,
          product_volume: cartProduct.product.productVolume,
          subscription_period: cartProduct.product.subscriptionPeriod,
          delivery_times: cartProduct.deliveryTimes,
          discounts: cartProduct.discountList, //discounts, // Fix 之前 delete 時 沒有顯示 折扣 的問題
          products: cartProduct.product.products,
          product_inventory: cartProduct.product.productInventory,
          product_sku: cartProduct.product.sku,
          product_url_naming:
            cartProduct.product.displayProduct.displayProductUrlNaming,
          recommended: cartProduct.product.displayProduct.recommended,
        });
        productQuantity += cartProduct.quantity;
      });

      /*
            let tempProductList = [];
            for(let cart of gwpShoppingCartList)
            {
                let discount = cart.discounts[0];
                if(productList.some(t => t.product_id == discount.condition))
                {
                    tempProductList.push(cart);
                }
            }
            tempProductList.forEach(t => productList.push(t));

            for(let cart of pwpShoppingCartList)
            {
                let discount = cart.discounts[0];
                if(discount.actionValue <= res.productTotal)
                {
					// productList.push(cart);
					// For Fix Guest 全清完後 有其他 pwpShoppingCartList 的 product 加入了
					if (productQuantity === 0) {
						
					} else {
						productList.push(cart);
					}
                }
            }
			*/
      let payload = {
        productList: productList,
        total: res.productTotal,
        coupon: res.coupon,
        total_before_discount: res.productTotalBeforeDiscount,
        quantity: productQuantity,
        pointToCash: 0,
        promotionCode: res.promotionCode,
        point: 0,
      };
      //console.log("payload",payload)
      store.dispatch(refreshShoppingCart(payload));
    })
    .catch((err) => {
      console.log(err);
    });
}

export function refreshShoppingCartByOrder(
  productId,
  quantity,
  deliveryTime = 1
) {
  let shoppingCartList = [];
  let couponCode = store.getState().shoppingCartReducer.coupon;
  let flag = 0;
  store.getState().shoppingCartReducer.productList.forEach((item) => {
    if (item.product_id == productId) {
      shoppingCartList.push({
        productId: item.product_id,
        quantity: item.quantity + quantity,
        deliveryTimes: deliveryTime,
        discounts: item.discounts ?? [],
      });
      flag = 1;
    } else
      shoppingCartList.push({
        productId: item.product_id,
        quantity: item.quantity,
        deliveryTimes: deliveryTime,
        discounts: item.discounts ?? [],
      });
  });
  if (flag === 0) {
    shoppingCartList.push({
      productId: productId,
      quantity: quantity,
      deliveryTimes: deliveryTime,
      discounts: [],
    });
  }

  const purifiedShoppingCartList = shoppingCartList
    .filter(
      (t) => !t.discounts.some((tt) => ["PWP", "GWP"].includes(tt.discountType))
    )
    .map((t) => {
      let n = Object.assign({}, t);
      delete n.discounts;
      return n;
    });
  const toxicShoppingCartList = store
    .getState()
    .shoppingCartReducer.productList.filter((t) => {
      if (t.discounts) {
        return t.discounts.some((tt) =>
          ["GWP", "PWP"].includes(tt.discountType)
        );
      }
      return false;
    });

  //console.log("[ShoppingCartService] refreshShoppingCartByOrder, purifiedShoppingCartList", purifiedShoppingCartList);
  //OrderAPI.calcGuestExpressAmount(JSON.stringify(purifiedShoppingCartList), couponCode)
  OrderAPI.calcGuestExpressAmount2(
    JSON.stringify(purifiedShoppingCartList),
    couponCode
  )
    .then((t) => t.json())
    .then((res) => {
      //console.log("[ShoppingCartService] refreshShoppingCartByOrder AAA");
      let productList = [];
      let productQuantity = 0;
      res.orderProducts.forEach((cartProduct) => {
        //console.log("[ShoppingCartService] refreshShoppingCartByOrder cartProduct", cartProduct);
        productList.push({
          product_display_product: cartProduct.product.displayProductId,
          product_image: cartProduct.product.productImage[0],
          product_discount_price: cartProduct.product.productDiscountPrice,
          product_name_chi: cartProduct.product.productNameChi,
          product_name_eng: cartProduct.product.productNameEng,
          product_price: cartProduct.product.productPrice,
          product_id: cartProduct.product.productId,
          point: null,
          quantity: cartProduct.quantity,
          product_volume: cartProduct.product.productVolume,
          subscription_period: cartProduct.product.subscriptionPeriod,
          delivery_times: cartProduct.deliveryTimes,
          discounts: cartProduct.discountList,
          products: cartProduct.product.products,
          product_inventory: cartProduct.product.productInventory,
          product_sku: cartProduct.product.sku,
          product_url_naming:
            cartProduct.product.displayProduct.displayProductUrlNaming,
          recommended: cartProduct.product.displayProduct.recommended,
        });
        productQuantity += cartProduct.quantity;
      });

      for (let cart of toxicShoppingCartList) {
        if (!productList.some((t) => t.productId == cart.productId)) {
          productList.push(cart);
        }
      }

      let payload = {
        productList: productList,
        total: res.productTotal,
        coupon: res.coupon,
        total_before_discount: res.productTotalBeforeDiscount,
        quantity: productQuantity,
        promotionCode: res.promotionCode,
        point_cash: 0,
        pointToCash: 0,
      };
      store.dispatch(refreshShoppingCart(payload));
    })
    .catch(($e) => console.error($e));
}

export function addToShoppingCart(productId, quantity) {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    let data = new FormData();
    data.append("productId", productId);
    data.append("quantity", quantity);
    authFetch(
      process.env.REACT_APP_BACKEND + "shoppingCart/cartProduct/add",
      data,
      "post"
    ).then((res) => {
      refreshReducerDispatch(res);
      //store.dispatch(showCartModal());
      alert("貨品已成功放入購物車");

      //=====
      //console.log("AAA", res);

      // send GA4 add_to_cart
      /* Dev Test site comment */
      if (process.env.REACT_APP_GA4_FLAG === null) {
      } else if (process.env.REACT_APP_GA4_FLAG === "true") {
        console.log("comonjames");
        try {
          let _p = res.cartProducts.filter(
            (_p) => _p.product.productId === productId
          );
          if (_p.length === 1) {
            _p = _p[0];
            //console.log(_p);
            let _item_value = _p.productPrice;
            let _item_discount = 0;
            if (_p.productDiscountPrice) {
              _item_discount = parseFloat(
                (_item_value - _p.productDiscountPrice).toFixed(2)
              );
            }
            let _ga4_value = parseFloat(
              (_item_value - _item_discount).toFixed(2)
            );

            let _value = parseFloat((_p.productPrice * quantity).toFixed(2));
            if (_p.productDiscountPrice) {
              _value = parseFloat(
                (_p.productDiscountPrice * quantity).toFixed(2)
              );
            }

            let _json = {
              currency: "HKD",
              value: _value,
              items: [
                {
                  item_id: _p.product.sku,
                  item_name: _p.product.productNameChi,
                  affiliation: "50Megumi",
                  currency: "HKD",
                  discount: _item_discount,
                  index: 0,
                  item_brand: "50Megumi",
                  item_variant: _p.product.productVolume,
                  price: _ga4_value,
                  quantity: quantity,
                  product_price: _item_value,
                },
              ],
            };
            myGtag("event", "add_to_cart", _json);
          }
        } catch (err) {
          console.log("gtag error");
        }
      }
      /* Dev Test site comment end */
      // send GA4 add_to_cart end

      // send MP AddToCart
      /* Dev Test site comment */
      if (process.env.REACT_APP_MP_FLAG === null) {
      } else if (process.env.REACT_APP_MP_FLAG === "true") {
        try {
          let _p = res.cartProducts.filter(
            (_p) => _p.product.productId === productId
          );
          if (_p.length === 1) {
            _p = _p[0];

            let _content_ids = [];
            let _contents = [];
            //-----
            let _id = _p.product.sku;
            let _content_id = _id;
            let _content_name = _p.product.productNameChi;
            let _quantity = quantity;

            let _item_value = _p.productPrice;
            let _item_discount = 0;
            if (_p.productDiscountPrice) {
              _item_discount = parseFloat(
                (_item_value - _p.productDiscountPrice).toFixed(2)
              );
            }
            let _ga4_value = parseFloat(
              (_item_value - _item_discount).toFixed(2)
            );

            let _value = parseFloat((_p.productPrice * quantity).toFixed(2));
            if (_p.productDiscountPrice) {
              _value = parseFloat(
                (_p.productDiscountPrice * quantity).toFixed(2)
              );
            }

            _content_ids.push(_content_id);

            _contents.push({
              id: _id,
              content_ids: [_content_id],
              content_name: _content_name,
              content_type: "product",
              value: _ga4_value,
              discount: _item_discount,
              quantity: _quantity,
              product_price: _item_value,
            });
            //-----
            let _json = {
              content_ids: _content_ids,
              contents: _contents,
              content_type: "product",
              currency: "HKD",
              value: _value,
            };
            //console.log("_json", _json);
            window.fbq("track", "AddToCart", _json);
          }
        } catch (err) {
          console.log("fbq error", err);
        }
      }
      /* Dev Test site comment end */
      // send MP AddToCart end
    });
  } else {
    fetch(process.env.REACT_APP_BACKEND + "product/" + productId)
      .then((res) => res.json())
      .then((res) => {
        refreshShoppingCartByOrder(productId, quantity);
        //store.dispatch(showCartModal());
        alert("成功加入購物車");

        //=====
        //console.log("BBB", res);

        // send GA4 add_to_cart
        /* Dev Test site comment */
        if (process.env.REACT_APP_GA4_FLAG === null) {
        } else if (process.env.REACT_APP_GA4_FLAG === "true") {
          try {
            let _p = res;

            let _item_value = _p.productPrice;
            let _item_discount = 0;
            if (_p.productDiscountPrice) {
              _item_discount = parseFloat(
                (_item_value - _p.productDiscountPrice).toFixed(2)
              );
            }
            let _ga4_value = parseFloat(
              (_item_value - _item_discount).toFixed(2)
            );

            let _value = parseFloat((_p.productPrice * quantity).toFixed(2));
            if (_p.productDiscountPrice) {
              _value = parseFloat(
                (_p.productDiscountPrice * quantity).toFixed(2)
              );
            }

            let _json = {
              currency: "HKD",
              value: _value,
              items: [
                {
                  item_id: _p.sku,
                  item_name: _p.productNameChi,
                  affiliation: "50Megumi",
                  currency: "HKD",
                  discount: _item_discount,
                  index: 0,
                  item_brand: "50Megumi",
                  item_variant: _p.productVolume,
                  price: _ga4_value,
                  quantity: quantity,
                  product_price: _item_value,
                },
              ],
            };
            myGtag("event", "add_to_cart", _json);
          } catch (err) {
            console.log("gtag error");
          }
        }
        /* Dev Test site comment end */
        // send GA4 add_to_cart end

        // send MP AddToCart
        /* Dev Test site comment */
        if (process.env.REACT_APP_MP_FLAG === null) {
        } else if (process.env.REACT_APP_MP_FLAG === "true") {
          try {
            let _p = res;
            //console.log("_p", _p);

            let _content_ids = [];
            let _contents = [];
            //-----
            let _id = _p.sku;
            let _content_id = _id;
            let _content_name = _p.productNameChi;
            let _quantity = quantity;

            let _item_value = _p.productPrice;
            let _item_discount = 0;
            if (_p.productDiscountPrice) {
              _item_discount = parseFloat(
                (_item_value - _p.productDiscountPrice).toFixed(2)
              );
            }
            let _ga4_value = parseFloat(
              (_item_value - _item_discount).toFixed(2)
            );

            let _value = parseFloat((_p.productPrice * quantity).toFixed(2));
            if (_p.productDiscountPrice) {
              _value = parseFloat(
                (_p.productDiscountPrice * quantity).toFixed(2)
              );
            }

            _content_ids.push(_content_id);

            _contents.push({
              id: _id,
              content_ids: [_content_id],
              content_name: _content_name,
              content_type: "product",
              value: _ga4_value,
              discount: _item_discount,
              quantity: _quantity,
              product_price: _item_value,
            });
            //-----
            let _json = {
              content_ids: _content_ids,
              contents: _contents,
              content_type: "product",
              currency: "HKD",
              value: _value,
            };
            //console.log("_json", _json);
            window.fbq("track", "AddToCart", _json);
          } catch (err) {
            console.log("fbq error", err);
          }
        }
        /* Dev Test site comment end */
        // send MP AddToCart end
      });
  }
}

export function addToShoppingCartWithDeliveryTimes(
  productId,
  quantity,
  deliveryTime
) {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    let data = new FormData();
    data.append("productId", productId);
    data.append("quantity", quantity);
    authFetch(
      process.env.REACT_APP_BACKEND + "shoppingCart/cartProduct/add",
      data,
      "post"
    ).then((res) => {
      let body = new FormData();
      body.append("productId", productId);
      body.append("deliveryTimes", deliveryTime);
      authFetch(
        process.env.REACT_APP_BACKEND +
          "shoppingCart/cartProduct/deliveryTimes",
        body,
        "post"
      ).then((res) => {
        refreshReducerDispatch(res);
        alert("good");
        //store.dispatch(showCartModal());
      });
    });
  } else {
    fetch(process.env.REACT_APP_BACKEND + "product/" + productId)
      .then((res) => res.json())
      .then((res) => {
        refreshShoppingCartByOrder(productId, quantity, deliveryTime);
        alert("good");
        //store.dispatch(showCartModal());
      });
  }
}
export function addShoppingCartItemQuantity(productId, quantity, callback) {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    let data = new FormData();
    data.append("productId", productId);
    data.append("quantity", quantity);
    authFetch(
      process.env.REACT_APP_BACKEND + "shoppingCart/cartProduct/add",
      data,
      "post"
    )
      .then((res) => {
        refreshReducerDispatch(res);
        if (callback != null) {
          if (res.productInventory === 0) {
            callback(false, res);
          } else {
            //console.log(res);
            // check
            let _flag_result = true;
            res.cartProducts.map((_p) => {
              if (_p.productInventory === 0) {
                if (_p.discounts) {
                  _p.discounts.map((_d) => {
                    if (_d.discountType && _d.discountType === "GWP") {
                    } else {
                      //console.log("AAA", _p);
                      _flag_result = false;
                    }
                  });
                } else {
                  //console.log("BBB", _p);
                  _flag_result = false;
                }
              } else if (_p.product.productInventory < _p.quantity) {
                if (_p.discounts) {
                  _p.discounts.map((_d) => {
                    if (_d.discountType && _d.discountType === "GWP") {
                    } else {
                      //console.log("CCC", _p);
                      _flag_result = false;
                    }
                  });
                } else {
                  //console.log("DDD", _p);
                  _flag_result = false;
                }
              }
            });
            // check end

            if (_flag_result) {
              callback(true, res);

              //=====
              //console.log("AAA", res);

              // send GA4 add_to_cart
              /* Dev Test site comment */
              if (process.env.REACT_APP_GA4_FLAG === null) {
              } else if (process.env.REACT_APP_GA4_FLAG === "true") {
                try {
                  let _p = res.cartProducts.filter(
                    (_p) => _p.product.productId === productId
                  );
                  if (_p.length === 1) {
                    _p = _p[0];

                    let _item_value = _p.productPrice;
                    let _item_discount = 0;
                    if (_p.productDiscountPrice) {
                      _item_discount = parseFloat(
                        (_item_value - _p.productDiscountPrice).toFixed(2)
                      );
                    }
                    let _ga4_value = parseFloat(
                      (_item_value - _item_discount).toFixed(2)
                    );

                    let _value = parseFloat(
                      (_p.productPrice * quantity).toFixed(2)
                    );
                    if (_p.productDiscountPrice) {
                      _value = parseFloat(
                        (_p.productDiscountPrice * quantity).toFixed(2)
                      );
                    }

                    let _json = {
                      currency: "HKD",
                      value: _value,
                      items: [
                        {
                          item_id: _p.product.sku,
                          item_name: _p.product.productNameChi,
                          affiliation: "50Megumi",
                          currency: "HKD",
                          discount: _item_discount,
                          index: 0,
                          item_brand: "50Megumi",
                          item_variant: _p.product.productVolume,
                          price: _ga4_value,
                          quantity: quantity,
                          product_price: _item_value,
                        },
                      ],
                    };
                    myGtag("event", "add_to_cart", _json);
                  }
                } catch (err) {
                  console.log("gtag error");
                }
              }
              /* Dev Test site comment end */
              // send GA4 add_to_cart end

              // send MP AddToCart
              /* Dev Test site comment */
              if (process.env.REACT_APP_MP_FLAG === null) {
              } else if (process.env.REACT_APP_MP_FLAG === "true") {
                try {
                  let _p = res.cartProducts.filter(
                    (_p) => _p.product.productId === productId
                  );
                  if (_p.length === 1) {
                    _p = _p[0];

                    let _content_ids = [];
                    let _contents = [];
                    //-----
                    let _id = _p.product.sku;
                    let _content_id = _id;
                    let _content_name = _p.product.productNameChi;
                    let _quantity = quantity;

                    let _item_value = _p.productPrice;
                    let _item_discount = 0;
                    if (_p.productDiscountPrice) {
                      _item_discount = parseFloat(
                        (_item_value - _p.productDiscountPrice).toFixed(2)
                      );
                    }
                    let _ga4_value = parseFloat(
                      (_item_value - _item_discount).toFixed(2)
                    );

                    let _value = parseFloat(
                      (_p.productPrice * quantity).toFixed(2)
                    );
                    if (_p.productDiscountPrice) {
                      _value = parseFloat(
                        (_p.productDiscountPrice * quantity).toFixed(2)
                      );
                    }

                    _content_ids.push(_content_id);

                    _contents.push({
                      id: _id,
                      content_ids: [_content_id],
                      content_name: _content_name,
                      content_type: "product",
                      value: _ga4_value,
                      discount: _item_discount,
                      quantity: _quantity,
                      product_price: _item_value,
                    });
                    //-----
                    let _json = {
                      content_ids: _content_ids,
                      contents: _contents,
                      content_type: "product",
                      currency: "HKD",
                      value: _value,
                    };
                    //console.log("_json", _json);
                    window.fbq("track", "AddToCart", _json);
                  }
                } catch (err) {
                  console.log("fbq error", err);
                }
              }
              /* Dev Test site comment end */
              // send MP AddToCart end
            } else {
              callback(false, res);
            }
          }
        }
      })
      .catch(() => {
        if (callback != null) {
          callback(false);
        }
      });
  } else {
    fetch(process.env.REACT_APP_BACKEND + "product/" + productId)
      .then((res) => res.json())
      .then((res) => {
        refreshShoppingCartByOrder(productId, quantity);
        if (callback != null) {
          if (res.productInventory === 0) {
            callback(false, res);
          } else {
            callback(true);

            //=====
            //console.log("BBB", res);

            // send GA4 add_to_cart
            /* Dev Test site comment */
            if (process.env.REACT_APP_GA4_FLAG === null) {
            } else if (process.env.REACT_APP_GA4_FLAG === "true") {
              try {
                let _p = res;

                let _item_value = _p.productPrice;
                let _item_discount = 0;
                if (_p.productDiscountPrice) {
                  _item_discount = parseFloat(
                    (_item_value - _p.productDiscountPrice).toFixed(2)
                  );
                }
                let _ga4_value = parseFloat(
                  (_item_value - _item_discount).toFixed(2)
                );

                let _value = parseFloat(
                  (_p.productPrice * quantity).toFixed(2)
                );
                if (_p.productDiscountPrice) {
                  _value = parseFloat(
                    (_p.productDiscountPrice * quantity).toFixed(2)
                  );
                }

                let _json = {
                  currency: "HKD",
                  value: _value,
                  items: [
                    {
                      item_id: _p.sku,
                      item_name: _p.productNameChi,
                      affiliation: "50Megumi",
                      currency: "HKD",
                      discount: _item_discount,
                      index: 0,
                      item_brand: "50Megumi",
                      item_variant: _p.productVolume,
                      price: _ga4_value,
                      quantity: quantity,
                      product_price: _item_value,
                    },
                  ],
                };
                myGtag("event", "add_to_cart", _json);
              } catch (err) {
                console.log("gtag error");
              }
            }
            /* Dev Test site comment end */
            // send GA4 add_to_cart end

            // send MP AddToCart
            /* Dev Test site comment */
            if (process.env.REACT_APP_MP_FLAG === null) {
            } else if (process.env.REACT_APP_MP_FLAG === "true") {
              try {
                let _p = res;
                //console.log("_p", _p);

                let _content_ids = [];
                let _contents = [];
                //-----
                let _id = _p.sku;
                let _content_id = _id;
                let _content_name = _p.productNameChi;
                let _quantity = quantity;

                let _item_value = _p.productPrice;
                let _item_discount = 0;
                if (_p.productDiscountPrice) {
                  _item_discount = parseFloat(
                    (_item_value - _p.productDiscountPrice).toFixed(2)
                  );
                }
                let _ga4_value = parseFloat(
                  (_item_value - _item_discount).toFixed(2)
                );

                let _value = parseFloat(
                  (_p.productPrice * quantity).toFixed(2)
                );
                if (_p.productDiscountPrice) {
                  _value = parseFloat(
                    (_p.productDiscountPrice * quantity).toFixed(2)
                  );
                }

                _content_ids.push(_content_id);

                _contents.push({
                  id: _id,
                  content_ids: [_content_id],
                  content_name: _content_name,
                  content_type: "product",
                  value: _ga4_value,
                  discount: _item_discount,
                  quantity: _quantity,
                  product_price: _item_value,
                });
                //-----
                let _json = {
                  content_ids: _content_ids,
                  contents: _contents,
                  content_type: "product",
                  currency: "HKD",
                  value: _value,
                };
                //console.log("_json", _json);
                window.fbq("track", "AddToCart", _json);
              } catch (err) {
                console.log("fbq error", err);
              }
            }
            /* Dev Test site comment end */
            // send MP AddToCart end
          }
        }
      })
      .catch(($e) => {
        console.error($e);
        if (callback != null) {
          callback(false);
        }
      });
  }
}

export function minusShoppingCartItemQuantity(productId, quantity, callback) {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    let body = new FormData();
    body.append("productId", productId);
    body.append("quantity", quantity);
    authFetch(
      process.env.REACT_APP_BACKEND + "shoppingCart/cartProduct/minus",
      body,
      "post"
    )
      .then((res) => {
        refreshReducerDispatch(res);
        if (callback != null) {
          callback(true, res);

          //=====
          //console.log("AAA", res);

          // send GA4 remove_from_cart
          /* Dev Test site comment */
          if (process.env.REACT_APP_GA4_FLAG === null) {
          } else if (process.env.REACT_APP_GA4_FLAG === "true") {
            try {
              let _p = res.cartProducts.filter(
                (_p) => _p.product.productId === productId
              );
              if (_p.length === 1) {
                _p = _p[0];
                let _json = {
                  currency: "HKD",
                  value: parseFloat(
                    (_p.productDiscountPrice * quantity).toFixed(2)
                  ),
                  items: [
                    {
                      item_id: _p.product.sku,
                      item_name: _p.product.productNameChi,
                      affiliation: "50Megumi",
                      currency: "HKD",
                      discount: parseFloat(
                        (_p.productPrice - _p.productDiscountPrice).toFixed(2)
                      ),
                      index: 0,
                      item_brand: "50Megumi",
                      item_variant: _p.product.productVolume,
                      price: parseFloat(_p.productDiscountPrice.toFixed(2)),
                      quantity: quantity,
                      product_price: _p.product.productPrice,
                    },
                  ],
                };
                myGtag("event", "remove_from_cart", _json);
              }
            } catch (err) {
              console.log("gtag error");
            }
          }
          /* Dev Test site comment end */
          // send GA4 remove_from_cart end
        }
      })
      .catch(() => {
        if (callback != null) {
          callback(false);
        }
      });
  } else {
    fetch(process.env.REACT_APP_BACKEND + "product/" + productId)
      .then((res) => res.json())
      .then((res) => {
        refreshShoppingCartByOrder(productId, -quantity);
        if (callback != null) {
          callback(true);

          //=====
          //console.log("BBB", res);

          // send GA4 remove_from_cart
          /* Dev Test site comment */
          if (process.env.REACT_APP_GA4_FLAG === null) {
          } else if (process.env.REACT_APP_GA4_FLAG === "true") {
            try {
              let _p = res;
              let _json = {
                currency: "HKD",
                value: parseFloat(
                  (_p.productDiscountPrice * quantity).toFixed(2)
                ),
                items: [
                  {
                    item_id: _p.sku,
                    item_name: _p.productNameChi,
                    affiliation: "50Megumi",
                    currency: "HKD",
                    discount: parseFloat(
                      (_p.productPrice - _p.productDiscountPrice).toFixed(2)
                    ),
                    index: 0,
                    item_brand: "50Megumi",
                    item_variant: _p.productVolume,
                    price: parseFloat(_p.productDiscountPrice.toFixed(2)),
                    quantity: quantity,
                    product_price: _p.productPrice,
                  },
                ],
              };
              myGtag("event", "remove_from_cart", _json);
            } catch (err) {
              console.log("gtag error");
            }
          }
          /* Dev Test site comment end */
          // send GA4 remove_from_cart end
        }
      })
      .catch(() => {
        if (callback != null) {
          callback(false);
        }
      });
  }
}

export function RefreshShoppingCartItem() {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    authFetch(process.env.REACT_APP_BACKEND + "shoppingCart", null, "get").then(
      (res) => {
        console.log("res123", res);
        refreshReducerDispatch(res);
      }
    );
  } else {
    refreshShoppingCartByOrderForCheck();
  }
}

export function refreshShoppingCartByOrderForCheck() {
  let shoppingCartList = [];
  let couponCode = store.getState().shoppingCartReducer.coupon;
  let flag = 0;
  store.getState().shoppingCartReducer.productList.forEach((item) => {
    //console.log("[ShoppingCartService][refreshShoppingCartByOrderForCheck], item", item);
    shoppingCartList.push({
      productId: item.product_id,
      quantity: item.quantity,
      deliveryTimes: item.delivery_times,
      discounts: item.discounts ?? [],
    });
  });

  const purifiedShoppingCartList = shoppingCartList
    .filter(
      (t) => !t.discounts.some((tt) => ["PWP", "GWP"].includes(tt.discountType))
    )
    .map((t) => {
      let n = Object.assign({}, t);
      delete n.discounts;
      return n;
    });
  const toxicShoppingCartList = store
    .getState()
    .shoppingCartReducer.productList.filter((t) => {
      if (t.discounts) {
        return t.discounts.some((tt) =>
          ["GWP", "PWP"].includes(tt.discountType)
        );
      }
      return false;
    });

  //console.log("[ShoppingCartService][refreshShoppingCartByOrderForCheck], purifiedShoppingCartList", purifiedShoppingCartList);
  //OrderAPI.calcGuestExpressAmount(JSON.stringify(purifiedShoppingCartList), couponCode)
  OrderAPI.calcGuestExpressAmount2(
    JSON.stringify(purifiedShoppingCartList),
    couponCode
  )
    .then((t) => t.json())
    .then((res) => {
      //console.log("[ShoppingCartService][refreshShoppingCartByOrderForCheck] AAA");
      let productList = [];
      let productQuantity = 0;
      res.orderProducts.forEach((cartProduct) => {
        //console.log("[ShoppingCartService][refreshShoppingCartByOrderForCheck] cartProduct", cartProduct);
        productList.push({
          product_display_product: cartProduct.product.displayProductId,
          product_image: cartProduct.product.productImage[0],
          product_discount_price: cartProduct.product.productDiscountPrice,
          product_name_chi: cartProduct.product.productNameChi,
          product_name_eng: cartProduct.product.productNameEng,
          product_price: cartProduct.product.productPrice,
          product_id: cartProduct.product.productId,
          point: null,
          quantity: cartProduct.quantity,
          product_volume: cartProduct.product.productVolume,
          subscription_period: cartProduct.product.subscriptionPeriod,
          delivery_times: cartProduct.deliveryTimes,
          discounts: cartProduct.discountList,
          products: cartProduct.product.products,
          product_inventory: cartProduct.product.productInventory,
          product_sku: cartProduct.product.sku,
          product_url_naming:
            cartProduct.product.displayProduct.displayProductUrlNaming,
          recommended: cartProduct.product.displayProduct.recommended,
        });
        productQuantity += cartProduct.quantity;
      });

      for (let cart of toxicShoppingCartList) {
        if (!productList.some((t) => t.productId == cart.productId)) {
          productList.push(cart);
        }
      }

      let payload = {
        productList: productList,
        total: res.productTotal,
        coupon: res.coupon,
        total_before_discount: res.productTotalBeforeDiscount,
        quantity: productQuantity,
        promotionCode: res.promotionCode,
        point_cash: 0,
        pointToCash: 0,
      };
      store.dispatch(refreshShoppingCart(payload));
      //console.log("[ShoppingCartService][refreshShoppingCartByOrderForCheck] BBB");
    })
    .catch(($e) => console.error($e));
}

export function RefreshShoppingCartItemAfterLogout() {
  store.dispatch(clearShoppingCart());
}

export function deleteShoppingCartItemWithCallback(product, $callback) {
  console.log("fun");
  let isLogin = store.getState().MemberReducer.token != null;
  let productId = product.product_id;
  if (isLogin) {
    /*
        let body = new FormData();
        body.append("productId", productId);
        authFetch(process.env.REACT_APP_BACKEND + "shoppingCart/cartProduct/delete",body,"post")
            .then(res => {
                refreshReducerDispatch(res)
            })
        */

    ShoppingCartAPI.removeShoppingCartItem(productId)
      .then((t) => (t.ok ? t.json() : null))
      .then((t) => {
        //t ? refreshReducerDispatch(t) : null
        refreshReducerDispatch(t);
        //console.log("product", product);

        //=====
        //console.log("AAA", res);

        // send GA4 remove_from_cart
        /* Dev Test site comment */
        if (process.env.REACT_APP_GA4_FLAG === null) {
        } else if (process.env.REACT_APP_GA4_FLAG === "true") {
          try {
            if (product) {
              let _p = product;
              let _json = {
                currency: "HKD",
                value: parseFloat(
                  (_p.product_discount_price * _p.quantity).toFixed(2)
                ),
                items: [
                  {
                    item_id: _p.product_sku,
                    item_name: _p.product_name_chi,
                    affiliation: "50Megumi",
                    currency: "HKD",
                    discount: parseFloat(
                      (_p.product_price - _p.product_discount_price).toFixed(2)
                    ),
                    index: 0,
                    item_brand: "50Megumi",
                    item_variant: _p.product_volume,
                    price: parseFloat(_p.product_discount_price.toFixed(2)),
                    quantity: _p.quantity,
                    product_price: _p.product_price,
                  },
                ],
              };
              //console.log("_p", _p, "_json", _json);
              myGtag("event", "remove_from_cart", _json);
            }
          } catch (err) {
            console.log("gtag error");
          }
        }
        /* Dev Test site comment end */
        // send GA4 remove_from_cart end
      })
      .catch(($e) => console.error($e))
      .finally(() => $callback());
  } else {
    refreshDeleteShoppingCartByOrder(productId);
    $callback();

    //=====
    //console.log("BBB", res);

    // send GA4 remove_from_cart
    /* Dev Test site comment */
    if (process.env.REACT_APP_GA4_FLAG === null) {
    } else if (process.env.REACT_APP_GA4_FLAG === "true") {
      try {
        if (product) {
          let _p = product;
          let _json = {
            currency: "HKD",
            value: parseFloat(
              (_p.product_discount_price * _p.quantity).toFixed(2)
            ),
            items: [
              {
                item_id: _p.product_sku,
                item_name: _p.product_name_chi,
                affiliation: "50Megumi",
                currency: "HKD",
                discount: parseFloat(
                  (_p.product_price - _p.product_discount_price).toFixed(2)
                ),
                index: 0,
                item_brand: "50Megumi",
                item_variant: _p.product_volume,
                price: parseFloat(_p.product_discount_price.toFixed(2)),
                quantity: _p.quantity,
                product_price: _p.product_price,
              },
            ],
          };
          //console.log("_p", _p, "_json", _json);
          myGtag("event", "remove_from_cart", _json);
        }
      } catch (err) {
        console.log("gtag error");
      }
    }
    /* Dev Test site comment end */
    // send GA4 remove_from_cart end
  }
}

export function emptyShoppingCart() {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    authFetch(
      process.env.REACT_APP_BACKEND + "shoppingCart/clean",
      null,
      "post"
    )
      .then((res) => {
        refreshReducerDispatch(res);
      })
      .catch((error) => console.log("emptyShoppingCart err", error));
  } else {
    store.dispatch(clearShoppingCart());
  }
}

export async function asyncApplyCoupon(couponCode) {
  const isLogin = store.getState().MemberReducer.token != null;

  let response = null;
  if (isLogin) {
    try {
      response = await ShoppingCartAPI.setCoupon(couponCode);
      if (!response.ok) {
        return false;
      }
      response = await response.json();
      refreshReducerDispatch(response);
      return { response: response, result: true };
    } catch ($e) {
      console.error($e);
    }
  } else {
    try {
      response = await CouponAPI.getByCodeGuest(couponCode);
      if (!response.ok) {
        return { response: response, result: false };
      }
      response = await response.json();

      if (!["現金券", "折扣券"].includes(response.discount.discountType)) {
        return { response: response, result: false };
      }

      store.dispatch(ShoppingCartAction.addCoupon(response));
      return { response: response, result: true };
    } catch ($e) {
      console.error($e);
    }
  }

  return false;
}

export async function asyncDeleteCoupon() {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    let response = null;
    try {
      response = await ShoppingCartAPI.removeCouponCode();
      if (!response.ok) {
        return false;
      }
      response = await response.json();
      refreshReducerDispatch(response);
      return true;
    } catch ($e) {
      console.error($e);
    }
  } else {
    store.dispatch(ShoppingCartAction.deleteCoupon());
    return true;
  }
  return false;
}

export function addPointProduct(productId, quantity, productPointId, callback) {
  let body = new FormData();
  body.append("productId", productId);
  body.append("productPointId", productPointId);
  body.append("quantity", quantity);
  authFetch(
    process.env.REACT_APP_BACKEND + "shoppingCart/cartProduct/usingPoint/add",
    body,
    "post"
  )
    .then((res) => {
      refreshReducerDispatch(res);
      if (callback) {
        callback(true);
      }
    })
    .catch(() => (callback ? callback(false) : null));
}

export function minusPointProduct(
  productId,
  quantity,
  productPointId,
  callback
) {
  let body = new FormData();
  body.append("productId", productId);
  body.append("productPointId", productPointId);
  body.append("quantity", quantity);
  authFetch(
    process.env.REACT_APP_BACKEND + "shoppingCart/cartProduct/usingPoint/minus",
    body,
    "post"
  )
    .then((res) => {
      refreshReducerDispatch(res);
      if (callback) {
        callback(true);
      }
    })
    .catch(() => (callback ? callback(false) : null));
}

export function deletePointProductWithCallback(
  productId,
  productPointId,
  $callback
) {
  ShoppingCartAPI.removePointProduct(productId, productPointId)
    .then((res) => (res.ok ? res.json() : null))
    .then((t) => (t ? refreshReducerDispatch(t) : null))
    .catch(($e) => console.error($e))
    .finally(() => $callback());
}

export function setPointCash(pointCash) {
  let reducer = store.getState().shoppingCartReducer;
  reducer["pointToCash"] = pointCash;
  //console.log(reducer)
  store.dispatch(refreshShoppingCart(reducer));
}

export function addPromotionCode(promotionCode) {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin == true) {
    let body = new FormData();
    body.append("promotionCode", promotionCode);
    authFetch(
      process.env.REACT_APP_BACKEND + "shoppingCart/promotionCode/set",
      body,
      "post"
    ).then((res) => refreshReducerDispatch(res));
  }
}

export function deletePromotionCode() {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin == true) {
    authFetch(
      process.env.REACT_APP_BACKEND + "shoppingCart/promotionCode/delete",
      null,
      "post"
    ).then((res) => refreshReducerDispatch(res));
  }
}

export function reBuy(orderId, callback) {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin == true) {
    authFetch(
      process.env.REACT_APP_BACKEND + "order/rebuy/" + orderId,
      null,
      "get"
    ).then((res) => {
      refreshReducerDispatch(res);
      callback();
    });
  }
}

/**
 * 
Expecting structures:

productList: {}
    productId: int
    quantity: int
promotionCode: string?
*/
export async function asyncDangerousSetShoppingCartWithParams(
  $productList,
  $promotionCode
) {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    let body = {
      productListJson: JSON.stringify($productList),
    };

    if ($promotionCode) {
      body.promotionCode = $promotionCode;
    }

    let result = await asyncAuthedPost("shoppingCart/setContent", body);
    return result.json();
  }

  return Promise.reject();
}

export function getShoppingCart() {
  return store.getState().shoppingCartReducer;
}

export function refreshShoppingCartItemWithCallback($callback) {
  let isLogin = store.getState().MemberReducer.token != null;
  if (isLogin) {
    ShoppingCartAPI.get()
      .then((t) => (t.ok ? t.json() : null))
      .then((t) => (t ? refreshReducerDispatch(t) : null))
      .catch(($e) => console.error($e))
      .finally(() => $callback());
    return;
  }

  $callback();
}

export function addGwpDiscountToShoppingCart(
  discountId,
  $callback,
  $errorCallback
) {
  return ShoppingCartAPI.addGwpDiscountToShoppingCart(discountId)
    .then((response) => {
      if (!response.ok) {
        $errorCallback ? $errorCallback() : $callback();
        return;
      }

      $callback();
    })
    .catch(($e) => {
      console.error($e);
      $errorCallback ? $errorCallback() : $callback();
    });
}

export function addGwpDiscountToShoppingCartForGuest(
  discountId,
  cartProductList,
  couponCode,
  $callback,
  $errorCallback
) {
  return ShoppingCartAPI.addGwpDiscountToShoppingCartForGuest(
    discountId,
    cartProductList,
    couponCode
  )
    .then((response) => {
      if (!response.ok) {
        $errorCallback ? $errorCallback() : $callback();
        return;
      }

      response.json().then((response2) => $callback(response2));
    })
    .catch(($e) => {
      console.error($e);
      $errorCallback ? $errorCallback() : $callback();
    });
}

export function removeGwpDiscountFromShoppingCart(discountId, $callback) {
  return ShoppingCartAPI.removeGwpDiscountFromShoppingCart(discountId)
    .catch(($e) => console.error($e))
    .finally(() => $callback());
}
