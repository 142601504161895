import React from "react";
import style from "../../layout/Checkout/Checkout.module.css";
import Button from "../Button/Button";
import Input from "../Input/Input";
import unionPayIcon from "../../assets/images/2593673_pay_union_icon.png";
import visaIcon from "../../assets/images/294654_visa_icon.png";
import aliPayIcon from "../../assets/images/alipay hk.png";
import weChatPayIcon from "../../assets/images/WeChat-Pay-HK-Logo.png";
import masterIcon from "../../assets/images/1322426_card_master_master card_master card new logo_method_icon.png";
import jcbIcon from "../../assets/images/2593677_jcb_icon.png";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/useWindowSize";

export default function CheckoutBox({ props, callBack, discountAmount }: any) {
  console.log("props", props);
  console.log("callBack", callBack);
  console.log("discountAmount", discountAmount());
  const { t } = useTranslation();
  const { width, height } = useWindowSize();

  return (
    <div className={style.checkoutContainer}>
      <div className={style.checkoutBox}>
        <div className={style.toggleGroup}>
          {/* <div className={style.toggleSilder}>123</div> */}
          <div className={style.progress}>
            <div className={style.circle}></div>
            <div
              className={style.toggleButtonCurrent}
              // onClick={activeLogin()}
            >
              <p style={{ padding: "3px" }}>
                {t("checkout.shoppingCartConfirmation")}
              </p>
            </div>
          </div>
          <div className={style.progress}>
            <div className={style.circle}></div>
            <div
              className={style.toggleButtonCurrent}
              // onClick={activeLogin()}
            >
              <p> {t("checkout.orderConfirmation")}</p>
            </div>
          </div>
          <div className={style.progress}>
            <div className={style.circle2}></div>
            <div className={style.toggleButton}>
              <p>{t("checkout.paymentConfirmation")}</p>
            </div>
            <div className={style.circle3}></div>
          </div>
        </div>
        <h3>{t("checkout.checkout")}</h3>
        <div className={style.boxItemLayout}>
          <span>{t("checkout.orderDetail")}</span>
          <span>{t("checkout.price")}</span>
        </div>
        <hr className={style.horizontalLine}></hr>

        <div className={style.boxItemLayout2}>
          <span>
            {t("checkout.product")}({props.productList.length})
          </span>
          <span>{props.total_before_discount.toFixed(2)}</span>
        </div>
        <p
          style={{
            fontSize: "12px",
            color: "#009da5",

            margin: "0.5em 0 0.5em 0 ",
          }}
        >
          {width > 768
            ? t("checkout.ForDetailedList")
            : t("checkout.ForDetailedListMobile")}
        </p>
        <hr className={style.horizontalLine}></hr>
        <div className={style.boxItemLayout}>
          <span>{t("checkout.coupons")}</span>
          <span style={{ color: "red" }}>
            {(Math.floor((discountAmount() + Number.EPSILON) * 10) / 10).toFixed(2)}
          </span>
        </div>
        <div style={{ margin: "1em 0", height: "2em" }}>
          {props.coupon ? (
            <Input
              type="text"
              value={props.coupon.couponCode}
              styleType={"loginInput"}
              callBack={(value) => callBack(value)}
              placeholder="優惠代碼"
              id={"取消"}
            />
          ) : (
            <Input
              type="text"
              styleType={"loginInput"}
              callBack={(value) => callBack(value)}
              placeholder="優惠代碼"
              id={"使用"}
            />
          )}
        </div>
        {props.coupon ? (
          <>
            <hr className={style.horizontalLine}></hr>
            <div className={style.boxItemLayout}>
              <span>{props.coupon.discount.discountTitle}</span>
              <span style={{ color: "red" }}>
                {/*round down to one decimal*/}
                {
                  (
                    props.total -
                    (props.total_before_discount + discountAmount())
                  )
                    .toString()
                    .split(".")[0]
                }
                .
                {(
                  props.total -
                  (props.total_before_discount + discountAmount())
                )
                  .toString()
                  .split(".")[1]
                  ? 
                    (
                      props.total -
                      (props.total_before_discount + discountAmount())
                    )
                      .toString()
                      .split(".")[1]
                      .substring(0, 1)
                    + "0"
                  : "00" /* (0).toFixed(2) */}
              </span>
            </div>
          </>
        ) : (
          <></>
        )}

        <hr className={style.horizontalLine}></hr>
        <div className={style.boxItemLayout}>
          <span>{t("checkout.total")}</span>
          <div>
            <span>HKD</span>
            &nbsp;
            <span style={{ color: "#009da5" }}>
              {props.coupon
                ? props.total.toFixed(2)
                :
                  (
                    Math.floor(
                      (
                        props.total_before_discount +
                        discountAmount() +
                        Number.EPSILON
                      ) * 10
                    ) / 10
                  ).toFixed(2)}
            </span>
          </div>
        </div>
        <div className={style.buttonContainer}>
          <Button
            children={"付款"}
            callBack={() => callBack({ action: "confirm" })}
            styleType={"loginButton"}
          />
        </div>
        <h5 className={style.policyContainer}>{t("checkout.paymentMethod")}</h5>
        <div className={style.boxItemLayout3}>
          <img src={unionPayIcon} alt="" />
          <img src={visaIcon} alt="" />
          <img src={masterIcon} alt="" />
          <img src={jcbIcon} alt="" />
        </div>
        {/* <div className={style.boxItemLayout3}>
          <img src={aliPayIcon} alt="" />
          <img src={weChatPayIcon} alt="" />
        </div> */}

        <div className={style.policyContainer2}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => callBack({ action: "modal", message: "messageOne" })}
          >
            服務及使用條款
          </span>
          <span>|</span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => callBack({ action: "modal", message: "messageTwo" })}
          >
            私隱政策聲明
          </span>
        </div>
      </div>
    </div>
  );
}
