import { store } from "../store";
import {
  addGuestFavorite,
  deleteGuestFavorite,
} from "../actions/FavoriteAction";
import { authFetch } from "./AuthenticationService";
import { REFRESH_GUEST_FAVOURITE } from "../../utils/Constant";

//import { myGtag } from './../api/GtagAPI';

export function addToFavorite(productId, _json) {
  const isLogin = store.getState().MemberReducer.token !== null;
  if (isLogin == true) {
    let body = new FormData();
    console.log("productId", productId);
    body.append("productId", productId);
    authFetch(
      process.env.REACT_APP_BACKEND + "member/favourite",
      body,
      "put"
    ).then((res) => {
      listFavorite();
    });
  } else {
    alert(`請先登入`);
    // store.dispatch(addGuestFavorite({
    //     productId: parseInt(productId)
    // }))
  }
  //store.dispatch(showFavoriteModal());

  //=====
  //console.log("AAA", _json);

  // if (_json) {
  // 	// send GA4 add_to_wishlist
  // 	/* Dev Test site comment */
  // 	if (process.env.REACT_APP_GA4_FLAG === null) {

  // 	} else if (process.env.REACT_APP_GA4_FLAG === "true") {
  // 		try {
  // 			myGtag("event", "add_to_wishlist", _json);
  // 		} catch (err) {
  // 			console.log("gtag error");
  // 		}
  // 	}
  // 	/* Dev Test site comment end */
  // 	// send GA4 add_to_wishlist end

  // 	// send MP ViewContent
  // 	/* Dev Test site comment */
  // 	if (process.env.REACT_APP_MP_FLAG === null) {

  // 	} else if (process.env.REACT_APP_MP_FLAG === "true") {
  // 		try {
  // 			let _json_mp = {
  // 				content_ids: [_json.items[0].item_id],
  // 				content_name: _json.items[0].item_name,
  // 				content_type: 'product',
  // 				currency: _json.currency,
  // 				value: _json.value,
  // 			};
  // 			//console.log("_json_mp", _json_mp);
  // 			window.fbq(
  // 				'track',
  // 				'AddToWishlist',
  // 				_json_mp
  // 			)
  // 		} catch (err) {
  // 			console.log("fbq error", err);
  // 		}
  // 	}
  // 	/* Dev Test site comment end */
  // 	// send MP ViewContent end
  // }
}

export function deleteFavorite(productId) {
  const isLogin = store.getState().MemberReducer.token !== null;
  if (isLogin == true) {
    let body = new FormData();
    body.append("productId", productId);
    authFetch(
      process.env.REACT_APP_BACKEND + "member/favourite",
      body,
      "delete"
    ).then((res) => {
      listFavorite();
    });
  } else {
    store.dispatch(
      deleteGuestFavorite({
        productId: parseInt(productId),
      })
    );
  }
}

export function listFavorite() {
  authFetch(
    process.env.REACT_APP_BACKEND + "member/favourite",
    null,
    "get"
  ).then((res) => {
    let productList = [];
    res.forEach((product) =>
      productList.push(parseInt(product.displayProductId))
    );
    store.dispatch({
      type: REFRESH_GUEST_FAVOURITE,
      payload: {
        productList: productList,
      },
    });
  });
  return store.getState().FavoriteReducer.productList;
}

export function listMybookShelf(memberId) {
  authFetch(
    process.env.REACT_APP_BACKEND + "book_shelf/getByMemberId/" + memberId,
    null,
    "get"
  ).then((res) => {
    let productList = [];
    res.forEach((product) =>
      productList.push(parseInt(product.displayProductId))
    );
    store.dispatch({
      type: "REFRESH_BOOKSHELF",
      payload: {
        productList: productList,
      },
    });
  });
  return store.getState().MyBookShelfReducer.productList;
}
