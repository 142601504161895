// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-search-box {
  width: 170px;
  height: 38px;
  border-radius: 19px;
  border-style: none;
  outline: none;
  padding-left: 20px;
  padding-right: 40px;
  font-size: 15px;
  padding-top: 5px;
}
.navigation-header.no-cover .header-search-box {
  width: calc(100% - 30px);
}
input::-webkit-input-placeholder,
input::-moz-placeholder,
input::-ms-input-placeholder,
textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea::-ms-input-placeholder {
  font-size: 15px;
  line-height: 38px;
  /* opacity: 1; */
}
.header-search-icon {
  position: absolute;
  top: 10px;
  right: -6px;
  font-size: 22px;
  display: inline-block;
  opacity: 0.6;
  cursor: pointer;
  margin-right: 10px;
}
.navigation-header.no-cover .header-search-icon {
  right: 30px !important;
}
.header-search-text {
  position: absolute;
  top: 0px;
  right: -15px;
  font-size: 15px;
  display: inline-block;
  width: 44px;
  height: 44px;
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-search-text:hover {
  border-radius: 50%;
}
.search-text {
  font-size: 18px;
  position: relative;
  right: 180px;
  bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Reader/searchBox/searchBox.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,wBAAwB;AAC1B;AACA;;;;;;EAME,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".header-search-box {\n  width: 170px;\n  height: 38px;\n  border-radius: 19px;\n  border-style: none;\n  outline: none;\n  padding-left: 20px;\n  padding-right: 40px;\n  font-size: 15px;\n  padding-top: 5px;\n}\n.navigation-header.no-cover .header-search-box {\n  width: calc(100% - 30px);\n}\ninput::-webkit-input-placeholder,\ninput::-moz-placeholder,\ninput::-ms-input-placeholder,\ntextarea::-webkit-input-placeholder,\ntextarea::-moz-placeholder,\ntextarea::-ms-input-placeholder {\n  font-size: 15px;\n  line-height: 38px;\n  /* opacity: 1; */\n}\n.header-search-icon {\n  position: absolute;\n  top: 10px;\n  right: -6px;\n  font-size: 22px;\n  display: inline-block;\n  opacity: 0.6;\n  cursor: pointer;\n  margin-right: 10px;\n}\n.navigation-header.no-cover .header-search-icon {\n  right: 30px !important;\n}\n.header-search-text {\n  position: absolute;\n  top: 0px;\n  right: -15px;\n  font-size: 15px;\n  display: inline-block;\n  width: 44px;\n  height: 44px;\n  cursor: pointer;\n  margin-right: 5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.header-search-text:hover {\n  border-radius: 50%;\n}\n.search-text {\n  font-size: 18px;\n  position: relative;\n  right: 180px;\n  bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
