import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import { routes } from "./global/Routes";
import {
  initSystemFont,
  initTheme,
} from "./utils/reader/serviceUtils/launchUtil";

function App() {
  const element = useRoutes(routes());
  useEffect(() => {
    initSystemFont();
    initTheme();
  }, []);

  return <>{element}</>;
}

export default App;
