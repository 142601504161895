import "whatwg-fetch";
import moment from "moment";
import { store } from "../redux/store";
import * as MemberService from "../redux/services/MemberService";

let api = process.env.REACT_APP_BACKEND;
console.log("api", api);
if (
  api
  && api.endsWith("/")
) {
  api = api.substr(0, api.length - 1);
}

function makeFetchOption(method, body) {
  let ret = {
    headers: {
      Accept: "application/json",
    },
    method: method,
    credentials: "omit",
    mode: "cors",
    redirect: "follow",
  };
  if (
    MemberService.getAuthenticateTokenStatus() ==
      MemberService.AuthenticateTokenStatus.LoggedIn ||
    MemberService.getAuthenticateTokenStatus() ==
      MemberService.AuthenticateTokenStatus.LoggedInNearExpire
  ) {
    const state = store.getState();
    const token = state.MemberReducer.token;

    if (token) {
      ret.headers.Authorization = `Bearer ${token}`;
    }
  }

  if (body) {
    ret.body = body;
  }

  return ret;
}

function appendFormData($fd, $data) {
  if ($data) {
    if (typeof $data === "object") {
      for (let key in $data) {
        const val = $data[key];
        if (typeof val === "undefined") {
          continue;
        }
        if (val === null) {
          continue;
        }
        if (Array.isArray(val)) {
          for (let v of val) {
            $fd.append(key, v);
          }
          continue;
        }
        if (val instanceof moment) {
          $fd.append(key, val.format("YYYY-MM-DD HH:mm"));
          continue;
        }
        if (val instanceof Date) {
          $fd.append(key, new moment(val).format("YYYY-MM-DD HH:mm"));
          continue;
        }
        if (typeof val === "function") {
          $fd.append(key, val());
          continue;
        }
        if (typeof val === "object") {
          $fd.append(key, JSON.stringify(val));
          continue;
        }
        $fd.append(key, val);
      }
    } else if (typeof $data === "function") {
      $data($fd);
    }
  }
}

export function authGet($url) {
  return fetch(api + $url, makeFetchOption("GET"));
}

export function authPost($url, $data) {
  let form = new FormData();
  appendFormData(form, $data);

  return fetch(api + $url, makeFetchOption("POST", form));
}

export function authPut($url, $data) {
  let form = new FormData();
  appendFormData(form, $data);

  return fetch(api + $url, makeFetchOption("PUT", form));
}

export function authDelete($url, $data) {
  let form = new FormData();
  appendFormData(form, $data);

  return fetch(api + $url, makeFetchOption("DELETE", form));
}

export const g = authGet;
export const p = authPost;
export const u = authPut;
export const d = authDelete;
export default authGet;
