import React from "react";
import Button from "../Button/Button";
import style from "../../layout/test/Layout.module.css";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import twitter from "../../assets/images/whatsapp.png";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/icons/iconsarrowleft@1x.svg";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/useWindowSize";

interface ILng {
  nativeName: string;
}
interface ILngs {
  en: ILng;
  tw: ILng;
  cn: ILng;
}

const lngs: ILngs = {
  en: { nativeName: "ENG" },
  tw: { nativeName: "繁" },
  cn: { nativeName: "簡" },
};

export default function LowerNavBar({ gospel }: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const { width, height } = useWindowSize();
  return (
    <>
      {width <= 1024 ?
        <>
          <div className={style.smallUpperNavbar} >
            <div className={style.loginContainer}>
            </div>
            <div className={style.languageContainer}>
              <div className={style.languageInnerContainer}>
                {Object.keys(lngs).map((lng) => (
                  <span
                    style={{
                      margin: "3px",
                      cursor: "pointer",
                      color: "rgb(20, 61, 72)",
                      fontSize: "14px",
                    }}
                    onClick={() => i18n.changeLanguage(lng)}
                  >
                    {lngs[lng as keyof ILngs].nativeName}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className={style.lowerNavbar}>
            <div className={style.searchContainer}>
              <div className={style.scrollContainer}>
                <div className={style.cs_c1}>每日福音</div>
                <p className={style.scrollText} title={gospel}>
                  {gospel}
                </p>
              </div>
            </div>
          </div>
        </>
        :
        <div className={style.lowerNavbar}>
          <div className={style.upperInnerNavbar}>
            <div className={style.backButtonContainer}>
              {window.location.pathname === "/login" ||
                window.location.pathname === "/register" ? (
                <>
                  <img src={backArrow} style={{ width: "20px" }} />
                  <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => navigate(-1)}
                  >
                    上一頁
                  </Button>
                </>
              ) : (
                <></>
              )}
              <div></div>
            </div>
            <div className={style.searchContainer}>
              <div className={style.scrollContainer}>
                <div className={style.cs_c1}>每日福音</div>
                <p className={style.scrollText} title={gospel}>
                  {gospel}
                </p>
              </div>
            </div>
            <div className={style.loginContainer2}>
              <Button
                styleType={"lowerNavbarButton"}
                callBack={() => navigate("events")}
              >
                {t("header.events")}
              </Button>
              <pre> | </pre>
              <Button
                styleType={"lowerNavbarButton"}
                callBack={() => navigate("news")}
              >
                {t("header.news")}
              </Button>
              <pre> | </pre>
              <Button
                styleType={"lowerNavbarButton"}
                callBack={() => navigate("FAQ")}
              >
                {t("header.faq")}
              </Button>
            </div>
            <div className={style.languageContainer2}>
              <img
                src={facebook}
                style={{ width: "25px", cursor: "pointer" }}
                onClick={() =>
                  openInNewTab(
                    "https://www.facebook.com/CatholicTruthSocietyofHongKong"
                  )
                }
              />
              <img
                src={instagram}
                style={{ width: "25px", cursor: "pointer" }}
                onClick={() =>
                  openInNewTab(
                    "https://www.instagram.com/catholictruthsocietyofhk/?igshid=YmMyMTA2M2Y%3D"
                  )
                }
              />
              <img
                src={twitter}
                style={{ width: "25px", cursor: "pointer" }}
                onClick={() => openInNewTab("https://wa.me/+85294800113")}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
}
