// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LowerSideNav_lowerSideNavContainer__WhW7g {
  margin: 16px 16px;
}

.LowerSideNav_lowerSideNavTitle__tm73j {
  color: #009da5;
  cursor: pointer;
  padding: 0 0 15px 0;
}


.LowerSideNav_lowerSideNavList__mZYJs {
  list-style: none;
  position: relative;
  padding: 15px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #009da5;
  /* justify-content: space-between; */
  width: 100%;
  height: 100%;
  gap: 0.5em;
  /* justify-content: flex-start; */
}

.LowerSideNav_circle__iFUXs {
  /* flex:0.5; */
  width: 15px;
  height: 15px;
  margin-right: 5%;
  background-color: #47d5cd;
  border-radius: 50%;
}
.LowerSideNav_arrow__y4fPZ {
  flex: 0.5 1;
  font-size: 12px !important;
  color: #47d5cd;
}
.LowerSideNav_listName__ZJXwp {
  flex: 5 1;
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.LowerSideNav_lowerSideNavList__mZYJs::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgb(232, 232, 232);
}
`, "",{"version":3,"sources":["webpack://./src/layout/SideNav/LowerSideNav.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;;;AAGA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,oCAAoC;EACpC,WAAW;EACX,YAAY;EACZ,UAAU;EACV,iCAAiC;AACnC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,WAAS;EACT,0BAA0B;EAC1B,cAAc;AAChB;AACA;EACE,SAAO;EACP,cAAc;EACd,aAAa;EACb,2BAA2B;EAC3B,4BAA4B;EAC5B,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,WAAW;EACX,oCAAoC;AACtC","sourcesContent":[".lowerSideNavContainer {\n  margin: 16px 16px;\n}\n\n.lowerSideNavTitle {\n  color: #009da5;\n  cursor: pointer;\n  padding: 0 0 15px 0;\n}\n\n\n.lowerSideNavList {\n  list-style: none;\n  position: relative;\n  padding: 15px 0;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  color: #009da5;\n  /* justify-content: space-between; */\n  width: 100%;\n  height: 100%;\n  gap: 0.5em;\n  /* justify-content: flex-start; */\n}\n\n.circle {\n  /* flex:0.5; */\n  width: 15px;\n  height: 15px;\n  margin-right: 5%;\n  background-color: #47d5cd;\n  border-radius: 50%;\n}\n.arrow {\n  flex: 0.5;\n  font-size: 12px !important;\n  color: #47d5cd;\n}\n.listName {\n  flex: 5;\n  font-size: 1em;\n  display: flex;\n  justify-content: flex-start;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  -webkit-line-clamp: 1;\n}\n\n.lowerSideNavList::after {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 1px;\n  background-color: rgb(232, 232, 232);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lowerSideNavContainer": `LowerSideNav_lowerSideNavContainer__WhW7g`,
	"lowerSideNavTitle": `LowerSideNav_lowerSideNavTitle__tm73j`,
	"lowerSideNavList": `LowerSideNav_lowerSideNavList__mZYJs`,
	"circle": `LowerSideNav_circle__iFUXs`,
	"arrow": `LowerSideNav_arrow__y4fPZ`,
	"listName": `LowerSideNav_listName__ZJXwp`
};
export default ___CSS_LOADER_EXPORT___;
