import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
  redirect,
} from "react-router-dom";
import Button from "../../components/Button/Button";
// import { useAuth } from "../../global/useAuth";
import style from "./Layout.module.css";
import logo from "../../assets/images/CTSLOG.svg";
import Select from "../../components/Select/Select";
import Input from "../../components/Input/Input";
import SearchIcon from "@mui/icons-material/Search";
import LowerNavBar from "../../components/NavBar/LowerNavBar";
import BookCard from "../../components/BookCard/BookCard";
import useWindowSize from "../../utils/useWindowSize";
import footer from "../../assets/images/Logo.svg";
import footer03 from "../../assets/images/Logo_03.png";
import footer04 from "../../assets/images/Logo_04.png";
import footer05 from "../../assets/images/Logo_05.png";
import footer06 from "../../assets/images/Logo_06.png";
import footer07 from "../../assets/images/Logo_07.png";
import footer08 from "../../assets/images/Logo_08.png";
import footer09 from "../../assets/images/Logo_09.png";
import footer10 from "../../assets/images/Logo_10.png";
import footer11 from "../../assets/images/Logo_11.png";
import footer14 from "../../assets/images/Logo_14.png";
import footer15 from "../../assets/images/Logo_15.png";
import footer16 from "../../assets/images/Logo_16.png";
import footer17 from "../../assets/images/Logo_17.png";
import footer19 from "../../assets/images/Logo_19.png";
import footer20 from "../../assets/images/Logo_20.png";
import footer21 from "../../assets/images/Logo_21.png";
import footer22 from "../../assets/images/Logo_22.png";
import footer23 from "../../assets/images/Logo_23.png";
import footer24 from "../../assets/images/Logo_24.png";
import footer25 from "../../assets/images/Logo_25.png";
import footer28 from "../../assets/images/Logo_28.png";
import footer29 from "../../assets/images/Logo_29.png";
import footer30 from "../../assets/images/Logo_30.png";
import footer31 from "../../assets/images/Logo_31.png";
import footer32 from "../../assets/images/Logo_32.png";
import favIcon from "../../assets/icons/fav.svg";
import shoppingCartIcon from "../../assets/icons/cart.svg";
import { connect } from "react-redux";
import { clearShoppingCart } from "../../redux/actions/ShoppingCartAction";
import { deleteMemberInformation } from "../../redux/actions/MemberAction";
import * as displayProductAPI from "../../api/DisplayProductAPI";
import fbIcon from "../../assets/images/3225194_app_facebook_logo_media_popular_icon.png";
import igIcon from "../../assets/images/3225191_app_instagram_logo_media_popular_icon.png";
import waIcon from "../../assets/images/3225179_app_logo_media_popular_social_icon.png";
import apple from "../../assets/images/google.png";
import google from "../../assets/images/apple.png";
import back from "../../assets/btn/back.svg";
import menu from "../../assets/btn/menu.svg";
import { store } from "../../redux/store";
import {
  listFavorite,
  listMybookShelf,
} from "../../redux/services/FavoriteService";
import bookshelfIcon from "../../assets/icons/bookshelf.svg";
import { useTranslation, Trans } from "react-i18next";
import {
  LOGIN_RIDIRECT_PATHNAME,
  STYLETYPE_SECOND,
} from "../../utils/Constant";
import { speedList } from "../../constants/reader/dropdownList";
import { decode as b64decode } from "js-base64";
import { RefreshShoppingCartItem } from "../../redux/services/ShoppingCartService";
import { asyncHandleLoginShoppingCart } from "../../api/AuthenticationAPI";
import DialogBox from "../DialogBox/DialogBox";
import Subscribe from "./Subscribe";
import { display } from "@mui/system";
import SideMenu from "./SideMenu";

interface ILng {
  nativeName: string;
}
interface ILngs {
  en: ILng;
  tw: ILng;
  cn: ILng;
}

const lngs: ILngs = {
  en: { nativeName: "ENG" },
  tw: { nativeName: "繁" },
  cn: { nativeName: "簡" },
};

type ContextType = { dateString: string };
function Layout({
  member,
  quantity,
  deleteMemberInformation,
  clearShoppingCart,
}: any) {
  const [product, setProduct] = React.useState([]);
  const [serachString, setSearchString] = React.useState("");
  const [searchProduct, setSearchProduct] = React.useState([]);
  const [gospel, setGospel] = React.useState("");
  const [dateString, setDateString] = React.useState("");
  const [showDialog, setShowDialog] = React.useState(false);
  const { width, height } = useWindowSize();
  const location = useLocation();
  const [asda] = useSearchParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [searchbtn, setSearchBtn] = useState(true);
  const [menuOut, setMenuOut] = useState(false);
  const [organization, setOrganization] = useState([]);

  React.useEffect(() => {
    document.getElementById("root")?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  //console.log("i18n.language", i18n.language);
  const logout = () => {
    deleteMemberInformation();
    clearShoppingCart();
    store.dispatch({
      type: "REFRESH_BOOKSHELF",
      payload: {
        productList: [],
      },
    });
    navigate("/");
  };

  function navigateToSearch(searchString: string) {
    console.log("searchString", searchString);
    if (searchString === "" || searchString === null) {
      return;
    }
    navigate("search?querystring=" + searchString);
  }

  useEffect(() => {
    // const userAgent = window.navigator.userAgent
    // const domain = window.location.href
    // if(userAgent.includes('ctsandroidapp') && domain.includes("5001")){
    //   console.log("jacky test")
    //   window.location.href = process.env.REACT_APP_APP_URI!
    // }

    fetch(`${process.env.REACT_APP_BACKEND}organization/all`)
      .then((res) => res.json())
      .then((res) => setOrganization(res));

    if (window.location.pathname !== "/login") {
      let loginRedirectPathname = localStorage.getItem(LOGIN_RIDIRECT_PATHNAME);
      localStorage.removeItem(LOGIN_RIDIRECT_PATHNAME);
      if (
        loginRedirectPathname &&
        loginRedirectPathname !== undefined &&
        member.member_first_name !== null
      ) {
        navigate(loginRedirectPathname);
      }
    }

    // fake 推廣組合
    fetchCollectionCategory();

    console.log("token", token);
    if (window.location.pathname === "/") {
      if (token !== null && token !== "" && token !== undefined) {
        let [, info] = token.split(".");

        let information = JSON.parse(b64decode(info));
        console.log("information", information);
        store.dispatch({
          type: "SET_MEMBER_INFORMATION",
          member_id: information.sub,
          member_first_name: information.firstname,
          member_last_name: information.lastname,
          member_level: information.authority,
          member_email: information.email, // 無用
          token: token,
          exp: information.exp,
        });
        asyncHandleLoginShoppingCart()
          .then(() => {
            RefreshShoppingCartItem();
            listMybookShelf(store.getState().MemberReducer.member_id);
            listFavorite();
            //alert("登入成功");
            /*
                      props.setShowLoginDropdown(false);
                      props.onHide();
                      */
            //history.push("/");
          })
          .then(() => navigate("/", { replace: true }));
      } else {
        const isLogin = store.getState().MemberReducer.token !== null;
        if (isLogin == true) {
          listMybookShelf(store.getState().MemberReducer.member_id);
          listFavorite();
        }
      }
    }

    // console.log(email);
  }, []);

  const fetchCollectionCategory = async () => {
    const productByCategory =
      await displayProductAPI.getDisplayProductByCategoryId(1);

    setProduct(productByCategory.list);
  };

  const fetchGospel = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND}gospel/current?date=${
        new Date().toISOString().split("T")[0]
      }`
    )
      .then((res) => res.json())
      .then((res: any) => {
        if (i18n.language === "en") {
          setGospel(res.engContent);
          setDateString(res.engDateString);
        } else {
          setGospel(res.chnContent);
          setDateString(res.chnDateString);
        }
      })
      .catch((err) => console.log("error:", err.message));
  };

  useEffect(() => {
    fetchGospel();
  }, [i18n.language]);
  const [timerId, setTimerId] = useState(0);
  const renderCount = useRef(1);

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
  });

  useEffect(() => {
    const autoLogout = () => {
      if (document.visibilityState === "hidden") {
        // set timer to log user out
        const id = window.setTimeout(() => logout(), 1800 * 1000);
        setTimerId(id);
      } else {
        // clear existing timer
        window.clearTimeout(timerId);
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => document.removeEventListener("visibilitychange", autoLogout);
  }, [timerId]);

  const subscribeDialogCallback = (action: string) => {
    switch (action) {
      case "close":
        setShowDialog(false);
        break;
      default:
    }
  };
  return (
    <>
      <nav className={style.navbar}>
        <div className={style.upperNavbar}>
          <div className={style.upperInnerNavbar}>
            {width > 1024 ? (
              <>
                <div
                  className={style.logoContainer}
                  onClick={() => navigate("/")}
                >
                  <img className={style.logo} src={logo} alt={"description"} />
                  <h2 className={style.logoTitle}>{t("header.logoName")}</h2>
                </div>
                <div className={style.searchContainer}>
                  <div className={style.searchInnerContainer}>
                    <Input
                      type={"text"}
                      styleType={"searchInput"}
                      placeholder={"header.searchPlaceholder"}
                      callBack={(value) => setSearchString(value)}
                      onKeyDown={{ action: navigate, value: serachString }}
                    />
                    <Button
                      styleType={"searchButtom"}
                      callBack={() => navigateToSearch(serachString)}
                    >
                      <SearchIcon />
                    </Button>
                  </div>
                </div>
                <div className={style.loginContainer}>
                  {member.member_first_name !== null ? (
                    <>
                      <p
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => navigate("user")}
                      >
                        {member.member_first_name}
                      </p>
                      <p
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => logout()}
                      >
                        {t("header.logout")}
                      </p>
                    </>
                  ) : (
                    <Link to="login">
                      <Button
                        styleType={"upperNavbarButton"}
                        callBack={() =>
                          window.location.pathname !== "/login"
                            ? localStorage.setItem(
                                LOGIN_RIDIRECT_PATHNAME,
                                window.location.pathname
                              )
                            : console.log()
                        }
                      >
                        {t("header.login")}
                      </Button>
                    </Link>
                  )}
                  {member.token !== null ? (
                    <div className={style.iconContainer}>
                      <div
                        className={style.shoppingCartIcon}
                        onClick={() => navigate("shoppingCart")}
                      >
                        <img src={shoppingCartIcon} alt="" />
                        {quantity === 0 ? (
                          <></>
                        ) : (
                          <div className={style.redDot}>
                            <p style={{ fontSize: "8px", color: "white" }}>
                              {quantity}
                            </p>
                          </div>
                        )}
                      </div>
                      <img
                        src={favIcon}
                        alt=""
                        onClick={() => navigate("favourite")}
                      />
                      <img
                        src={bookshelfIcon}
                        alt=""
                        onClick={() => navigate("bookshelf")}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={style.languageContainer}>
                  <div className={style.languageInnerContainer}>
                    {Object.keys(lngs).map((lng) => (
                      <span
                        style={{
                          margin: "3px",
                          cursor: "pointer",
                          color: "rgb(20, 61, 72)",
                          fontSize: "14px",
                        }}
                        onClick={() => i18n.changeLanguage(lng)}
                      >
                        {lngs[lng as keyof ILngs].nativeName}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className={style.upper}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: searchbtn ? "contents" : "none" }}>
                    <span
                      className={style.SideBtn}
                      style={{ height: "40px" }}
                      onClick={() => setMenuOut(!menuOut)}
                    >
                      {menuOut ? "X" : "☰"}
                    </span>
                    <div
                      style={{ display: searchbtn ? "contents" : "none" }}
                      className={style.logoContainer}
                      onClick={() => navigate("/")}
                    >
                      <img
                        className={style.logo}
                        src={logo}
                        alt={"description"}
                      />
                      <h2 className={style.logoTitle}>
                        {t("header.logoName")}
                      </h2>
                    </div>
                  </div>
                </div>
                {searchbtn ? (
                  <div className={style.displayRight}>
                    <SearchIcon onClick={() => setSearchBtn(!searchbtn)} />
                  </div>
                ) : (
                  <>
                    <img
                      src={back}
                      onClick={() => setSearchBtn(!searchbtn)}
                      style={{ height: "30px" }}
                    ></img>
                    <div
                      className={style.displayRight}
                      style={{ width: "100%" }}
                    >
                      <Input
                        type={"text"}
                        styleType={"searchInput"}
                        placeholder={"header.searchPlaceholder"}
                        callBack={(value) => setSearchString(value)}
                        onKeyDown={{ action: navigate, value: serachString }}
                      />
                      <Button
                        styleType={"searchButtom"}
                        callBack={() => navigateToSearch(serachString)}
                      >
                        <SearchIcon />
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {width <= 1024 ? (
          <div className={style.smallUpperNavbar}>
            {/* <div className={style.loginContainer}> */}
            {member.member_first_name !== null ? (
              <>
                <p
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    padding: "2px",
                  }}
                  onClick={() => navigate("user")}
                >
                  {member.member_first_name}
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    padding: "2px",
                  }}
                  onClick={() => logout()}
                >
                  {t("header.logout")}
                </p>
              </>
            ) : (
              <Link to="login">
                <Button
                  styleType={"upperNavbarButton"}
                  callBack={() =>
                    window.location.pathname !== "/login"
                      ? localStorage.setItem(
                          LOGIN_RIDIRECT_PATHNAME,
                          window.location.pathname
                        )
                      : console.log()
                  }
                >
                  {t("header.login")}
                </Button>
              </Link>
            )}
            {member.token !== null ? (
              <div className={style.iconContainer}>
                <div
                  className={style.shoppingCartIcon}
                  onClick={() => navigate("shoppingCart")}
                >
                  <img src={shoppingCartIcon} alt="" />
                  {quantity === 0 ? (
                    <></>
                  ) : (
                    <div className={style.redDot}>
                      <p style={{ fontSize: "8px", color: "white" }}>
                        {quantity}
                      </p>
                    </div>
                  )}
                </div>
                <img
                  src={favIcon}
                  alt=""
                  onClick={() => navigate("favourite")}
                />
                <img
                  src={bookshelfIcon}
                  alt=""
                  onClick={() => navigate("bookshelf")}
                />
              </div>
            ) : (
              <></>
            )}
            {/* </div> */}
            {/* <div className={style.languageContainer}> */}
            <div className={style.languageInnerContainer}>
              {Object.keys(lngs).map((lng) => (
                <span
                  style={{
                    margin: "3px",
                    cursor: "pointer",
                    color: "rgb(20, 61, 72)",
                    fontSize: "14px",
                  }}
                  onClick={() => i18n.changeLanguage(lng)}
                >
                  {lngs[lng as keyof ILngs].nativeName}
                </span>
              ))}
            </div>
            {/* </div> */}
          </div>
        ) : (
          <></>
        )}

        {width <= 1024 ? (
          <div className={style.lowerNavbar}>
            <div className={style.scrollContainer}>
              <div className={style.cs_c1}>每日福音</div>
              <p className={style.scrollText} title={gospel}>
                {gospel}
              </p>
            </div>
          </div>
        ) : (
          <LowerNavBar gospel={gospel} />
        )}
        {/* <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/privates">Privates</Link>
          </li>
          {token && (
            <li>
              <Button callBack={value =>handleLogOut(value)}>logout</Button>
            </li>
          )}
        </ul>  */}
      </nav>
      <Outlet context={{ dateString }} />
      {location.pathname === "/shoppingCart" ||
      location.pathname.includes("/product") ? (
        <div className={style.relatedBookBackground}>
          <div className={style.relatedBookLayout}>
            <h3>其他推廣組合</h3>
            <div className={style.innerRealatedBookLayout}>
              {product.map((x: any) => (
                <BookCard props={x} key={x.displayProductId} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <footer className={style.footer}>
        {width > 1024 ? (
          <>
            <div className={style.footerBackground}> </div>
            <div className={style.footerLinkContainer}>
              <div className={style.footerLinkDetails}>
                <h3>{t("footer.aboutUs")}</h3>
                <pre> </pre>
                <p onClick={() => navigate("aboutus")}>
                  {t("footer.aboutCTS")}
                </p>
                <p onClick={() => navigate("PrivacyPolicy")}>
                  {t("footer.privacyPolicy")}
                </p>
                <p onClick={() => navigate("TermsOfService")}>
                  {t("footer.terms")}
                </p>
                <p onClick={() => navigate("Disclaimer")}>
                  {t("footer.disclaimer")}
                </p>
              </div>
              <div className={style.footerLinkDetails}>
                <h3>{t("footer.services")}</h3>
                <pre> </pre>
                <p onClick={() => navigate("BecomeMember")}>
                  {t("footer.becomeMember")}
                </p>
                <p onClick={() => navigate("beginnerGuide")}>
                  {t("footer.beginner")}
                </p>
                <p onClick={() => setShowDialog(true)}>
                  {t("footer.subscribe")}
                </p>
              </div>
              <div className={style.footerLinkDetails}>
                <h3>{t("footer.collaborate")}</h3>
                <pre> </pre>
                <p onClick={() => navigate("joinUs")}>{t("footer.joinUs")}</p>
                <p onClick={() => navigate("customer")}>
                  {t("footer.customer")}
                </p>
                <p onClick={() => navigate("AccountRecovery")}>
                  {t("footer.account")}
                </p>
                <p onClick={() => navigate("customerService")}>
                  {t("footer.customerQ")}
                </p>
              </div>
              <div className={style.footerLinkDetails}>
                <h3>{t("footer.links")}</h3>
                <pre> </pre>
                <p
                  onClick={() =>
                    (window.location.href =
                      "https://www.facebook.com/CatholicTruthSocietyofHongKong")
                  }
                >
                  香港公教真理學會Facebook
                </p>
                <p
                  onClick={() =>
                    (window.location.href = "https://wa.me/+85294800113")
                  }
                >
                  香港公教真理學會Whatsapp Group
                </p>
                <p
                  onClick={() =>
                    (window.location.href = "https://cts.catholic.org.hk/")
                  }
                >
                  香港公教真理學會網站
                </p>
                <p
                  onClick={() =>
                    (window.location.href =
                      "https://www.instagram.com/catholictruthsocietyofhk/?igshid=YmMyMTA2M2Y%3D")
                  }
                >
                  香港公教真理學會Instagram
                </p>
              </div>
              <div className={style.footerIconContainer}>
                <div className={style.footerMainIconContainer}>
                  <img style={{ cursor: "pointer" }} src={apple} />
                  <img style={{ cursor: "pointer" }} src={google} />
                </div>
                <div className={style.footerSecondIconContainer}>
                  <img
                    src={fbIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.facebook.com/CatholicTruthSocietyofHongKong")
                    }
                  />
                  <img
                    src={igIcon}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.instagram.com/catholictruthsocietyofhk/?igshid=YmMyMTA2M2Y%3D")
                    }
                  />
                  <img
                    src={waIcon}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      (window.location.href = "https://wa.me/+85294800113")
                    }
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={style.footerIconContainer}>
            <div className={style.footerMainIconContainer}>
              <img style={{ cursor: "pointer" }} src={apple} />
              <img style={{ cursor: "pointer" }} src={google} />
            </div>
            <div className={style.footerSecondIconContainer}>
              <img
                src={fbIcon}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href =
                    "https://www.facebook.com/CatholicTruthSocietyofHongKong")
                }
              />
              <img
                src={igIcon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href =
                    "https://www.instagram.com/catholictruthsocietyofhk/?igshid=YmMyMTA2M2Y%3D")
                }
              />
              <img
                src={waIcon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = "https://wa.me/+85294800113")
                }
              />
            </div>
          </div>
        )}
        <div className={style.footerImg}>
          {organization.map((org: any) =>
            org.logoUrl ? (
              <img
                src={`${process.env.REACT_APP_CMS_BACKEND_URL}/cts_backend/${org.logoUrl}`}
                className={style.footerImgSmall}
                onClick={() => {
                  if (org.webUrl) window.location.replace(org.webUrl);
                }}
              />
            ) : (
              <></>
            )
          )}
          {/* <div className={style.footerImgContainer}>
            <img src={footer03} className={style.footerImgSmall}></img>
            <img src={footer04} className={style.footerImgSmall}></img>
            <img src={footer05} className={style.footerImgLong}></img>
            <img src={footer06} className={style.footerImgSmall}></img>
            <img src={footer07} className={style.footerImgSmall}></img>
            <img src={footer08} className={style.footerImgLong}></img>
            <img src={footer09} className={style.footerImgSmall}></img>
            <img src={footer10} className={style.footerImgSmall}></img>
            <img src={footer11} className={style.footerImgSmall}></img>
            <img src={footer14} className={style.footerImgSmall}></img>
            <img src={footer15} className={style.footerImgSmall}></img>
            <img src={footer16} className={style.footerImgSmall}></img>
            <img src={footer17} className={style.footerImgSmall}></img>
            <img src={footer19} className={style.footerImgSmall}></img>
            <img src={footer20} className={style.footerImgSmall}></img>
            <img src={footer21} className={style.footerImgSmall}></img>
            <img src={footer22} className={style.footerImgSmall}></img>
            <img src={footer23} className={style.footerImgSmall}></img>
            <img src={footer24} className={style.footerImgSmall}></img>
            <img src={footer25} className={style.footerImgSmall}></img>
          </div>
          <center>
              <img src={footer28} className={style.footerImgLong}></img>
              <img src={footer29} className={style.footerImgLong}></img>
              <img src={footer30} className={style.footerImgLong}></img>
              <img src={footer31} className={style.footerImgLong}></img>
              <img src={footer32} className={style.footerImgLong}></img>
          </center> */}
        </div>
        {/*<img src={footer} className={style.footerImg}></img>*/}
        <p className={style.copyright}>
          Copyright &copy;{t("footer.CTS")} 2024 All Rights Reserved.
        </p>
      </footer>

      <DialogBox
        styleType={STYLETYPE_SECOND}
        showDialog={showDialog}
        title={t("footer.subscribe")}
        scroll={false}
      >
        <Subscribe callback={subscribeDialogCallback} />
      </DialogBox>
      <div style={{ display: menuOut ? "contents" : "none" }}>
        <SideMenu menuOut={menuOut} setMenuOut={setMenuOut} />
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  //console.log("state", state);
  return {
    member: state.MemberReducer,
    quantity: state.shoppingCartReducer.quantity,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    deleteMemberInformation: () => {
      dispatch(deleteMemberInformation());
    },

    clearShoppingCart: () => dispatch(clearShoppingCart()),
  };
}

export function useDateString() {
  return useOutletContext<ContextType>();
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
