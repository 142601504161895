/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDto,
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';
import {
    ProductCommentDto,
    ProductCommentDtoFromJSON,
    ProductCommentDtoFromJSONTyped,
    ProductCommentDtoToJSON,
} from './ProductCommentDto';
import {
    ProductPointDto,
    ProductPointDtoFromJSON,
    ProductPointDtoFromJSONTyped,
    ProductPointDtoToJSON,
} from './ProductPointDto';

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    aboutAuthor?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    aboutSpeaker?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    bookIsbn?: string;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof ProductDto
     */
    categories?: Array<CategoryDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    coverFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    deposit?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    displayProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    ePubPreviewFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    ePubProdFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    episode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    favourite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    isGroupProduct?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDto
     */
    multimediaFileNames?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof ProductDto
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductDto
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductDto
     */
    pPublishDate?: Date;
    /**
     * 
     * @type {Array<ProductPointDto>}
     * @memberof ProductDto
     */
    points?: Array<ProductPointDto>;
    /**
     * 
     * @type {Array<ProductCommentDto>}
     * @memberof ProductDto
     */
    productComments?: Array<ProductCommentDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productHashtag?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDto
     */
    productImage?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productInventory?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productStep?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productVolumeUnit?: string;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof ProductDto
     */
    products?: Array<ProductDto>;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof ProductDto
     */
    publishHouse?: OrganizationDto;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    rating?: number;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof ProductDto
     */
    relatedProducts?: Array<ProductDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    restrictedContent?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    sales?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    sapArticleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    seriesName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    seriesNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    speaker?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    subscriptionPeriod?: string;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof ProductDto
     */
    supplier?: OrganizationDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDto
     */
    thumbFileNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    totalDuration?: string;
}

export function ProductDtoFromJSON(json: any): ProductDto {
    return ProductDtoFromJSONTyped(json, false);
}

export function ProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aboutAuthor': !exists(json, 'aboutAuthor') ? undefined : json['aboutAuthor'],
        'aboutSpeaker': !exists(json, 'aboutSpeaker') ? undefined : json['aboutSpeaker'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'bookIsbn': !exists(json, 'bookIsbn') ? undefined : json['bookIsbn'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(CategoryDtoFromJSON)),
        'coverFileName': !exists(json, 'coverFileName') ? undefined : json['coverFileName'],
        'deposit': !exists(json, 'deposit') ? undefined : json['deposit'],
        'displayProductId': !exists(json, 'displayProductId') ? undefined : json['displayProductId'],
        'ePubPreviewFileName': !exists(json, 'ePubPreviewFileName') ? undefined : json['ePubPreviewFileName'],
        'ePubProdFileName': !exists(json, 'ePubProdFileName') ? undefined : json['ePubProdFileName'],
        'episode': !exists(json, 'episode') ? undefined : json['episode'],
        'favourite': !exists(json, 'favourite') ? undefined : json['favourite'],
        'isGroupProduct': !exists(json, 'isGroupProduct') ? undefined : json['isGroupProduct'],
        'multimediaFileNames': !exists(json, 'multimediaFileNames') ? undefined : json['multimediaFileNames'],
        'offShelfDate': !exists(json, 'offShelfDate') ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': !exists(json, 'onShelfDate') ? undefined : (new Date(json['onShelfDate'])),
        'pPublishDate': !exists(json, 'pPublishDate') ? undefined : (new Date(json['pPublishDate'])),
        'points': !exists(json, 'points') ? undefined : ((json['points'] as Array<any>).map(ProductPointDtoFromJSON)),
        'productComments': !exists(json, 'productComments') ? undefined : ((json['productComments'] as Array<any>).map(ProductCommentDtoFromJSON)),
        'productDescription': !exists(json, 'productDescription') ? undefined : json['productDescription'],
        'productDiscountPrice': !exists(json, 'productDiscountPrice') ? undefined : json['productDiscountPrice'],
        'productHashtag': !exists(json, 'productHashtag') ? undefined : json['productHashtag'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productImage': !exists(json, 'productImage') ? undefined : json['productImage'],
        'productInventory': !exists(json, 'productInventory') ? undefined : json['productInventory'],
        'productNameChi': !exists(json, 'productNameChi') ? undefined : json['productNameChi'],
        'productNameEng': !exists(json, 'productNameEng') ? undefined : json['productNameEng'],
        'productPrice': !exists(json, 'productPrice') ? undefined : json['productPrice'],
        'productStep': !exists(json, 'productStep') ? undefined : json['productStep'],
        'productVolume': !exists(json, 'productVolume') ? undefined : json['productVolume'],
        'productVolumeUnit': !exists(json, 'productVolumeUnit') ? undefined : json['productVolumeUnit'],
        'products': !exists(json, 'products') ? undefined : ((json['products'] as Array<any>).map(ProductDtoFromJSON)),
        'publishHouse': !exists(json, 'publishHouse') ? undefined : OrganizationDtoFromJSON(json['publishHouse']),
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'relatedProducts': !exists(json, 'relatedProducts') ? undefined : ((json['relatedProducts'] as Array<any>).map(ProductDtoFromJSON)),
        'restrictedContent': !exists(json, 'restrictedContent') ? undefined : json['restrictedContent'],
        'sales': !exists(json, 'sales') ? undefined : json['sales'],
        'sapArticleNumber': !exists(json, 'sapArticleNumber') ? undefined : json['sapArticleNumber'],
        'seriesName': !exists(json, 'seriesName') ? undefined : json['seriesName'],
        'seriesNumber': !exists(json, 'seriesNumber') ? undefined : json['seriesNumber'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'speaker': !exists(json, 'speaker') ? undefined : json['speaker'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'subscriptionPeriod': !exists(json, 'subscriptionPeriod') ? undefined : json['subscriptionPeriod'],
        'supplier': !exists(json, 'supplier') ? undefined : OrganizationDtoFromJSON(json['supplier']),
        'thumbFileNames': !exists(json, 'thumbFileNames') ? undefined : json['thumbFileNames'],
        'totalDuration': !exists(json, 'totalDuration') ? undefined : json['totalDuration'],
    };
}

export function ProductDtoToJSON(value?: ProductDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aboutAuthor': value.aboutAuthor,
        'aboutSpeaker': value.aboutSpeaker,
        'author': value.author,
        'bookIsbn': value.bookIsbn,
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(CategoryDtoToJSON)),
        'coverFileName': value.coverFileName,
        'deposit': value.deposit,
        'displayProductId': value.displayProductId,
        'ePubPreviewFileName': value.ePubPreviewFileName,
        'ePubProdFileName': value.ePubProdFileName,
        'episode': value.episode,
        'favourite': value.favourite,
        'isGroupProduct': value.isGroupProduct,
        'multimediaFileNames': value.multimediaFileNames,
        'offShelfDate': value.offShelfDate === undefined ? undefined : (value.offShelfDate.toISOString()),
        'onShelfDate': value.onShelfDate === undefined ? undefined : (value.onShelfDate.toISOString()),
        'pPublishDate': value.pPublishDate === undefined ? undefined : (value.pPublishDate.toISOString()),
        'points': value.points === undefined ? undefined : ((value.points as Array<any>).map(ProductPointDtoToJSON)),
        'productComments': value.productComments === undefined ? undefined : ((value.productComments as Array<any>).map(ProductCommentDtoToJSON)),
        'productDescription': value.productDescription,
        'productDiscountPrice': value.productDiscountPrice,
        'productHashtag': value.productHashtag,
        'productId': value.productId,
        'productImage': value.productImage,
        'productInventory': value.productInventory,
        'productNameChi': value.productNameChi,
        'productNameEng': value.productNameEng,
        'productPrice': value.productPrice,
        'productStep': value.productStep,
        'productVolume': value.productVolume,
        'productVolumeUnit': value.productVolumeUnit,
        'products': value.products === undefined ? undefined : ((value.products as Array<any>).map(ProductDtoToJSON)),
        'publishHouse': OrganizationDtoToJSON(value.publishHouse),
        'rating': value.rating,
        'relatedProducts': value.relatedProducts === undefined ? undefined : ((value.relatedProducts as Array<any>).map(ProductDtoToJSON)),
        'restrictedContent': value.restrictedContent,
        'sales': value.sales,
        'sapArticleNumber': value.sapArticleNumber,
        'seriesName': value.seriesName,
        'seriesNumber': value.seriesNumber,
        'shortDescription': value.shortDescription,
        'speaker': value.speaker,
        'status': value.status,
        'subscriptionPeriod': value.subscriptionPeriod,
        'supplier': OrganizationDtoToJSON(value.supplier),
        'thumbFileNames': value.thumbFileNames,
        'totalDuration': value.totalDuration,
    };
}

