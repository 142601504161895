import {g, p} from './BasicAPI';

export function getCurrentMemberOrder()
{
    return g('/order/member');
}

export function getMemberOrder(id)
{
    return g(`/order/member/${id}`);
}

export function getGuestOrder(id)
{
    return g(`/order/guest/${id}`);
}

export function checkOrder(orderNo, phone)
{
    return p('/order/checking', {
        orderNo,
        phone,
    });
}

export function addExpressOrder(
	countryId,
	shippingMethod,
	pointToCash,
	productId,
	quantity,
	deliveryTimes,
	orderAddress,
	orderAddressRecipient,
	orderAddressProvince,
	orderAddressCity,
	orderAddressZone,
	orderAddress1,
	orderAddress2,
	orderAddress3,
	orderAddress4,
	phone
)
{
    return p('/order/member/add',{
        countryId, 
        shippingMethod, 
        pointToCash,
        productId, 
        quantity, 
        deliveryTimes, 
        orderAddressRecipient,
		orderAddressProvince,
		orderAddressCity,
		orderAddressZone,
        orderAddress1, 
        orderAddress2,
        orderAddress3, 
        orderAddress4,
        orderAddress,
        phone,
    });
}


export function addOrder(
	countryId,
	shippingMethod,
	pointToCash,
	productId,
	quantity,
	deliveryTimes,
	orderAddress,
	orderAddressRecipient,
	orderAddressProvince,
	orderAddressCity,
	orderAddressZone,
	orderAddress1,
	orderAddress2,
	orderAddress3,
	orderAddress4,
	phone
)
{
    return p('/order/add',{
        countryId, 
        shippingMethod, 
        pointToCash,
        productId, 
        quantity, 
        deliveryTimes, 
        orderAddressRecipient, 
		orderAddressProvince,
		orderAddressCity,
		orderAddressZone,
        orderAddress1, 
        orderAddress2,
        orderAddress3, 
        orderAddress4,
        orderAddress,
        phone,
    });
}

export function addGuestOrder(
    countryId, 
    shippingMethod, 
    orderAddress,
    orderAddressRecipient,
	orderAddressProvince,
	orderAddressCity,
	orderAddressZone,
    orderAddress1,
    orderAddress2,
    orderAddress3,
    orderAddress4,
    phone,
    productListJson,
    couponCode)
{
    return p('/order/guest/add',{
        countryId, 
        shippingMethod, 
        orderAddress,
        orderAddressRecipient,
		orderAddressProvince,
		orderAddressCity,
		orderAddressZone,
        orderAddress1, 
        orderAddress2,
        orderAddress3, 
        orderAddress4,
        phone,
        productListJson,
        couponCode,
    });
}

export function calcGuestExpressAmount(productListJson, promotionCode)
{
    return p('/order/guest/pre', {
        productListJson,
        promotionCode,
    });
}

export function calcGuestExpressAmount2(productListJson, promotionCode)
{
    return p('/order/guest/pre2', {
        productListJson,
        promotionCode,
    });
}

export function calcMemberExpressAmount(productId, quantity, deliveryTimes, promotionCode)
{
    return p('/order/member/pre', {
        productId,
        quantity,
        deliveryTimes,
        promotionCode,
    });
}

export function getMemberOrderPointRecords()
{
    return g('/order/member/pointRecord');
}

export function handleOrderPayment(orderId, paymentMethod, orderStatus)
{
    return g('/order/success/' + orderId + '/' + paymentMethod + '/' + orderStatus);
}