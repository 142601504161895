//图书样式设置的下拉菜单页面
import React from "react";
import { isElectron } from "react-device-detect";
import { connect } from "react-redux";
import { dropdownList } from "../../../../constants/reader/dropdownList";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import "./dropdownList.css";

import { Trans, Translation } from "react-i18next";

class DropdownListSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFontFamilyIndex: dropdownList
        .filter((item) => item.value === "fontFamily")[0]
        .option.findIndex((item) => {
          return (
            item ===
            (StorageUtil.getReaderConfig("fontFamily") || "Built-in font")
          );
        }),
      currentLineHeightIndex: dropdownList
        .filter((item) => item.value === "lineHeight")[0]
        .option.findIndex((item) => {
          return item === (StorageUtil.getReaderConfig("lineHeight") || "1.25");
        }),
      currentTextAlignIndex: dropdownList
        .filter((item) => item.value === "textAlign")[0]
        .option.findIndex((item) => {
          return item === (StorageUtil.getReaderConfig("textAlign") || "left");
        }),
      chineseConversionIndex: dropdownList
        .filter((item) => item.value === "convertChinese")[0]
        .option.findIndex((item) => {
          return (
            item ===
            (StorageUtil.getReaderConfig("convertChinese") || "Default")
          );
        }),
      currentFontSizeIndex: dropdownList
        .filter((item) => item.value === "fontSize")[0]
        .option.findIndex((item) => {
          return item === (StorageUtil.getReaderConfig("fontSize") || "16");
        }),
    };
  }
  componentDidMount() {
    //使下拉菜单选中预设的值
    if (isElectron) {
      const fontList = window.require("font-list");
      fontList.getFonts({ disableQuoting: true }).then((result) => {
        if (!result || result.length === 0) return;
        dropdownList.filter((item) => item.value === "fontFamily")[0].option =
          result;
        dropdownList
          .filter((item) => item.value === "fontFamily")[0]
          .option.push("Built-in font");
        this.setState(
          {
            currentFontFamilyIndex: dropdownList
              .filter((item) => item.value === "fontFamily")[0]
              .option.findIndex((item) => {
                return (
                  item ===
                  (StorageUtil.getReaderConfig("fontFamily") || "Built-in font")
                );
              }),
          },
          () => {
            document
              .querySelector(
                ".paragraph-character-container[itemvalue=fontFamily] .general-setting-dropdown"
              )
              .children[
                this.state.currentFontFamilyIndex === -1
                  ? 0
                  : this.state.currentFontFamilyIndex
              ]?.setAttribute("selected", "selected");
          }
        );
      });
    }

    try {
      document
        .querySelector(
          ".paragraph-character-container[itemvalue=lineHeight] .general-setting-dropdown"
        )
        .children[this.state.currentLineHeightIndex]?.setAttribute(
          "selected",
          "selected"
        );
    } catch (err) {
      console.log("err");
    }
    try {
      document
        .querySelector(
          ".paragraph-character-container[itemvalue=textAlign] .general-setting-dropdown"
        )
        .children[this.state.currentTextAlignIndex]?.setAttribute(
          "selected",
          "selected"
        );
    } catch (err) {
      console.log("err");
    }
    try {
      document
        .querySelector(
          ".paragraph-character-container[itemvalue=convertChinese] .general-setting-dropdown"
        )
        .children[this.state.chineseConversionIndex]?.setAttribute(
          "selected",
          "selected"
        );
    } catch (err) {
      console.log("err");
    }
    try {
      document
        .querySelector(
          ".paragraph-character-container[itemvalue=fontSize] .general-setting-dropdown"
        )
        .children[this.state.currentFontSizeIndex]?.setAttribute(
          "selected",
          "selected"
        );
    } catch (err) {
      console.log("err", err);
    }
  }

  //切换不同的样式
  handleView(event, option) {
    let arr = event.target.value.split(",");
    StorageUtil.setReaderConfig(option, arr[0]);
    switch (option) {
      case "fontFamily":
        this.setState({
          currentFontFamilyIndex: arr[1],
        });

        break;

      case "lineHeight":
        this.setState({
          currentLineHeightIndex: arr[1],
        });

        break;
      case "textAlign":
        this.setState({
          currentTextAlignIndex: arr[1],
        });

        break;
      case "convertChinese":
        this.setState({
          chineseConversionIndex: arr[1],
        });
      case "fontSize":
        this.setState({
          currentFontSizeIndex: arr[1],
        });

        break;
      default:
        break;
    }
    this.props.renderFunc();
  }

  tranTitle(_name) {
    return <Translation>{(t, { i18n }) => <>{t(_name)}</>}</Translation>;
    return _name;
  }

  render() {
    //console.log('asdfdas', this.props)
    const renderParagraphCharacter = () => {
      return dropdownList
        .filter((item) => {
          if (item.value === this.props.propName) {
            return true;
          }
          return false;
        })
        .map((item) => {
          let _isHideStyle = "";
          if (item.value === "fontSize") {
            let _isApplyFont = StorageUtil.getReaderConfig("isApplyFont");
            if (_isApplyFont === undefined) {
              _isHideStyle = "hide";
            } else if (_isApplyFont === "yes") {
            } else if (_isApplyFont === "no") {
              _isHideStyle = "hide";
            }
          }
          return (
            <li
              key={item.id}
              className={["paragraph-character-container", _isHideStyle].join(
                " "
              )}
              itemvalue={item.value}
            >
              {/*
            <p className="general-setting-title">
              {item.title}
            </p>
            */}
              <p className="general-setting-title">
                {this.tranTitle(item.title)}
              </p>
              <select
                name=""
                className="general-setting-dropdown"
                onChange={(event) => {
                  this.handleView(event, item.value);
                }}
              >
                {item.option.map((subItem, index) => (
                  <option
                    value={[subItem, index.toString()]}
                    key={index}
                    className="general-setting-option"
                  >
                    {subItem}
                    {/* {this.props.t(subItem)} */}
                  </option>
                ))}
              </select>
            </li>
          );
        });
    };

    return (
      <ul className="paragraph-character-setting">
        {renderParagraphCharacter()}
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    renderFunc: state.book.renderFunc,
  };
};
const actionCreator = {};
export default connect(mapStateToProps, actionCreator)(DropdownListSingle);
