const initialState = {
  productList: [],
};

function MyBookShelfReducer(state = initialState, action) {
  if (action.type == "REFRESH_BOOKSHELF") {
    return action.payload;
  }
  return state;
}

export default MyBookShelfReducer;
