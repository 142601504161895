/* tslint:disable */
/* eslint-disable */
/**
 * My REST API
 * Some custom description of API.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jason.tai@octopus-tech.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegerListRequestModel,
    IntegerListRequestModelFromJSON,
    IntegerListRequestModelToJSON,
    MarketingEventDto,
    MarketingEventDtoFromJSON,
    MarketingEventDtoToJSON,
    ProductDto,
    ProductDtoFromJSON,
    ProductDtoToJSON,
    ProductRequestModel,
    ProductRequestModelFromJSON,
    ProductRequestModelToJSON,
} from '../models';

export interface AddProductInventoryUsingPOSTRequest {
    productId: number;
    num: number;
    name?: string;
}

export interface AddProductPointUsingPOSTRequest {
    productId: number;
    point: number;
    money: number;
    name?: string;
}

export interface AddProductUsingPOSTRequest {
    productNameChi: string;
    productDescription: string;
    isGroupProduct: string;
    productPrice: number;
    sapArticleNumber: string;
    author: string;
    seriesName: string;
    seriesNumber: number;
    supplierId: number;
    publishHouseId: number;
    name?: string;
    productNameEng?: string;
    categoryIds?: number;
    onShelfDate?: Date;
    offShelfDate?: Date;
    productInventory?: number;
    subscriptionPeriod?: string;
    productVolume?: string;
    productVolumeUnit?: string;
    productImage?: string;
    productIds?: number;
    bookIsbn?: string;
    restrictedContent?: string;
    speaker?: string;
    totalDuration?: string;
    episode?: number;
    pPublishDate?: Date;
    shortDescription?: string;
    aboutAuthor?: string;
    aboutSpeaker?: string;
    body?: Blob;
}

export interface AddProductUsingPOST1Request {
    name?: string;
    productRequestModel?: ProductRequestModel;
}

export interface CheckProductExistsUsingGETRequest {
    sapArticleNumber: string;
    name?: string;
}

export interface DeleteProductPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
}

export interface DeleteProductUsingPOST2Request {
    productId: number;
    name?: string;
}

export interface DisableProductPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
}

export interface EnableProductPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
}

export interface GetPagedProductAdminUsingGETRequest {
    page: string;
    size: string;
    name?: string;
}

export interface GetPagedProductByCategoryIdExtraUsingGETRequest {
    categoryId: number;
    page?: number;
    size?: number;
    bookName?: string;
    author?: string;
    bookDescription?: string;
    fromYear?: number;
    toYear?: number;
    organizationId?: number;
}

export interface GetPagedProductByCategoryIdUsingGETRequest {
    categoryId: number;
    page?: number;
    size?: number;
    keyword?: string;
    year?: number;
    organizationId?: number;
}

export interface GetPagedProductBySearchingQueryExtraUsingGETRequest {
    page?: number;
    size?: number;
    bookName?: string;
    author?: string;
    bookDescription?: string;
    fromYear?: number;
    toYear?: number;
    organizationId?: number;
    categoryId?: number;
}

export interface GetPagedProductBySearchingQueryUsingGETRequest {
    page?: number;
    size?: number;
    keyword?: string;
    year?: number;
    organizationId?: number;
    categoryId?: number;
}

export interface GetPagedProductUsingGETRequest {
    page: string;
    size: string;
}

export interface GetPagedProductWithPointAdminUsingGETRequest {
    page: string;
    size: string;
    name?: string;
}

export interface GetPagedProductWithPointUsingGETRequest {
    page: string;
    size: string;
}

export interface GetProductByCategoryIdAdminUsingGETRequest {
    categoryId: number;
    name?: string;
}

export interface GetProductByGroupProductIdAdminUsingGETRequest {
    groupProductId: number;
    name?: string;
}

export interface GetProductByGroupProductIdUsingGETRequest {
    groupProductId: number;
}

export interface GetProductByProductIdAdminUsingGETRequest {
    productId: number;
    name?: string;
}

export interface GetProductByProductIdUsingGETRequest {
    productId: number;
    memberId?: number;
    productCommentPage?: number;
    productCommentSize?: number;
}

export interface GetProductBySkuWithParameterAdminUsingGETRequest {
    sapArticleNumber: string;
    name?: string;
}

export interface GetProductBySkuWithParameterUsingGETRequest {
    sku: string;
}

export interface GetProductBySkuWithPathVariableAdminUsingGETRequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithPathVariableUsingGETRequest {
    sku: string;
}

export interface ListBelongedMarketingEventUsingGETRequest {
    productId: number;
    name?: string;
}

export interface UpdateProductInventoryUsingPOSTRequest {
    productId: number;
    num: number;
    name?: string;
}

export interface UpdateProductPriceUsingPOSTRequest {
    productId: number;
    price: number;
    name?: string;
}

export interface UpdateProductStatusAdminUsingGETRequest {
    productId: number;
    name?: string;
}

export interface UpdateProductUsingPATCHRequest {
    productId: number;
    name?: string;
    productRequestModel?: ProductRequestModel;
}

export interface UpdateProductUsingPOSTRequest {
    productId: number;
    name?: string;
    productNameChi?: string;
    productNameEng?: string;
    productDescription?: string;
    isGroupProduct?: string;
    productPrice?: number;
    productInventory?: number;
    subscriptionPeriod?: string;
    productVolume?: string;
    productVolumeUnit?: string;
    productImage?: string;
    productIds?: number;
    sapArticleNumber?: string;
    bookIsbn?: string;
    restrictedContent?: string;
    author?: string;
    speaker?: string;
    seriesName?: string;
    seriesNumber?: number;
    totalDuration?: string;
    episode?: number;
    supplierId?: number;
    publishHouseId?: number;
    shortDescription?: string;
    aboutAuthor?: string;
    aboutSpeaker?: string;
    integerListRequestModel?: IntegerListRequestModel;
}

/**
 * 
 */
export class ProductControllerApi extends runtime.BaseAPI {

    /**
     * addProductInventory
     */
    async addProductInventoryUsingPOSTRaw(requestParameters: AddProductInventoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling addProductInventoryUsingPOST.');
        }

        if (requestParameters.num === null || requestParameters.num === undefined) {
            throw new runtime.RequiredError('num','Required parameter requestParameters.num was null or undefined when calling addProductInventoryUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.num !== undefined) {
            queryParameters['num'] = requestParameters.num;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/{productId}/addInventory`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * addProductInventory
     */
    async addProductInventoryUsingPOST(requestParameters: AddProductInventoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.addProductInventoryUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * addProductPoint
     */
    async addProductPointUsingPOSTRaw(requestParameters: AddProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling addProductPointUsingPOST.');
        }

        if (requestParameters.point === null || requestParameters.point === undefined) {
            throw new runtime.RequiredError('point','Required parameter requestParameters.point was null or undefined when calling addProductPointUsingPOST.');
        }

        if (requestParameters.money === null || requestParameters.money === undefined) {
            throw new runtime.RequiredError('money','Required parameter requestParameters.money was null or undefined when calling addProductPointUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.point !== undefined) {
            queryParameters['point'] = requestParameters.point;
        }

        if (requestParameters.money !== undefined) {
            queryParameters['money'] = requestParameters.money;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/point/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * addProductPoint
     */
    async addProductPointUsingPOST(requestParameters: AddProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.addProductPointUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * addProduct
     */
    async addProductUsingPOSTRaw(requestParameters: AddProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productNameChi === null || requestParameters.productNameChi === undefined) {
            throw new runtime.RequiredError('productNameChi','Required parameter requestParameters.productNameChi was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.productDescription === null || requestParameters.productDescription === undefined) {
            throw new runtime.RequiredError('productDescription','Required parameter requestParameters.productDescription was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.isGroupProduct === null || requestParameters.isGroupProduct === undefined) {
            throw new runtime.RequiredError('isGroupProduct','Required parameter requestParameters.isGroupProduct was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.productPrice === null || requestParameters.productPrice === undefined) {
            throw new runtime.RequiredError('productPrice','Required parameter requestParameters.productPrice was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.sapArticleNumber === null || requestParameters.sapArticleNumber === undefined) {
            throw new runtime.RequiredError('sapArticleNumber','Required parameter requestParameters.sapArticleNumber was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.author === null || requestParameters.author === undefined) {
            throw new runtime.RequiredError('author','Required parameter requestParameters.author was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.seriesName === null || requestParameters.seriesName === undefined) {
            throw new runtime.RequiredError('seriesName','Required parameter requestParameters.seriesName was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.seriesNumber === null || requestParameters.seriesNumber === undefined) {
            throw new runtime.RequiredError('seriesNumber','Required parameter requestParameters.seriesNumber was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.supplierId === null || requestParameters.supplierId === undefined) {
            throw new runtime.RequiredError('supplierId','Required parameter requestParameters.supplierId was null or undefined when calling addProductUsingPOST.');
        }

        if (requestParameters.publishHouseId === null || requestParameters.publishHouseId === undefined) {
            throw new runtime.RequiredError('publishHouseId','Required parameter requestParameters.publishHouseId was null or undefined when calling addProductUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productNameChi !== undefined) {
            queryParameters['productNameChi'] = requestParameters.productNameChi;
        }

        if (requestParameters.productNameEng !== undefined) {
            queryParameters['productNameEng'] = requestParameters.productNameEng;
        }

        if (requestParameters.categoryIds !== undefined) {
            queryParameters['categoryIds'] = requestParameters.categoryIds;
        }

        if (requestParameters.productDescription !== undefined) {
            queryParameters['productDescription'] = requestParameters.productDescription;
        }

        if (requestParameters.isGroupProduct !== undefined) {
            queryParameters['isGroupProduct'] = requestParameters.isGroupProduct;
        }

        if (requestParameters.onShelfDate !== undefined) {
            queryParameters['onShelfDate'] = (requestParameters.onShelfDate as any).toISOString();
        }

        if (requestParameters.offShelfDate !== undefined) {
            queryParameters['offShelfDate'] = (requestParameters.offShelfDate as any).toISOString();
        }

        if (requestParameters.productPrice !== undefined) {
            queryParameters['productPrice'] = requestParameters.productPrice;
        }

        if (requestParameters.productInventory !== undefined) {
            queryParameters['productInventory'] = requestParameters.productInventory;
        }

        if (requestParameters.subscriptionPeriod !== undefined) {
            queryParameters['subscriptionPeriod'] = requestParameters.subscriptionPeriod;
        }

        if (requestParameters.productVolume !== undefined) {
            queryParameters['productVolume'] = requestParameters.productVolume;
        }

        if (requestParameters.productVolumeUnit !== undefined) {
            queryParameters['productVolumeUnit'] = requestParameters.productVolumeUnit;
        }

        if (requestParameters.productImage !== undefined) {
            queryParameters['product_image'] = requestParameters.productImage;
        }

        if (requestParameters.productIds !== undefined) {
            queryParameters['productIds'] = requestParameters.productIds;
        }

        if (requestParameters.sapArticleNumber !== undefined) {
            queryParameters['sapArticleNumber'] = requestParameters.sapArticleNumber;
        }

        if (requestParameters.bookIsbn !== undefined) {
            queryParameters['bookIsbn'] = requestParameters.bookIsbn;
        }

        if (requestParameters.restrictedContent !== undefined) {
            queryParameters['restrictedContent'] = requestParameters.restrictedContent;
        }

        if (requestParameters.author !== undefined) {
            queryParameters['author'] = requestParameters.author;
        }

        if (requestParameters.speaker !== undefined) {
            queryParameters['speaker'] = requestParameters.speaker;
        }

        if (requestParameters.seriesName !== undefined) {
            queryParameters['seriesName'] = requestParameters.seriesName;
        }

        if (requestParameters.seriesNumber !== undefined) {
            queryParameters['seriesNumber'] = requestParameters.seriesNumber;
        }

        if (requestParameters.totalDuration !== undefined) {
            queryParameters['totalDuration'] = requestParameters.totalDuration;
        }

        if (requestParameters.episode !== undefined) {
            queryParameters['episode'] = requestParameters.episode;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplierId'] = requestParameters.supplierId;
        }

        if (requestParameters.publishHouseId !== undefined) {
            queryParameters['publishHouseId'] = requestParameters.publishHouseId;
        }

        if (requestParameters.pPublishDate !== undefined) {
            queryParameters['pPublishDate'] = (requestParameters.pPublishDate as any).toISOString();
        }

        if (requestParameters.shortDescription !== undefined) {
            queryParameters['shortDescription'] = requestParameters.shortDescription;
        }

        if (requestParameters.aboutAuthor !== undefined) {
            queryParameters['aboutAuthor'] = requestParameters.aboutAuthor;
        }

        if (requestParameters.aboutSpeaker !== undefined) {
            queryParameters['aboutSpeaker'] = requestParameters.aboutSpeaker;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * addProduct
     */
    async addProductUsingPOST(requestParameters: AddProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.addProductUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * addProduct
     */
    async addProductUsingPOST1Raw(requestParameters: AddProductUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/add-body`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductRequestModelToJSON(requestParameters.productRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * addProduct
     */
    async addProductUsingPOST1(requestParameters: AddProductUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.addProductUsingPOST1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkProductExists
     */
    async checkProductExistsUsingGETRaw(requestParameters: CheckProductExistsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.sapArticleNumber === null || requestParameters.sapArticleNumber === undefined) {
            throw new runtime.RequiredError('sapArticleNumber','Required parameter requestParameters.sapArticleNumber was null or undefined when calling checkProductExistsUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.sapArticleNumber !== undefined) {
            queryParameters['sapArticleNumber'] = requestParameters.sapArticleNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/check-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * checkProductExists
     */
    async checkProductExistsUsingGET(requestParameters: CheckProductExistsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.checkProductExistsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteProductPoint
     */
    async deleteProductPointUsingPOSTRaw(requestParameters: DeleteProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling deleteProductPointUsingPOST.');
        }

        if (requestParameters.productPointId === null || requestParameters.productPointId === undefined) {
            throw new runtime.RequiredError('productPointId','Required parameter requestParameters.productPointId was null or undefined when calling deleteProductPointUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.productPointId !== undefined) {
            queryParameters['productPointId'] = requestParameters.productPointId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/point/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * deleteProductPoint
     */
    async deleteProductPointUsingPOST(requestParameters: DeleteProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.deleteProductPointUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST2Raw(requestParameters: DeleteProductUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling deleteProductUsingPOST2.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/delete/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST2(requestParameters: DeleteProductUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.deleteProductUsingPOST2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * disableProductPoint
     */
    async disableProductPointUsingPOSTRaw(requestParameters: DisableProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling disableProductPointUsingPOST.');
        }

        if (requestParameters.productPointId === null || requestParameters.productPointId === undefined) {
            throw new runtime.RequiredError('productPointId','Required parameter requestParameters.productPointId was null or undefined when calling disableProductPointUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.productPointId !== undefined) {
            queryParameters['productPointId'] = requestParameters.productPointId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/point/disable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * disableProductPoint
     */
    async disableProductPointUsingPOST(requestParameters: DisableProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.disableProductPointUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * enableProductPoint
     */
    async enableProductPointUsingPOSTRaw(requestParameters: EnableProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling enableProductPointUsingPOST.');
        }

        if (requestParameters.productPointId === null || requestParameters.productPointId === undefined) {
            throw new runtime.RequiredError('productPointId','Required parameter requestParameters.productPointId was null or undefined when calling enableProductPointUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.productPointId !== undefined) {
            queryParameters['productPointId'] = requestParameters.productPointId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/point/enable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * enableProductPoint
     */
    async enableProductPointUsingPOST(requestParameters: EnableProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.enableProductPointUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductAdmin
     */
    async getPagedProductAdminUsingGETRaw(requestParameters: GetPagedProductAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedProductAdminUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedProductAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductAdmin
     */
    async getPagedProductAdminUsingGET(requestParameters: GetPagedProductAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedProductAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductByCategoryIdExtra
     */
    async getPagedProductByCategoryIdExtraUsingGETRaw(requestParameters: GetPagedProductByCategoryIdExtraUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getPagedProductByCategoryIdExtraUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.bookName !== undefined) {
            queryParameters['bookName'] = requestParameters.bookName;
        }

        if (requestParameters.author !== undefined) {
            queryParameters['author'] = requestParameters.author;
        }

        if (requestParameters.bookDescription !== undefined) {
            queryParameters['bookDescription'] = requestParameters.bookDescription;
        }

        if (requestParameters.fromYear !== undefined) {
            queryParameters['fromYear'] = requestParameters.fromYear;
        }

        if (requestParameters.toYear !== undefined) {
            queryParameters['toYear'] = requestParameters.toYear;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/categoryExtra/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductByCategoryIdExtra
     */
    async getPagedProductByCategoryIdExtraUsingGET(requestParameters: GetPagedProductByCategoryIdExtraUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedProductByCategoryIdExtraUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductByCategoryId
     */
    async getPagedProductByCategoryIdUsingGETRaw(requestParameters: GetPagedProductByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getPagedProductByCategoryIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductByCategoryId
     */
    async getPagedProductByCategoryIdUsingGET(requestParameters: GetPagedProductByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedProductByCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductBySearchingQueryExtra
     */
    async getPagedProductBySearchingQueryExtraUsingGETRaw(requestParameters: GetPagedProductBySearchingQueryExtraUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.bookName !== undefined) {
            queryParameters['bookName'] = requestParameters.bookName;
        }

        if (requestParameters.author !== undefined) {
            queryParameters['author'] = requestParameters.author;
        }

        if (requestParameters.bookDescription !== undefined) {
            queryParameters['bookDescription'] = requestParameters.bookDescription;
        }

        if (requestParameters.fromYear !== undefined) {
            queryParameters['fromYear'] = requestParameters.fromYear;
        }

        if (requestParameters.toYear !== undefined) {
            queryParameters['toYear'] = requestParameters.toYear;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/searchExtra`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductBySearchingQueryExtra
     */
    async getPagedProductBySearchingQueryExtraUsingGET(requestParameters: GetPagedProductBySearchingQueryExtraUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedProductBySearchingQueryExtraUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductBySearchingQuery
     */
    async getPagedProductBySearchingQueryUsingGETRaw(requestParameters: GetPagedProductBySearchingQueryUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.organizationId !== undefined) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductBySearchingQuery
     */
    async getPagedProductBySearchingQueryUsingGET(requestParameters: GetPagedProductBySearchingQueryUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedProductBySearchingQueryUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProduct
     */
    async getPagedProductUsingGETRaw(requestParameters: GetPagedProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedProductUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedProductUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProduct
     */
    async getPagedProductUsingGET(requestParameters: GetPagedProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductWithPointAdmin
     */
    async getPagedProductWithPointAdminUsingGETRaw(requestParameters: GetPagedProductWithPointAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedProductWithPointAdminUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedProductWithPointAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/admin/all/withPoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductWithPointAdmin
     */
    async getPagedProductWithPointAdminUsingGET(requestParameters: GetPagedProductWithPointAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedProductWithPointAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductWithPoint
     */
    async getPagedProductWithPointUsingGETRaw(requestParameters: GetPagedProductWithPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPagedProductWithPointUsingGET.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPagedProductWithPointUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/all/withPoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductWithPoint
     */
    async getPagedProductWithPointUsingGET(requestParameters: GetPagedProductWithPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<object> {
        const response = await this.getPagedProductWithPointUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByCategoryIdAdmin
     */
    async getProductByCategoryIdAdminUsingGETRaw(requestParameters: GetProductByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getProductByCategoryIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/admin/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getProductByCategoryIdAdmin
     */
    async getProductByCategoryIdAdminUsingGET(requestParameters: GetProductByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductDto>> {
        const response = await this.getProductByCategoryIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByGroupProductIdAdmin
     */
    async getProductByGroupProductIdAdminUsingGETRaw(requestParameters: GetProductByGroupProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters.groupProductId === null || requestParameters.groupProductId === undefined) {
            throw new runtime.RequiredError('groupProductId','Required parameter requestParameters.groupProductId was null or undefined when calling getProductByGroupProductIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/admin/groupproduct/{groupProductId}`.replace(`{${"groupProductId"}}`, encodeURIComponent(String(requestParameters.groupProductId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getProductByGroupProductIdAdmin
     */
    async getProductByGroupProductIdAdminUsingGET(requestParameters: GetProductByGroupProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductDto>> {
        const response = await this.getProductByGroupProductIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByGroupProductId
     */
    async getProductByGroupProductIdUsingGETRaw(requestParameters: GetProductByGroupProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters.groupProductId === null || requestParameters.groupProductId === undefined) {
            throw new runtime.RequiredError('groupProductId','Required parameter requestParameters.groupProductId was null or undefined when calling getProductByGroupProductIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/groupproduct/{groupProductId}`.replace(`{${"groupProductId"}}`, encodeURIComponent(String(requestParameters.groupProductId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getProductByGroupProductId
     */
    async getProductByGroupProductIdUsingGET(requestParameters: GetProductByGroupProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<ProductDto>> {
        const response = await this.getProductByGroupProductIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByProductIdAdmin
     */
    async getProductByProductIdAdminUsingGETRaw(requestParameters: GetProductByProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductByProductIdAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/admin/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductByProductIdAdmin
     */
    async getProductByProductIdAdminUsingGET(requestParameters: GetProductByProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.getProductByProductIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByProductId
     */
    async getProductByProductIdUsingGETRaw(requestParameters: GetProductByProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getProductByProductIdUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['memberId'] = requestParameters.memberId;
        }

        if (requestParameters.productCommentPage !== undefined) {
            queryParameters['productCommentPage'] = requestParameters.productCommentPage;
        }

        if (requestParameters.productCommentSize !== undefined) {
            queryParameters['productCommentSize'] = requestParameters.productCommentSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductByProductId
     */
    async getProductByProductIdUsingGET(requestParameters: GetProductByProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.getProductByProductIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingGETRaw(requestParameters: GetProductBySkuWithParameterAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.sapArticleNumber === null || requestParameters.sapArticleNumber === undefined) {
            throw new runtime.RequiredError('sapArticleNumber','Required parameter requestParameters.sapArticleNumber was null or undefined when calling getProductBySkuWithParameterAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.sapArticleNumber !== undefined) {
            queryParameters['sapArticleNumber'] = requestParameters.sapArticleNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingGET(requestParameters: GetProductBySkuWithParameterAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithParameterAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingGETRaw(requestParameters: GetProductBySkuWithParameterUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getProductBySkuWithParameterUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.sku !== undefined) {
            queryParameters['sku'] = requestParameters.sku;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingGET(requestParameters: GetProductBySkuWithParameterUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithParameterUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithPathVariableAdmin
     */
    async getProductBySkuWithPathVariableAdminUsingGETRaw(requestParameters: GetProductBySkuWithPathVariableAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getProductBySkuWithPathVariableAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/admin/sku/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithPathVariableAdmin
     */
    async getProductBySkuWithPathVariableAdminUsingGET(requestParameters: GetProductBySkuWithPathVariableAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithPathVariableAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithPathVariable
     */
    async getProductBySkuWithPathVariableUsingGETRaw(requestParameters: GetProductBySkuWithPathVariableUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getProductBySkuWithPathVariableUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/sku/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithPathVariable
     */
    async getProductBySkuWithPathVariableUsingGET(requestParameters: GetProductBySkuWithPathVariableUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithPathVariableUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * listBelongedMarketingEvent
     */
    async listBelongedMarketingEventUsingGETRaw(requestParameters: ListBelongedMarketingEventUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling listBelongedMarketingEventUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/{productId}/marketingEvent`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * listBelongedMarketingEvent
     */
    async listBelongedMarketingEventUsingGET(requestParameters: ListBelongedMarketingEventUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.listBelongedMarketingEventUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * listMarketingEventWhichHasProduct
     */
    async listMarketingEventWhichHasProductUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/marketingEvent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * listMarketingEventWhichHasProduct
     */
    async listMarketingEventWhichHasProductUsingGET(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.listMarketingEventWhichHasProductUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * updateProductInventory
     */
    async updateProductInventoryUsingPOSTRaw(requestParameters: UpdateProductInventoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling updateProductInventoryUsingPOST.');
        }

        if (requestParameters.num === null || requestParameters.num === undefined) {
            throw new runtime.RequiredError('num','Required parameter requestParameters.num was null or undefined when calling updateProductInventoryUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.num !== undefined) {
            queryParameters['num'] = requestParameters.num;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/{productId}/updateInventory`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateProductInventory
     */
    async updateProductInventoryUsingPOST(requestParameters: UpdateProductInventoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.updateProductInventoryUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateProductPrice
     */
    async updateProductPriceUsingPOSTRaw(requestParameters: UpdateProductPriceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling updateProductPriceUsingPOST.');
        }

        if (requestParameters.price === null || requestParameters.price === undefined) {
            throw new runtime.RequiredError('price','Required parameter requestParameters.price was null or undefined when calling updateProductPriceUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.price !== undefined) {
            queryParameters['price'] = requestParameters.price;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/{productId}/updatePrice`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateProductPrice
     */
    async updateProductPriceUsingPOST(requestParameters: UpdateProductPriceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.updateProductPriceUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateProductStatusAdmin
     */
    async updateProductStatusAdminUsingGETRaw(requestParameters: UpdateProductStatusAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling updateProductStatusAdminUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/admin/updateProductStatus/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateProductStatusAdmin
     */
    async updateProductStatusAdminUsingGET(requestParameters: UpdateProductStatusAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.updateProductStatusAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateProduct
     */
    async updateProductUsingPATCHRaw(requestParameters: UpdateProductUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling updateProductUsingPATCH.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/update-body`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProductRequestModelToJSON(requestParameters.productRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateProduct
     */
    async updateProductUsingPATCH(requestParameters: UpdateProductUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.updateProductUsingPATCHRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateProduct
     */
    async updateProductUsingPOSTRaw(requestParameters: UpdateProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling updateProductUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        if (requestParameters.productNameChi !== undefined) {
            queryParameters['productNameChi'] = requestParameters.productNameChi;
        }

        if (requestParameters.productNameEng !== undefined) {
            queryParameters['productNameEng'] = requestParameters.productNameEng;
        }

        if (requestParameters.productDescription !== undefined) {
            queryParameters['productDescription'] = requestParameters.productDescription;
        }

        if (requestParameters.isGroupProduct !== undefined) {
            queryParameters['isGroupProduct'] = requestParameters.isGroupProduct;
        }

        if (requestParameters.productPrice !== undefined) {
            queryParameters['productPrice'] = requestParameters.productPrice;
        }

        if (requestParameters.productInventory !== undefined) {
            queryParameters['productInventory'] = requestParameters.productInventory;
        }

        if (requestParameters.subscriptionPeriod !== undefined) {
            queryParameters['subscriptionPeriod'] = requestParameters.subscriptionPeriod;
        }

        if (requestParameters.productVolume !== undefined) {
            queryParameters['productVolume'] = requestParameters.productVolume;
        }

        if (requestParameters.productVolumeUnit !== undefined) {
            queryParameters['productVolumeUnit'] = requestParameters.productVolumeUnit;
        }

        if (requestParameters.productImage !== undefined) {
            queryParameters['product_image'] = requestParameters.productImage;
        }

        if (requestParameters.productIds !== undefined) {
            queryParameters['productIds'] = requestParameters.productIds;
        }

        if (requestParameters.sapArticleNumber !== undefined) {
            queryParameters['sapArticleNumber'] = requestParameters.sapArticleNumber;
        }

        if (requestParameters.bookIsbn !== undefined) {
            queryParameters['bookIsbn'] = requestParameters.bookIsbn;
        }

        if (requestParameters.restrictedContent !== undefined) {
            queryParameters['restrictedContent'] = requestParameters.restrictedContent;
        }

        if (requestParameters.author !== undefined) {
            queryParameters['author'] = requestParameters.author;
        }

        if (requestParameters.speaker !== undefined) {
            queryParameters['speaker'] = requestParameters.speaker;
        }

        if (requestParameters.seriesName !== undefined) {
            queryParameters['seriesName'] = requestParameters.seriesName;
        }

        if (requestParameters.seriesNumber !== undefined) {
            queryParameters['seriesNumber'] = requestParameters.seriesNumber;
        }

        if (requestParameters.totalDuration !== undefined) {
            queryParameters['totalDuration'] = requestParameters.totalDuration;
        }

        if (requestParameters.episode !== undefined) {
            queryParameters['episode'] = requestParameters.episode;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplierId'] = requestParameters.supplierId;
        }

        if (requestParameters.publishHouseId !== undefined) {
            queryParameters['publishHouseId'] = requestParameters.publishHouseId;
        }

        if (requestParameters.shortDescription !== undefined) {
            queryParameters['shortDescription'] = requestParameters.shortDescription;
        }

        if (requestParameters.aboutAuthor !== undefined) {
            queryParameters['aboutAuthor'] = requestParameters.aboutAuthor;
        }

        if (requestParameters.aboutSpeaker !== undefined) {
            queryParameters['aboutSpeaker'] = requestParameters.aboutSpeaker;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["JWT"] = this.configuration.apiKey("JWT"); // JWT authentication
        }

        const response = await this.request({
            path: `/product/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegerListRequestModelToJSON(requestParameters.integerListRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateProduct
     */
    async updateProductUsingPOST(requestParameters: UpdateProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProductDto> {
        const response = await this.updateProductUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
