import React from "react";
import PopupMenu from "../../../components/Reader/popups/popupMenu/component";
import RecordLocation from "../../../utils/reader/readUtils/recordLocation";
import StyleUtil from "../../../utils/reader/readUtils/styleUtil";
import ImageViewer from "../../../components/Reader/imageViewer/component";
import {
  getIframeDoc,
  getIframeDoc2,
} from "../../../utils/reader/serviceUtils/docUtil";
import { tsTransform } from "../../../utils/reader/serviceUtils/langUtil";
import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
import {
  handlePercentage,
  handleOpenMenu,
  handleShowBookmark,
  handleReadingEpub,
  handleReaderLog,
} from "../../../redux/actions/reader";
import { connect } from "react-redux";
import "./index.css";

class EpubViewer extends React.Component {
  isFirst = true;
  constructor(props) {
    //console.log("EpubViewerprops", props);
    super(props);
    this.state = {
      // cfiRange: null,
      rect: null,
      chapterIndex: 0,
      chapter: "",
      pageWidth: 0,
      pageHeight: 0,
      leftOrRight: 0,
      selection: {},
      mediaOverlay: this.props.mediaOverlay,
      mediaOverlay2: this.props.mediaOverlay2,
    };
    this.isFirst = true;
  }

  componentDidMount() {
    let epub = this.props.currentEpub;
    window.rangy.init(); // 初始化

    this.props.rendition.on("mousedown", (e) => {
      //console.log("event", e.target);
      //e.preventDefault();
      //e.stopPropagation();
      if (e.which === 1) {
        //console.log(e);
        //console.log(e.target);//e.target.parent());
        //console.log("window.parent[1]", window.parent[1]);

        if (
          window.parent[0].document.childNodes[0].innerHTML.includes(
            e.target.innerHTML
          )
        ) {
          this.state.leftOrRight = 0;
        } else {
          this.state.leftOrRight = 1;
        }

        console.log(
          "this.state.leftOrRight BBB",
          e.pageX,
          this.state.leftOrRight
        );
      }
    });

    this.props.rendition.on("click", (e) => {
      //console.log("click event", e.target);
      //console.log("smilModel", this.props.mediaOverlay);

      let _this = this;
      let spanClassId = e.target.id;
      // console.log(
      //   _this.props.rendition.location
      // );
      // console.log(
      //   _this.props.rendition.location.start.href.replace("Text/", "") +
      //     "#" +
      //     spanClassId
      // );
      try {
        let node = _this.props.mediaOverlay.findNodeByTextSrc(
          _this.props.rendition.location.start.href.replace("Text/", "") +
            "#" +
            spanClassId
        );
        //console.log("gfgg", _this.props.rendition.location.start.href.replace("Text/", "") +
        // "#" +
        // spanClassId)
        //console.log("node",node);
        _this.props.mediaOverlay2.pause();
        _this.props.mediaOverlay.startPlayback(node);
        _this.props.setIsPlaying(true);
      } catch (err) {
        try {
          //console.log("gfgg2", _this.props.rendition.location.end.href.replace("Text/", "") +
          // "#" +
          // spanClassId)
          let node = _this.props.mediaOverlay2.findNodeByTextSrc(
            _this.props.rendition.location.end.href.replace("Text/", "") +
              "#" +
              spanClassId
          );
          //console.log("node",node);
          _this.props.mediaOverlay.pause();
          _this.props.mediaOverlay2.startPlayback(node);
          _this.props.setIsPlaying(true);
        } catch (err2) {
          console.log("[EpubViewer] rendition click err2", err2);
        }
      }

      //e.preventDefault();
      //e.stopPropagation();
      //if (e.which === 1) {
      //console.log(e);
      //console.log(e.target);//e.target.parent());
      //console.log("window.parent[1]", window.parent[1]);

      if (
        window.parent[0].document.childNodes[0].innerHTML.includes(
          e.target.innerHTML
        )
      ) {
        this.state.leftOrRight = 0;
      } else {
        this.state.leftOrRight = 1;
      }

      // console.log(
      //   "this.state.leftOrRight BBB222",
      //   e.pageX,
      //   this.state.leftOrRight
      // );
      //}
    });

    this.props.rendition.on("locationChanged", () => {
      //console.log("locationChangedlocationChangedlocationChanged");
      this.props.handleReadingEpub(epub);
      this.props.handleOpenMenu(false);
      const currentLocation = this.props.rendition.currentLocation();
      if (!currentLocation.start) {
        return;
      }
      const cfi = currentLocation.start.cfi;
      this.props.handleShowBookmark(
        this.props.bookmarks &&
          this.props.bookmarks.filter((item) => item.cfi === cfi)[0]
          ? true
          : false
      );

      if (!this.isFirst && this.props.locations) {
        let percentage = this.props.locations.percentageFromCfi(cfi);

        if (this.props.readerLog !== undefined) {
          let newReaderLog = this.props.readerLog;
          newReaderLog["currentCfi"] = cfi;

          if (percentage !== null) {
            if (newReaderLog["percentage"] < percentage) {
              newReaderLog["percentage"] = percentage;
            }
          } else {
            newReaderLog["percentage"] = 0;
          }

          this.props.handleReaderLog(newReaderLog);
        }

        RecordLocation.recordCfi(this.props.currentBook.key, cfi, percentage);
        this.props.handlePercentage(percentage);
      } else if (!this.isFirst) {
        //如果过暂时没有解析出locations，就直接记录cfi
        let percentage = RecordLocation.getCfi(this.props.currentBook.key).percentage;

        if (this.props.readerLog !== undefined) {
          let newReaderLog = this.props.readerLog;
          newReaderLog["currentCfi"] = cfi;

          if (percentage !== null) {
            if (newReaderLog["percentage"] < percentage) {
              newReaderLog["percentage"] = percentage;
            }
          } else {
            newReaderLog["percentage"] = 0;
          }

          this.props.handleReaderLog(newReaderLog);
        }

        RecordLocation.recordCfi(this.props.currentBook.key, cfi, percentage);
      }
      this.isFirst = false;
      //======
      // handle some epub ltr position problem
      if (
        StorageUtil.getReaderConfig("readerMode") === "single" ||
        StorageUtil.getReaderConfig("readerMode") === "scroll"
      ) {
      } else {
        //=====
        setTimeout(function () {
          try {
            let _target = document.getElementsByClassName("epub-container")[0];
            let _flag = _target.dir === "ltr";
            if (_flag) {
              // console.log(
              //   "epubView handleRenderBook BBB setTimeout start",
              //   _target.getBoundingClientRect().width
              // );
              let ___w = _target.getBoundingClientRect().width / 2;
              document.querySelector('.epub-view[ref="0"]').style.marginLeft =
                ___w + "px";
            }
          } catch (err) {
            console.log("err", err);
          }
          //console.log("handleRenderBook BBB END");
        }, 300);
      }
      // handle some epub ltr position problem end
    });

    this.props.rendition.on("rendered", () => {
      let doc = getIframeDoc2(this.state.leftOrRight);
      if (!doc) return;
      // console.log("ffffffeeeee", this.props.rendition)
      //console.log("CP1", this.props.rendition)
      //TODO HT chapter error
      try {
        const currentLocation = this.props.rendition.currentLocation();
        let chapterHref = currentLocation.start.href;
        if (!currentLocation || !currentLocation.start) return;
        this.setState({
          chapterIndex: currentLocation.start.index,
          pageWidth: this.props.currentEpub.rendition._layout.width,
          pageHeight: this.props.currentEpub.rendition._layout.height,
        });
        let chapter = "";
        let currentChapter = this.props.flattenChapters.filter(
          (item) => item.href.split("#")[0] === chapterHref
        )[0];
        if (currentChapter) {
          chapter = currentChapter.label.trim(" ");
        }
        this.setState({ chapter });
      } catch (eeee) {
        //console.log("eeee", eeee);
      }
      //console.log("rendered here");
      StyleUtil.addDefaultCss();
      try {
        StyleUtil.addDefaultCss2(1);
      } catch (err) {}
      this.props.rendition.themes.default(StyleUtil.getCustomCss(false));
      tsTransform();

      // handle epub font in css
      //console.log("epub.archive.urlCache", epub.archive.urlCache);
      try {
        let _urlCache = epub.archive.urlCache;
        Object.keys(_urlCache).map((keyName, i) => {
          //console.log(keyName, _urlCache[keyName]);
          if (keyName.toLocaleLowerCase().includes("/css/")) {
            //console.log(keyName, _urlCache[keyName]);
            fetch(_urlCache[keyName])
              .then((response) => response.blob())
              .then((myBlob) => {
                let reader = new FileReader();
                reader.onload = function () {
                  //console.log(reader.result);
                  try {
                  let re =
                    /(\s*(@[fF][oO][nN][tT]-[fF][aA][cC][eE])\s*\{([^\}]*?)\})/gm;
                  for (const match of reader.result.match(re)) {
                    //console.log(match);
                    let re2 = /(\s*\s*\{([^\}]*?)\})/gm;
                    for (const match2 of match.match(re2)) {
                      try {
                        //console.log(match2);

                        let _str_ff = "";
                        let re_ff =
                          /(([fF][oO][nN][tT]-[fF][aA][mM][iI][lL][yY])\s*(:)\s*([^\}]\S*)(\;)\s*)/gm;
                        for (const match_ff of match2.match(re_ff)) {
                          //console.log(match_ff);
                          _str_ff = re_ff.exec(match_ff)[4];
                          //console.log(_str_ff);
                        }

                        let _str_src = "";
                        let re_src =
                          /(([sS][rR][cC])\s*(:)\s*([^\}]\S*)(\;)\s*)/gm;

                        for (const match_src of match2.match(re_src)) {
                          //console.log(match_src);
                          _str_src = re_src.exec(match_src)[4];
                          //console.log(_str_src);
                          _str_src = _str_src.replaceAll('"', "");
                          _str_src = _str_src.replaceAll("..", "/OPS");
                          _str_src = _str_src.replaceAll("\\", "/");
                          if (_urlCache[_str_src]) {
                            _str_src = _urlCache[_str_src];
                          }
                          //console.log(_str_src);
                        }

                        StyleUtil.addFontFaceCss2(0, _str_ff, _str_src);
                        try {
                          StyleUtil.addFontFaceCss2(1, _str_ff, _str_src);
                        } catch (err) {}
                      } catch (eee) {
                        console.log("err", eee);
                      }
                    }
                  }
                  } catch(err) {
                    console.log("err", err);
                  }
                };
                reader.readAsText(myBlob);
              });
          }
        });
      } catch (err) {
        console.log("err", err);
      }
      // handle epub font in css end
      
      // 處理 ePub 第一次入 又是 第 1 頁 會白頁
      let _epubview = document.getElementsByClassName("epub-view");
      if (_epubview.length == 0) {
      } else if (_epubview.length == 1) {
        let _epubview_clone = _epubview[0].cloneNode(true);
        let _this = this;
        setTimeout(function() {
          let _epubview = document.getElementsByClassName("epub-view");
          if (_epubview.length == 0) {
            StorageUtil.setReaderConfig("readerMode", "single");
            window.location.reload();
          }
        }, 300);
      }
      // 處理 ePub 第一次入 又是 第 1 頁 會白頁 end
    });

    this.props.rendition.on("selected", (cfiRange, contents, event) => {
      let selectionIdx = contents.sectionIndex - 1;
      var range = contents.range(cfiRange);
      let rect;
      if (range) {
        //console.log("EpubViewer AAA", range);
        rect = range.getBoundingClientRect();
        //console.log("rect", rect);
        let currentFramePosition;
        if (selectionIdx > 1) {
        } else {
        }
        //console.log("contents.sectionIndex sssss", this.state.leftOrRight);

        let doc = getIframeDoc2(this.state.leftOrRight);
        if (!doc) return;
        if (!doc.getSelection()) return;
        this.state.selection = doc.getSelection();
        if (doc.getSelection().toString() != "") {
          let sel = doc.getSelection();
          localStorage.setItem("selectionaa", doc.getSelection().toString());
          localStorage.setItem("selection", JSON.stringify(sel));
        }
        currentFramePosition = document
          .querySelectorAll("iframe[id^=epubjs-view")
          [this.state.leftOrRight].getBoundingClientRect();
        //console.log("currentFramePosition", currentFramePosition);

        // re
        // https://localcoder.org/getboundingclientrect-from-within-iframe
        //console.log("rect", rect);
        //console.log("currentFramePosition", currentFramePosition);
        let elemTop = rect.top + currentFramePosition.y;
        let elemBottom = rect.bottom + currentFramePosition.y;
        let elemLeft = rect.left + currentFramePosition.x;
        let elemRight = rect.right + currentFramePosition.x;

        /*
          rect.top = elemTop;
          rect.bottom = elemBottom;
          */
        let temp = {
          bottom: elemBottom, // rect.bottom,
          height: rect.height,
          left: elemLeft, // rect.left,
          right: elemRight, //rect.right,
          top: elemTop, // rect.top,
          width: rect.width,
          x: rect.x,
          y: rect.y,
        };
        rect = temp;
        // re end
      } else {
        let doc = getIframeDoc2(this.state.leftOrRight);
        if (!doc) return;
        if (!doc.getSelection()) return;
        //console.log("EpubViewer BBB");
        rect = doc.getSelection().getRangeAt(0).getBoundingClientRect();
        this.state.selection = doc.getSelection();
      }

      this.setState({ rect });
    });
    this.props.rendition.themes.default(StyleUtil.getCustomCss(false));
    this.props.rendition.display(
      RecordLocation.getCfi(this.props.currentBook.key) === null
        ? ""
        : RecordLocation.getCfi(this.props.currentBook.key).cfi
    );
  }

  render() {
    const popupMenuProps = {
      rendition: this.props.rendition,
      rect: this.state.rect,
      pageWidth: this.state.pageWidth,
      pageHeight: this.state.pageHeight,
      chapterIndex: this.state.chapterIndex,
      chapter: this.state.chapter,
      leftOrRight: this.state.leftOrRight,
      selection: this.state.selection,
      readerType: "EPUB",
      handleRenderBook: this.props.handleRenderBook,
      handleSaveReaderLogNote: this.props.handleSaveReaderLogNote,
    };
    const imageViewerProps = {
      isShow: this.props.isShow,
      rendition: this.props.rendition,
      handleEnterReader: this.props.handleEnterReader,
      handleLeaveReader: this.props.handleLeaveReader,
    };
    return (
      <div className="view-area">
        <ImageViewer {...imageViewerProps} />

        <PopupMenu {...popupMenuProps} />
        <>
          {this.props.isShowBookmark ? <div className="bookmark"></div> : null}
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chapters: state.reader.chapters,
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    locations: state.progressPanel.locations,
    flattenChapters: state.reader.flattenChapters,
    bookmarks: state.reader.bookmarks,
    isShowBookmark: state.viewArea.isShowBookmark,
    readerLog: state.reader.readerLog,
  };
};
const actionCreator = {
  handlePercentage,
  handleOpenMenu,
  handleShowBookmark,
  handleReadingEpub,
  handleReaderLog,
};

export default connect(mapStateToProps, actionCreator)(EpubViewer);
