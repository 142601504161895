// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reader_style {

}

body.reader_style #root {
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/layout/reader/pdfReader/pdfReader.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".reader_style {\r\n\r\n}\r\n\r\nbody.reader_style #root {\r\n    overflow: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
