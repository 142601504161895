// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-digest-button {
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  position: absolute;
  top: 3px !important;
  right: 6px !important;
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
}
.delete-digest-icon {
  position: relative;
  float: right;
  font-size: 12px;
  margin-right: 3.5px;
  margin-top: 3px;
  cursor: pointer;
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Reader/deleteIcon/deleteIcon.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;EACX,sCAAsC;AACxC;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".delete-digest-button {\n  width: 18px !important;\n  height: 18px !important;\n  border-radius: 50%;\n  cursor: pointer;\n  text-align: center;\n  position: absolute;\n  top: 3px !important;\n  right: 6px !important;\n  z-index: 10;\n  animation: popup 0.1s ease-in-out 0s 1;\n}\n.delete-digest-icon {\n  position: relative;\n  float: right;\n  font-size: 12px;\n  margin-right: 3.5px;\n  margin-top: 3px;\n  cursor: pointer;\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
