import {g, p} from './BasicAPI';

export function addGwpDiscountToShoppingCart(discountId)
{
    return p('/shoppingCart/cartProduct/gwp/add', {
        discountId
    });
}

export function addGwpDiscountToShoppingCartForGuest(discountId, cartProductList, couponCode)
{
    return p('/shoppingCart/cartProduct/gwp/guestadd', {
        discountId,
        productListJson: cartProductList,
        couponCode,
    });
}

export function removeGwpDiscountFromShoppingCart(discountId)
{
    return p('/shoppingCart/cartProduct/gwp/delete', {
        discountId
    });
}

export function get()
{
    return g('/shoppingCart');
}

export function setCoupon(couponCode)
{
    return p('/shoppingCart/coupon/set', {
        couponId: couponCode,
    });
}

export function removeCouponCode()
{
    return p('/shoppingCart/coupon/delete');
}

export function removePointProduct(productId, productPointId)
{
    return p('/shoppingCart/cartProduct/usingPoint/delete', {
        productId,
        productPointId,
    });
}

export function removeShoppingCartItem(productId)
{
    return p('/shoppingCart/cartProduct/delete', {
        productId,
    });
}