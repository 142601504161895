import React, { useEffect, useRef, useState } from "react";
import style from "./Favourite.module.css";
import { Path, useForm, UseFormRegister, SubmitHandler } from "react-hook-form";
import { authFetch } from "../../redux/services/AuthenticationService";
import BookCard, { IBookData } from "../../components/BookCard/BookCard";
import { connect } from "react-redux";
import Button from "../../components/Button/Button";
import { handleReaderMode } from "../../redux/actions/reader/reader";
import { openBook } from "../../utils/openBook";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import bookshelfIcon from "../../assets/images/shelf.svg";
import { useTranslation } from "react-i18next";
import UpperSideNav from "../SideNav/UpperSideNav";
import LowerSideNav from "../SideNav/LowerSideNav";
import * as CategoryAPI from "../../api/CategoryAPI";
import useWindowSize from "../../utils/useWindowSize";
import { useNavigate } from "react-router";
import { checkLogin } from "../../utils/GlobalFunction";

const month = () => {
  let monthArray = [];
  for (let i = 1; i <= 12; i++) {
    monthArray.push({ value: `${i}`, display: `${i}月` });
  }
  return monthArray;
};

interface IFormValues {
  firstName: string;
  gender: string;
  code: number;
  birthday: string;
  district: string;
  password: string;
  confirmPassword: string;
  email: string;
  phone: string;
}

type InputProps = {
  label: Path<IFormValues>;
  placeholder: string;
  register: UseFormRegister<IFormValues>;
  required: boolean;
  type?: string;
  pattern?: any;
  value?: string;
  ref?: any;
};

const formSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "密碼長度至少8位"),
    confirmPassword: yup
      .string()
      .required("Password is required")
      .min(8, "密碼長度至少8位")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();

function Favourite({ member, handleReaderMode, reader, favoriteReducer }: any) {
  const [favourite, setFavourite] = useState([]);
  const userInfoRef = useRef<any>(null);
  const [bookshelf, setBookShelf] = useState<any>([]);
  const [tempProductId, setTempProductId] = useState<number | null>();
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingProd, setLoadingProd] = useState(false);
  const [loadingOnlineRead, setLoadingOnlineRead] = useState(false);
  const [readBook, setReadBook] = useState();
  const [loader, setLoader] = useState(false);
  
  const { t } = useTranslation();
  const navigate = useNavigate();


  useEffect(() => {
    const isLogined = checkLogin();
    if (!isLogined) {
      navigate("/");
      return;
    }
    fetchFavorite();
    userInformation();
    fetchUserBookShelf();
    categoryList();
  }, [favoriteReducer]);

  useEffect(() => {
    //import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
    if (tempProductId !== undefined) {
      let readerConfig = localStorage.getItem("readerConfig");
      if (readerConfig !== null) {
        let readerConfigObj = JSON.parse(readerConfig);

        if (reader.mode !== "" && reader.mode !== undefined) {
          readerConfigObj["rm"] = reader.mode.substring(0, 1);
          localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));
          if (reader.mode === "read") {
            setLoadingProd(true);
          }

          // if (reader.mode === "preview") {
          //   setLoadingPreview(true);
          // }

          // if (props.reader.mode === "online") {
          //   setLoadingOnlineRead(true);
          //   handleReaderMode("");
          // }
        }
      }
    }

    // if (props.userInfo.mode !== undefined) {
    //   console.log("props10", props.userInfo.mode);
    //   if (props.reader.mode === "read") {
    //     setLoadingProd(true);
    //   }
    //   if (props.reader.mode === "preview") {
    //     setLoadingPreview(true);
    //   }
    // }
  }, [reader]);

  // useEffect(() => {
  //   if (loadingPreview) {
  //     openBook(readBook);
  //     setLoadingPreview(false);
  //     setLoadingProd(false);
  //     setLoadingOnlineRead(false);
  //     setTempProductId(null);
  //   }
  // }, [loadingPreview]);

  useEffect(() => {
    if (loadingProd) {
      openBook(readBook,setLoader);
      setLoadingPreview(false);
      setLoadingProd(false);
      setLoadingOnlineRead(false);
      setTempProductId(null);
    }
  }, [loadingProd]);

  const handleReadBook = (actionType: string, book: any) => {
    setTempProductId(book.productId);
    setReadBook(book);
    console.log("actionType", actionType);
    if (
      actionType === "read" ||
      actionType === "preview" ||
      actionType === "online"
    ) {
      handleReaderMode(actionType);
      //setReadAction(actionType);
    } else {
      //   setLoadingOnlineRead(true);
    }
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IFormValues>({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    console.log("data", data);
    //alert(JSON.stringify(data));
    handleUpdate(data);
  };

  function handleUpdate(data: any) {
    let formBody = new FormData();
    for (var key in data) {
      formBody.append(key, data[key]);
    }
    formBody.append("memberId", member.member_id);
    authFetch(
      process.env.REACT_APP_BACKEND + "member/update",
      formBody,
      "post"
    ).then((res) => {
      alert("成功更新");
    });
  }
  // The following component is an example of your existing Input Component
  const Input = ({
    label,
    placeholder,
    type,
    pattern,
    register,
    required,
    value,
  }: InputProps) => (
    <>
      <input
        placeholder={placeholder}
        value={value}
        type={type}
        {...register(label)}
      />
      {errors.email && label === "email" && (
        <span role="alert">{errors.email.message}</span>
      )}
      {errors.password && label === "password" && (
        <span role="alert">{errors.password.message}</span>
      )}
      {errors.confirmPassword && label === "confirmPassword" && (
        <span role="alert">{errors.confirmPassword.message}</span>
      )}
    </>
  );
  // you can use React.forwardRef to pass the ref too
  const Select = React.forwardRef<
    HTMLSelectElement,
    { value: any[] } & ReturnType<UseFormRegister<IFormValues>>
  >(({ onChange, onBlur, name, value }, ref) => (
    <>
      <select name={name} ref={ref} onChange={onChange}>
        {value.map((item, idx) => (
          <option key={idx} value={item.value}>
            {item.display}
          </option>
        ))}
      </select>
    </>
  ));

  const fetchFavorite = async () => {
    authFetch(
      process.env.REACT_APP_BACKEND + "member/favourite",
      null,
      "get"
    ).then((res) => setFavourite(res));
  };

  const userInformation = async () => {
    authFetch(process.env.REACT_APP_BACKEND + "member/", null, "get").then(
      (res) => {
        userInfoRef.current = res;
        setValue("firstName", res.firstname);
        setValue("email", res.email);
        setValue("phone", res.phone);
        setValue("gender", res.gender);
        setValue("birthday", res.birthdayMonth);
        setValue("district", res.district);
      }
    );
  };

  const fetchUserBookShelf = async () => {
    authFetch(
      process.env.REACT_APP_BACKEND +
        `book_shelf/getByMemberId/${parseInt(member.member_id)}`,
      null,
      "get"
    ).then((res) => {
      if (res.length % 5 !== 0) {
        const addNumberOfRow = 5 - (res.length % 5);
        const x = res.slice();
        for (let i = 0; i < addNumberOfRow; i++) {
          x.push(null);
        }
        setBookShelf(x);
      } else {
        setBookShelf(res);
      }
    });
  };
  const [displayCategory, setDisplayCategory] = useState([]);

  const categoryList = async () => {
    const categories = await CategoryAPI.getAll();
    setDisplayCategory(categories);
  };

  const { width } = useWindowSize();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        maxWidth: "1440px",
      }}
    >
      {width > 1024 ? (
        <div
          style={{ display: "flex", width: "250px", flexDirection: "column" }}
        >
          <UpperSideNav props={displayCategory} />
          <LowerSideNav props={displayCategory} />
        </div>
      ) : (
        <></>
      )}
      <div className={style.shopingCartLayout}>
        <section>
          <h1>{t("page.myFavourite")}</h1>
          <p>
            {favourite.length}
            {t("page.books")}
          </p>
          <div className={style.cardLayout}>
            {favourite.map((item: any) => (
              <BookCard props={item} key={item.displayProductId} />
            ))}
          </div>
        </section>
        {/* <section>
        <h1>我的書架</h1>
        <p>
          {bookshelf.filter((x: any) => x !== null).length}
          本書籍。
        </p>
        <div className={style.bookshelfLayout}>
          {bookshelf.map((book: any, idx: any) => (
            <>
              <div className={style.bookContainer} key={idx}>
                {book !== null ? (
                  <>
                    <div className={style.bookshelfBookTitleContainer}>
                      <p className={style.bookshelfBookTitle}>
                        {book.productNameChi}
                      </p>
                    </div>

                    <div className={style.readButtonContainer}>
                      <Button
                        styleType={"bookIntroButton"}
                        callBack={() => handleReadBook("read", book)}
                        children={"開始閱讀"}
                      />
                    </div>
                    <div className={style.bookImgContainer} key={idx}>
                      <img
                        src={
                          process.env.REACT_APP_BACKEND +
                          "file/" +
                          book.productImage[0]
                        }
                      ></img>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {(idx + 1) % 5 === 0 || idx === bookshelf.length - 1 ? (
                <img src={bookshelfIcon} className={style.bookshelf} />
              ) : (
                <></>
              )}
            </>
          ))}
        </div>
      </section> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    member: state.MemberReducer,
    reader: state.reader,
    favoriteReducer: state.FavoriteReducer.productList,
  };
};

const actionCreator = {
  handleReaderMode,
};
export default connect(mapStateToProps, actionCreator)(Favourite);
