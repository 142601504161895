import "whatwg-fetch";
import { store } from "../redux/store";
import { decode as b64decode } from "js-base64";
import { RefreshShoppingCartItem } from "../redux/services/ShoppingCartService";
import { asyncDangerousSetShoppingCartWithParams } from "../redux/services/ShoppingCartService";

// const api = process.env.REACT_APP_BACKEND_URL;
const api = process.env.REACT_APP_BACKEND_URL;
const headers = {
  Accept: "application/json",
};

export function authFetch(url, body, method) {
  // const { token } = UseToken();
  const tokenString = sessionStorage.getItem("token");
  const token = JSON.parse(tokenString);
  return fetch(url, {
    method: method,
    body: body,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      console.log(res);
      if (res.status != 200) {
        res.json().then((res) => {
          alert(res.message);
        });
        throw new Error("something fail");
      } else if (res == null) return res;
      else return res.json();
    })
    .then(
      (res) => res,
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
}

export const adminLogin = (username, password) => {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/login`, {
    method: "POST",
    headers,
    body: formData,
    mode: "cors",
    credentials: "omit",
  });
};

export const login = (username, password) => {
  let data = new FormData();
  data.append("username", username);
  data.append("password", password);
  console.log("data", data);

  fetch(process.env.REACT_APP_BACKEND + "login", {
    method: "post",
    body: data,
  }).then((res) => {
    console.log("res", res);
    let token = res.headers.get("token");
    if (token == null) alert("登入失敗");
    else {
      let [, information] = token.split(".");
      information = JSON.parse(b64decode(information));
      console.log("information", information);
      store.dispatch({
        type: "SET_MEMBER_INFORMATION",
        member_id: information.sub,
        member_first_name: information.firstname,
        member_last_name: information.lastname,
        member_level: information.authority,
        member_email: information.email, // 無用
        token: token,
        exp: information.exp,
      });
      asyncHandleLoginShoppingCart()
        .then(() => {
          RefreshShoppingCartItem();
          //alert("登入成功");
          /*
                    props.setShowLoginDropdown(false);
                    props.onHide();
                    */
          //history.push("/");
        })
        .then(() => (window.location.pathname = "/"));
    }
  });
};

export function asyncHandleLoginShoppingCart() {
  const state = store.getState();
  if (
    state.shoppingCartReducer.productList &&
    state.shoppingCartReducer.productList.length > 0
  ) {
    let purifiedProductList = state.shoppingCartReducer.productList.filter(
      (t) => {
        return (
          t.discounts == null ||
          t.discounts.length === 0 ||
          !t.discounts.some((t) => ["GWP", "PWP"].includes(t.discountType))
        );
      }
    );

    purifiedProductList = purifiedProductList.map((t) => {
      return {
        productId: t.product_id,
        quantity: t.quantity,
      };
    });

    return asyncDangerousSetShoppingCartWithParams(purifiedProductList);
  } else {
    return Promise.resolve();
  }
}
