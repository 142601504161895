import RecordLocation from "../../../utils/reader/readUtils/recordLocation";
export function handleLocations(locations) {
  return { type: "HANDLE_LOCATIONS", payload: locations };
}
export function handlePercentage(percentage) {
  return { type: "HANDLE_PERCENTAGE", payload: percentage };
}
export function handleFetchPercentage(book) {
  return (dispatch) => {
    let percentage =
      book.format === "EPUB"
        ? RecordLocation.getCfi(book.key).percentage
        : RecordLocation.getHtmlLocation(book.key).percentage;

    // CTS
    if (percentage == null) {
      percentage = 0;
    }
    // CTS end
    //dispatch(handlePercentage(percentage));
  };
}
export function handleFetchLocations(epub) {
  return (dispatch) => {
    epub.locations
      .generate()
      .then((result) => {
        dispatch(handleLocations(epub.locations));
      })
      .catch(() => {
        //console.log("Error occurs");
      });
  };
}
