import { useState } from "react";
import { useTranslation } from "react-i18next";
import { addSubscriptionEmail } from "../../api/SubscriptionEmailAPI";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { validateEmail } from "../../utils/GlobalFunction";

export default function Subscribe(props: any) {
  const { t, i18n } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [email, setEmail] = useState("");

  const resetState = () => {
    setShowError(false);
    setEmail("");
  };

  const submit = () => {
    if (validateEmail(email)) {
      setShowError(false);
      addSubscriptionEmail(email).then((res: any) => {
        console.log("dksaopjdaosp", res);
      });
      close();
    } else {
      setShowError(true);
    }
  };

  const close = () => {
    resetState();
    props.callback("close");
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "50px",
          marginBottom: "10px",
          marginRight: "5px",
        }}
      >
        <Input
          type="text"
          placeholder={"signIn.emailAddress"}
          styleType={"loginInput"}
          callBack={(value) => {
            setEmail(value);
          }}
          value={email}
        />
      </div>
      <h5 style={{ color: "red", display: showError ? "block" : "none" }}>
        無效的電郵地址
      </h5>
      <div
        style={{
          display: "flex",
          gap: "10px",
          width: "100%",
          height: "40px",
          marginRight: "5px",
          marginTop: "10px",
        }}
      >
        <Button
          buttonType={"button"}
          styleType={"loginButton"}
          callBack={(value) => submit()}
        >
          {"訂閱"}
        </Button>
      </div>

      <div
        style={{
          position: "absolute",
          display: "flex",
          top: "20px",
          right: "20px",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#47d5cd",
            borderRadius: "50%",
            fontWeight: "bold",
          }}
          onClick={() => close()}
        >
          X
        </div>
      </div>
    </>
  );
}
