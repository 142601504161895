import {
  SET_MEMBER_INFORMATION,
  DELETE_MEMBER_INFORMATION,
  SET_TOKEN,
  DELETE_TOKEN,
} from "../../utils/Constant";

const initialState = {
  member_id: null,
  member_first_name: null,
  member_last_name: null,
  member_level: null,
  member_email: null,
  token: null,
};

function MemberReducer(state = initialState, action) {
  if (action.type == SET_MEMBER_INFORMATION) {
    return {
      member_id: action.member_id,
      member_first_name: action.member_first_name,
      member_last_name: action.member_last_name,
      member_level: action.member_level,
      member_email: action.member_email,
      token: action.token,
      exp: action.exp,
    };
  }
  if (action.type == DELETE_MEMBER_INFORMATION) {
    return {
      member_id: null,
      member_first_name: null,
      member_last_name: null,
      member_level: null,
      member_email: null,
      token: null,
      exp: null,
    };
  }
  return state;
}

export default MemberReducer;
