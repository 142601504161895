// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CarItem_cartItemContainer__rsj4d {
  display: flex;
  justify-content: space-between;
  margin: 1em 2em 1em 0;
}

.CarItem_bookCover__iDLsx {
  object-fit: cover;
  width: 100px;
  height: 150px;
}
.CarItem_bookDetailsContainer__Yk8BN {
  margin: 1.5em;
  font-size: 1em;
}
.CarItem_bookDetails__XUcWl {
  display: flex;
  flex-direction: column;
}

.CarItem_bookContainer__k7SIL {
  display: flex;
}

.CarItem_cartItemOther__YNrak {
  display: flex;
  align-items: center;
}

.CarItem_bookPrice__rter9 {
  display: flex;
  font-size: 1em;
  gap: 1em;
}

.CarItem_cartItemButtonContainer__dGf7U{
  margin: 0 0 0 2em;
}

h1{
  margin: 0.5em 0 ;
}

.CarItem_originalPrice__8Gch5{
  text-decoration: line-through;
}

@media only screen and (max-width: 1024px) {

  .CarItem_cartItemContainer__rsj4d{
    flex-wrap: wrap;
    row-gap: 0.5em;
    margin: 1em 0;
  }

  .CarItem_cartItemOther__YNrak {
    width: 100%;
    justify-content: flex-end;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CartItem/CarItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;AACf;AACA;EACE,aAAa;EACb,cAAc;AAChB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,QAAQ;AACV;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;;EAEE;IACE,eAAe;IACf,cAAc;IACd,aAAa;EACf;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;AACF","sourcesContent":[".cartItemContainer {\n  display: flex;\n  justify-content: space-between;\n  margin: 1em 2em 1em 0;\n}\n\n.bookCover {\n  object-fit: cover;\n  width: 100px;\n  height: 150px;\n}\n.bookDetailsContainer {\n  margin: 1.5em;\n  font-size: 1em;\n}\n.bookDetails {\n  display: flex;\n  flex-direction: column;\n}\n\n.bookContainer {\n  display: flex;\n}\n\n.cartItemOther {\n  display: flex;\n  align-items: center;\n}\n\n.bookPrice {\n  display: flex;\n  font-size: 1em;\n  gap: 1em;\n}\n\n.cartItemButtonContainer{\n  margin: 0 0 0 2em;\n}\n\nh1{\n  margin: 0.5em 0 ;\n}\n\n.originalPrice{\n  text-decoration: line-through;\n}\n\n@media only screen and (max-width: 1024px) {\n\n  .cartItemContainer{\n    flex-wrap: wrap;\n    row-gap: 0.5em;\n    margin: 1em 0;\n  }\n\n  .cartItemOther {\n    width: 100%;\n    justify-content: flex-end;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cartItemContainer": `CarItem_cartItemContainer__rsj4d`,
	"bookCover": `CarItem_bookCover__iDLsx`,
	"bookDetailsContainer": `CarItem_bookDetailsContainer__Yk8BN`,
	"bookDetails": `CarItem_bookDetails__XUcWl`,
	"bookContainer": `CarItem_bookContainer__k7SIL`,
	"cartItemOther": `CarItem_cartItemOther__YNrak`,
	"bookPrice": `CarItem_bookPrice__rter9`,
	"cartItemButtonContainer": `CarItem_cartItemButtonContainer__dGf7U`,
	"originalPrice": `CarItem_originalPrice__8Gch5`
};
export default ___CSS_LOADER_EXPORT___;
