import {g, p, u, d} from './BasicAPI';

export const getAll = () => 
{
    return g(`/pageContent/all`)
        .then(res => res.json());
}

export const getPageContentById = (id) => 
{
    return g(`/pageContent/${id}`)
        .then(res => res.json());
}

export const update = (data) => 
{
    return p(`/pageContent/update`, data)
        .then(res => res.json());
}

export const create = (data) => 
{
    return p(`/pageContent/add`, data)
        .then(res => res.json());
}

export const deleteById = (id) => 
{
    return p(`/pageContent/delete/${id}`)
        .then(res => res.json());
}