import {
  ADD_GUEST_FAVORITE,
  DELETE_GUEST_FAVORITE,
  REFRESH_GUEST_FAVOURITE,
} from "../../utils/Constant";

const initialState = {
  productList: [],
};

function FavoriteReducer(state = initialState, action) {
  if (action.type == ADD_GUEST_FAVORITE) {
    let productList = state.productList;
    productList.push(action.payload.productId);
    return {
      productList: productList,
    };
  }
  if (action.type == DELETE_GUEST_FAVORITE) {
    let productList = [];
    state.productList.forEach((productId) => {
      if (productId != action.payload.productId) productList.push(productId);
    });
    return {
      productList: productList,
    };
  }
  if (action.type == REFRESH_GUEST_FAVOURITE) {
    return action.payload;
  }
  return state;
}

export default FavoriteReducer;
