import React, { useEffect, useState } from "react";
import { Path, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DialogBox from "../../layout/DialogBox/DialogBox";
import { IFormValues } from "../../layout/Login/RegisterPage";
import * as API from "../../api/PageContentAPI";

type InputProps = {
  id: Path<IFormValues>;
  label?: any;
  placeholder?: string;
  register: UseFormRegister<IFormValues>;
  type?: string;
  errors?: any;
};

export default function Checkbox({
  id,
  label,
  placeholder,
  type,
  register,

  errors,
}: InputProps) {
  const [checked, setChecked] = React.useState(false);
  const [messageOne, setMessageOne] = React.useState(false);
  const [messageTwo, setMessageTwo] = React.useState(false);
  const [messageThree, setMessageThree] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [pageContent, setPageContent] = useState("");
  const [haveRead, setHaveRead] = React.useState({
    messageOne:false,
    messageTwo:false,
    messageThree:false,
  });

  useEffect(() => {
    if (messageOne) {
      API.getPageContentById(6).then((res) =>
        setPageContent(res.content.split(":::")[i18n.language === "en" ? 1 : 0])
      );
    }
    if (messageTwo) {
      API.getPageContentById(5).then((res) =>
        setPageContent(res.content.split(":::")[i18n.language === "en" ? 1 : 0])
      );
    }
    if (messageThree) {
      API.getPageContentById(14).then((res) =>
        setPageContent(res.content.split(":::")[i18n.language === "en" ? 1 : 0])
      );
    }
  }, [messageOne, messageTwo, messageThree]);

  const callBackModal = (value: any) => {
    console.log("value", value);
    if (value.message === "close") {
      setMessageOne(false);
      setMessageTwo(false);
      setMessageThree(false);
      if(haveRead.messageOne && haveRead.messageTwo && haveRead.messageThree  ){
        setChecked(true)
      }
      return;
    }
  };
  return (
    <div>
      <input
        id={id}
        placeholder={placeholder}
        type={type}
        checked={checked}
        {...register(id)}
        onChange={(e) => {
          setChecked(e.target.checked);
        }}
      />
      {id === "agreement2" ? (
        <label htmlFor={id}>
          <span>{label} </span>
        </label>
      ) : (
        <></>
      )}
      {id === "agreement" ? (
        <>
          <label >
            <span>
              {label}&nbsp;
              <span>
                <span
                                    onClick={() => {setMessageOne(!messageOne)
                                      setHaveRead({
                                        ...haveRead, // Copy the old fields
                                        messageOne: true // But override this one
                                      });
                                    }}
                  style={{ color: "#47d5cd", cursor: "pointer" }}
                >
                  {t("signUp.terms")}
                </span>
                &nbsp;
                <span
                                    onClick={() => {setMessageThree(!messageThree)
                                      setHaveRead({
                                        ...haveRead, // Copy the old fields
                                        messageThree: true // But override this one
                                      });
                                    }}
                  style={{
                    color: "#47d5cd",
                    cursor: "pointer",
                    display: "inline",
                  }}
                >
                  {t("signUp.disclaimer")}
                </span>
                &nbsp;
                {t("signUp.and")}
                &nbsp;
                <span
                                    onClick={() => {setMessageTwo(!messageTwo)
                                      setHaveRead({
                                        ...haveRead, // Copy the old fields
                                        messageTwo: true // But override this one
                                      });
                                    }}
                  style={{
                    color: "#47d5cd",
                    cursor: "pointer",
                    display: "inline",
                  }}
                >
                  {t("signUp.conditions")}
                </span>
              </span>
              <div>
                <span style={{ color: "red" }}>{errors}</span>
              </div>
            </span>
          </label>
          <DialogBox
            showDialog={messageOne}
            styleType={"Main"}
            button={"關閉"}
            callBack={(value: any) => callBackModal(value)}
          >
            <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
          </DialogBox>
          <DialogBox
            showDialog={messageTwo}
            styleType={"Main"}
            button={"關閉"}
            callBack={(value: any) => callBackModal(value)}
          >
            <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
          </DialogBox>
          <DialogBox
            showDialog={messageThree}
            styleType={"Main"}
            button={"關閉"}
            callBack={(value: any) => callBackModal(value)}
          >
            <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
          </DialogBox>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
