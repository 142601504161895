import React from "react";
import "./navigationPanel.css";
import ContentList from "../../lists/contentList/component";
import BookNavList from "../../lists/navList/component";
import SearchBox from "../../../../components/Reader/searchBox/component";
import Parser from "html-react-parser";
import EmptyCover from "../../../../components/Reader/emptyCover/index";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import { Tooltip } from "react-tippy";
import {
  handleFetchBookmarks,
  handleSearch,
} from "../../../../redux/actions/reader";
import { connect } from "react-redux";
import { Trans } from "react-i18next";

class NavigationPanel extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "contents",
      chapters: [],
      cover: this.props.currentBook.cover,
      isSearch: false,
      searchList: null,
      startIndex: 0,
      currentIndex: 0,
      isNavLocked:
        false, //StorageUtil.getReaderConfig("isNavLocked") === "yes" ? true : false,
    };
  }
  handleSearchState = (isSearch) => {
    this.setState({ isSearch });
  };
  handleSearchList = (searchList) => {
    this.setState({ searchList });
  };
  componentDidMount() {
    this.props.handleFetchBookmarks();
  }

  handleChangeTab = (currentTab) => {
    this.setState({ currentTab });
  };
  handleLock = () => {
    this.setState({ isNavLocked: !this.state.isNavLocked }, () => {
      StorageUtil.setReaderConfig(
        "isNavLocked",
        "no" // this.state.isNavLocked ? "yes" : "no"
      );
    });
  };
  renderSearchList = () => {
    if (!this.state.searchList[0]) {
      return <div className="navigation-panel-empty-bookmark">Empty</div>;
    }
    return this.state.searchList
      .slice(
        this.state.currentIndex * 10,
        this.state.currentIndex * 10 + 10 > this.state.searchList.length
          ? this.state.searchList.length
          : this.state.currentIndex * 10 + 10
      )
      .map((item, index) => {
        return (
          <li
            className="nav-search-list-item"
            key={"nav-search-list-item-" + index}
            onClick={async() => {
              if (this.props.currentBook.format === "EPUB") {
                this.props.currentEpub.rendition.display(item.cfi);
              } else {
                let bookLocation = JSON.parse(item.cfi) || {};
                this.props.htmlBook.rendition.goToPosition(
                  bookLocation.text,
                  bookLocation.chapterTitle,
                  bookLocation.count
                );
              }
            }}
          >
            {Parser(item.excerpt)}
          </li>
        );
      });
  };
  renderSearchPage = () => {
    let startIndex = this.state.startIndex;
    let currentIndex =
      startIndex > 0 ? startIndex + 2 : this.state.currentIndex;
    let pageList = [];
    let total = Math.ceil(this.state.searchList.length / 10);
    if (total <= 5) {
      for (let i = 0; i < total; i++) {
        pageList.push(
          <li
            className={
              currentIndex === i
                ? "nav-search-page-item active-page "
                : "nav-search-page-item"
            }
            onClick={() => {
              this.setState({ currentIndex: i });
            }}
          >
            {i + 1}
          </li>
        );
      }
    } else {
      for (
        let i = 0;
        i < (total - startIndex < 5 ? total - startIndex : 5);
        i++
      ) {
        let isShow = currentIndex > 2 ? i === 2 : currentIndex === i;
        pageList.push(
          <li
            className={
              isShow
                ? "nav-search-page-item active-page "
                : "nav-search-page-item"
            }
            onClick={() => {
              if (i === 3 && startIndex === 0) {
                this.setState({
                  startIndex: 1,
                  currentIndex: 3,
                });
                return;
              }
              this.setState({
                startIndex: currentIndex > 2 ? i + startIndex - 2 : 0,
                currentIndex: i + startIndex,
              });
            }}
          >
            {i + startIndex + 1}
          </li>
        );
      }
      if (total - startIndex < 5) {
        for (let i = 0; i < 6 - pageList.length; i++) {
          pageList.push(<li className="nav-search-page-item">EOF</li>);
        }
      }
    }
    return pageList;
  };
  getSearchBox = () => {
    const searchProps = {
      mode: this.state.isSearch ? "" : "nav",
      width: "150px",
      height: "35px",
      isNavSearch: this.state.isSearch,
      handleSearchState: this.handleSearchState,
      handleSearchList: this.handleSearchList,
    };
    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    if (readerConfigObj.rm === "r" || readerConfigObj.rm === "o") {
      return (
        <div className="navigation-search-box">
          <SearchBox {...searchProps} />
        </div>
      );
    }
  };

  getNavigation = () => {
    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    if (readerConfigObj.rm === "r" || readerConfigObj.rm === "o") {
      return (
        <div className="navigation-navigation">
          <span
            className="book-content-title"
            onClick={() => {
              this.handleChangeTab("contents");
            }}
            style={this.state.currentTab === "contents" ? {} : { opacity: 0.5 }}
          >
            <Trans>Content</Trans>
          </span>
          <span
            className="book-bookmark-title"
            style={
              this.state.currentTab === "bookmarks" ? {} : { opacity: 0.5 }
            }
            onClick={() => {
              this.handleChangeTab("bookmarks");
            }}
          >
            <Trans>Bookmark</Trans>
          </span>
          <span
            className="book-bookmark-title"
            style={this.state.currentTab === "notes" ? {} : { opacity: 0.5 }}
            onClick={() => {
              this.handleChangeTab("notes");
            }}
          >
            <Trans>Note</Trans>
          </span>
          <span
            className="book-bookmark-title"
            style={
              this.state.currentTab === "digests" ? {} : { opacity: 0.5 }
            }
            onClick={() => {
              this.handleChangeTab("digests");
            }}
          >
            <Trans>Highlight</Trans>
          </span>
        </div>
      );
    }
  };

  getBodyParent = () => {
    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);
    const bookmarkProps = {
      currentTab: this.state.currentTab,
      handleSaveReaderLogBookmark: this.props.handleSaveReaderLogBookmark,
      handleSaveReaderLogNote: this.props.handleSaveReaderLogNote,
    };

    if (readerConfigObj.rm === "r" || readerConfigObj.rm === "o") {
      return (
        <div className="navigation-body-parent">
          <div className="navigation-body">
            {this.state.currentTab === "contents" ? (
              <ContentList />
            ) : (
              <BookNavList {...bookmarkProps} />
            )}
          </div>
        </div>
      );
    }
  };

  getTextSerachBox = () => {
    const searchProps = {
      mode: this.state.isSearch ? "" : "nav",
      width: "150px",
      height: "35px",
      isNavSearch: this.state.isSearch,
      handleSearchState: this.handleSearchState,
      handleSearchList: this.handleSearchList,
    };
    let readerConfig = localStorage.getItem("readerConfig");
    let readerConfigObj = JSON.parse(readerConfig);

    if (readerConfigObj.rm === "r" || readerConfigObj.rm === "o") {
      return <SearchBox {...searchProps} />;
    }
  };

  render() {
    const searchProps = {
      mode: this.state.isSearch ? "" : "nav",
      width: "150px",
      height: "35px",
      isNavSearch: this.state.isSearch,
      handleSearchState: this.handleSearchState,
      handleSearchList: this.handleSearchList,
      readerType: this.props.readerType,
    };
    const bookmarkProps = {
      currentTab: this.state.currentTab,
      readerType: this.props.readerType,
    };
    return (
      <div className="navigation-panel">
        {this.state.isSearch ? (
          <>
            <div
              className="nav-close-icon"
              onClick={() => {
                this.handleSearchState(false);
                this.props.handleSearch(false);
                this.setState({ searchList: null });
              }}
            >
              <span className="icon-close"></span>
            </div>

            <div
              className="header-search-container"
              style={this.state.isSearch ? { left: 40 } : {}}
            >
              <div
                className="navigation-search-title"
                style={{ height: "20px", margin: "0px 25px 13px" }}
              >
                <Trans>Search in the book</Trans>
              </div>
              {this.getTextSerachBox()}
            </div>
            <ul className="nav-search-list">
              {this.state.searchList ? this.renderSearchList() : null}
            </ul>
            <ul className="nav-search-page">
              {this.state.searchList ? this.renderSearchPage() : null}
            </ul>
          </>
        ) : (
          <>
            <div className={[
              "navigation-header",
              this.state.cover &&
              this.props.currentBook.cover !== "noCover"
                ? ""
                : "no-cover"
            ].join(" ")}>
              <Tooltip
                title={this.state.isNavLocked ? "Unlock" : "Lock"}
                position="bottom"
                trigger="mouseenter"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "30px",
                }}
              >
                <span
                  className={
                    this.state.isNavLocked
                      ? "icon-lock nav-lock-icon hidden"
                      : "icon-unlock nav-lock-icon hidden"
                  }
                  onClick={() => {
                    this.handleLock();
                  }}
                ></span>
              </Tooltip>
               {/*<div className="book-cover">
                  <EmptyCover
                    {...{
                      format: this.props.currentBook.format,
                      title: this.props.currentBook.name,
                      scale: 0.86,
                    }}
                  />
                  </div>*/}
              {this.state.cover &&
              this.props.currentBook.cover !== "noCover" ? (
                <img className="book-cover" src={this.state.cover} alt="" />
              ) : (
                <></>
                
              )}

              <p className="book-title">{this.props.currentBook.name}</p>
              <p className="book-arthur">
                <Trans>Author</Trans>:{" "}
                {this.props.currentBook.author
                  ? this.props.currentBook.author
                  : "Unknown Authur"}
              </p>
              <span className="reading-duration">
                <Trans>Reading Time</Trans>: {Math.floor(this.props.time / 60)}
                &nbsp; <Trans>min</Trans>
              </span>
              {this.getSearchBox()}

              {this.getNavigation()}
            </div>
            {this.getBodyParent()}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    bookmarks: state.reader.bookmarks,
    htmlBook: state.reader.htmlBook,
  };
};
const actionCreator = { handleFetchBookmarks, handleSearch };
export default connect(mapStateToProps, actionCreator)(NavigationPanel);
