import copy from "copy-text-to-clipboard";
import localforage from "localforage";
import React from "react";
import { connect } from "react-redux";
import { popupList } from "../../../../constants/reader/popupList";
import Note from "../../../../model/Note";
import {
  handleChangeDirection,
  handleFetchNotes,
  handleMenuMode,
  handleOpenMenu,
  handleOriginalText,
} from "../../../../redux/actions/reader";
import { getHightlightCoords } from "../../../../utils/reader/fileUtils/pdfUtil";
import RecordLocation from "../../../../utils/reader/readUtils/recordLocation";
import {
  getIframeDoc,
  getIframeDoc2,
} from "../../../../utils/reader/serviceUtils/docUtil";
import {
  getSelection,
  getSelection2,
} from "../../../../utils/reader/serviceUtils/mouseEvent";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import { openExternalUrl } from "../../../../utils/reader/serviceUtils/urlUtil";
import ColorOption from "../../colorOption/component";
import "./popupOption.css";
import { Tooltip } from "react-tippy";
import { Trans } from "react-i18next";
//import SelectOption from "../../../SelectOption/SelectOption";
//import { STYLETYPE_SECOND } from "../../../../utils/Contants";
import { getElementById } from "domutils";

class PopupOption extends React.Component {
  langTxt = "粵語";
  constructor(props) {
    super(props);
    var playSound = false;

    ////console.log("PopupOption", this.props);
  }
  handleNote = () => {
    //console.log("handleNote");
    this.props.handleChangeDirection(false);
    this.props.handleMenuMode("note");
    this.handleEdge("note");
  };
  handleEdge = (menuMode) => {
    //console.log("PopupOption2", this.props);
    //TO DO position
    let page = { offsetLeft: 0 };

    if (this.props.currentBook.format !== "PDF") {
      page = document.getElementById("page-area");
      if (!page.clientWidth) return;
    }
    let _this = this;
    setTimeout(function() {
      console.log("[PopupOption][handleEdge][setTimeout]");
      let popupMenu = document.querySelector(".popup-menu-container");
      let popupMenu_rect = popupMenu?.getBoundingClientRect();
      let posX = popupMenu?.style.left;
      let posY = popupMenu?.style.top;
      posX = parseInt(posX.substr(0, posX.length - 2));
      posY = parseInt(posY.substr(0, posY.length - 2));
      console.log("CP1", _this.props.rect, popupMenu_rect);
      //let rightEdge = _this.props.pageWidth - 310 + page.offsetLeft * 2;
      let rightEdge = _this.props.rect.left + (_this.props.rect.width / 2) - (59 + 20);
      /*
      if (_this.props.leftOrRight == 1) {
        rightEdge = _this.props.pageWidth + page.offsetLeft * 2;
      }
      */
      let _pageWidth = window.innerWidth;
      console.log("CP2"
        , "rightEdge", rightEdge
        , "popupMenu_rect.width", popupMenu_rect.width
        , "(rightEdge + popupMenu_rect.width)", (rightEdge + popupMenu_rect.width)
        , "_pageWidth", _pageWidth
      );
      if ( (rightEdge + popupMenu_rect.width) > _pageWidth) {
        console.log("CP2a");
        rightEdge = _pageWidth - popupMenu_rect.width;
        rightEdge -= 10; // popup-close
        rightEdge -= 10; // gap
      }
      if (rightEdge < 0) {
        console.log("CP2b");
        rightEdge = 0
      }
      
      /*
      if (posX > rightEdge) {
        popupMenu?.setAttribute("style", `left:${rightEdge}px;top:${posY}px`);
      }
      */
      let bottomEdge = _this.props.rect.top - popupMenu_rect.height;
      if (bottomEdge < 0 && posY > bottomEdge) {
        bottomEdge = _this.props.rect.bottom;
        console.log("CP2 menuMode", menuMode);
        if (menuMode === "trans") {
          bottomEdge += 16;
        }
      }
      console.log("CP1 bottomEdge", bottomEdge);
      popupMenu?.setAttribute("style", `left:${rightEdge}px;top:${bottomEdge}px`);
    }, 300);
  };
  handleCopy = () => {
    let text = "";
    if (this.props.currentBook.format === "EPUB") {
      text = getSelection2(this.props.leftOrRight);
    } else if (this.props.currentBook.format === "PDF") {
      text = getSelection2(0);
    }
    text = localStorage.getItem("selectionaa").replace(/(\r\n|\n|\r)/gm, "");
    if (!text) return;
    copy(text);
    this.props.handleOpenMenu(false);
    let doc = getIframeDoc2(this.props.leftOrRight);
    if (!doc) return;
    doc.getSelection()?.empty();
    alert("Copy Successfully");
  };
  handleTrans = () => {
    this.props.handleMenuMode("trans");
    if (this.props.currentBook.format != "PDF") {
      this.props.handleOriginalText(
        getSelection2(this.props.leftOrRight) || ""
      );
    } else {
      this.props.handleOriginalText(getSelection2(0) || "");
    }
    this.handleEdge("trans");
  };
  handleDigest = () => {
    ////console.log("handleDigest left or right:",this.props.leftOrRight);
    // //console.log("handleDigest this.props:",this.props);
    // //console.log("handleDigest, localStorage range 111" + localStorage.getItem("range") );

    let _localStorage_range = {};
    try {
      _localStorage_range = JSON.parse(localStorage.getItem("range"));
    } catch (err) {
      //console.log("err", err);
    }
    // //console.log("handleDigest, localStorage range 222", _localStorage_range );

    let bookKey = this.props.currentBook.key;
    let cfi = "";
    if (this.props.currentBook.format === "EPUB") {
      cfi = RecordLocation.getCfi(this.props.currentBook.key).cfi;
    } else if (this.props.currentBook.format === "PDF") {
      cfi = JSON.stringify(
        //RecordLocation.getPDFLocation(this.props.currentBook.md5)
        RecordLocation.getPDFLocation(this.props.currentBook.md5.split("-")[0])
      );
    } else {
      cfi = JSON.stringify(
        RecordLocation.getHtmlLocation(this.props.currentBook.key)
      );
    }
    let percentage = RecordLocation.getCfi(this.props.currentBook.key)
      .percentage
      ? RecordLocation.getCfi(this.props.currentBook.key).percentage
      : 0;
    let color = this.props.color;
    let notes = "";
    let pageArea = document.getElementById("page-area");
    if (!pageArea) return;
    //let iframe = pageArea.getElementsByTagName("iframe")[this.props.leftOrRight];
    let iframe;
    if (this.props.currentBook.format === "PDF") {
      iframe = pageArea.getElementsByTagName("iframe")[0];
    } else {
      iframe = pageArea.getElementsByTagName("iframe")[this.props.leftOrRight];
    }
    // if (!iframe) return;
    // let doc = iframe.contentDocument;
    // if (!doc) return;

    ////console.log("ddddddfeeee",doc.getSelection());
    // let charRange;
    // if (this.props.currentBook.format !== "PDF") {
    //   charRange = window.rangy
    //     .getSelection(iframe)
    //     .saveCharacterRanges(doc.body)[0];
    // }
    // //console.log("handleDigest charRange", charRange);
    // if (charRange == undefined) {
    //   charRange = _localStorage_range;
    // }

    let range =
      this.props.currentBook.format === "PDF"
        ? JSON.stringify(getHightlightCoords())
        : JSON.stringify(_localStorage_range);
    //let text = doc.getSelection()?.toString();
    let text = localStorage.getItem("selectionaa");
    //console.log("ssdsfsdfsdfsdfsdfdsfsdfs", text);
    if (!text) return;
    text = text.replace(/\s\s/g, "");
    text = text.replace(/\r/g, "");
    text = text.replace(/\n/g, "");
    text = text.replace(/\t/g, "");
    text = text.replace(/\f/g, "");
    // //console.log("bookKey:::::",bookKey)
    // //console.log("chapter:::::",this.props.chapter)
    // //console.log("chapterIndex:::::",this.props.chapterIndex)
    // //console.log("text:::::",text)
    // //console.log("cfi:::::",cfi)
    // //console.log("notes:::::",notes)
    // //console.log("percentage:::::",percentage)
    // //console.log("color:::::",color)
    // //console.log("this.props.leftOrRight:::::",this.props.leftOrRight)

    let digest = new Note(
      bookKey,
      this.props.chapter,
      this.props.chapterIndex,
      text,
      cfi,
      range,
      notes,
      percentage,
      color,
      this.props.leftOrRight,
      []
    );
    //console.log("digest", digest);
    let noteArr = this.props.notes;
    noteArr.push(digest);
    localforage.setItem("notes", noteArr).then(() => {
      this.props.handleOpenMenu(false);
      //alert("Add Successfully");
      this.props.handleFetchNotes();
      this.props.handleMenuMode("highlight");

      // CTS
      try {
        let _notes = this.props.notes;
        this.props.handleSaveReaderLogNote(_notes);
      } catch (err) {
        console.log("CP1 err", err);
      }
      // CTS end
    });

    // let classes = [
    //   "color-0",
    //   "color-1",
    //   "color-2",
    //   "color-3",
    //   "line-0",
    //   "line-1",
    //   "line-2",
    //   "line-3",
    // ];
    // if (this.props.currentBook.format === "PDF") {
    //   showHighlight(JSON.parse(range), classes[color]);
    // }
  };
  handleJump = (url) => {
    openExternalUrl(url);
  };
  handleSearchInternet = () => {
    let text = "";
    if (this.props.currentBook.format === "EPUB") {
      text = getSelection2(this.props.leftOrRight);
    } else if (this.props.currentBook.format === "PDF") {
      text = getSelection2(0);
    }

    text = localStorage.getItem("selectionaa").replace(/(\r\n|\n|\r)/gm, "");
    switch (StorageUtil.getReaderConfig("searchEngine")) {
      case "google":
        this.handleJump("https://www.google.com/search?q=" + text); //getSelection2(this.props.leftOrRight));
        break;
      case "baidu":
        this.handleJump("https://www.baidu.com/s?wd=" + text); //getSelection2(this.props.leftOrRight));
        break;
      case "bing":
        this.handleJump("https://www.bing.com/search?q=" + text); //getSelection2(this.props.leftOrRight));
        break;
      case "duckduckgo":
        this.handleJump("https://duckduckgo.com/?q=" + text); //getSelection2(this.props.leftOrRight));
        break;
      case "yandex":
        this.handleJump("https://yandex.com/search/?text=" + text); //getSelection2(this.props.leftOrRight));
        break;
      case "yahoo":
        this.handleJump("https://search.yahoo.com/search?p=" + text); //getSelection2(this.props.leftOrRight));
        break;
      case "naver":
        this.handleJump(
          "https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=1&ie=utf8&query=" +
            text //getSelection2(this.props.leftOrRight)
        );
        break;
      case "baike":
        this.handleJump("https://baike.baidu.com/item/" + text); //getSelection2(this.props.leftOrRight));
        break;
      case "wiki":
        this.handleJump("https://en.wikipedia.org/wiki/" + text); //getSelection2(this.props.leftOrRight));
        break;
      default:
        this.handleJump(
          navigator.language === "zh-CN"
            ? "https://www.baidu.com/s?wd=" + text //getSelection2(this.props.leftOrRight)
            : "https://www.google.com/search?q=" + text //getSelection2(this.props.leftOrRight)
        );
        break;
    }
  };
  handleSearchBook = () => {
    let leftPanel = document.querySelector(".left-panel");
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    // //console.log("handleSearchBook111111",this.props.currentBook.format )
    if (this.props.currentBook.format === "EPUB") {
      if (!leftPanel) return;
      leftPanel.dispatchEvent(clickEvent);
    }
    const focusEvent = new MouseEvent("focus", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    let searchBox;
    let searchIcon;
    if (this.props.currentBook.format === "PDF") {
      searchBox = document
        .getElementsByTagName("iframe")[0]
        .contentDocument.getElementById("findInput");
      searchIcon = document
        .getElementsByTagName("iframe")[0]
        .contentDocument.getElementById("viewFind");
      //console.log("searchBox", searchBox);
      //console.log("searchIcon", searchIcon);
    } else if (this.props.currentBook.format === "EPUB") {
      searchBox = document.querySelector(".header-search-box");
      searchIcon = document.querySelector(".header-search-icon");
      //console.log("searchBox2", searchBox);
      //console.log("searchIcon2", searchIcon);
    }
    searchBox.dispatchEvent(focusEvent);
    if (this.props.currentBook.format === "PDF") {
      if (!searchIcon.classList.contains("toggled")) {
        searchIcon?.dispatchEvent(clickEvent);
      }
    } else if (this.props.currentBook.format === "EPUB") {
      searchIcon?.dispatchEvent(clickEvent);
    }

    let text = "";
    if (this.props.currentBook.format === "EPUB") {
      text = getSelection2(this.props.leftOrRight);
    } else if (this.props.currentBook.format === "PDF") {
      text = getSelection2(0);
    }
    text = localStorage.getItem("selectionaa").replace(/(\r\n|\n|\r)/gm, "");
    //console.log("searchbox", text);
    searchBox.value = text; //getSelection2(this.props.leftOrRight) || "";
    const keyEvent = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: true,
      keyCode: 13,
    });
    searchBox.dispatchEvent(keyEvent);
    this.props.handleOpenMenu(false);
  };

  handleSpeak = () => {
    if (this.voiceIdx === undefined) {
      this.voiceIdx = 1;
    }
    //console.log("handleSpeak 1", this.langTxt);
    let text = "";
    if (this.props.currentBook.format === "EPUB") {
      text = getSelection2(this.props.leftOrRight);
    } else if (this.props.currentBook.format === "PDF") {
      text = getSelection2(0);
    }
    text = localStorage.getItem("selectionaa").replace(/(\r\n|\n|\r)/gm, "");
    //console.log("handleSpeak 2", text, this.voiceIdx);
    window.speechSynthesis.cancel();

    var msg = new SpeechSynthesisUtterance(text);
    //getSelection2(this.props.leftOrRight));
    //msg.text = getSelection() || "";

    switch (this.langTxt) {
      case "粵語":
        msg.lang = "zh-HK";
        break;
      case "普通話":
        msg.lang = "zh-TW";
        break;
      case "英語":
        msg.lang = "en-US";
        break;
    }

    //msg.text = text;

    //console.log("handleSpeak 3", msg.lang);

    // console.log(
    //   "handleSpeak 3",
    //   msg.lang,
    //   window.speechSynthesis.getVoices().findIndex((it) => it.lang === msg.lang)
    // );
    msg.voice =
      window.speechSynthesis.getVoices()[
        window.speechSynthesis
          .getVoices()
          .findIndex((it) => it.lang === msg.lang)
      ];

    window.speechSynthesis.speak(msg);
    //console.log("handleSpeak 4", msg);
    return new Promise((resolve) => {
      msg.onend = function () {
        this.playSound = false;
      };
    });
  };

  //TODO TTS filter
  handleVoiceLang = (_langTxt, index) => {
    let langTxt = "";

    if (index === 1) {
      switch (_langTxt) {
        case "zh-HK":
          this.langTxt = "粵語";
          break;
        case "zh-TW":
          this.langTxt = "普通話";
          break;
        case "en-US":
          this.langTxt = "英語";
          break;
      }
    }
    switch (_langTxt) {
      case "zh-HK":
        langTxt = "粵語";
        break;
      case "zh-TW":
        langTxt = "普通話";
        break;
      // case "zh-CN":
      //   langTxt = " 普通話";
      //   break;
      // case "ru-RU":
      //   langTxt="俄羅斯語";
      //   break;
      case "en-US":
        langTxt = "英語"; //"美式英語";
        break;
      // case "ja-JP":
      //   langTxt="日語";
      //   break;
      // case "pt-BR":
      //   langTxt="葡萄牙語";
      //   break;
      // case "it-IT":
      //   langTxt="意大利語";
      //   break;
      // case "ko-KR":
      //   langTxt="韓語";
      //   break;
      // case "nl-NL":
      //   langTxt="荷蘭語";
      //   break;
      // case "pl-PL":
      //   langTxt="波蘭語";
      //   break;
      // case "id-ID":
      //   langTxt="印尼語";
      //   break;
      // case "hi-IN":
      //   langTxt="印度語";
      //   break;
      // case "fr-FR":
      //   langTxt="法語";
      //   break;
      // case "es-US":
      //   langTxt="西班牙英語";
      //   break;
      // case "es-ES":
      //   langTxt="西班牙語";
      //   break;
      // case "en-GB":
      //   langTxt = " 英語";
      //   break;
      // case "de-DE":
      //   langTxt="德國語";
      //   break;
      // case "he-IL":
      //   langTxt="希伯來語";
      //   break;
      // case "el-GR":
      //   langTxt="希臘";
      //   break;
      // case "sv-SE":
      //   langTxt="瑞典語";
      //   break;
      // case "ar-AE":
      //   langTxt="阿拉伯語";
      //   break;
    }

    return langTxt;
  };

  handleVoiceSelect = () => {
    let voiceSelect = document.createElement("select");
    for (var i = 0; i < window.speechSynthesis.getVoices().length; i++) {
      console.log(window.speechSynthesis.getVoices()[i]);
      let option = document.createElement("option");
      option.value = i;
      option.innerHTML =
        window.speechSynthesis.getVoices()[i].lang +
        " " +
        window.speechSynthesis.getVoices()[i].name;
      voiceSelect.append(option);
    }
    //return voiceSelect;
  };

  handleChange = (e) => {
    //console.log("handleChange1", e);
    this.voiceIdx = e.target.value;

    let selectOption = document.getElementById("voiceIdx");
    let value = selectOption.value;
    let text = selectOption.options[selectOption.selectedIndex].text;
    //console.log("handleChange2", value);
    //console.log("handleChange3", text);
    this.langTxt = text;

    // handle other stuff like persisting to store etc
  };

  componentDidMount() {
    var sUsrAg = navigator.userAgent;
    //console.log("popupComponent", sUsrAg);
    //console.log("popupComponent", window.speechSynthesis.getVoices());
  }

  render() {
    const renderMenuList = () => {
      return (
        <>
          <div className="menu-list">
            {popupList.map((item, index) => {
              return (
                <div
                  key={item.name}
                  className={item.name + "-option"}
                  onClick={() => {
                    switch (index) {
                      case 0:
                        this.handleNote();
                        break;
                      case 1:
                        this.handleDigest();
                        break;
                      case 2:
                        this.handleTrans();
                        break;
                      case 3:
                        this.handleCopy();
                        break;
                      case 4:
                        this.handleSearchBook();
                        break;
                      case 5:
                        this.handleSearchInternet();
                        break;
                      case 6:
                        if (this.playSound) {
                          window.speechSynthesis.cancel();
                          this.playSound = false;
                        } else {
                          this.handleSpeak();
                          this.playSound = true;
                        }
                        break;

                      default:
                        break;
                    }
                  }}
                >
                  <Tooltip
                    title={item.title}
                    position="top"
                    trigger="mouseenter"
                    theme="light"
                  >
                    <span
                      className={`icon-${item.icon} ${item.name}-icon`}
                    ></span>
                  </Tooltip>
                  {
                    //index == 6 ? <select id="voiceIdx" onChange={e => this.handleChange(e.target.value)}> {window.speechSynthesis.getVoices().map((val, key) => this.handleVoiceLang(val.lang)!==""?<option value={key}>{this.handleVoiceLang(val.lang) +" "+val.name}</option>:"")}</select>: <></>

                    window.speechSynthesis !== undefined &&
                    window.speechSynthesis !== null ? (
                      window.speechSynthesis.getVoices() !== undefined &&
                      window.speechSynthesis.getVoices() !== null ? (
                        index === 6 ? (
                          <>
                            <select
                              id="voiceIdx"
                              onChange={(e) => this.handleChange(e)}
                            >
                              {window.speechSynthesis
                                .getVoices()
                                .filter(
                                  (v, i, a) =>
                                    a?.findIndex((v2) => v2.lang === v.lang) ===
                                    i
                                )
                                .filter((it) => {
                                  if (
                                    it.lang.toLowerCase().includes("en-us") ||
                                    it.lang.toLowerCase().includes("hk") ||
                                    it.lang.toLowerCase().includes("tw")
                                  ) {
                                    return it;
                                  }
                                })
                                .map((it, index) => {
                                  return this.handleVoiceLang(it.lang) !==
                                    "" ? (
                                    <>
                                      <option value={index + 1}>
                                        {this.handleVoiceLang(
                                          it.lang,
                                          index + 1
                                        )}
                                      </option>
                                    </>
                                  ) : (
                                    <></>
                                  );
                                })}
                            </select>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )

                    // index == 6 ? (
                    //     <select id="voiceIdx" onChange={(e) => this.handleChange(e.target.value)}>
                    //         {" "}
                    //         {window.speechSynthesis
                    //             .getVoices()
                    //             .map((val, key) =>
                    //                 this.handleVoiceLang(val.lang) !== "" ? <option value={key}>{this.handleVoiceLang(val.lang)}</option> : ""
                    //             )}
                    //     </select>
                    // ) : (
                    //     <></>
                    // )
                  }
                </div>
              );
            })}
          </div>
          <ColorOption {...{ readerType: this.props.readerType }} />
        </>
      );
    };
    return renderMenuList();
  }
}
const mapStateToProps = (state) => {
  return {
    currentBook: state.book.currentBook,
    currentEpub: state.book.currentEpub,
    selection: state.viewArea.selection,
    notes: state.reader.notes,
    color: state.reader.color,
    flattenChapters: state.reader.flattenChapters,
    menuMode: state.viewArea.menuMode,
  };
};
const actionCreator = {
  handleOpenMenu,
  handleMenuMode,
  handleFetchNotes,
  handleOriginalText,
  handleChangeDirection,
};
export default connect(mapStateToProps, actionCreator)(PopupOption);
