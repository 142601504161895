import {g, p} from './BasicAPI';

export const getAll = () =>
{
    return g(`/category/all`).then(res => res.json());
}

export function getById($id)
{
    return g(`/category/${$id}`).then(res => res.json());
}

export function updateByCategoryName(categoryName, bannerImage, mobileBannerImage)
{
    return p(`/category/update/${categoryName}`, 
    {
        bannerImage,
        mobileBannerImage,
    }).then(t => t.json());
}