// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShoppingCart_shopingCartContainer__DAQEU {
  width: min(100%, 1095px);
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
}

.ShoppingCart_horizontalLine__sCD8w {
  width: 100%;
  border-top: 1px solid rgb(198 198 198);
}

.ShoppingCart_cartHeader__MJylr {
  display: flex;
  justify-content: space-between;
  margin: 0 6em 0 0;
  font-size: 1em;
}

.ShoppingCart_totalPriceContainer__CJW-L {
  display: flex;
  justify-content: space-between;
  margin: 2em 0 2em 0;
  font-size: 2em;
}

.ShoppingCart_buttonContainer__hDpVJ {
  height: 60px;
}

@media only screen and (max-width:1024px) {

  .ShoppingCart_shopingCartContainer__DAQEU{
    margin-left: 2em;
    margin-right: 2em;
    max-width: -webkit-fill-available;
  }

}`, "",{"version":3,"sources":["webpack://./src/components/ShopingCart/ShoppingCart.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE;IACE,gBAAgB;IAChB,iBAAiB;IACjB,iCAAiC;EACnC;;AAEF","sourcesContent":[".shopingCartContainer {\n  width: min(100%, 1095px);\n  display: flex;\n  flex-direction: column;\n  margin: 3rem auto;\n}\n\n.horizontalLine {\n  width: 100%;\n  border-top: 1px solid rgb(198 198 198);\n}\n\n.cartHeader {\n  display: flex;\n  justify-content: space-between;\n  margin: 0 6em 0 0;\n  font-size: 1em;\n}\n\n.totalPriceContainer {\n  display: flex;\n  justify-content: space-between;\n  margin: 2em 0 2em 0;\n  font-size: 2em;\n}\n\n.buttonContainer {\n  height: 60px;\n}\n\n@media only screen and (max-width:1024px) {\n\n  .shopingCartContainer{\n    margin-left: 2em;\n    margin-right: 2em;\n    max-width: -webkit-fill-available;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shopingCartContainer": `ShoppingCart_shopingCartContainer__DAQEU`,
	"horizontalLine": `ShoppingCart_horizontalLine__sCD8w`,
	"cartHeader": `ShoppingCart_cartHeader__MJylr`,
	"totalPriceContainer": `ShoppingCart_totalPriceContainer__CJW-L`,
	"buttonContainer": `ShoppingCart_buttonContainer__hDpVJ`
};
export default ___CSS_LOADER_EXPORT___;
