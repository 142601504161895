import React, { useEffect, useState } from "react";
import style from "../FrequentlyQuestions/FrequentlyQuestions.module.css";
import * as API from "../../api/PageContentAPI";

export default function News() {
  const [pageContent, setPageContent] = useState("");
  useEffect(() => {
    API.getPageContentById(2).then((res) => setPageContent(res.content));
  }, []);
  return (
    <div
      className={style.container}
      dangerouslySetInnerHTML={{ __html: pageContent }}
    ></div>
  );
}
