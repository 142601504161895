import React from "react";
import BookCard from "../BookCard/BookCard";
import style from "./BookCollection.module.css";
import dropDownMore from "../../assets/icons/componentdropdownmore.svg";
import { useNavigate, useParams } from "react-router-dom";
import dropdown from "../../assets/icons/down@1x.svg";
import { useTranslation, Trans } from "react-i18next";

export default function BookCollection({ props, title, icon }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className={style.BookCollectionHeaderContainer}>
        <img src={icon} alt="" />
        <h2>
          {title === 10000
            ? t(`category.10000`)
            : title === 20000
            ? t(`category.20000`)
            : title === 30000
            ? t(`category.30000`)
            : title === 40000
            ? t(`category.40000`)
            : title === 50000
            ? t(`category.50000`)
            : ""}
        </h2>
      </div>
      <div className={style.bookCardContainer}>
        {props &&
          props.map((item: any) => (
            <BookCard props={item} key={item.displayProductId} />
          ))}
      </div>
      <div className={style.dropDownMore}>
        <p onClick={() => navigate(`category/${title}`)}>{t("viewMore")}</p>
        <img src={dropdown} />
      </div>
      <hr className={style.horizontalLine}></hr>
    </>
  );
}
