import {
  ADD_COUPON,
  ADD_PRODUCT_ITEM,
  CLEAR_SHOPPING_CART,
  DELETE_COUPON,
  DELETE_PRODUCT_ITEM,
  REFRESH_SHOPPING_CART,
} from "../../utils/Constant";

const initialState = {
  productList: [],
  total: 0,
  total_before_discount: 0,
  quantity: 0,
  point: 0,
  point_cash: 0,
  coupon: null,
  promotionCode: null,
  pointToCash: 0,
};

function shoppingCartReducer(state = initialState, action) {
  //console.log("shoppingCartReducer", action, state);
  if (action.type === ADD_PRODUCT_ITEM) {
    let addProduct = [];
    let total = 0;
    let flag = 0;
    let quantity = 0;
    let total_before_discount = 0;
    state.productList.forEach((item) => {
      if (item.product_id === action.payload.product_id) {
        flag = 1;
        let product = action.payload;
        product.quantity = product.quantity + item.quantity;
        addProduct = addProduct.concat(product);
        if (action.payload.product_discount_price == null) {
          total += action.payload.product_price * action.payload.quantity;
        } else {
          total +=
            action.payload.product_discount_price * action.payload.quantity;
        }
        total_before_discount +=
          action.payload.product_price * action.payload.quantity;
        quantity += action.payload.quantity;
      } else {
        addProduct = addProduct.concat(item);
        if (item.product_discount_price == null) {
          total += item.product_price * item.quantity;
        } else {
          total += item.product_discount_price * item.quantity;
        }
        total_before_discount += item.product_price * item.quantity;
        quantity += item.quantity;
      }
    });
    if (flag === 1) {
      return Object.assign({}, state, {
        productList: addProduct,
        total: total,
        quantity: quantity,
        total_before_discount: total_before_discount,
        coupon: state.coupon,
        point_cash: state.coupon,
        pointToCash: state.pointToCash,
        promotionCode: state.promotionCode,
      });
    } else {
      return Object.assign({}, state, {
        productList: state.productList.concat(action.payload),
        total:
          action.payload.product_discount_price == null
            ? state.total +
              action.payload.product_price * action.payload.quantity
            : state.total +
              action.payload.product_discount_price * action.payload.quantity,
        quantity: state.quantity + action.payload.quantity,
        total_before_discount: total_before_discount,
        coupon: state.coupon,
        point_cash: state.coupon,
        promotionCode: state.promotionCode,
      });
    }
  }
  if (action.type === DELETE_PRODUCT_ITEM) {
    let deletedProduct = [];
    let total = state.total;
    let quantity = state.quantity;
    let totalBeforeDiscount = state.total_before_discount;
    state.productList.forEach((item) => {
      if (item.product_id === action.payload.product_id) {
        if (item.product_discount_price == null)
          total -= item.product_price * item.quantity;
        else total -= item.product_discount_price * item.quantity;
        totalBeforeDiscount -= item.product_price;
        quantity -= item.quantity;
      } else {
        deletedProduct = deletedProduct.concat(item);
      }
    });
    return Object.assign({}, state, {
      productList: deletedProduct,
      total: total,
      quantity: quantity,
      total_before_discount: totalBeforeDiscount,
      pointToCash: state.pointToCash,
      point_cash: state.point_cash,
    });
  }

  if (action.type === CLEAR_SHOPPING_CART) {
    return {
      productList: [],
      total: 0,
      total_before_discount: 0,
      quantity: 0,
      coupon: null,
      promotionCode: null,
      point_cash: 0,
      point: 0,
      pointToCash: 0,
    };
  }

  if (action.type === REFRESH_SHOPPING_CART) {
    return action.payload;
  }

  if (action.type === ADD_COUPON) {
    return {
      productList: state.productList,
      total: state.total,
      quantity: state.quantity,
      total_before_discount: state.total_before_discount,
      coupon: action.payload,
      point: state.point,
      pointToCash: state.pointToCash,
      point_cash: state.point_cash,
    };
  }

  if (action.type === DELETE_COUPON) {
    return {
      productList: state.productList,
      total: state.total,
      quantity: state.quantity,
      total_before_discount: state.total_before_discount,
      coupon: null,
      point: state.point,
      pointToCash: state.pointToCash,
      point_cash: state.point_cash,
    };
  }

  return state;
}

export default shoppingCartReducer;
