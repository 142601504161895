import React, { Component } from "react";
import Button from "../Button/Button";
import style from "./Input.module.css";
import { withTranslation } from "react-i18next";

interface IProps {
  type: string;
  placeholder: any;
  value?: string;
  callBack: (value: any, value2?: any) => void;
  styleType: string;
  id?: string;
  name?: any;
  children?: any;
  checked?: boolean;
  min?: number;
  max?: number;
  step?: number;
  t?: any;
  onKeyDown?: any;
}

interface IState {
  textLength: number;
  validData: object;
  showError: boolean;
  errorStyle: object;
  value?: string;
}

class Input extends Component<IProps, IState> {
  state = {
    textLength: 0,
    validData: {},
    showError: false,
    errorStyle: {},
    value: "",
  };

  resetState = () => {
    this.setState({ textLength: 0, validData: {}, showError: false });
  };
  hanldeOnChangeText = (event: any) => {
    this.resetState();
    // console.log(typeof event.target);
    if (event.target.value === "") {
      this.setState({ textLength: event.target.value.length });
    }
    switch (event.target.type) {
      case "text":
        this.props.callBack(event.target.value, this.state.showError);
        this.setState({ value: event.target.value });
        break;
      case "password":
      case "email":
        this.setState({ ...this.state, textLength: event.target.value.length });
        this.props.callBack(event.target.value, this.state.showError);
        break;
      case "number":
        if (event.target.value !== "") {
          this.setState({ textLength: 1 });
        } else {
          this.setState({ textLength: 0 });
        }
        this.props.callBack(parseInt(event.target.value));
        break;
      case "checkbox":
      case "radio":
        this.props.callBack(event.target.checked);
        break;
      case "file":
        this.props.callBack(event.nativeEvent.target.files);
        break;
    }
  };

  onKeyDown = (e: any) => {
    if (this.props.onKeyDown !== undefined) {
      if (this.props.onKeyDown.action === "login") {
      }
      if (e.key === "Enter") {
        if (this.props.onKeyDown.value !== "") {
          this.props.onKeyDown.action(
            "search?querystring=" + this.props.onKeyDown.value
          );
        } else {
          return;
        }
        // 👇 Get input value
      }
    }
  };

  // getErrorMsg = () => {
  //   const { validData } = this.state;
  //   if (!validData.hasOwnProperty("month")) {
  //     if (!validData["month"]) {
  //       return <>請輸入正確日期</>;
  //     }
  //   }

  //   if (validData.hasOwnProperty("date")) {
  //     if (!validData["month"]) {
  //       return <>請輸入正確日期</>;
  //     }
  //   }
  // };
  render() {
    return (
      <div
        className={
          this.props.styleType === "checkbox"
            ? style.checkbox
            : this.props.styleType === "loginInput"
            ? style.loginInput
            : this.props.styleType === "searchInput"
            ? style.searchInput
            : this.props.styleType === "commentInput"
            ? style.commentInput
            : ""
        }
      >
        <input
          id={this.props.id}
          type={this.props.type}
          placeholder={
            this.props.placeholder != ""
              ? this.props.t(this.props.placeholder)
              : this.props.placeholder
          }
          value={
            this.props.type === "number"
              ? this.props.value === ""
                ? 0
                : this.props.value
              : this.props.value
          }
          onChange={this.hanldeOnChangeText}
          checked={this.props.checked}
          max={this.props.max}
          min={this.props.min}
          step={this.props.step}
          onKeyDown={this.onKeyDown}
          // className={
          //   this.props.styleType === "style1"
          //     ? style.inputPrimary
          //     : this.props.styleType === "searchInput"
          //     ? style.searchInput
          //     : this.props.styleType === "checkbox"
          //     ? style.checkbox
          //     : this.props.styleType === "loginInput"
          //     ? style.loginInput
          //     : ""
          // }
        ></input>
        {this.props.id === "使用" ? (
          <Button
            buttonType={"button"}
            styleType={"verifyButton"}
            callBack={() =>
              this.props.callBack({
                action: "applyDiscount",
                couponCode: this.state.value,
              })
            }
          >
            {this.props.id}
          </Button>
        ) : this.props.id === "取消" ? (
          <Button
            buttonType={"button"}
            styleType={"verifyButton"}
            callBack={() =>
              this.props.callBack({
                action: "cancelDiscount",
              })
            }
          >
            {this.props.id}
          </Button>
        ) : (
          <></>
        )}
        {this.props.name ? (
          <label htmlFor={this.props.id}>{this.props.name}</label>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withTranslation()(Input);
