import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { handleFetchPercentage } from "../../../../redux/actions/reader";
import RecordLocation from "../../../../utils/reader/readUtils/recordLocation";
import "./progressPanel.css";
import { Tooltip } from "react-tippy";


class ProgressPanel extends React.Component {
  constructor(props) {
    super(props);
    let _percentage = this.props.percentage
      ? this.props.percentage
      : (
          props
          && props.currentBook
          && props.currentBook.key
        )
        ? RecordLocation.getCfi(props.currentBook.key).percentage
        : 0;

    this.state = {
      displayPercentage: _percentage,

      currentPage: 0,
      totalPage: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentEpub.rendition &&
      nextProps.currentEpub.rendition.location &&
      this.props.currentEpub.rendition
    ) {
      const currentLocation =
        this.props.currentEpub.rendition.currentLocation();
      if (!currentLocation.start) {
        return;
      }
      let _percentage = RecordLocation.getCfi(this.props.currentBook.key)
        .percentage
        ? RecordLocation.getCfi(this.props.currentBook.key).percentage
        : 0;
      this.setState({
        //displayPercentage: _percentage,
        currentPage: currentLocation.start.displayed.page,
        totalPage: currentLocation.start.displayed.total,
        currentChapterIndex: currentLocation.start.index,
      });
    }
    if (nextProps.currentBook.key) {
      this.props.handleFetchPercentage(this.props.currentBook);
    }
    if (nextProps.percentage) {
      let _percentage = nextProps.percentage;
      if (
        nextProps.currentEpub.rendition
        && nextProps.currentEpub.rendition.location
        && nextProps.currentEpub.rendition.location.atEnd
        && this.props.currentEpub.rendition
      ) {
        _percentage = 1;
      }
      this.setState({ displayPercentage: _percentage });
    }
  }
  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
  onProgressChange = (event) => {
    const percentage = event.target.value / 100;
    this.setState({ displayPercentage: percentage });

    if (this.props.locations) {
    } else {
      return;
    }
    const location = percentage
      ? this.props.locations.cfiFromPercentage(percentage)
      : 0;
    //if (this.props.currentEpub.rendition.location.atStart) return;
    //if (this.props.currentEpub.rendition.location.atEnd) return;
    this.props.currentEpub.rendition.display(location);
  };
  //使进度百分比随拖动实时变化
  onProgressInput = (event) => {
    const percentage = event.target.value / 100;
    this.setState({ displayPercentage: percentage });
  };
  previourChapter = () => {
    if (!this.props.currentEpub.rendition) {
      return;
    }
    const currentLocation = this.props.currentEpub.rendition.currentLocation();
    if (!currentLocation.start) return;
    let chapterIndex = currentLocation.start.index;
    this.setState({
      currentChapterIndex: chapterIndex,
    });
    const section = this.props.currentEpub.section(chapterIndex - 1);
    if (section && section.href) {
      this.props.currentEpub.rendition.display(section.href).then(() => {
        let percentage = RecordLocation.getCfi(this.props.currentBook.key)
          .percentage
          ? RecordLocation.getCfi(this.props.currentBook.key).percentage
          : 0;

        this.setState({ displayPercentage: percentage });
      });
    }
  };
  nextChapter = () => {
    const currentLocation = this.props.currentEpub.rendition.currentLocation();
    if (!currentLocation.start) return;
    let chapterIndex = currentLocation.start.index;
    this.setState({
      currentChapterIndex: chapterIndex,
    });
    const section = this.props.currentEpub.section(chapterIndex + 1);
    if (section && section.href) {
      this.props.currentEpub.rendition.display(section.href).then(() => {
        let percentage = RecordLocation.getCfi(this.props.currentBook.key)
          .percentage
          ? RecordLocation.getCfi(this.props.currentBook.key).percentage
          : 0;
        this.setState({ displayPercentage: percentage });
        //console.log("percentage", percentage);
      });
    }
  };
  handleJumpChapter = (event) => {
    if (!event.target.value) return;
    const section = this.props.currentEpub.section(event.target.value);
    if (section && section.href) {
      this.props.currentEpub.rendition.display(section.href).then(() => {
        let percentage = RecordLocation.getCfi(this.props.currentBook.key)
          .percentage
          ? RecordLocation.getCfi(this.props.currentBook.key).percentage
          : 0;
        this.setState({ displayPercentage: percentage });
      });
    }
  };

  render() {
    /*
    if (!this.props.locations && this.props.currentEpub.rendition) {
      //return <div className="progress-panel" style={{ textAlign: 'center' }}>Loading</div>;
    }
    */
    if (this.props.locations) {
    } else {
      return <div className="progress-panel" style={{ textAlign: 'center' }}>Loading</div>;
    }

    let _displayPercentage = Math.round(
      this.state.displayPercentage > 1
        ? 100
        : this.state.displayPercentage * 100
    );
    let _flag_NaN = false;
    if (isNaN(_displayPercentage)) {
      _flag_NaN = true;
    }

    return (
      <div className="progress-panel">
        <p className="progress-text" style={{ marginTop: "5px" }}>
          {this.state.currentPage > 0 ? (
            <>
              {/* Chapters
              <input
                type="text"
                name="jumpPage"
                id="jumpPage"
                value={this.state.currentPage}
              />
              <span>/ {this.state.totalPage}</span>&nbsp;&nbsp;&nbsp; */}
              {/* Chapters
              <input
                type="text"
                name="jumpChapter"
                id="jumpChapter"
                onBlur={(event) => {
                  this.handleJumpChapter(event);
                }}
                defaultValue={this.state.currentChapterIndex}
              />
              <span>
                / {this.props.currentEpub.rendition.book.spine.length}
              </span> */}
            </>
          ) : null}
        </p>
        <div>
          <input
            className="input-progress"
            defaultValue={Math.round(this.state.displayPercentage * 100)}
            value={Math.round(this.state.displayPercentage * 100)}
            type="range"
            max="100"
            min="0"
            step="1"
            onMouseUp={(event) => {
              this.onProgressChange(event);
            }}
            onTouchEnd={(event) => {
              this.onProgressChange(event);
            }}
            onChange={(event) => {
              this.onProgressInput(event);
            }}
            style={{ width: 300, left: 4, top: 34 }}
          />
        </div>

        {/* <div
          className="previous-chapter"
          onClick={() => {
            this.previourChapter();
          }}
        >
          <Tooltip
            title={"Prev Chapter"}
            position="top"
            trigger="mouseenter"
          >
            <span className="icon-dropdown previous-chapter-icon"> </span>
          </Tooltip>
        </div> */}

        {/* <div
          className="next-chapter"
          onClick={() => {
            this.nextChapter();
          }}
        >
          <Tooltip
            title={"Next Chapter"}
            position="top"
            trigger="mouseenter"
          >
            <span className="icon-dropdown next-chapter-icon"></span>
          </Tooltip>
        </div> */}
        <span 
          className="progress_str"
          style={{ position: "absolute", left: "308px", top: "24px", whiteSpace: "nowrap" }}
        >
          {_flag_NaN
            ? <Trans>Loading Current Progress</Trans>
            : <>
              <Trans>Current Progress</Trans>:&nbsp;
              {_displayPercentage}
              %&nbsp;&nbsp;&nbsp;
            </>
          }
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    percentage: state.progressPanel.percentage,
    locations: state.progressPanel.locations,
    flattenChapters: state.reader.flattenChapters,
  };
};
const actionCreator = { handleFetchPercentage };
export default connect(mapStateToProps, actionCreator)(ProgressPanel);
