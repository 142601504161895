//#region For react-redux

//#endregion

//#region Route Path

export const HOME = "/";
export const LOGIN = "/auth/login";
export const REGISTER = "/auth/login/register";
export const LOGINBYIP = "/auth/loginByIp";
export const LOGOUT = "/auth/logout";
export const VERIFY = "/auth/verify";
export const FORGET = "/auth/forget";
export const ADMINISTRATIVE = "/administrative";
export const BOOKMANAGEMENT = "/bookmanagement";
export const SYSTEMSETTING = "/systemsetting";
export const ORGANIZATION = "/organization";
export const USER_INTERNAL = "/user/internal";
export const USER_PUBLISHER = "/user/publisher";
export const USER_PUBLISHER_BYID = "/user/publisher/:id";
export const USER_SCHOOL = "/user/school";
export const ALL_USER = "/user/all";
export const USER_CHANGE_PASSWORD = "/user/change-password";
export const ADD_USER = "/user/add";
export const DELETE_USER = "/user/delete";
export const UPDATE_USER = "/user/update";
export const ALL_SYSTEM = "/system/all";
export const ADD_SYSTEM = "/system/add";
export const DELETE_SYSTEM = "/system/delete";
export const UPDATE_SYSTEM = "/system/update";
export const ALL_ROLE = "/role/all";
export const ASSIGN_ROLE = "/role/assignRoleToUser";
export const UPDATE_ROLE = "/role/update";
export const ADD_ROLE = "/role/add";
export const DELETE_ROLE = "/role/delete";
export const IMPORT_BOOKS = "/file/import/books";
export const DOWNLOAD_FILE = "/file/download-file";
export const ALL_BOOK = "/book/all";
export const ADD_BOOK = "/book/add";
export const UPDATE_BOOK = "/book/update";
export const DELTE_BOOK = "/book/delete";
export const BASIC = "/book/basic/";
export const COPYRIGHT = "/book/copyright/";
export const LIMIT = "/book/limit/";
export const ALL_ORGANIZATION = "/organization/all";
export const ADD_ORGANIZATION = "/organization/add";
export const UPDATE_ORGANIZATION = "/organization/update";
export const DELETE_ORGANIZATION = "/organization/delete";
export const ADD_IP = "/white-list-ip/add";
export const ALL_IP = "/white-list-ip/all";
export const UPDATE_IP = "/white-list-ip/update";
export const DELETE_IP = "/white-list-ip/delete";
export const ALL_BISAC = "/bisac/all";
export const ADD_BISAC = "/bisac/add";
export const UPDATE_BISAC = "/bisac/update";
export const DELETE_BISAC = "/bisac/delete";
export const ALL_LOCATION = "/location/all";
export const ADD_LOCATION = "/location/add";
export const UPDATE_LOCATION = "/location/update";
export const DELETE_LOCATION = "/location/delete";
export const ALL_PUBLISH = "/publish-house/all";
export const ADD_PUBLISH = "/publish-house/add";
export const UPDATE_PUBLISH = "/publish-house/update";
export const DELETE_PUBLISH = "/publish-house/delete";
export const ALL_SUPPLIER = "/supplier/all";
export const ADD_SUPPLIER = "/supplier/add";
export const UPDATE_SUPPLIER = "/supplier/update";
export const DELETE_SUPPLIER = "/supplier/delete";
export const ALL_PLATFORM = "/platform/all";
export const ADD_PLATFORM = "/platform/add";
export const UPDATE_PLATFORM = "/platform/update";
export const DELETE_PLATFORM = "/platform/delete";
export const ALL_AWARD = "/award/all";
export const ADD_AWARD = "/award/add";
export const UPDATE_AWARD = "/award/update";
export const DELETE_AWARD = "/award/delete";
export const ALL_CATEGORY = "/category/all";
export const ADD_CATEGORY = "/category/add";
export const UPDATE_CATEGORY = "/category/update";
export const DELETE_CATEGORY = "/category/delete";
export const ALL_LANGUAGE = "/language/all";
export const ADD_LANGUAGE = "/language/add";
export const UPDATE_LANGUAGE = "/language/update";
export const DELETE_LANGUAGE = "/language/delete";
export const ALL_PRODUCT_TYPE = "/product-type/all";
export const ADD_PRODUCT_TYPE = "/product-type/add";
export const UPDATE_PRODUCT_TYPE = "/product-type/update";
export const DELETE_PRODUCT_TYPE = "/product-type/delete";
export const ALL_SUBJECT = "/subject/all";
export const ADD_SUBJECT = "/subject/add";
export const UPDATE_SUBJECT = "/subject/update";
export const DELETE_SUBJECT = "/subject/delete";
//#endregion

//#region Route Path Breadcrumb Name
export const HOME_BREADCRUMB_NAME = "主頁";
export const LOGIN_BREADCRUMB_NAME = "登入";
export const LOGOUT_BREADCRUMB_NAME = "登出";
export const VERIFY_BREADCRUMB_NAME = "驗證";
export const FORGET_BREADCRUMB_NAME = "忘記密碼";
export const ADMINISTRATIVE_BREADCRUMB_NAME = "行政";
export const BOOKMANAGEMENT_BREADCRUMB_NAME = "書籍";
export const BOOK_MANAGEMENT_BREADCRUMB_NAME = "書籍管理";
export const ADD_BOOK_MANAGEMENT_BREADCRUMB_NAME = "新增書籍";
export const ORGANIZATION_BREADCRUMB_NAME = "機構";
export const USER_MANAGEMENT_BREADCRUMB_NAME = "用戶管理";
export const SYSTEMSETTING_BREADCRUMB_NAME = "系統管理";
export const ROLE_MANAGEMENT_BREADCRUMB_NAME = "角色管理";
export const USER_INTERNAL_BREADCRUMB_NAME = "內部";
export const USER_PUBLISHER_BREADCRUMB_NAME = "出版商";
export const USER_SCHOOL_BREADCRUMB_NAME = "機構/學校";
export const ALL_PLATFORM_BREADCRUMB_NAME = "平台管理";
export const ALL_LANGUAGE_BREADCRUMB_NAME = "語言管理";
export const ALL_CATEGORY_BREADCRUMB_NAME = "欄目管理";
export const ALL_PRODUCT_TYPE_BREADCRUMB_NAME = "產品類型管理";
export const ALL_SUPPLIER_BREADCRUMB_NAME = "供應商管理";
export const ALL_PUBLISH_BREADCRUMB_NAME = "出版社管理";
export const ALL_AWARD_BREADCRUMB_NAME = "獲獎名單管理";
//#endregion

//#region Permission Code
export const CODE_NO_NEED_AUTH = "VALID";
export const CODE_LIST_AD = "LIST_AD";
export const CODE_LIST_BOOK = "LIST_BOOK";
export const CODE_LIST_OR = "LIST_OR";
export const CODE_LIST_INTERNAL = "LIST_INTERNAL";
export const CODE_LIST_PUBLISHER = "LIST_PUBLISHER";
export const CODE_LIST_SCHOOL = "LIST_SCHOOL";
export const CODE_LOGIN = "LOGIN";
export const CODE_IP_LOGIN = "IP_LOGIN";
export const CODE_LOGOUT = "LOGOUT";
export const CODE_HOME = "HOME";
export const CODE_GET_USER_LIST = "GET_USER_LIST";
export const CODE_USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const CODE_ADD_USER = "ADD_USER";
export const CODE_DELETE_USER = "DELETE_USER";
export const CODE_UPDATE_USER = "UPDATE_USER";
export const CODE_GET_ROLE_LIST = "GET_ROLE_LIST";
export const CODE_ASSIGN_ROLE = "ASSIGN_ROLE";
export const CODE_UPDATE_ROLE = "UPDATE_ROLE";
export const CODE_ADD_ROLE = "ADD_ROLE";
export const CODE_DELETE_ROLE = "DELETE_ROLE";
export const CODE_IMPORT_BOOKS = "IMPORT_BOOKS";
export const CODE_SELECT_BOOK_FILES = "SELECT_BOOK_FILES";
export const CODE_SELECT_ALL_BOOK = "SELECT_ALL_BOOK";
export const CODE_ADD_BOOK = "ADD_BOOK";
export const CODE_UPDATE_BOOK = "UPDATE_BOOK";
export const CODE_DELETE_BOOK = "DELETE_BOOK";
export const CODE_ACCESS_BOOK_BASIC_INFO = "ACCESS_BOOK_BASIC_INFO";
export const CODE_ACCESS_BOOK_COPYRIGHT_INFO = "ACCESS_BOOK_COPYRIGHT_INFO";
export const CODE_ACCESS_BOOK_LIMIT_INFO = "ACCESS_BOOK_LIMIT_INFO";
export const CODE_ADD_BOOK_BASIC_INFO = "ADD_BOOK_BASIC_INFO";
export const CODE_ADD_BOOK_COPYRIGHT_INFO = "ADD_BOOK_COPYRIGHT_INFO";
export const CODE_ADD_BOOK_LIMIT_INFO = "ADD_BOOK_LIMIT_INFO";
export const CODE_UPDATE_BOOK_BASIC_INFO = "UPDATE_BOOK_BASIC_INFO";
export const CODE_UPDATE_BOOK_COPYRIGHT_INFO = "UPDATE_BOOK_COPYRIGHT_INFO";
export const CODE_UPDATE_BOOK_LIMIT_INFO = "UPDATE_BOOK_LIMIT_INFO";
export const CODE_DELETE_BOOK_BASIC_INFO = "DELETE_BOOK_BASIC_INFO";
export const CODE_DELETE_BOOK_COPYRIGHT_INFO = "DELETE_BOOK_COPYRIGHT_INFO";
export const CODE_DELETE_BOOK_LIMIT_INFO = "DELETE_BOOK_LIMIT_INFO";
export const CODE_DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const CODE_DOWNLOAD_FILE_BASE64 = "DOWNLOAD_FILE_BASE64";
export const CODE_DOWNLOAD_ZIP_FILE = "DOWNLOAD_ZIP_FILE";
export const CODE_DEV_ONLY_RUN_CRON = "DEV_ONLY_RUN_CRON";
export const CODE_ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const CODE_SELECT_ORGANIZATION = "SELECT_ORGANIZATION";
export const CODE_UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const CODE_DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const CODE_ADD_IP = "ADD_IP";
export const CODE_GET_IP = "GET_IP";
export const CODE_DELETE_IP = "DELETE_IP";
export const CODE_ADD_BISAC = "ADD_BISAC";
export const CODE_SELECT_BISAC = "SELECT_BISAC";
export const CODE_UPDATE_BISAC = "UPDATE_BISAC";
export const CODE_DELETE_BISAC = "DELETE_BISAC";
export const CODE_ADD_LOCATION = "ADD_LOCATION";
export const CODE_SELECT_LOCATION = "SELECT_LOCATION";
export const CODE_UPDATE_LOCATION = "UPDATE_LOCATION";
export const CODE_DELETE_LOCATION = "DELETE_LOCATION";
export const CODE_ADD_PUBLISH_HOUSE = "ADD_PUBLISH_HOUSE";
export const CODE_SELECT_PUBLISH_HOUSE = "SELECT_PUBLISH_HOUSE";
export const CODE_UPDATE_PUBLISH_HOUSE = "UPDATE_PUBLISH_HOUSE";
export const CODE_DELETE_PUBLISH_HOUSE = "DELETE_PUBLISH_HOUSE";
export const CODE_ADD_SUPPLIER = "ADD_SUPPLIER";
export const CODE_SELECT_SUPPLIER = "SELECT_SUPPLIER";
export const CODE_UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const CODE_DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const CODE_ADD_PLATFORM = "ADD_PLATFORM";
export const CODE_SELECT_PLATFORM = "SELECT_PLATFORM";
export const CODE_UPDATE_PLATFORM = "UPDATE_PLATFORM";
export const CODE_DELETE_PLATFORM = "DELETE_PLATFORM";
export const CODE_ADD_AWARD = "ADD_AWARD";
export const CODE_SELECT_AWARD = "SELECT_AWARD";
export const CODE_UPDATE_AWARD = "UPDATE_AWARD";
export const CODE_DELETE_AWARD = "DELETE_AWARD";
export const CODE_ADD_CATEGORY = "ADD_CATEGORY";
export const CODE_SELECT_CATEGORY = "SELECT_CATEGORY";
export const CODE_UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const CODE_DELETE_CATEGORY = "DELETE_CATEGORY";
export const CODE_ADD_LANGUAGE = "ADD_LANGUAGE";
export const CODE_SELECT_LANGUAGE = "SELECT_LANGUAGE";
export const CODE_UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const CODE_DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const CODE_ADD_PRODUCT_TYPE = "ADD_PRODUCT_TYPE";
export const CODE_SELECT_PRODUCT_TYPE = "SELECT_PRODUCT_TYPE";
export const CODE_UPDATE_PRODUCT_TYPE = "UPDATE_PRODUCT_TYPE";
export const CODE_DELETE_PRODUCT_TYPE = "DELETE_PRODUCT_TYPE";
//#endregion

//#region Catogary Name
export const CAT_MANAGEMENT = "MANAGEMENT";
export const CAT_BOOK = "BOOK";
export const CAT_BASIC = "BASIC";
//#endregion

//#region Contorl
export const ADD = "add";
export const UPDATE = "update";
export const DELETE = "delete";
//#endregion

//#region session storage
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const USER_INFO = "userInfo";
export const CLIENT_INFO = "clientInfo";
//#endregion

//#region AuthApi
export const EMAIL_SENT = "Email Sent";
export const EMAIL_CHECK = "Please Check email";
//#endregion

//#region Component
export const CARDVIEW = "Card View";
export const LISTVIEW = "List View";
//#endregion

//#region TabListTitle
export const INTERNAL = "SUEP";
export const PUBLISHER = "PUBLISHER";
export const SCHOOL = "SCHOOL";
//#endregion

//#region Button / INPUT / SELECT OPTION
export const STYLETYPE_DEFAULT = "default";
export const STYLETYPE_MAIN = "Main";
export const STYLETYPE_SECOND = "Second";
export const STYLETYPE_THIRD = "Third";
export const STYLETYPE_FOUR = "Four";
export const STYLETYPE_FIFTH = "Fifth";
export const STYLETYPE_SIX = "Six";
export const STYLETYPE_SEVEN = "Seven";
export const STYLETYPE_EIGHT = "Eight";
export const STYLETYPE_NINE = "Nine";
export const STYLETYPE_IMG = "Img";
export const STYLETYPE_CUSTOM = "custom";
export const STYLETYPE_SELECT_FILE = "file";
export const STYLETYPE_DATALIST = "datalist";
export const STYLETYPE_SEARCH_INPUT = "search_input";
export const STYLETYPE_SEARCH_INPUT_OPTION = "search_input_option";
//#endregion

//#region Display Style
export const STYLE_LIST = "list_style";
export const STYLE_CARD = "list_card";
//#endregion

//#region Dialog Action
export const DIALOG_CANCEL = "Cancel";
export const DIALOG_COMPLETE = "Complete";
export const DIALOG_DELETE = "Delete";
//#endregion

//#region File
export const CHUNK_SIZE = 10 * 1024 * 1024;
export const MAX_REQUEST_NUM = 6;
export const MAX_RETRY_NUM = 3;
export const TEL = 35686915;
//#endregion

//#redux
export const ADD_PRODUCT_ITEM = "ADD_PRODUCT_ITEM";
export const DELETE_PRODUCT_ITEM = "DELETE_PRODUCT_ITEM";
export const CLEAR_SHOPPING_CART = "CLEAR_SHOPPING_CART";
export const REFRESH_SHOPPING_CART = "REFRESH_SHOPPING_CART";

export const ADD_COUPON = "ADD_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";

export const SET_MEMBER_INFORMATION = "SET_MEMBER_INFORMATION";
export const SET_TOKEN = "SET_TOKEN";
export const DELETE_MEMBER_INFORMATION = "DELETE_MEMBER_INFORMATION";
export const DELETE_TOKEN = "DELETE_TOKEN";

export const ADD_GUEST_FAVORITE = "ADD_GUEST_FAVORITE";
export const DELETE_GUEST_FAVORITE = "DELETE_GUEST_FAVORITE";
export const REFRESH_GUEST_FAVOURITE = "REFRESH_GUEST_FAVOURITE";

export const SHOW_SHOPPING_CART_MODAL = "SHOW_SHOPPING_CART_MODAL";
export const HIDE_SHOPPING_CART_MODAL = "HIDE_SHOPPING_CART MODAL";

export const SHOW_FAVORITE_MODAL = "SHOW_FAVORITE_MODAL";
export const HIDE_FAVORITE_MODAL = "HIDE_FAVORITE_MODAL";

export const UNLOCK_SHOPPING_CART = "UNLOCK_SHOPPING_CART";
export const LOCK_SHOPPING_CART = "LOCK_SHOPPING_CART";

export const SHOW_CUSTOM_MODAL = "SHOW_CUSTOM_MODAL";
export const HIDE_CUSTOM_MODAL = "HIDE_CUSTOM_MODAL";

export const SET_LOGIN_MODAL_SHOW = "SET_LOGIN_MODAL_SHOW";
export const SET_SIGN_UP_MODAL_SHOW = "SET_SIGN_UP_MODAL_SHOW";


export const LOGIN_RIDIRECT_PATHNAME = "LOGIN_RIDIRECT_PATHNAME"