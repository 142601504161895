// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BookCollection_bookCardContainer__dLcWj {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5em;
  overflow-x: auto;
}

.BookCollection_BookCollectionHeaderContainer__GlQJk {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 0 0 1em 0;
}
.BookCollection_dropDownMore__fMfIv {
  display: flex;
  justify-content: center;
  margin: 1em auto;
  color: #e8703c;
  cursor: pointer;
  gap: 0.3em;
}
.BookCollection_dropDownMore__fMfIv > p {
  font-size: 12px;
  font-weight: 700;
}

.BookCollection_dropDownMore__fMfIv > img {
  width: 12px;
  margin: 3px 0 0 0;
}

.BookCollection_horizontalLine__0US49 {
  width: 100%;
  border-top: 1px solid rgb(198 198 198);
}


@media only screen and (max-width: 1024px) {
  .BookCollection_bookCardContainer__dLcWj {
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1em;
  }
}

@media only screen and (max-width: 768px) {
  .BookCollection_bookCardContainer__dLcWj {
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/BookCollection/BookCollection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,UAAU;AACZ;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,sCAAsC;AACxC;;;AAGA;EACE;IACE,qBAAqB;IACrB,sCAAsC;IACtC,QAAQ;EACV;AACF;;AAEA;EACE;IACE,qBAAqB;IACrB,8BAA8B;IAC9B,QAAQ;EACV;AACF","sourcesContent":[".bookCardContainer {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 5em;\n  overflow-x: auto;\n}\n\n.BookCollectionHeaderContainer {\n  display: flex;\n  align-items: center;\n  gap: 1em;\n  margin: 0 0 1em 0;\n}\n.dropDownMore {\n  display: flex;\n  justify-content: center;\n  margin: 1em auto;\n  color: #e8703c;\n  cursor: pointer;\n  gap: 0.3em;\n}\n.dropDownMore > p {\n  font-size: 12px;\n  font-weight: 700;\n}\n\n.dropDownMore > img {\n  width: 12px;\n  margin: 3px 0 0 0;\n}\n\n.horizontalLine {\n  width: 100%;\n  border-top: 1px solid rgb(198 198 198);\n}\n\n\n@media only screen and (max-width: 1024px) {\n  .bookCardContainer {\n    justify-items: center;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    gap: 1em;\n  }\n}\n\n@media only screen and (max-width: 768px) {\n  .bookCardContainer {\n    justify-items: center;\n    grid-template-columns: 1fr 1fr;\n    gap: 1em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookCardContainer": `BookCollection_bookCardContainer__dLcWj`,
	"BookCollectionHeaderContainer": `BookCollection_BookCollectionHeaderContainer__GlQJk`,
	"dropDownMore": `BookCollection_dropDownMore__fMfIv`,
	"horizontalLine": `BookCollection_horizontalLine__0US49`
};
export default ___CSS_LOADER_EXPORT___;
