import React, { useEffect, useState } from "react";
import style from "./Carousel.module.css";

export const CarouselItem = ({ children, width }: any) => {
  return (
    <div className={style.carouselItem} style={{ width: width }}>
      {children}
    </div>
  );
};

export default function Carousel({ children }: any) {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <div className={style.carousel}>
      <div
        className={style.innerCarousel}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, idx) => {
          return React.cloneElement(child, {
            width: "100%",
          });
        })}
      </div>
      <div className={style.carouselButtonContainer}>
        {React.Children.map(children, (child, idx) => {
          return <button onClick={() => setActiveIndex(idx)}></button>;
        })}
      </div>
    </div>
  );
}
