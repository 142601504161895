// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Category_searchResultContainer__ohBPM {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Category_searchResultInnerContainer__46LzZ {
  width: 1024px;
  min-height: 80vh;
  padding:  0 1em 2em 1em;
  margin-top: 25px;
}

.Category_cardLayout__kssR8 {
  display: flex;
  gap: 3em;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .Category_cardLayout__kssR8{
    gap:0;
    row-gap: 1em;
    justify-content: space-between;
  }

}`, "",{"version":3,"sources":["webpack://./src/layout/Category/Category.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE;IACE,KAAK;IACL,YAAY;IACZ,8BAA8B;EAChC;;AAEF","sourcesContent":[".searchResultContainer {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.searchResultInnerContainer {\n  width: 1024px;\n  min-height: 80vh;\n  padding:  0 1em 2em 1em;\n  margin-top: 25px;\n}\n\n.cardLayout {\n  display: flex;\n  gap: 3em;\n  flex-wrap: wrap;\n}\n\n@media only screen and (max-width: 1024px) {\n  .cardLayout{\n    gap:0;\n    row-gap: 1em;\n    justify-content: space-between;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchResultContainer": `Category_searchResultContainer__ohBPM`,
	"searchResultInnerContainer": `Category_searchResultInnerContainer__46LzZ`,
	"cardLayout": `Category_cardLayout__kssR8`
};
export default ___CSS_LOADER_EXPORT___;
