import React from "react";
import style from "../../layout/Favourite/Favourite.module.css";
import useWindowSize from "../../utils/useWindowSize";

export default function OrderProduct({ product }: any) {
  const { width } = useWindowSize();
  
  let _finalPrice = "";
  if (product && product.finalPrice) {
    _finalPrice = parseFloat(product.finalPrice).toFixed(2);
  }
  console.log("CP1", product);
  return (
    <>
      <div className={style.orderProductDetailHeader}>
        <div style={{ height: "120px" }}>
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/file/${product.product.productImage[0]}`}
          />
        </div>
        {width > 768 ?
          <>
            <div>
              <p>{product.product.ctsId}</p>
            </div>
            <div>
              <p>{product.product.productNameChi}</p>
            </div>
          </>
          :
          <></>
        }
        <div>
          <p>{_finalPrice}</p>
        </div>
      </div>
    </>
  );
}
